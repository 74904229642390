import { NotificaDTO } from "../dtos";
import { BeneficiaryInterface, NotificaInterface } from "../interfaces";
import { BeneficiaryModel } from "./beneficiary.model";

export class NotificaModel implements NotificaInterface {

  protected beneficiario: BeneficiaryInterface;
  protected id: number;
  protected idGratuita: number;
  protected protocollo: string;
  protected statoLavorazione: string;
  protected visualizzato: boolean;
  protected data: string;

  constructor(dto: NotificaDTO) {
    this.id = dto?.idNotifica;
    this.beneficiario = !!dto?.beneficiario ? new BeneficiaryModel(dto?.beneficiario) : null;
    this.idGratuita = dto?.idGratuita;
    this.protocollo = dto?.protocollo;
    this.statoLavorazione = dto?.statoLavorazione;
    this.visualizzato = dto?.visualizzato;
    this.data = dto?.data;
  }
  getNomeBeneficiario(): string {
    const beneficiario = this.getBeneficiario();
    return beneficiario?.getAnagrafica()?.getNome() + ' ' + beneficiario?.getAnagrafica()?.getCognome();
  }

  getBeneficiario(): BeneficiaryInterface {
    return this.beneficiario;
  }



  getId(): number {
    return this.id;
  }
  getIdGratuita(): number {
    return this.idGratuita;
  }
  getData(): string {
    return this.data;
  }
  getProtocollo(): string {
    return this.protocollo;
  }
  getStatoLavorazione(): string {
    return this.statoLavorazione;
  }
  isVisualizzato(): boolean {
    return this.visualizzato;
  }
  getText(): string {
      return 'NOTIFICA.'+ this.getStatoLavorazione().toUpperCase()
  }
}
