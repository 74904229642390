import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { CurrentUserObserver } from 'core-model';
import { AuthFacade, UtenteFacade } from '../facades';

export const authGuard: CanActivateFn = async () => {
  const authFacade: AuthFacade = inject(AuthFacade);
  const utenteFacade: UtenteFacade = inject(UtenteFacade);
  const router: Router = inject(Router);
  const userObserver: CurrentUserObserver = inject(CurrentUserObserver);
  try {
    const utente = await utenteFacade.getUtente();
    authFacade.saveUser(utente);
    userObserver.set(utente);
    if(!!utente && utente?.isAttivo() == false){
      return router.createUrlTree(['area-personale','valida-account']);
    } else {
      return router.createUrlTree(['area-personale']);
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};
