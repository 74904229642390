import { AddressModel, AnagraficaModel, InvaliditaModel } from ".";
import { FamilyDTO } from "../dtos/family.dto";
import { AddressInterface, AnagraficaInterface, FamilyInterface, InvaliditaInterface } from "../interfaces";

export class FamilyModel implements FamilyInterface {
    private anagraficaFamiliare: AnagraficaInterface;
    private datiBeneficiario: InvaliditaInterface; 
    private email: string;
    private indirizzoFamiliare: AddressInterface;
    constructor(dto: FamilyDTO) {
        this.email = dto.email;
        this.anagraficaFamiliare = dto?.anagraficaFamiliare ? new AnagraficaModel(dto.anagraficaFamiliare) : null;
        this.indirizzoFamiliare = dto?.indirizzoFamiliare ? new AddressModel(dto.indirizzoFamiliare) : null;
        this.datiBeneficiario = dto?.datiBeneficiario ? new InvaliditaModel(dto.datiBeneficiario) : null;
    }

    toDTO(): FamilyDTO {
        return {
            email: this.email,
            anagraficaFamiliare: this.anagraficaFamiliare?.toDTO(),
            indirizzoFamiliare: this.indirizzoFamiliare?.toDTO(),
            datiBeneficiario: this.datiBeneficiario?.toDTO()
        }
    }
}