import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take } from "rxjs";
import { AppIoDTO } from "../dtos";

@Injectable({
  providedIn: 'root'
})
export class AppIoService {
  private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }

  /**
   * Request to send the validation code on AppIo.
   *
   * @return { Promise<AppIoDTO> }
   */
  requestValidationCode(): Promise<AppIoDTO> {
    return new Promise<any>(
      (resolve, reject) => {
        this.http.post(this.url_endpoint + api.appio_aggiungi, {}, { observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: HttpResponse<any>) => {
              if (!!resp?.body) {
                resolve(resp?.body);
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  /**
   * Rinnovo dell'attivazione dell'APPIO.
   *
   * @param { string } id -
   * @return { Promise<any> }
   */
  renew(id: string): Promise<any> {
    return new Promise<any>(
      (resolve, reject) => {
        this.http.post(this.url_endpoint + api.appio_rinnova_attivazione, {}, { params: { id: id }, observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: HttpResponse<any>) => {
              if (!!resp?.body) {
                resolve(resp?.body);
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  /**
   * Valida appIo utilizzando il codice fornito.
   *
   * @param { string } code - Il codice inviato su AppIo.
   * @return { Promise<string> }
   */
  valid(code: string): Promise<AppIoDTO> {
    return new Promise<AppIoDTO>(
      (resolve, reject) => {
        const data = {
          code: code
        }
        this.http.put(this.url_endpoint + api.appio_validazione, {}, { params: data, observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              if (!!resp?.body) {
                resolve(resp?.body);
              } else {
                reject({
                  status: 0,
                  error: resp?.message
                })
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }
}
