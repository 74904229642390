import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OperatoreInterface } from '../interfaces';

@Injectable()
export class CurrentOperatorObserver {
    protected userLogged: OperatoreInterface;
    protected utente$: BehaviorSubject<OperatoreInterface>;

    constructor() {
        this.userLogged = null;
        this.utente$ = new BehaviorSubject<OperatoreInterface>(this.userLogged);
    }

    public get(): OperatoreInterface {
        return this.utente$.value;
    }

    public on(): Observable<OperatoreInterface> {
        return this.utente$.asObservable();
    }

    public set(userLogged: OperatoreInterface) {
        this.userLogged = userLogged;
        this.utente$.next(this.userLogged);
    }
  }