import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take } from "rxjs";
import { EnteDTO } from "../dtos";

@Injectable({
    providedIn: 'root'
})
export class EnteService {

      private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }

    /**
      * Ottiene la lista degli enti finanziatori
      *
      * @return { Promise<EnteDTO[]> }
      */
    getAll(): Promise<EnteDTO[]> {
        return new Promise<EnteDTO[]>(
            (resolve, reject) => {
                this.http.get(this.url_endpoint + api.ente_lista, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }
}
