import { Component, OnInit } from '@angular/core';
import { AuthFacade, NotificaFacade } from '../shared/facades';
import { ConteggioNotificheObserver } from 'core-model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private authFacade: AuthFacade,
    private notificaFacade: NotificaFacade,
    private notificationObserver: ConteggioNotificheObserver
  ) {}

  ngOnInit() {
    this.init();
  }

  private async init(){
    const utente = await this.authFacade.checkAndRestoreCurrentUser();
    try {
      if(!!utente && utente?.isAttivo()){
        this.countNotifiche();
      }
    } catch (error) {
      console.error(error);
    }
  }

  private async countNotifiche(){
    const notitifiche = await this.notificaFacade.getNotifiche();
    this.notificationObserver.set(notitifiche);
  }
}
