<section aria-labelledby="title1" role="region">


    <h1 id="title1">Login</h1>

    <form [formGroup]="form">
        <div class="row">
            <div class="col col-sm-12 col-md-6">
                <label for="codiceFiscale" class="h6 text-primary" translate>
                    FORM.CODICE_FISCALE
                </label>
                <div class="form-group">
                    <input type="text" class="form-control" formControlName="codiceFiscale" id="codiceFiscale" placeholder="{{'FORM.CODICE_FISCALE' | translate}}">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col col-sm-12 col-md-6">
                <label for="email" class="h6 text-primary" translate>
                    FORM.PASSWORD
                </label>
                <div class="form-group">
                    <input type="password" class="form-control" formControlName="password"id="password" aria-describedby="password" placeholder="Password">
                </div>
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col col-md-4">
            <ng-container *ngIf="form?.valid">
                <a translate (click)="login()" class="btn btn-primary btn-icon btn-full">
                    <svg class="rounded-circle bg-white text-white icon align-middle me-2" aria-hidden="true">
                        <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-user"></use>
                    </svg>FORM.LOGIN</a>
            </ng-container>
            <ng-container *ngIf="!form?.valid">
                <button translate class="btn btn-outline-secondary btn-icon btn-full disabled">
                    <svg class="rounded-circle bg-white text-white icon align-middle me-2" aria-hidden="true">
                        <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-user"></use>
                    </svg>FORM.LOGIN
                </button>
            </ng-container>
        </div>
    </div>
</section>
