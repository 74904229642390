import { Component } from '@angular/core';

@Component({
  selector: 'app-errore-generico',
  templateUrl: './errore-generico.component.html',
  styleUrls: ['./errore-generico.component.scss']
})
export class ErroreGenericoComponent {

}
