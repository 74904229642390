import { UtenteDTO } from "../dtos";
import { AnagraficaInterface, RuoloInterface } from "../interfaces";
import { OperatoreInterface } from "../interfaces/operatore.interface";
import { AnagraficaModel } from "./anagrafica.model";
import { RuoloModel } from "./ruolo.model";

export class OperatoreModel implements OperatoreInterface {
    protected abilitato: boolean;
    protected attivo: boolean;
    protected cognome: string;
    protected identificativo: string;
    protected nome: string;
    protected ruoli: Array<RuoloInterface>;
    protected superuser: boolean;
    protected anagrafica: AnagraficaInterface;
    protected configurazioneBudgetAnnualeInScadenza: boolean;
    protected configurazioneBudgetIndividualeInScadenza: boolean;
    protected configurazioneBudgetMensileInScadenza: boolean;
    protected riscontroINPSErrato: boolean;

    constructor(dto: UtenteDTO) {
        this.abilitato = dto?.abilitato;
        this.attivo = dto?.attivo;
        this.anagrafica = !!dto?.anagrafica ? new AnagraficaModel(dto?.anagrafica) : null;
        this.cognome = dto?.anagrafica?.cognome;
        this.identificativo = dto?.identificativo;
        this.nome = dto?.anagrafica?.nome;
        this.ruoli = dto?.ruoli?.map((dto) => new RuoloModel(dto));
        this.superuser = dto?.superuser;
        this.configurazioneBudgetAnnualeInScadenza = dto?.configurazioneBudgetAnnualeInScadenza;
        this.configurazioneBudgetIndividualeInScadenza = dto?.configurazioneBudgetIndividualeInScadenza;
        this.configurazioneBudgetMensileInScadenza = dto?.configurazioneBudgetMensileInScadenza;
        this.riscontroINPSErrato = dto?.riscontroINPSErrato;

    }


    getAliasRuoli(): string[] {
        return this.ruoli?.map((ruolo) => ruolo.getAlias())
    }
    getRuoli(): RuoloInterface[] {
      return this.ruoli;
    }
    getCognome(): string {
        return this.cognome;
    }
    getCodiceFiscale(): string {
      return this.anagrafica.getCodiceFiscale();
    }
    getId(): string {
        return this.identificativo;
    }
    getNome(): string {
        return this.nome;
    }
    getNominativo(): string {
      return this.getNome() + ' ' + this.getCognome();
    }

    enabledBackOffice(): boolean {
      return (this.isManager() || this.isOperatore());
    }

    isAbilitato(): boolean {
        return this.abilitato == true && this.attivo == true;
    }
    isAttivo(): boolean {
      return this.attivo
    }
    isEditor(): boolean {
      return this.ruoli?.some((ruolo) => (ruolo.isEditor() && ruolo?.isAttivo()));
    }
    isManager(): boolean {
        return this.ruoli?.some((ruolo) => (ruolo.isManager() && ruolo?.isAttivo()));
    }
    isOperatore(): boolean {
      return this.ruoli?.some((ruolo) => (ruolo.isOperatore() && ruolo?.isAttivo()));
    }
    isSuperUser(): boolean {
        return this.superuser;
    }
    getRiscontroINPSErrato(): boolean {
        return this.riscontroINPSErrato;
    }
    getConfigurazioneBudgetAnnualeInScadenza(): boolean {
        return this.configurazioneBudgetAnnualeInScadenza;
    }
    getConfigurazioneBudgetIndividualeInScadenza(): boolean {
        return this.configurazioneBudgetIndividualeInScadenza;
    }
    getConfigurazioneBudgetMensileInScadenza(): boolean {
        return this.configurazioneBudgetMensileInScadenza;
    }
}
