import { ANALISI_SPESE_TYPE } from "../constants/analisi-spese.enum";
import { AnalisiSpeseDTO } from "../dtos";
import { AnalisiSpeseInterface, GraficiAnalisiSpeseInterface } from "../interfaces";
import { GraficiAnalisiSpeseModel } from "./grafici-analisi-spese.model";

export class AnalisiSpeseModel implements AnalisiSpeseInterface {
    protected fatturatoTotale: number;
    protected fatturatoLiquidato: number;
    protected graficiFatture: GraficiAnalisiSpeseInterface[]

    constructor(dto?: AnalisiSpeseDTO) {
        this.fatturatoTotale = dto?.fatturatoTotale;
        this.fatturatoLiquidato = dto?.fatturatoLiquidato;
        this.graficiFatture = dto.graficiFatture.map((grafico) => new GraficiAnalisiSpeseModel(grafico));
    }
    getGrafico(type: ANALISI_SPESE_TYPE): GraficiAnalisiSpeseInterface {
        return this.getGrafici().find(g => g.getSeries().find(s => s.getName() == type));
    }
    getGrafici(): GraficiAnalisiSpeseInterface[] {
        return this.graficiFatture;
    }
    getFatturatoTotale(): number {
        return this.fatturatoTotale;
    }
    getFatturatoLiquidato(): number {
        return this.fatturatoLiquidato;
    }
}
