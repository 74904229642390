import { Injectable } from '@angular/core';
import { ContactService } from '../../../services';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorFacade } from '../../news/facades';

@Injectable()
export class ContactFacade {

  constructor(private contactService: ContactService,
              private spinner: NgxSpinnerService,
              private errorFacade: ErrorFacade) { }

  async invia(data: any): Promise<boolean> {
    try {
      this.spinner.show();
      const dto = await this.contactService.invia(data);
      return dto;
  } catch (error) {
      this.errorFacade.handle(error, null);
      throw null;
  } finally {
      this.spinner.hide();
  }
  }
}
