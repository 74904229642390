import { Component, Input, OnInit } from '@angular/core';
import { Newsfacade } from '../../facades/news.facade';
import { NewsModel } from '../../../../models';

@Component({
  selector: 'app-carousel-news',
  templateUrl: './carousel-news.component.html',
  styleUrls: ['./carousel-news.component.scss']
})
export class CarouselNewsComponent implements OnInit {
  @Input() news: NewsModel;
  constructor(private newsFacade: Newsfacade) { }

  ngOnInit(): void {
    this.newsFacade.ottieniImmaginiCarousel(this.news.getId()).then((immagini: any) => {
      this.news.setImmagini(immagini);
      console.log(this.news.getImmagini())
    });
  }
}
