import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificaInterface } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ConteggioNotificheObserver {
  private notifiche: Array<NotificaInterface> = [];
  protected subject$: BehaviorSubject<Array<NotificaInterface>>;
  constructor() {
    this.subject$ = new BehaviorSubject<Array<NotificaInterface>>(this.notifiche);
  }

  public set(notifiche: Array<NotificaInterface>) {
    this.notifiche = notifiche;
    this.subject$.next(this.notifiche);
  }

  public get():Array<NotificaInterface> {
    return this.notifiche;
  }

  public on(): Observable<Array<NotificaInterface>> {
    return this.subject$.asObservable();
  }
}
