import { Injectable } from '@angular/core';
import { SuperadminService } from '../../../services';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorObserverService } from '../../../singletons';

@Injectable()
export class AdminLoginFacade {

  constructor(private superAdminService: SuperadminService,
              private spinner: NgxSpinnerService,
              private errorObserver: ErrorObserverService) { }

    /**
   * Accesso Super User
   *
   * @param {string} passsword - The password for authentication.
   * @param {string} identificatore - The identifier for authentication.
   * @return {Promise<any>}
   */
    public async accessoAdmin(passsword: string, identificatore: string): Promise<string> {
      try {
        this.spinner.show();
        const dto = await this.superAdminService.accesso(passsword, identificatore);
        return dto;
      } catch (error) {
        this.errorObserver.send({ name: 'MAIN', title: 'Login', message: error?.error });
      } finally {
        this.spinner.hide();
      }
      return null;
    }
}
