import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take } from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class RinunciaService {

      private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }

    /**
     * Rinuncia
     *
     * @param { number } idGratuita
     * @param { string } noteRinuncia
     * @return { Promise<any> }
     */
    rinuncia(idGratuita: number, noteRinuncia: string): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                const dto = {
                    idGratuita: idGratuita,
                    noteRinuncia: noteRinuncia
                };
                this.http.put(this.url_endpoint + api.rinuncia_ativa, dto, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }
}
