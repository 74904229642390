import { inject } from '@angular/core';
import { ActivatedRoute, ResolveFn } from '@angular/router';
import { ShowCasePageEnum } from '../../constants';
import { ShowcasePageInterface } from '../../interfaces';
import { ShowcasePageFacade } from './facades/showcase-page.facade';

export const homePageResolver: ResolveFn<ShowcasePageInterface> = async (route, state) => {
  const data: { [key: string]: ShowcasePageInterface[] } = inject(ActivatedRoute).snapshot.data;
  const showcasePageType = ShowCasePageEnum[state.url];

  if(data['showcasePage']?.filter((p) => p.getNavbarLabel() == showcasePageType)?.length > 0) {
    const showcasePageFacade = inject(ShowcasePageFacade);
    const showcasePageType = ShowCasePageEnum[state.url];
    return await showcasePageFacade.ottieni(data['showcasePage'].find((p) => p.getNavbarLabel() == showcasePageType)?.getId()!);
  } else {
    return null;
  }
};


