import { CurrentOperatorObserver } from './../../observers/current-operator.observer';
import { Component, OnInit } from '@angular/core';
import { Newsfacade } from './facades';
import { OperatoreInterface, PaginationInterface } from '../../interfaces';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  public page = 1;
  public pageSize = 10;
  public newsList: PaginationInterface = null;
  public isManager: boolean = true;

  public currentOperator: OperatoreInterface;
  public currentOperator$: Subscription;

  constructor(private newsFacade: Newsfacade,
              private currentOperatorObserver: CurrentOperatorObserver
  ) { }

  async ngOnInit(): Promise<void> {
    this.currentOperator$ = this.currentOperatorObserver.on().subscribe((currentOperator) => {
      this.currentOperator = currentOperator;
      this.pageChange();

    });
  }

  async pageChange() {
    const page = this.page;
    this.page = page;

    if(this.currentOperator && (this.currentOperator.isEditor() || this.currentOperator.isManager() || this.currentOperator.isSuperUser())) {
      this.newsList = await this.newsFacade.listaEditor(this.page - 1, this.pageSize);
    }
    else {
      this.newsList = await this.newsFacade.lista(this.page - 1, this.pageSize);
    }
  }

}
