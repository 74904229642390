import { InvaliditaInterface } from "../interfaces";
import { DatiIndennitaBeneficiarioDTO } from "../dtos";
import { DatePipe } from '@angular/common';

export class InvaliditaModel implements InvaliditaInterface {
    dataCertificazioneInvalidita: Date;
    disabilityCategory: string[];
    disabilityDegree: string[];
    disabilityRequirement: string[];

    constructor(dto:DatiIndennitaBeneficiarioDTO) {
        this.dataCertificazioneInvalidita = dto?.dataCertificazioneInvalidita ? new Date(dto?.dataCertificazioneInvalidita):null;
        this.disabilityCategory = dto?.disabilityCategory;
        this.disabilityDegree = dto?.disabilityDegree;
        this.disabilityRequirement = dto?.disabilityRequirement;
    }
    
    getAllRequisitito(): string[] {
        return this.disabilityRequirement;
    }

    getCategoria(): string {
        return this.disabilityCategory?.at(0);
    }

    getDataCertificazione(): Date {
        return this.dataCertificazioneInvalidita;
    }
    getGrado(): string {
        return this.disabilityDegree.at(0);
    }

    toDTO():DatiIndennitaBeneficiarioDTO {
        const datepipe: DatePipe = new DatePipe('en-US');
        return {
            dataCertificazioneInvalidita: datepipe.transform(this.dataCertificazioneInvalidita, 'yyyy-MM-dd'),
            disabilityCategory: this.disabilityCategory,
            disabilityDegree: this.disabilityDegree,
            disabilityRequirement: this.disabilityRequirement
        }
    }
}