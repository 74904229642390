<section role="region" class="h-content">
    <div class="container row w-full">
        <div class="col col-md-7 col-lg-8">
            <ng-container *ngIf="!!traduzioneVetrina?.html">
                <quill-view-html [content]="traduzioneVetrina?.html"></quill-view-html>
            </ng-container>
        </div>
        <div class="col col-md-5 col-lg-4">
            <lib-button-login></lib-button-login>
        </div>
    </div>
    <lib-carousel></lib-carousel>
</section>
