import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsRoutingModule } from './news-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NewsComponent } from './news.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { CardNewsModule } from './module';

@NgModule({
  declarations: [
    NewsComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NewsRoutingModule,
    CardNewsModule,
    NgbPaginationModule
  ]
})
export class NewsModule { }
