import { ImageNewsDTO, NewsDTO, TagsDTO, TraduzioniNewsDTO } from "../dtos";
import { NewsInterface, TraduzioniNewsInterface } from "../interfaces";
import { LinguaTraduzioneDTO } from "../dtos/lingua-traduzione.dto";

export class NewsModel implements NewsInterface {
    private id: number;
    private traduzioniNews: TraduzioniNewsModel[];
    private dataInizioVisualizzazione: Date | null;
    private dataScadenza: Date | null;
    private immagini: ImageNewsDTO[] | null;
    private tagsNews: TagsDTO[] | null;
    private copertina: ImageNewsDTO | null;

    constructor(dto: NewsDTO) {
        this.id = dto.id;
        this.traduzioniNews = dto.traduzioniNews.map((traduzione) => new TraduzioniNewsModel(traduzione));
        this.dataInizioVisualizzazione = dto.dataInizioVisualizzazione ? new Date(dto.dataInizioVisualizzazione) : null;
        this.dataScadenza = dto.dataScadenza ? new Date(dto.dataScadenza) : null;
        this.immagini = dto?.immagini ? dto.immagini : null;
        this.copertina = dto?.copertina ? dto.copertina : null;
        this.tagsNews = dto.tagsNews ? dto.tagsNews?.map((t) => t.tag) : null;
    }

    hasCopertina(): boolean {
        return this.copertina ? true : false
    }

    getTraduzioniNews(): TraduzioniNewsModel[] {
        return this.traduzioniNews
    }

    getTraduzioneByLingua(lingua: string): TraduzioniNewsModel | null {
        const traduzioneNews = this.traduzioniNews.find((traduzione) => traduzione.linguaTraduzione?.lingua === lingua);

        if (traduzioneNews) {
            return traduzioneNews;
        }
        return null;
    }

    getId(): number {
        return this.id;
    }

    public getCopertina(): ImageNewsDTO | null {
        return this.copertina;
    }

    public setCopertina(image: ImageNewsDTO) {
        this.copertina = image;
    }

    public getImmagini(): ImageNewsDTO[] | null {
        return this.immagini ? this.immagini : null
    }

    public setImmagini(immagini: ImageNewsDTO[]) {
        this.immagini = immagini
    }

    getDataDiScadenza(): Date | null {
        return this.dataScadenza ? this.dataScadenza : null
    }

    public getDataInizioVisualizzazione(): Date | null {
        return this.dataInizioVisualizzazione ? this.dataInizioVisualizzazione : null
    }

    getTagsNews(): TagsDTO[] | null {
        return this.tagsNews ? this.tagsNews : null
    }

    toString(): NewsDTO {
        return {
            id: this.id,
            traduzioniNews: this.traduzioniNews,
            dataInizioVisualizzazione: this.dataInizioVisualizzazione?.toISOString() ? this.dataInizioVisualizzazione?.toISOString() : null,
            dataScadenza: this.dataScadenza?.toISOString() ? this.dataScadenza?.toISOString() : null,
            immagini: this.getImmagini(),
            copertina: this.getCopertina(),
            tags: this.getTagsNews(),
        }
    }
}

export class TraduzioniNewsModel implements TraduzioniNewsInterface {
    titolo: string;
    html: string;
    descrizione: string;
    linguaTraduzione: LinguaTraduzioneDTO | null;

    constructor(dto: TraduzioniNewsDTO) {
        this.titolo = dto.titolo;
        this.html = dto.html;
        this.descrizione = dto.descrizione;
        this.linguaTraduzione = dto.linguaTraduzione ? dto.linguaTraduzione : null;
    }

    isEmpty(): boolean {
        return (this.getHtml() == null || this.getHtml()?.length <= 0) && this.getTitolo()?.length <= 0 && this.getDescrizione()?.length <= 0
    }

    getTitolo(): string {
        return this.titolo;
    }

    getDescrizione(): string {
        return this.descrizione;
    }

    getHtml(): string {
        return this.html;
    }

    getLingua(): string | null {
        return this.linguaTraduzione?.lingua ? this.linguaTraduzione.lingua : null;
    }
}
