<label for="tags" class="h6 text-primary">{{'FORM.SELECT_LANGUAGE' | translate}}</label>
<div class="nav-item dropdown" ngbDropdown>
    <a role="button" class="nav-link dropdown-toggle d-flex align-items-center" id="dropdownLang"
        href="javascript: void(0)" data-bs-toggle="dropdown" ngbDropdownToggle>
        <span class="visually-hidden">{{ 'ACCESSIBILITA.SELEZIONE_LINGUA' | translate }} : {{
            'ACCESSIBILITA.LINGUA_SELEZIONATA' | translate }}</span>
        <span>{{ ('LINGUA.'+lang.toUpperCase()) | translate }} {{ lang == 'it' ? ' *' : ''}}</span>
        <svg class="icon d-none d-lg-block">
            <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-expand"></use>
        </svg>
    </a>
    <div class="dropdown-menu" aria-labelledby="dropdownLang" ngbDropdownMenu>
        <div class="row">
            <div class="col-12">
                <div class="link-list-wrapper">
                    <ul class="link-list">
                        <li><a class="dropdown-item list-item" href="javascript: void(0)"
                                (click)="onSelect('it')"><span>Italiano *<span class="visually-hidden"
                                        *ngIf="lang == 'it'">{{ 'ACCESSIBILITA.LINGUA_SELEZIONATA' | translate
                                        }}</span></span></a></li>
                        <li><a class="dropdown-item list-item" href="javascript: void(0)"
                                (click)="onSelect('en')"><span>Inglese<span class="visually-hidden"
                                        *ngIf="lang == 'en'">{{ 'ACCESSIBILITA.LINGUA_SELEZIONATA' | translate
                                        }}</span></span></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
