import { CategoriaDisabilitaDTO } from "../dtos";
import { CategoriaInteraface } from "../interfaces";
import { GradoDisabilitaModel } from "./grado-disabilita.model";
import { RequisitoDisabilitaModel } from "./requisito-disabilita.model";

export class CategoriaDisabilitaModel implements CategoriaInteraface {
    protected id: number;
    protected attivo: boolean;
    private codice: string;
    protected daAttivareConfigurazione: boolean;
    protected attivo_configurazione: boolean;
    protected gradiStorico: Array<GradoDisabilitaModel>;
    protected requisitiStorico: Array<RequisitoDisabilitaModel>;
    private descrizione: string;
    protected gradiInvaliditaBeneficiario: Array<GradoDisabilitaModel>;
    protected requisitiInvaliditaBeneficiario: Array<RequisitoDisabilitaModel>;
    constructor(dto?: CategoriaDisabilitaDTO) {
      if(dto){
        this.id = dto?.id;
        this.attivo = dto?.attivo;
        this.codice = dto?.codice;
        this.descrizione = dto?.listaTraduzioniDescrizione?.at(0).descrizione;
        this.attivo_configurazione = dto?.attivoConfigurazione;
        this.daAttivareConfigurazione = dto?.daAttivareConfigurazione;
        this.gradiStorico = dto?.gradiStorico?.map((grado) => new GradoDisabilitaModel(grado));
        this.requisitiStorico = dto?.requisitiStorico?.map((requisito) => new RequisitoDisabilitaModel(requisito));
        this.gradiInvaliditaBeneficiario = dto?.gradiInvaliditaBeneficiario?.map((grado) => new GradoDisabilitaModel(grado));
        this.requisitiInvaliditaBeneficiario = dto?.requisitiInvaliditaBeneficiario?.map((requisito) => new RequisitoDisabilitaModel(requisito));
      }
    }
  isAttivo(): boolean {
    return this.attivo;
  }
  getGradiInvaliditaStorico(): GradoDisabilitaModel[] {
    return this.gradiStorico;
  }
  getRequisitiInvaliditaStorico(): RequisitoDisabilitaModel[] {
    return this.requisitiStorico;
  }
  isAttivoConfigurazione(): boolean {
    return this.attivo_configurazione;
  }
  isDaAttivareConfigurazione(): boolean {
    return this.daAttivareConfigurazione;
  }
    getCodice(): string {
        return this.codice;
    }

    getID(): number {
        return this.id;
    }

    getDescrizione(): string {
        return this.descrizione;
    }

    getGradiInvaliditaBeneficiario(): Array<GradoDisabilitaModel> {
        return this.gradiInvaliditaBeneficiario;
    }

    getRequisitiInvaliditaBeneficiario(): Array<RequisitoDisabilitaModel> {
        return this.requisitiInvaliditaBeneficiario;
    }

    setGradiInvaliditaBeneficiario(gradi: Array<GradoDisabilitaModel>) {
        this.gradiInvaliditaBeneficiario = gradi;
    }

    setRequisitiInvaliditaBeneficiario(requisiti: Array<RequisitoDisabilitaModel>) {
        this.requisitiInvaliditaBeneficiario = requisiti;
    }

}
