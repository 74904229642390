import { AnnualitaConfigurazioneBudgetProvinceDTO } from "../dtos";
import { AnnualitaConfigurazioneBudgetProvinceInterface } from "../interfaces";

export class AnnualitaConfigurazioneBudgetProvinceModel implements AnnualitaConfigurazioneBudgetProvinceInterface {
    protected dataInizioConfigurazione: string;
    protected dataFineConfigurazione: string;
    constructor(dto: AnnualitaConfigurazioneBudgetProvinceDTO) {
        this.dataInizioConfigurazione = dto?.dataInizioConfigurazione;
        this.dataFineConfigurazione = dto?.dataFineConfigurazione;
    }
    
    getStartDate(): string {
        return this.dataInizioConfigurazione;
    }

    getEndDate(): string {
        return this.dataFineConfigurazione;
    }

    getYear():number {
        const startDate = new Date(this.dataInizioConfigurazione);
        return startDate.getFullYear()
    }

    isGreaterThan(annualita: AnnualitaConfigurazioneBudgetProvinceInterface): boolean {
        const startDate = new Date(this.dataFineConfigurazione);
        const annualitaDate = new Date(annualita.getStartDate());
        return startDate.getTime() > annualitaDate.getTime();
    }
}