<div class="it-header-slim-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-12 pe-0 ">
        <div class="it-header-slim-wrapper-content">
          <a class="d-none d-lg-block navbar-brand" tabindex="0" href="https://www.regione.puglia.it/web/territorio-mobilita-e-infrastrutture" target="_blank">
            <span class="visually-hidden">{{ 'BRAND.SUBTITLE' | translate }} {{ 'BRAND.DIPARTIMENTO' | translate }}: </span>{{ 'GENERAL.ENTE.NOME' | translate }}
          </a>
          <div class="nav-mobile">
            <nav tabindex="0" [attr.aria-label]="'ACCESSIBILITA.NAVIGAZIONE_ACCESSORIA' | translate">
              <a class="it-opener d-lg-none" href="https://www.regione.puglia.it/web/territorio-mobilita-e-infrastrutture" target="_blank">
                <span>{{ 'GENERAL.ENTE.NOME' | translate }}</span>
                <!-- <svg class="icon" aria-hidden="true">
                  <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-expand"></use>
                </svg> -->
              </a>
            </nav>
          </div>
          <div class="it-header-slim-right-zone" tabindex="0">
            <div class="nav-item dropdown" ngbDropdown>
              <a role="button" class="nav-link dropdown-toggle" id="dropdownLang" data-bs-toggle="dropdown" ngbDropdownToggle>
                <span class="visually-hidden" translate>ACCESSIBILITA.LINGUA_SELEZIONATA</span>
                <span>{{ ('LINGUA.' + lang.toUpperCase()) | translate }}</span>
                <svg class="icon d-none d-lg-block">
                  <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-expand"></use>
                </svg>
              </a>
              <div class="dropdown-menu" aria-labelledby="dropdownLang" ngbDropdownMenu>
                <div class="row">
                  <div class="col-12">
                    <div class="link-list-wrapper">
                      <ul class="link-list">
                        <li><a role="button" class="dropdown-item list-item" (click)="onSelect('it')">
                          <span class="visually-hidden me-1" *ngIf="lang == 'it'" translate>ACCESSIBILITA.SELEZIONE_LINGUA</span>ITA</a>
                        </li>
                        <li><a role="button" class="dropdown-item list-item" (click)="onSelect('en')">
                          <span class="visually-hidden me-1" *ngIf="lang == 'en'" translate>ACCESSIBILITA.SELEZIONE_LINGUA</span>ENG</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="it-access-top-wrapper pe-0">
              <!--utente non loggato-->
              <!-- <a [href]="loginUrl" class="btn btn-primary btn-icon btn-full" *ngIf="currentUser == null">
                <svg class="rounded-circle bg-white text-white icon align-middle me-2" aria-hidden="true">
                  <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-user"></use>
                </svg>
                {{ 'NAVIGATION.ACCEDI_AREA_PERSONALE' | translate | uppercase }}
              </a> -->
              <a [routerLink]="['/fake-login']" class="btn btn-primary btn-icon btn-full" *ngIf="currentUser == null && environment?.production == false">
                <svg class="rounded-circle bg-white text-white icon align-middle me-2" aria-hidden="true">
                  <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-user"></use>
                </svg>
                {{ 'NAVIGATION.ACCEDI_AREA_PERSONALE' | translate | uppercase }}
              </a>
              <a [href]="'https://sgc.myzerodddevdomain.it/accessoServizi'" class="btn btn-primary btn-icon btn-full" *ngIf="currentUser == null && environment?.production == true">
                <svg class="rounded-circle bg-white text-white icon align-middle me-2" aria-hidden="true">
                  <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-user"></use>
                </svg>
                {{ 'NAVIGATION.ACCEDI_AREA_PERSONALE' | translate | uppercase }}
              </a>
              <!-- utente loggato-->
              <div *ngIf="!!currentUser" class="nav-item" ngbDropdown>
                <a role="button" class="btn btn-primary btn-icon btn-full" tabindex="0" id="dropdownMenuProfile"
                  title="{{ 'ACCESSIBILITA.NOME_UTENTE' | translate }}: {{(currentUser?.getAnagrafica()?.getNome() + ' ' + currentUser?.getAnagrafica()?.getCognome())}}" ngbDropdownToggle>
                  <svg class="rounded-circle bg-white text-white icon align-middle me-2" aria-hidden="true">
                    <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-user"></use>
                  </svg>

                  <span *ngIf="notifiche?.length == 1"  class="visually-hidden" translate>ACCESSIBILITA.NUMERO_SINGOLA_NOTIFICA</span>
                  <span *ngIf="notifiche?.length > 1"  class="visually-hidden" translate [translateParams]="{ count: notifiche?.length}">ACCESSIBILITA.NUMERO_MULTI_NOTITIFCA</span>

                  <span *ngIf="notifiche?.length>0" class=" text-decoration-none badge bg-secondary">{{notifiche?.length}}</span>

                  <span class="ms-1">
                    {{ (currentUser?.getAnagrafica()?.getNome() + ' ' + currentUser?.getAnagrafica()?.getCognome()) | titlecase }}
                  </span>
                  <svg class="icon icon-white d-none d-lg-block ms-3">
                    <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-expand"></use>
                  </svg>
                </a>
                <!--menu dropdown-->
                <div ngbDropdownMenu aria-labelledby="dropdownMenuProfile">
                  <span id="dropdownNavigationProfile" class="visually-hidden"> {{ 'ACCESSIBILITA.MENU_PROFILO' | translate }}</span>
                  <div class="row">
                    <div class="col-12">
                      <div class="link-list-wrapper">
                        <ul class="link-list">
                          <!--Dashboard-->
                          <li *ngIf="currentUser?.isAttivo()">
                            <a class="dropdown-item list-item" [routerLink]="['/','area-personale', 'profilo']" ngbDropdownItem>
                              <span class="ps-2 position-relative top-2p fw-500 text-fourth" translate>NAVIGATION.PROFILO</span>
                            </a>
                          </li>
                          <li *ngIf="currentUser?.isAttivo()">
                            <a class="dropdown-item list-item" [routerLink]="['/','area-personale', 'notifiche']" ngbDropdownItem>
                              <span class="ps-2 position-relative top-2p fw-500 text-fourth"  translate>NAVIGATION.NOTIFICHE</span>
                              <span *ngIf="notifiche?.length>0" class="text-decoration-none badge ps-2 pe-2 bg-secondary text-white">{{notifiche?.length}}</span>
                            </a>
                          </li>
                          <li *ngIf="currentUser?.isAttivo()">
                            <a class="dropdown-item list-item" [routerLink]="['/','area-personale', 'richiesta-gratuita']" ngbDropdownItem>
                              <span class="ps-2 position-relative top-2p fw-500 text-fourth" translate>NAVIGATION.NUOVA_GRATUITA</span>
                            </a>
                          </li>
                          <li *ngIf="currentUser?.isAttivo() && currentUser?.enabledBackOffice()">
                            <a (click)="goBackOffice()"  class="dropdown-item list-item" href="javascript: void(0)" ngbDropdownItem>
                              <span class="ps-2 position-relative top-2p fw-500 text-fourth">
                                {{'NAVIGATION.BACKOFFICE' | translate}}
                              </span>
                            </a>
                          </li>
                          <li><span class="divider"></span></li>
                          <li>
                            <a (click)="logout()" class="dropdown-item list-item" ngbDropdownItem>
                              <svg class="icon icon-md icon-primary" aria-hidden="true">
                                <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-logout"></use>
                              </svg>
                              <span class="ps-2 position-relative top-2p fw-500 text-fourth">{{ 'NAVIGATION.EXIT' | translate }}
                                <span class="visually-hidden" *ngIf="lang == 'it'">{{ 'NAVIGATION.EXIT' | translate }}</span>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
