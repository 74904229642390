import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatatableComponent } from './datatable.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SplitKeyPipe } from '../../pipes';



@NgModule({
  declarations: [
    DatatableComponent,
    SplitKeyPipe
  ],
  exports: [
    DatatableComponent
  ],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    RouterModule,
    TranslateModule
  ]
})
export class DatatableModule { }
