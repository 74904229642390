import { PaginationDTO, SortDTO } from "../dtos";
import { PaginationInterface } from "../interfaces";

export class PaginationModel implements PaginationInterface {
    protected content: any[];
    protected pageable: PaginationDTO;
    protected last: boolean;
    protected totalPages: number;
    protected totalElements: number;
    protected size: number;
    protected number: number;
    protected sort: SortDTO;
    protected first: boolean;
    protected numberOfElements: number;
    protected empty: boolean;
    constructor(dto?: PaginationDTO) {
        if (!!dto) {
            this.content = dto?.content;
            this.pageable = dto?.pageable;
            this.last = dto?.last;
            this.totalPages = dto?.totalPages;
            this.totalElements = dto?.totalElements;
            this.size = dto?.size;
            this.number = dto?.number;
            this.sort = dto?.sort;
            this.first = dto?.first;
            this.numberOfElements = dto?.numberOfElements;
            this.empty = dto?.empty;
        }
    }

    getContent(): any[] {
        return this.content;
    }
    
    getNumberOfElements(): number {
        return this.numberOfElements;
    }

    getTotalElements(): number {
        return this.totalElements;
    }

    isLast(): boolean {
        return this.last;
    }
    
    isFirst(): boolean {
        return this.first;
    }
}