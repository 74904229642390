import { PaginationDTO, RendicontazioneProvinciaDTO } from "../dtos";
import { RendicontazioneProvinciaInterface, RendicontazioneProvinciaPaginationInterface } from "../interfaces";
import { RendicontazioneProvinciaPaginationModel } from "./rendicontazione-provincia-pagination.model";

export class RendicontazioneProvinciaTablePaginationModel extends RendicontazioneProvinciaPaginationModel implements RendicontazioneProvinciaPaginationInterface {
    constructor(dto?: PaginationDTO) {
        super(dto);
    }

    getRows(): Array<RendicontazioneProvinciaDTO> {
        return this.content.map((model: RendicontazioneProvinciaInterface) => {
            return {
                aliasProvincia: model.getProvincia(),
                annotazioni: model.getAnnotazioni(),
                budgetRendicontato: model.getBudget(),
                dataRendicontazione: model.getData(),
                id: model.getId()
            }
        });
    }
}