import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ConfigService, CryptoService } from '../../../singletons';
import { CurrentUserObserver } from '../../../observers';
import { UtenteService } from '../../../services';
import { UtenteModel, ValidaUtenteModel } from '../../../models';
import { UtenteInterface } from '../../../interfaces';

@Injectable({
    providedIn: 'root'
})
export class AuthFacade {
    protected SUFFIX_LOCAL_STORAGE: string;
    constructor(
        protected configService: ConfigService,
        protected cryptoService: CryptoService,
        protected currentUserObserver: CurrentUserObserver,
        protected userService: UtenteService,
        protected http: HttpClient,
        protected router: Router
    ) {
        this.SUFFIX_LOCAL_STORAGE = this.configService.sessionSuffix;
    }

    /**
     * Checks if the current user is valid and restores it if necessary.
     *
     * @return {Promise<UtenteModel>} The restored current user, or null if the current user is not valid.
     */
    // public async checkAndRestoreCurrentUser(): Promise<UtenteModel> {
    //     if (this.getCurretUser() != null) {
    //         const curretUser = await this.userService.getUtente();
    //         this.saveUser(curretUser);
    //         this.currentUserObserver.set(curretUser);
    //         return curretUser;
    //     }
    //     return null;
    // }

    /**
     * Pulizia del local storage
     */
    public clearUser(): void {
        localStorage.removeItem(this.SUFFIX_LOCAL_STORAGE);
        this.currentUserObserver.set(null);
    }

    /**
     * Ottiene i parametri del curretUser
     *
     * @returns { UtenteModel | ValidaUtenteModel }
     */
    public getCurretUser(caseAccountValidation: boolean = false): UtenteModel | ValidaUtenteModel {
        if (localStorage.getItem(this.SUFFIX_LOCAL_STORAGE) != null) {
            const curretUser = this.cryptoService.get(localStorage.getItem(this.SUFFIX_LOCAL_STORAGE));
            const json = JSON.parse(curretUser);
            if (caseAccountValidation) {
                return new ValidaUtenteModel(json);
            }
            return new UtenteModel(json);
        } else {
            return null;
        }
    }

    /**
     * Handles an HTTP error response.
     *
     * @param {HttpErrorResponse} httpError - The HTTP error response.
     */
    public handleError(httpError: HttpErrorResponse) {
        if (httpError.status == 401) {
            this.clearUser();
            this.router.navigate(['/']);
        }
    }

    /**
     * Ritorna true se l'utente è autenticato
     *
     * @returns { boolean }
     */
    public isAuthenticated(): boolean {
        const curretUser = this.getCurretUser();
        if (!!curretUser?.hasLastEmailValid() && !!curretUser?.getIdentificativo()) {
            return true;
        }
        return false;
    }

    // public async logout() {
    //     try {
    //         this.spinner.show();
    //         await this.userService.logout();
    //         this.clearUser();
    //         this.router.navigate(['/']);
    //     } catch(error) {
    //         console.error(error);
    //     } finally {
    //         this.spinner.hide();
    //     }
    // }

    /**
    * Salva nel local storage i parametri del curretUser in forma criptata
    * @param { UtenteModel } curretUser
    */
    public saveUser(curretUser: UtenteInterface): void {
      // const curretUser_encrypted = this.cryptoService.set(JSON.stringify(curretUser.toJson()));
      const curretUser_encrypted = this.cryptoService.set(JSON.stringify(curretUser));
      localStorage.setItem(this.SUFFIX_LOCAL_STORAGE, curretUser_encrypted);
  }
}
