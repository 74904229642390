import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { CurrentUserObserver, ShowcasePageInterface, UtenteInterface } from 'core-model';
import { ShowcasePageFacade } from '../../../../shared/facades/showcase-page.facade';
import { Subscription } from 'rxjs';
import { environment } from 'projects/app-front-office/src/environments/environment';

@Component({
  selector: 'app-header-navbar',
  templateUrl: './header-navbar.component.html',
  styleUrls: ['./header-navbar.component.scss']
})
export class HeaderNavbarComponent implements OnInit {
  public toggle = false;

  public currentUser: UtenteInterface;
  public currentUser$: Subscription;
  public environment = environment;

  public showCasePage: ShowcasePageInterface[] = null;

  constructor(private currentUserObserver: CurrentUserObserver,
    private showcasePageFacade: ShowcasePageFacade,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.currentUser$ = this.currentUserObserver.on().subscribe((currentUser) => {
      this.currentUser = currentUser;
    });

    this.showcasePageFacade.lista().then((vetrina) => {
      this.showCasePage = vetrina;
      this.activatedRoute.snapshot.data = { showcasePage: vetrina.sort((a, b) => a.getOrdinamento() - b.getOrdinamento()) };
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.activatedRoute.snapshot.data = { showcasePage: this.showCasePage?.sort((a, b) => a.getOrdinamento() - b.getOrdinamento()) };
      }
    });

    this.showcasePageFacade.on().subscribe((showcasePage) => {
      this.showCasePage = this.showCasePage?.map((page) => {
        if (page.getNavbarLabel() === showcasePage.getNavbarLabel()) {
          page = showcasePage;
        }
        return page
      });
    });
  }

  toggleMenu() {
    this.toggle = !this.toggle;
  }
}
