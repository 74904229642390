
import { FreeInstanceModel } from "./free-instance.model";
import { FILE_TYPE, STATO_BOZZA, TIPOLOGIA_RICHIEDENTE } from "../constants";
import { FreeInstanceDTO } from "../dtos";
import { DraftFreeInstanceInterface, DocumentInterface  } from "../interfaces";
export class DraftFreeInstanceModel extends FreeInstanceModel implements DraftFreeInstanceInterface {
    protected aggiungiAccompagnatore: boolean;

    constructor(dto: FreeInstanceDTO) {
        super(dto);
    }

    getAggiungiAccompagnatore(): boolean {
        return this.aggiungiAccompagnatore;
    }

    getDocumentoGratuita(): DocumentInterface {
        return this.file?.find((file) => file?.getTipologiaDocumento() === FILE_TYPE.FREEINSTANCE);
    }

    getStep(): number {
        if (this.acquisizioneGratuita) {
            if (this.isTipologiaBeneficiario() || this.isTipologiaFamiliare()) {
                return 5;
            }
            if (this.isTipologiaDelefato()) {
                return 9;
            }
        }
        if (!this.getTipologiaRichiedente()) {
            return 1;
        }
        if (!this.getTipologiaRichiedente() && !this.stato && !this.acquisizioneGratuita) {
            return 2;
        }
        switch (this.stato) {
            case STATO_BOZZA.BENEFICIARY_INDENNITA:
                if (this.aggiungiAccompagnatore == false) {
                    return 4;
                }
                return 3;
            case STATO_BOZZA.BENEFICIARY_ACCOMPAGNATORE:
                return 4;
            case STATO_BOZZA.FAMILY_INDENNITA:
                return 3;
            case STATO_BOZZA.FAMILY_ACCOMPAGNATORE:
                return 4;
            case STATO_BOZZA.DELEGATE_BENEFICIARIO:
                return 3;
            case STATO_BOZZA.DELEGATE_DATA:
                if(!this.isFirmaDocumentoDelegaCompleted()) {
                    return 4;
                } else {
                    return 6;
                }
            case STATO_BOZZA.DELEGATE_INDENNITA:
                return 6;
            case STATO_BOZZA.DELEGATE_ACCOMPAGNATORE:
                return 7;
            default:
                return 1;
        }
    }

    isDatiIndennitaCompleted(): boolean {
        if (!this.getTipologiaRichiedente()) {
            return false;
        }
        if(this.acquisizioneGratuita == true) {
            return true;
        }
        switch (this.stato) {
            case STATO_BOZZA.BENEFICIARY_INDENNITA:
                return true;
            case STATO_BOZZA.BENEFICIARY_ACCOMPAGNATORE:
                return true;
            case STATO_BOZZA.FAMILY_INDENNITA:
                return true;
            case STATO_BOZZA.FAMILY_ACCOMPAGNATORE:
                return true;
            case STATO_BOZZA.DELEGATE_BENEFICIARIO:
                return false;
            case STATO_BOZZA.DELEGATE_DATA:
                return false;
            case STATO_BOZZA.DELEGATE_INDENNITA:
                return true;
            case STATO_BOZZA.DELEGATE_ACCOMPAGNATORE:
                return true;
            default: return false;
        }
    }

    isDatiAccompgnatoreCompleted(): boolean {
        if (!this.getTipologiaRichiedente()) {
            return false;
        }
        if(this.acquisizioneGratuita == true) {
            return true;
        }
        switch (this.stato) {
            case STATO_BOZZA.BENEFICIARY_ACCOMPAGNATORE:
                return true;
            case STATO_BOZZA.FAMILY_ACCOMPAGNATORE:
                return true;
            case STATO_BOZZA.DELEGATE_ACCOMPAGNATORE:
                return true;
            default:
                return this.aggiungiAccompagnatore == false;
        }
    }

    isDatiAnagraficaBeneficiarioCompleted(): boolean {
        return this.getStep() >= 2;
    }

    isDatiAnagraficaDeleganteCompleted(): boolean {
        return this.stato == STATO_BOZZA.DELEGATE_DATA;
    }

    isConfermaDatiCompleted(): boolean {
        if (!this.getTipologiaRichiedente()) {
            return false;
        }
        switch (this.stato) {
            case STATO_BOZZA.BENEFICIARY_INDENNITA:
                return false;
            case STATO_BOZZA.BENEFICIARY_ACCOMPAGNATORE:
                return true;
            case STATO_BOZZA.FAMILY_INDENNITA:
                return false;
            case STATO_BOZZA.FAMILY_ACCOMPAGNATORE:
                return false;
            case STATO_BOZZA.DELEGATE_BENEFICIARIO:
                return false;
            case STATO_BOZZA.DELEGATE_DATA:
                return false;
            case STATO_BOZZA.DELEGATE_INDENNITA:
                return false;
            case STATO_BOZZA.DELEGATE_ACCOMPAGNATORE:
                return true;
            default: return false;
        }
    }

    isFirmaDocumentoDelegaCompleted(): boolean {
        return !!this.getFileDelega() && !!this.getFileCartaIdentitaDelegante(); 
    }

    isFirmaDocumentoGratuitaCompleted(): boolean {
        const file = this.getDocumentoGratuita();
        return !!file;
    }

    isTipologiaRichiedenteCompleted(): boolean {
        return !!this.getTipologiaRichiedente()
    }

    isTipologiaBeneficiario(): boolean {
        return this.getTipologiaRichiedente() === TIPOLOGIA_RICHIEDENTE.BENEFICIARIO;
    }

    isTipologiaDelefato(): boolean {
        return this.getTipologiaRichiedente() === TIPOLOGIA_RICHIEDENTE.TERZO_DELEGATO;
    }

    isTipologiaFamiliare(): boolean {
        return this.getTipologiaRichiedente() === TIPOLOGIA_RICHIEDENTE.COMPONENTE_NUCLEO_FAMILIARE;
    }

    /**
     * Gestione step Dati accompagnatore, se false l'utente ha scelto di aggiungere l'accompagnatore successivamente
     * @param bool 
     */
    setAggiungiAccompagnatore(bool: boolean) {
        this.aggiungiAccompagnatore = bool;
    }
}