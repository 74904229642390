import { AppIoDTO } from "../dtos";
import { AppIoInterface } from "../interfaces";

export class AppIoModel implements AppIoInterface {
    private codice: string;
    private dataScadenzaCodice: Date;
    private id: number;
    private verificato: boolean;
    
    constructor(dto: AppIoDTO) {
        this.codice = dto?.codice;
        this.dataScadenzaCodice = !!dto?.dataScadenzaCodice ? new Date(dto?.dataScadenzaCodice) : undefined;
        this.id = dto?.id;
        this.verificato = dto?.verificato;
    }

    getCode(): string {
        return this.codice;
    }

    getDataSCadenzaCodice(): Date {
        return this.dataScadenzaCodice;
    }

    isVerificato(): boolean {
        return this?.verificato == true;
    }

    toDTO(): AppIoDTO {
        return {
            codice: this.codice,
            dataScadenzaCodice: this.dataScadenzaCodice?.toJSON(),
            id: this.id,
            verificato: this.verificato
        }
    }
}