import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FooterModule, HeaderModule } from './modules';
import { CoreModelFoModule, ConfigModule, CryptoModule, LanguageTranslationModule } from 'core-model';
import { ErrorModule } from '../shared/modules/';
import { TokenInterceptor } from '../shared/interceptors';
import { AccountValidationFacade, AuthFacade, FreeInstanceFacade, ErrorFacade, RichiestaGratuitaFacade, UtenteFacade } from '../shared/facades';
import { environment } from '../environments/environment';

registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ConfigModule.forRoot({ session_suffix: 'SGP/' }),
    CoreModelFoModule.forRoot({ environment: environment }),
    CryptoModule.forRoot({ key: 'SGP_SECRETKEY' }),
    ErrorModule,
    FooterModule,
    HeaderModule,
    HttpClientModule,
    LanguageTranslationModule,
    NgxSpinnerModule.forRoot({ type: 'line-spin-clockwise-fade' })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'it' },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    AccountValidationFacade, AuthFacade, FreeInstanceFacade, ErrorFacade, RichiestaGratuitaFacade, UtenteFacade
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
