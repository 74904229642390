import { GradoDisabilitaDTO } from "../dtos";

export class GradoDisabilitaModel {
    protected id: number;
    protected attivo: boolean;
    private codice: string;
    private descrizione: string;
    protected daAttivareConfigurazione: boolean;
    protected attivazioneNuovaConfigurazione: boolean;
    protected attivo_configurazione: boolean;
    constructor(dto: GradoDisabilitaDTO) {
        this.id = dto?.id;
        this.attivo = dto?.attivo;
        this.codice = dto?.codice;
        this.daAttivareConfigurazione = dto?.daAttivareConfigurazione;
        this.attivo_configurazione = dto?.attivoConfigurazione;
        this.descrizione = dto?.listaTraduzioniDescrizione?.at(0).descrizione;
    }
    getCodice(): string {
        return this.codice;
    }

    isAttivo(): boolean {
        return this.attivo;
    }

    getID(): number {
       return this.id;
    }

    isAttivoConfigurazione(): boolean {
        return this.attivo_configurazione;
    }

    isDaAttivareInConfigurazione(): boolean {
        return this.daAttivareConfigurazione;
    }

    setAttivazioneConfigurazione(daAttivare: boolean) {
        this.daAttivareConfigurazione = daAttivare;
    }

    setAttivaConfigurazione(attivazione){
      this.attivo_configurazione = attivazione
    }

    setAttivazioneNuovaConfigurazione(attivazione){
      this.attivazioneNuovaConfigurazione = attivazione
    }

    isAttivazioneNuovaConfigurazione(){
      return this.attivazioneNuovaConfigurazione;
    }

    getDescrizione(): string {
        return this.descrizione;
    }
}
