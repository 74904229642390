import { PaginationDTO } from "../dtos";
import { PaginationInterface, SpesaInterface } from "../interfaces";
import { PaginationModel, SpesaModel } from "./";

export class SpesaPaginationModel extends PaginationModel implements PaginationInterface {
    override content: SpesaInterface[];
    constructor(dto?: PaginationDTO) {
        super(dto);
        this.content = dto?.content?.map((el) => new SpesaModel(el));
    }

    override getContent(): SpesaInterface[] {
        return this.content;
    }
}