import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { RouterModule } from '@angular/router';
import { HeaderSlimModule } from './header-slim/header-slim.module';
import { HeaderCenterModule } from './header-center/header-center.module';
import { HeaderNavbarModule } from './header-navbar/header-navbar.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  imports: [
    CommonModule,
    HeaderCenterModule,
    HeaderNavbarModule,
    HeaderSlimModule,
    TranslateModule,
    RouterModule
  ]
})
export class HeaderModule { }
