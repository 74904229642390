
import { InvoiceStatus } from "../constants";
import { InvoiceDTO } from "../dtos";
import { EnteInterface, InvoiceInterface } from "../interfaces";
import { EnteModel } from "./ente.model";

export class InvoiceModel implements InvoiceInterface {
    protected idFattura?: number;
    protected numeroFattura: String;
    protected data: Date;
    protected dataInizio: Date;
    protected dataFine: Date;
    protected annualita: string;
    protected oggetto: string;
    protected aliquotaIva: number;
    protected imponibile: number;
    protected idEnte?: number;
    protected statoFattura: InvoiceStatus;
    protected ente: EnteInterface;

    constructor(dto: InvoiceDTO) {
        this.idFattura = dto?.idFattura ? Number(dto?.idFattura) : dto?.id ? Number(dto?.id) : undefined;
        this.numeroFattura = dto?.numeroFattura ? dto?.numeroFattura : dto?.numero ? dto?.numero : undefined;
        this.data = dto?.data ? new Date(dto.data) : undefined;
        this.annualita = dto?.annualita;
        this.oggetto = dto?.oggetto;
        this.aliquotaIva = dto?.aliquotaIva;
        this.imponibile = dto?.imponibile;
        this.idEnte = dto?.idEnte;
        this.ente = new EnteModel(dto?.ente);
        this.statoFattura = dto?.statoFattura;
        this.dataInizio = dto?.dataInizio ? new Date(dto.dataInizio) : undefined;
        this.dataFine = dto?.dataFine ? new Date(dto.dataFine) : undefined;
    }

    getDataInizio(): Date {
        return this.dataInizio;
    }

    getDataFine(): Date {
        return this.dataFine;
    }

    getIdFattura(): number {
        return this.idFattura;
    }

    getNumeroFattura(): String {
        return this.numeroFattura;
    }

    getData(): Date {
        return this.data;
    }

    getAnnualita(): string {
        return this.annualita;
    }

    getOggetto(): string {
        return this.oggetto;
    }

    getAliquotaIva(): number {
        return this.aliquotaIva;
    }

    getImponibile(): number {
        return this.imponibile;
    }

    getEnte(): EnteInterface {
        return this.ente;
    }

    getStatoFattura(): InvoiceStatus {
        return this.statoFattura;
    }
}
