import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AggiungiNewsComponent } from './aggiungi-news.component';
import { TranslateModule } from '@ngx-translate/core';
import { AggiungiNewsRoutingModule } from './aggiungi-news-routing.module';
import { QuillModule } from 'ngx-quill';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownLanguageModule, SharedModule, TagsModule, UploadDragDropModule } from '../../module';
import { ErrorModule } from '../../../../modules'



@NgModule({
  declarations: [
    AggiungiNewsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    TranslateModule,
    UploadDragDropModule,
    TagsModule,
    AggiungiNewsRoutingModule,
    DropdownLanguageModule,
    SharedModule,
    ErrorModule
  ]
})
export class AggiungiNewsModule { }
