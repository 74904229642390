import { ProvvedimentoDTO } from "../dtos";
import { DocumentInterface, ProvvedimentoInterface } from "../interfaces";
import { DocumentModel } from "./document.model";

export class ProvvedimentoModel implements ProvvedimentoInterface {

    protected estremi?: string;
    protected dataScadenza?: Date;
    protected nota?: string;
    protected file?: DocumentInterface[];
    protected dataTermineUltimo?: Date;
    protected dataInizio?: Date;
    protected rispostaProvvedimento?: ProvvedimentoInterface;

    constructor(dto?: ProvvedimentoDTO) {
        this.estremi = dto?.estremi;
        this.dataScadenza = dto?.dataScadenza ? new Date(dto.dataScadenza) : undefined;
        this.nota = dto?.nota;
        this.file = dto?.file.map(f => new DocumentModel(f));
        this.dataTermineUltimo = dto?.dataTermineUltimo ? new Date(dto.dataTermineUltimo) : undefined;
        this.dataInizio = dto?.dataInizio ? new Date(dto.dataInizio) : undefined;
        this.rispostaProvvedimento = !!dto?.rispostaProvvedimento ? new ProvvedimentoModel(dto?.rispostaProvvedimento) : null;
    }
    getRispostaProvvedimento() {
        return this.rispostaProvvedimento;
    }

    getDataInizio(): Date {
        return this.dataInizio;
    }

    getDataTermineUltimo(): Date {
        return this.dataTermineUltimo;
    }

    getEstremi(): string {
        return this.estremi;
    }
    getNote(): string {
        return this.nota;
    }
    getFile(): DocumentInterface[] {
        return this.file;
    }

    hasRispostaProvvedimento(): boolean {
      return this.rispostaProvvedimento != null;
  }
}
