
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take } from "rxjs";
import { DatiIndennitaDelegatoDTO, DelegatoDeleganteDTO, FreeInstanceDTO } from "../dtos";


@Injectable({
  providedIn: 'root'
})
export class DelegatoService {

    private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }


  /**
   * Inserimento dei dati di indennità del beneficiario
   *
   * @param { DatiIndennitaDelegatoDTO } datiIndennita
   * @return { Promise<DelegatoDTO> } Una promise che si risolve con la bozza di gratuità.
   */
  aggiungiGratuita(datiIndennita: DatiIndennitaDelegatoDTO): Promise<FreeInstanceDTO> {
    return new Promise<FreeInstanceDTO>(
      (resolve, reject) => {
        this.http.put(this.url_endpoint + api.delegato_aggiungi_gratuita, datiIndennita, { observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: HttpResponse<any>) => {
              if (!!resp?.body) {
                resolve(resp?.body);
              } else {
                resolve(null);
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }


  /**
   * Creazione bozza delegato - Dati delega.
   *
   * @param { DelegatoDeleganteDTO  } delega - La delega da aggiungere.
   * @return { Promise<FreeInstanceDTO> }
   */
  aggiungiDelega(delega: DelegatoDeleganteDTO): Promise<FreeInstanceDTO> {
    return new Promise<FreeInstanceDTO>(
      (resolve, reject) => {
        this.http.post(this.url_endpoint + api.delegato_aggiungi_delega, delega, { observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              if (!!resp?.body) {
                resolve(resp.body);
              } else {
                reject({
                  status: 1,
                  error: resp?.message
                })
              }
            },
            error: (error) => {
              reject(error);
            }
          });
      });
  }

  /**
   * Generazione documento delega
   *
   * @param {string} idGratuita - L'ID dell'istanza gratuita.
   * @return {Promise<boolean>} Una promessa che si risolve in `true` se il delegato viene generato con successo.
   */
  generaDelega(idGratuita: string): Promise<boolean> {
    return new Promise<boolean>(
      (resolve, reject) => {
        const params = new HttpParams()
          .append('idFreeInstance', idGratuita)
        this.http.get(this.url_endpoint + api.delegato_genera_delega, { params: params, observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              if (!!resp?.body) {
                resolve(true);
              } else {
                reject({
                  status: 0,
                  error: resp?.message
                })
              }
            },
            error: (error) => {
              reject(error);
            }
          });
      });
  }

}
