// const url_endpoint = "http://127.0.0.1:8080/sgp";
const base = "/api/v1/it"
const back_office = "/api/v1/back-office/it";
const front_office = "/api/v1/front-office/it";

export const api = {
    //ACCESSO
    accesso_admin: back_office + '/superadmin/accesso',
    // ACCOMPAGNATORE
    accompagnatore_aggiungi: front_office + '/accompagnatore/aggiungi',
    accompagnatore_attiva: front_office + '/accompagnatore/attiva',
    accompagnatore_elimina: front_office + '/accompagnatore/elimina',
    accompagnatore_modifica: front_office + '/accompagnatore/modifica',
    accompagnatore_ottieni_lista: front_office + '/accompagnatore/ottieni-lista',
    accompagnatore_sospendi: front_office + '/accompagnatore/sospendi',
    // APP IO
    appio_aggiungi: base + '/app-io/aggiungi',
    appio_rinnova_attivazione: base + '/app-io/rinnova-attivazione',
    appio_validazione: base + '/app-io/validazione',
    //AUTH
    authentication_logout: base + '/autenticazione/disconnetti',
    // BENEFICIARIO
    beneficiario_aggiungi: front_office + '/beneficiario/aggiungi',
    beneficiario_controllo: front_office + '/beneficiario/controllo',
    //CAMBIA_STATO
    cambia_acl: back_office + '/superadmin/operatore',
    cambia_stato: back_office + '/superadmin/operatore',
    // CATEGORIA DISABILITA
    categoria_disabilita_lista: base + '/categoria-disabilita/lista',
    // CENSIMENTO OPERATORE
    censimento_operatore: back_office + '/superadmin/operatore/censimento',
    // CONFIGURAZIONE
    configurazione_aggiungi: back_office + '/configurazione/aggiungi',
    configurazione_elimina: back_office + '/configurazione/elimina',
    configurazione_lista: back_office + '/configurazione/lista',
    configurazione_lista_fasce: back_office + '/configurazione/lista-fasce-isee',
    configurazione_lista_storico: back_office + '/configurazione/lista-storico',
    configurazione_ottieni: back_office + '/configurazione/ottieni',
    configurazione_storico_ottieni: back_office + '/configurazione/ottieni-dettaglio',
    // CONFIGURAZIONE BUDGET ANNUALE
    configurazione_budget_annuale_aggiungi: back_office + '/configurazione-budget-annuale/aggiungi',
    configurazione_budget_annuale_aggiungi_ente: back_office + '/configurazione-budget-annuale/aggiungi-ente',
    configurazione_budget_annuale_date_ottieni: back_office + '/configurazione-budget-annuale/date/ottieni',
    configurazione_budget_annuale_impota_pagina: back_office + '/configurazione-budget-annuale/imposta-pagina',
    configurazione_budget_annuale_modifica: back_office + '/configurazione-budget-annuale/modifica',
    configurazione_budget_annuale_ottieni: back_office + '/configurazione-budget-annuale/ottieni',
    configurazione_budget_annuale_rimuovi: back_office + '/configurazione-budget-annuale/rimuovi',
    configurazione_budget_annuale_rimuovi_voce_costo: back_office + '/configurazione-budget-annuale/rimuovi-voce-costo',
    // CONFIGURAZIONE BUDGET PROVINCE
    configurazione_budget_province_aggiorna: back_office + '/configurazione-budget-province/aggiorna',
    configurazione_budget_province_aggiungi: back_office + '/configurazione-budget-province/aggiungi',
    configurazione_budget_province_date_ottieni: back_office + '/configurazione-budget-province/date/ottieni',
    configurazione_budget_province_ottieni: back_office + '/configurazione-budget-province/ottieni',
    configurazione_budget_province_rendicontazioni_aggiungi: back_office + '/configurazione-budget-province/rendicontazioni/aggiungi',
    configurazione_budget_province_rendicontazioni_elimina: back_office + '/configurazione-budget-province/rendicontazioni/elimina',
    configurazione_budget_province_rendicontazioni_modifica: back_office + '/configurazione-budget-province/rendicontazioni/modifica',
    configurazione_budget_province_rendicontazioni_ottieni: back_office + '/configurazione-budget-province/rendicontazioni/ottieni',
    configurazione_budget_province_rimuovi: back_office + '/configurazione-budget-province/elimina',

    // CONFIGURAZIONE SOGLIA MENSILE
    configurazione_soglia_mensile_aggiungi: back_office + '/configurazione-soglia/mensile/aggiungi',
    configurazione_soglia_mensile_ottieni: back_office + '/configurazione-soglia/mensile/ottieni',
    // DELEGATO
    delegato_aggiungi_delega: front_office + '/delegato/aggiungi-delega',
    delegato_aggiungi_gratuita: front_office + '/delegato/aggiungi-gratuita',
    delegato_genera_delega: front_office + '/delegato/genera-delega',
    // EMAIL
    email_aggiungi: base + '/email/aggiungi',
    email_attivazione_notifica: base + '/email/attivazione-notifica',
    email_cambia_stato: base + '/email/cambio-stato',
    email_rinnova_attivazione: base + '/email/rinnova-attivazione',
    email_validazione: base + '/email/validazione',
    // ENTE FINANZIATORE
    ente_lista: back_office + '/ente/lista',
    // FAMILIARE
    familiare_aggiungi: front_office + '/familiare/aggiungi',
    familiare_lista: front_office + '/familiare/lista',
    // FILE
    file_carica_documento: base + '/file/carica-documento',
    file_genera_delega_riscatto: front_office + '/file/genera-delega-riscatto',
    file_genera_documento: base + '/file/genera-documento',
    file_recupera_documento: back_office + '/file/recupera-documento',
    file_scarica_documento: front_office + '/file/scarica-documento',
    // FREE INSTANCE
    free_instance_cambio_richiedente: front_office + '/gratuita/cambio-richiedente',
    free_instance_cambio_richiedente_back_office: back_office + '/gratuita/avvio-cambio-richiedente',
    free_instance_controllo_cambi_attivi: front_office + '/gratuita/controllo-cambi-attivi',
    free_instance_elimina_bozza: front_office + '/gratuita/elimina/bozza',
    free_instance_genera_documento: front_office + '/gratuita/genera-documento',
    free_instance_genera_qrcode: front_office + '/gratuita/genera-qrcode',
    free_instance_ottieni: front_office + '/gratuita/ottieni',
    free_instance_ottieni_back_office: back_office + '/gratuita/recupera',
    free_instance_ottieni_threshold: back_office + '/gratuita/threshold-individuale/ottieni',
    free_instance_ricerca: front_office + '/gratuita/ricerca-gratuita',
    free_instance_ricerca_avanzata: back_office + '/gratuita/filtro',
    free_instance_ricerca_filtrata: front_office + '/gratuita/ricerca-gratuita-filtrata',
    free_instance_riscatta_gratuita: front_office + '/gratuita/riscatta-gratuita',
    free_instance_scarica_gratuita: base + '/gratuita/scarica-gratuita',
    free_instance_verifica_riscatto: front_office + '/gratuita/verifica-riscatto',
    // GRADO DISABILITA
    grado_disabilita_lista: base + '/grado-disabilita/lista',
    // GRAFICI
    grafici_budget_province_ottieni: back_office + '/grafici/budget-province/ottieni',
    grafici_comuni_ottieni: back_office + '/grafici/comuni/ottieni',
    grafici_ottieni: back_office + '/grafici/ottieni',
    // CONTATTI
    info_invia: base + '/display/info/invia',
    // IMMAGINI NEWS
    immagine_carosello: base + '/news/immagini-carosello/ottieni',
    immagine_copertina: base + '/news/copertina/ottieni',
    // INVALIDITÀ CIVILE
    invalidita_civile_controllo: base + '/external/invalidita-civile/controllo',
    // ISEE
    isee_controllo: base + '/external/isee/controllo',
    // FATTURE
    invoice_aggiorna: back_office + '/fattura/aggiorna',
    invoice_aggiungi: back_office + '/fattura/aggiungi',
    invoice_elimina: back_office + '/fattura/elimina',
    invoice_grafici: back_office + '/fattura/grafici',
    invoice_lista: back_office + '/fattura/lista',
    invoice_ottieni: back_office + '/fattura/ottieni',
    //LAVORAZIONE
    lavorazione_aggiungi_gratuita: back_office + '/lavorazione/aggiungi',
    lavorazione_riscontro_gratuita: back_office + '/lavorazione/riscontro',
    //LISTA RUOLI
    lista_ruoli: back_office + '/superadmin/ruolo/lista',
    // MONITORAGGIO SPESA
    monitoraggio_budget_tabella: back_office + '/monitoraggio-budget-tabella/ottieni',
    monitoraggio_budget_scarica_tabella: back_office + '/monitoraggio-budget-tabella/scarica-monitoraggi',
    // NEWS
    news_aggiorna: base + '/news/aggiorna',
    news_aggiungi: base + '/news/aggiungi',
    news_lista: base + '/news/lista',
    news_lista_editor: base + '/news/lista-editor',
    news_ottieni: base + '/news/ottieni',
    news_rimuovi: base + '/news/elimina',
    //NOTIFICHE
    notifica_visualizza: base + '/notifica/visualizza',
    notifiche_lista: base + '/notifica/lista',
    // OPERATORE
    operatore_ottieni: back_office + '/operatore/ottieni',
    // operatore_ottieni: base + '/utente/ottieni',

    operatore_ottieni_codice_fiscale: back_office + '/superadmin/operatore',
    operatore_presa_visione: back_office + '/operatore/presa-visione',
    operatore_switch: back_office + '/operatore/switch',
    // PHONE NUMBER
    phone_number_aggiungi: base + '/telefono/aggiungi',
    phone_number_attivazione_notifica: base + '/telefono/attivazione-notifica',
    phone_number_cambia_stato: base + '/telefono/cambio-stato',
    phone_number_rinnova_attivazione: base + '/telefono/rinnova-attivazione',
    phone_number_validazione: base + '/telefono/validazione',
    // REQUISITO DISABILITA
    requisito_disabilita_lista: base + '/requisito-disabilita/lista',
    // RICHIEDENTE
    richiedente_aggiungi_generalita: front_office + '/richiedente/aggiungi/generalita',
    richiedente_accetta_privacy: front_office + '/richiedente/accetta-privacy',
    richiedente_ottieni: front_office + '/richiedente/ottieni',
    // TODO ELIMINARE richiedente_scelta_notifica
    richiedente_scelta_notifica: front_office + '/richiedente/scelta-notifica',
    // RINUNCIA
    rinuncia_ativa: front_office + '/rinuncia/attiva',
    // RISCONTRO
    riscontro: front_office + '/richiedente/aggiungi/riscontro',
    // VETRINA
    showcasePage_aggiorna: base + '/display/pagina-vetrina/aggiorna',
    showcasePage_aggiungi: base + '/display/pagina-vetrina/aggiungi',
    showcasePage_lista: base + '/display/pagina-vetrina/lista',
    showcasePage_ottieni: base + '/display/pagina-vetrina/ottieni',
    // TAGS
    tags_ottieni: base + '/news/tags/ottieni',
    tags_ricerca: base + '/news/tags/ricerca',
    // TICKETS
    tickets_ricerca_avanzata: front_office + '/biglietto/filtro-titoli-viaggio',
    tickets_ricerca_avanzata_backoffice: back_office + '/titoli-di-viaggio/ottieni',
    tickets_scarica_titoli_viaggio_bo: back_office + '/biglietto/scarica-titoli-viaggio',
    tickets_scarica_titoli_viaggio_fo: front_office + '/biglietto/ottieni-titoli-viaggio',
    tickets_tipologie_biglietto: base + '/biglietto/tipologie-biglietto',
    // UTENTE
    utente_appio: base + '/utente/app-io',
   // utente_ottieni: base + '/utente/ottieni',
    utente_ottieni: front_office + '/richiedente/ottieni',


}
