export enum FILE_TYPE {
    DELEGATION = 'DELEGATION',
    FREEINSTANCE = 'FREEINSTANCE',
    RENUNCIATION = 'RENUNCIATION',
    IDENTITY_CARD = 'IDENTITY_CARD',
    INTEGRATION = 'INTEGRATION',
    SUSPENSION = 'SUSPENSION',
    INTEGRATION_RESPONSE = 'INTEGRATION_RESPONSE',
    SUSPENSION_RESPONSE = 'SUSPENSION_RESPONSE',
    SUSPENSION_MEASURE = 'SUSPENSION_MEASURE',
    REVOCATION = 'REVOCATION',
    REVOCATION_MEASURE = 'REVOCATION_MEASURE',
    ADMISSION_MEASURE = 'ADMISSION_MEASURE',
    ACCEPTED = 'ACCEPTED',
    NOT_ADMISSION_MEASURE = 'NOT_ADMISSION_MEASURE',
}
