import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";

export const PasswordGuard: CanActivateFn = async () => {
  const router: Router = inject(Router);
  const providedPassword = prompt('Enter the password:');
  if (providedPassword === 'password') {
    return true
  }
  return router.createUrlTree(['home']);
  
};
