import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldInterface } from '../../interfaces';
import { SORT } from '../../constants';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})

export class DatatableComponent implements OnInit {
  @Input() caption: string;
  @Input() rows: any[];
  @Input() page: number = 1;
  @Input() pageSize;
  @Input() set columns(columns: FieldInterface[]) {
    this.cols = columns.sort((a, b) => a.positionTable - b.positionTable);
  }

  @Output() sortBy: EventEmitter<{ key: string, sort: SORT }> = new EventEmitter();

  public SORT = SORT;

  public cols: FieldInterface[];

  public loading = false;

  constructor(private spinner: NgxSpinnerService) { }
  
  ngOnInit(): void {
    this.spinner.spinnerObservable.subscribe((value) => {
      if (value) {
        this.loading = value.show;
      }
    });
  }

  onSort(column: FieldInterface) {
    switch (column.sort) {
      case SORT.ANY: column.sort = SORT.ASC; break;
      case SORT.ASC: column.sort = SORT.DESC; break;
      default: column.sort = SORT.ANY;
    }
    this.cols.forEach(c => c.key != column.key ? c.sort = 0 : null);
    this.sortBy.emit({ key: column.keySort, sort: column.sort });
  }

  getEmptyRows(): any {
    const emptyRows = [];
    if (this.pageSize > this.rows?.length) {
      for (let i = 0; i < this.pageSize - this.rows.length; i++) {
        emptyRows.push(i);
      }
    }

    return emptyRows;
  }

  getRoute(row: any, route: string, keyId: Array<string>): string {
    const values = keyId.map((key) => row[key]);
    return route + '/' + values.join('/');
  }

  getColSpan(): number {
    return this.cols?.filter((col) => col.visible).length;
  }
}
