<div class="row">
    <div class="col">
        <h2 tabindex="0" class="h5" translate>COMPONI_TABELLA.TITLE</h2>
    </div>
</div>
<div class="row">
    <div class="col-12 col-md-3">
        <div class="d-grid" ngbDropdown #myDrop="ngbDropdown">
            <button tabindex="0" type="button" class="btn btn-outline-primary dropdown-toggle mx-1 mb-2" id="dropdownForm" ngbDropdownToggle>
                {{ 'COMPONI_TABELLA.SELEZIONA_COLONNE' | translate }}
                <svg class="icon-expand icon icon-sm icon-primary"><use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-expand"></use></svg>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownForm">
                <form class="p-2">
                    <ng-container *ngFor="let field of options; let i=index">
                        <div class="form-check" *ngIf="field?.positionTable > -1">
                            <input tabindex="0" id="{{'check_'+ field.key + '_' + i }}" class="form-check-input" type="checkbox" [(ngModel)]="field.visible" [ngModelOptions]="{standalone: true}">
                            <label tabindex="0" class="form-check-label fs-6" for="{{'check_'+ field.key + '_' + i }}">
                                {{ field.title | translate }}
                            </label>
                        </div>
                    </ng-container>
                </form>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6">
        <div class="d-grid gap-2 mb-2 d-md-block">
            <button tabindex="0" class="btn btn-primary mx-1" (click)="onClick()" translate>FORM.VISUALIZZA</button>
            <button tabindex="0" class="btn btn-secondary mx-1" (click)="cancel()" translate>FORM.RESET</button>
        </div>
    </div>
</div>
<hr class="border-0 my-3"/>
