<footer class="it-footer" id="footer" role="contentinfo">
    <div class="it-footer-main">
        <div class="container-xxl">
            <section class="footer-utilities pt-5">
                <div class="row">
                    <div class="col-12 col-md-4 col-lg-4 mb-5 mb-lg-0">
                        <p tabindex="0" class="fw-normal pb-4">{{ 'GENERAL.ENTE.NOME_ESTESO' | translate }}</p>
                        <div class="link-list-wrapper">
                            <ul class="link-list footer-list clearfix mb-0 h-100">
                                <li tabindex="0">
                                    <a class="list-item mt-3" href="javascript:void(0);" [attr.aria-label]="'ACCESSIBILITA.ENTE.INDIRIZZO' | translate">{{ 'GENERAL.ENTE.INDIRIZZO' | translate }}</a>
                                </li>
                                <li tabindex="0" class="d-flex lh-base">
                                    <div class="lh-base me-1 fs-6">{{ 'GENERAL.SCRIVICI' | translate }}: </div>

                                    <a class="lh-base fs-6" [title]="'ACCESSIBILITA.ENTE.EMAIL' | translate" [href]="'mailto:' + ('GENERAL.ENTE.EMAIL' | translate )" [attr.aria-label]="'ACCESSIBILITA.ENTE.EMAIL' | translate">{{ 'GENERAL.EMAIL' | translate }}</a>

                                    <div class="lh-base mx-1 fs-6">-</div>

                                    <a class="lh-base fs-6" [title]="'ACCESSIBILITA.ENTE.EMAIL_PEC' | translate" [href]="'mailto:' + ('GENERAL.ENTE.EMAIL_PEC' | translate )" [attr.aria-label]="'ACCESSIBILITA.ENTE.EMAIL_PEC' | translate">{{ 'GENERAL.EMAIL_PEC' | translate }}</a>

                                </li>
                                <li tabindex="0">
                                    <a class="list-item my-3 text-nowrap" href="javascript:void(0);" [attr.aria-label]="'ACCESSIBILITA.ENTE.NOME_INIZIATIVA' | translate">{{ 'GENERAL.ENTE.NOME_INIZIATIVA' | translate }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 col-lg-4 mb-5 mb-lg-0">
                        <p class="h6 pb-4" tabindex="0">{{ 'GENERAL.SEGUICI_SU' | translate }}</p>
                        <a class="btn p-0 me-4" tabindex="0" href="https://www.facebook.com/quiregionepuglia" [attr.aria-label]="'ACCESSIBILITA.SEGUICI_SU_FACEBOOK' | translate" target="_blank">
                            <svg class="icon icon-sm icon-white align-top" role="img">
                                <title>{{ 'ACCESSIBILITA.SEGUICI_SU_FACEBOOK' | translate }}</title>
                                <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-facebook"></use>
                            </svg>
                        </a>

                        <a class="btn p-0" tabindex="0" href="https://twitter.com/RegionePuglia" [attr.aria-label]="'ACCESSIBILITA.SEGUICI_SU_TWITTER' | translate" target="_blank">
                            <svg class="icon icon-sm icon-white align-top" role="img">
                                <title>{{ 'ACCESSIBILITA.SEGUICI_SU_TWITTER' | translate }}</title>
                                <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-twitter"></use>
                            </svg>
                        </a>
                    </div>
                    <div class="col-12 col-md-4 col-lg-4 mb-5 mb-lg-0">
                        <p class="visually-hidden text-white" tabindex="0">{{ 'ACCESSIBILITA.SELEZIONA_LINK_UTILI' | translate }}</p>
                        <div class="link-list-wrapper">
                            <ul class="link-list footer-list clearfix mb-0 h-100">
                                <li tabindex="0">
                                    <a class="list-item my-3" href="https://www.regione.puglia.it/servizio-stampa-della-giunta" target="_blank">{{ 'NAVIGATION.UFFICIO_STAMPA_DI_GIUNTA' | translate }}</a>
                                </li>
                                <li tabindex="0">
                                    <a class="list-item my-3" href="https://press.regione.puglia.it/" target="_blank">{{ 'NAVIGATION.PRESS_REGIONE' | translate }}</a>
                                </li>
                                <li tabindex="0">
                                    <a class="list-item my-3" href="https://www.regione.puglia.it/web/comunicazione-istituzionale/stemma-regionale" target="_blank">{{ 'NAVIGATION.LOGO_E_IDENTITA_REGIONALE' | translate }}</a>
                                </li>
                                <li tabindex="0">
                                    <a class="list-item my-3" href="https://www.regione.puglia.it/responsabili-di-pubblicazione" target="_blank">{{ 'NAVIGATION.RESPONSABILI_DI_REDAZIONE' | translate }}</a>
                                </li>
                                <li tabindex="0">
                                    <a class="list-item my-3" href="https://form.agid.gov.it/view/75bfc521-95dd-4fe3-917b-159bec2962f0/" target="_blank" [attr.aria-label]="'ACCESSIBILITA.DICHIARAZIONE_ACCESSIBILITA_AGID' | translate">{{ 'NAVIGATION.DICHIARAZIONE_DI_ACCESSIBILITA' | translate }}</a>
                                </li>
                                <li tabindex="0">
                                    <a class="list-item my-3" href="https://protezionecivile.puglia.it/" target="_blank">{{ 'NAVIGATION.VAI_AL_SITO_DI_PROTEZIONE_CIVILE_PUGLIA' | translate }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div class="it-footer-small-prints clearfix">
        <div class="container-xxl">
            <div class="visually-hidden text-white" translate>ACCESSIBILITA.SEZIONE_LINK_UTILI</div>
            <ul class="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row">
                <!-- <li class="list-inline-item" tabindex="0"><a href="/note-legali/">Media policy</a></li> -->
                <li class="list-inline-item" tabindex="0"><a href="/note-legali/">Note legali</a></li>
                <li class="list-inline-item" tabindex="0"><a href="/privacy-policy/">Privacy policy</a></li>
            </ul>
        </div>
    </div>
</footer>
