import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take } from "rxjs";
import { AggiungiVoceDiCostoDTO, AnnualitaConfigurazioneBudgetDTO, BudgetAnnualeDTO, ConfigurazioneBudgetAnnualeDTO, ModificaVoceDiCostoDTO, VoceDiCostoDTO } from "../dtos";

@Injectable({
    providedIn: 'root'
})
export class ConfigurazioneBudgetAnnualeService {
    private url_endpoint;
    constructor(private http: HttpClient, @Inject('env') env) {
      this.url_endpoint = env.environment.url_endpoint;
    }

    /**
     * Aggiungi una voce di costo nella configurazione corrente
     *
     * @param { AggiungiVoceDiCostoDTO } dto
     * @return { Promise<VoceDiCostoDTO> }
     */
    aggiungiVoceDiCosto(dto: AggiungiVoceDiCostoDTO): Promise<VoceDiCostoDTO> {
        return new Promise<any>(
            (resolve, reject) => {
                this.http.put(this.url_endpoint + api.configurazione_budget_annuale_aggiungi_ente, dto, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Ottiene tutte le annualità delle configurazioni budget
     *
     * @return { Promise<AnnualitaConfigurazioneBudgetDTO[]> }
     */
    getAnnualita(): Promise<AnnualitaConfigurazioneBudgetDTO[]> {
        return new Promise<AnnualitaConfigurazioneBudgetDTO[]>(
            (resolve, reject) => {
                this.http.get(this.url_endpoint + api.configurazione_budget_annuale_date_ottieni, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
      * Ottiene la configurazione budget annuale attiva e quella programmata
      *
      * @return { Promise<BudgetAnnualeDTO[]> }
      */
    getBudgets(): Promise<BudgetAnnualeDTO[]> {
        return new Promise<BudgetAnnualeDTO[]>(
            (resolve, reject) => {
                this.http.get(this.url_endpoint + api.configurazione_budget_annuale_ottieni, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Ottiene la prima data disponibile per la configurazione (default date)
     *
     * @return { Promise<{configurazioneEsistente:boolean, defaultDate:string}> }
     */

    getDefaultDate(): Promise<{ configurazioneEsistente: boolean, defaultDate: string }> {
        return new Promise<{ configurazioneEsistente: boolean, defaultDate: string }>(
            (resolve, reject) => {
                this.http.get(this.url_endpoint + api.configurazione_budget_annuale_impota_pagina, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Invia la configurazione budget annuale
     *
     * @return { Promise<any> }
     */
    invia(configurazioneBudgetAnnualeDTO: ConfigurazioneBudgetAnnualeDTO): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                this.http.post(this.url_endpoint + api.configurazione_budget_annuale_aggiungi, configurazioneBudgetAnnualeDTO, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Modifica una voce di costo
     *
     * @param { ModificaVoceDiCostoDTO } configurazioneBudgetAnnualeDTO
     * @return { Promise<any> }
     */
    modificaVoceDiCosto(dto: ModificaVoceDiCostoDTO): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                this.http.put(this.url_endpoint + api.configurazione_budget_annuale_modifica, dto, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Rimuovi configurazione
     *
     * @param { number } id
     * @return { Promise<boolean> }
     */
    rimuoviConfigurazione(id: number): Promise<boolean> {
        return new Promise<boolean>(
            (resolve, reject) => {
                const params = new HttpParams()
                    .append('idConfiguration', id);
                this.http.delete(this.url_endpoint + api.configurazione_budget_annuale_rimuovi, { params, observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(true);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Rimuovi una voce di costo nella configurazione corrente
     *
     * @param { number } id
     * @return { Promise<boolean> }
     */
    rimuoviVoceDiCosto(id: number): Promise<boolean> {
        return new Promise<boolean>(
            (resolve, reject) => {
                const params = new HttpParams()
                    .append('idCostItem', id);
                this.http.delete(this.url_endpoint + api.configurazione_budget_annuale_rimuovi_voce_costo, { params, observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(true);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

}
