
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take } from "rxjs";
import { PaginationDTO, SearchQueryDTO, TipologiaBigliettoDTO } from "../dtos";

@Injectable({
    providedIn: 'root'
})
export class TicketService {

    private url_endpoint;
    constructor(private http: HttpClient, @Inject('env') env) {
        this.url_endpoint = env.environment.url_endpoint;
    }
    /**
     * Ottiene una lista dei titoli di viaggio in base alla query di ricerca
     *
     * @param { SearchQueryDTO } searchQuery
     * @return { Promise<PaginationDTO> }
     */
    advancedSearch(searchQuery: SearchQueryDTO): Promise<PaginationDTO> {
        return new Promise<PaginationDTO>(
            (resolve, reject) => {
                this.http.post(this.url_endpoint + api.tickets_ricerca_avanzata, searchQuery, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            // resolve(resp);
                            if (resp.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject(
                                    {
                                        status: 0,
                                        error: resp?.message
                                    }
                                );
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Ottiene il file con lista dei titoli di viaggio in base alla query di ricerca per il front office
     *
     * @param { 'CSV' | 'PDF' } tipoFile
     * @param { SearchQueryDTO } searchQuery
     * @param { string[] } colonne - nomi delle colonne da ottenere
     * @return { Promise<any> }
     */
    advancedSearchDowload(idGratuita: number, tipoFile: 'CSV' | 'PDF', searchQuery: SearchQueryDTO, colonne: string[]): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                const dto = {
                    idGratuita: idGratuita,
                    dtoRicerca: searchQuery,
                    colonne: colonne,
                    tipoFile: tipoFile
                };
                this.http.post(this.url_endpoint + api.tickets_scarica_titoli_viaggio_fo, dto, { observe: 'response', responseType: 'blob' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                          if (!!resp?.body) {
                            resolve(resp?.body);
                        }
                        else {
                            reject({
                                status: 3,
                                error: null
                            })
                        }

                        },
                        error: () => {
                          reject({
                            status: 3,
                            error: null
                        })
                        }
                    });
            });
    }

    /**
     * Ottiene una lista di gratuità in base alla query di ricerca
     *
     * @param { SearchQueryDTO } searchQuery
     * @return { Promise<PaginationDTO> }
     */
    advancedSearchBackoffice(searchQuery: SearchQueryDTO): Promise<PaginationDTO> {
        return new Promise<PaginationDTO>(
            (resolve, reject) => {
                this.http.post(this.url_endpoint + api.tickets_ricerca_avanzata_backoffice, searchQuery, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject(
                                    {
                                        status: 0,
                                        error: resp?.message
                                    }
                                );
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Ottiene il file con lista dei titoli di viaggio in base alla query di ricerca per il back office
     *
     * @param { 'CSV' | 'PDF' } tipoFile
     * @param { SearchQueryDTO } searchQuery
     * @param { string[] } colonne - nomi delle colonne da ottenere
     * @return { Promise<any> }
     */
    advancedSearchBackofficeDownload(idGratuita: number, tipoFile: 'CSV' | 'PDF', searchQuery: SearchQueryDTO, colonne: string[]): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                const dto = {
                    idGratuita: idGratuita,
                    dtoRicerca: searchQuery,
                    colonne: colonne,
                    tipoFile: tipoFile
                };
                this.http.post(this.url_endpoint + api.tickets_scarica_titoli_viaggio_bo, dto, { observe: 'response', responseType: 'blob' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                          if (!!resp?.body) {
                            resolve(resp?.body);
                        }
                        else {
                            reject({
                                status: 3,
                                error: null
                            })
                        }

                        },
                        error: () => {
                          reject({
                            status: 3,
                            error: null
                        })
                        }
                    });
            });
    }


    /**
     * Ottiene la tipologie del biglietto
     *
     * @returns {Promise<TipologiaBigliettoDTO[]>}
     */
    get(): Promise<TipologiaBigliettoDTO[]> {
        return new Promise<TipologiaBigliettoDTO[]>(
            (resolve, reject) => {
                this.http.get(this.url_endpoint + api.tickets_tipologie_biglietto, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: HttpResponse<any>) => {
                            resolve(resp?.body);
                            // if (!!resp?.body) {
                            //     resolve(true);
                            // }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }
}
