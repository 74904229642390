import { PercentualeBigliettoConfigurationDTO } from "../dtos";
import { PercentualeBigliettoInterface } from "../interfaces";

export class PercentualeBigliettoModel implements PercentualeBigliettoInterface {

  public tipologieBiglietto: number;
  protected tipologiaBigliettoDescrizione: string;
  protected id: number;
  protected tipologiaBiglietto: number;
  protected percentualeBiglietto: number;
  protected modificato: boolean = false;
  protected listaTraduzioni: Array<any>;

  constructor(dto?: PercentualeBigliettoConfigurationDTO){
   // this.tipologiaBiglietto = dto?.tipologieBiglietto;
   if(dto){
    this.tipologiaBiglietto = dto?.id;
    this.percentualeBiglietto = dto?.percentuale;
    this.listaTraduzioni = dto?.listaTraduzioni;
    this.id = dto?.id
    this.tipologiaBigliettoDescrizione = this.listaTraduzioni?.at(0)?.etichetta;
   }

  }
  getId(): number {
    return this.id;
  }
  isModificato(): boolean {
    return this.modificato;
  }
  setModifica(modifica: boolean) {
    this.modificato = modifica;
  }
  getTipologiaBigliettoDescrizione(): string {
    return this.tipologiaBigliettoDescrizione
  }
  setTipologiaBigliettoDescrizione(tipologia: string) {
    this.tipologiaBigliettoDescrizione = tipologia;
  }
  setTipologiaBiglietto(tipologia: number) {
    this.tipologiaBiglietto = tipologia;
  }

  getTipologieBiglietto(): number {
    return this.tipologiaBiglietto;
  }
  getPercentualeBiglietto(): number {
    return this.percentualeBiglietto;
  }
  setTipologieBiglietto(tipologie: number) {
    this.tipologieBiglietto = tipologie;
  }
  setPercentualeBiglietto(percentuale: number) {
    this.percentualeBiglietto = percentuale;
  }

}
