import { Directive, ElementRef, Input } from '@angular/core';
import { ImageNewsDTO } from '../dtos';

@Directive({
  selector: '[appGetBase64]'
})
export class GetBase64Directive {

  @Input() set setBase64(file: ImageNewsDTO) {
    if (!!file) {
      const img = `data:${file.estensioneDocumento};base64,${file.immagine}`
      this.el.nativeElement.src = img;
      this.el.nativeElement.title = file.alt
      this.el.nativeElement.alt = file.alt
    }
  }
  
  constructor(private el: ElementRef<HTMLImageElement>) { }
}
