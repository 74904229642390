import { PaginationDTO } from "../dtos";
import { InvoiceInterface, PaginationInterface } from "../interfaces";
import { InvoiceModel } from "./invoice.model";

export class InvoicePaginationModel implements PaginationInterface {

    protected content: InvoiceInterface[];
    protected numberOfElements: number;
    protected totalElements: number;
    protected last: boolean;
    protected first: boolean;

    constructor(dto: PaginationDTO) {
        this.content = dto?.content?.map((el) => new InvoiceModel(el));
        this.numberOfElements = dto?.numberOfElements;
        this.totalElements = dto?.totalElements;
        this.last = dto?.last;
        this.first = dto?.first;
    }

    getContent(): InvoiceInterface[] {
        return this.content;
    }

    getNumberOfElements(): number {
        return this.numberOfElements;
    }
    getTotalElements(): number {
        return this.totalElements;
    }
    isLast(): boolean {
        return this.last;
    }
    isFirst(): boolean {
        return this.first;
    }
}