import { AddressDTO } from "../dtos";
import { AddressInterface } from "../interfaces";

export class AddressModel implements AddressInterface {
  private codicePostaleDomicilio?: string;
  private codicePostaleResidenza?: string;
  private coincidono?: boolean;
  private comuneDomicilio?: string;
  private comuneResidenza?: string;
  private dataAggiornamento?: Date;
  private dataAttivazione?: Date;
  private dataCreazione?: Date;
  private dataDisattivazione?: Date;
  private id?: number;
  private numeroCivicoDomicilio?: string;
  private numeroCivicoResidenza?: string;
  private provinciaDomicilio?: string;
  private provinciaResidenza?: string;

  constructor(dto: AddressDTO) {
    this.id = dto?.id;
    this.coincidono = dto?.coincidono;
    this.codicePostaleResidenza = dto?.codicePostaleResidenza;
    this.comuneResidenza = dto?.comuneResidenza;
    this.provinciaResidenza = dto?.provinciaResidenza;
    this.numeroCivicoResidenza = dto?.numeroCivicoResidenza;
    this.codicePostaleDomicilio = dto?.codicePostaleDomicilio;
    this.comuneDomicilio = dto?.comuneDomicilio;
    this.provinciaDomicilio = dto?.provinciaDomicilio;
    this.numeroCivicoDomicilio = dto?.numeroCivicoDomicilio;
    
    this.dataAttivazione = !!dto?.dataAttivazione ? new Date(dto.dataAttivazione) : null;
    this.dataDisattivazione = !!dto?.dataDisattivazione ? new Date(dto.dataDisattivazione) : null;
    this.dataCreazione = !!dto?.dataCreazione ? new Date(dto.dataCreazione) : null;
    this.dataAggiornamento = !!dto?.dataAggiornamento ? new Date(dto.dataAggiornamento) : null;
  }

  // Metodi getter

  public getCodicePostaleDomicilio(): string {
    return this.codicePostaleDomicilio;
  }

  public getCodicePostaleResidenza(): string {
    return this.codicePostaleResidenza;
  }

  public getCoincidono(): boolean {
    return this.coincidono;
  }

  public getComuneDomicilio(): string {
    return this.comuneDomicilio;
  }

  public getComuneResidenza(): string {
    return !!this.comuneResidenza ? this.comuneResidenza : null;
  }

  public getDataAggiornamento(): Date {
    return this.dataAggiornamento;
  }

  public getDataAttivazione(): Date {
    return this.dataAttivazione;
  }

  public getDataCreazione(): Date {
    return this.dataCreazione;
  }

  public getDataDisattivazione(): Date {
    return this.dataDisattivazione;
  }

  public getId(): number {
    return this.id;
  }

  public getNumeroCivicoDomicilio(): string {
    return this.numeroCivicoDomicilio;
  }

  public getNumeroCivicoResidenza(): string {
    return this.numeroCivicoResidenza;
  }

  public getProvinciaDomicilio(): string {
    return this.provinciaDomicilio;
  }

  public getProvinciaResidenza(): string {
    return this.provinciaResidenza;
  }

  // Metodi setter

  public setCodicePostaleDomicilio(codicePostaleDomicilio: string): void {
    this.codicePostaleDomicilio = codicePostaleDomicilio;
  }

  public setCodicePostaleResidenza(codicePostaleResidenza: string): void {
    this.codicePostaleResidenza = codicePostaleResidenza;
  }

  public setCoincidono(coincidono: boolean): void {
    this.coincidono = coincidono;
  }

  public setComuneDomicilio(comuneDomicilio: string): void {
    this.comuneDomicilio = comuneDomicilio;
  }

  public setComuneResidenza(comuneResidenza: string): void {
    this.comuneResidenza = comuneResidenza;
  }

  public setDataAggiornamento(dataAggiornamento: Date): void {
    this.dataAggiornamento = dataAggiornamento;
  }

  public setDataAttivazione(dataAttivazione: Date): void {
    this.dataAttivazione = dataAttivazione;
  }

  public setDataCreazione(dataCreazione: Date): void {
    this.dataCreazione = dataCreazione;
  }

  public setDataDisattivazione(dataDisattivazione: Date): void {
    this.dataDisattivazione = dataDisattivazione;
  }

  public setId(id: number): void {
    this.id = id;
  }

  public setNumeroCivicoDomicilio(numeroCivicoDomicilio: string): void {
    this.numeroCivicoDomicilio = numeroCivicoDomicilio;
  }

  public setNumeroCivicoResidenza(numeroCivicoResidenza: string): void {
    this.numeroCivicoResidenza = numeroCivicoResidenza;
  }

  public setProvinciaDomicilio(provinciaDomicilio: string): void {
    this.provinciaDomicilio = provinciaDomicilio;
  }

  public setProvinciaResidenza(provinciaResidenza: string): void {
    this.provinciaResidenza = provinciaResidenza;
  }

  public toDTO(): AddressDTO {
    return {
      id: this.id,
      numeroCivicoDomicilio: this.numeroCivicoDomicilio,
      numeroCivicoResidenza: this.numeroCivicoResidenza,
      codicePostaleDomicilio: this.codicePostaleDomicilio,
      codicePostaleResidenza: this.codicePostaleResidenza,
      comuneDomicilio: this.comuneDomicilio,
      comuneResidenza: this.comuneResidenza,
      provinciaDomicilio: this.provinciaDomicilio,
      provinciaResidenza: this.provinciaResidenza,
      coincidono: this.coincidono,
      dataAttivazione: this.dataAttivazione?.toJSON(), // datepipe.transform(this.dataAttivazione, 'yyyy-MM-dd'),
      dataDisattivazione: this.dataDisattivazione?.toJSON(), // datepipe.transform(this.dataDisattivazione, 'yyyy-MM-dd'),
      dataCreazione: this.dataCreazione?.toJSON(), // datepipe.transform(this.dataCreazione, 'yyyy-MM-dd'),
      dataAggiornamento: this.dataAggiornamento?.toJSON() // datepipe.transform(this.dataAggiornamento, 'yyyy-MM-dd')
    };
  }
}
