import { Injectable, Optional } from "@angular/core";
import * as CryptoJS from 'crypto-js';

export class CryptoConfig {
    key: string;
}

@Injectable({
    providedIn: 'root'
})
export class CryptoService {
    private KEY: string;

    constructor(@Optional() config?: CryptoConfig) {
        if (config) {
            this.KEY = config.key;
        }
    }

    /**
     * Cifra il valore di input
     * @param { string } value
     * @returns { string }
     */
    set(value: string) {
      const key = CryptoJS.SHA256(this.KEY);
      const iv = CryptoJS.SHA256(this.KEY);
      const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key, {
                keySize: 128 / 8,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
        return encrypted.toString();
    }

    /**
     * Decifra il valore di input
     * @param { string } value
     * @returns { string }
     */
    get(value: string) {
      const key = CryptoJS.SHA256(this.KEY);
      const iv = CryptoJS.SHA256(this.KEY);
      const decrypted = CryptoJS.AES.decrypt(value, key, {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
      return decrypted.toString(CryptoJS.enc.Utf8);
  }

}
