import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { take } from 'rxjs';
import { api } from '../constants';
import { TagsDTO } from '../dtos';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }

  public async ottieni(id: number): Promise < TagsDTO[] > {
  return new Promise((resolve, reject) => {
    const params = new HttpParams().append('id', id);
    this.http.get(this.url_endpoint + api.tags_ottieni, { observe: 'body', params })
      .pipe(take(1))
      .subscribe({
        next: (resp: any) => {
          if (!!resp?.body && resp?.status == 'OK') {
            resolve(resp?.body);
          } else {
            reject(
              {
                status: 2,
                error: resp?.message
              }
            );
          }
        },
        error: (error) => {
          reject(error);
        }
      });
  })
}

  public async ricerca(lavel: string): Promise < TagsDTO[] > {
  return new Promise((resolve, reject) => {
    const params = new HttpParams().append('label', lavel);
    this.http.get(this.url_endpoint + api.tags_ricerca, { observe: 'body', params })
      .pipe(take(1))
      .subscribe({
        next: (resp: any) => {
          if (!!resp?.body && resp?.status == 'OK') {
            resolve(resp?.body);
          } else {
            reject(
              {
                status: 2,
                error: resp?.message
              }
            );
          }
        },
        error: (error) => {
          reject(error);
        }
      });
  })
}
}
