import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Newsfacade } from '../pages/news/facades';
import { NewsInterface } from '../interfaces';

export const dettaglioNewsFullresolver: ResolveFn<NewsInterface> = async (route) => {
  const id = parseInt(route.paramMap.get('id')!);
  const news = await inject(Newsfacade);
  return news.ottieniFull(id);
};
