import { SpesaDTO } from "../dtos";
import { SpesaInterface } from "../interfaces";


export class SpesaModel implements SpesaInterface {
    protected anno: number;
    protected comuneBeneficiario: string;
    // protected id: number;
    protected importo: number;
    protected mese: number;
    protected provinciaBeneficiario: string;
    protected tipoBiglietto: string;

    constructor(public dto: SpesaDTO) {
        this.anno = dto?.anno;
        this.comuneBeneficiario = dto?.comuneBeneficiario;
        this.importo = dto?.importo;
        this.mese = dto?.mese;
        this.provinciaBeneficiario = dto?.provinciaBeneficiario;
        this.tipoBiglietto = dto?.tipoBiglietto;
    }

    getAnno(): number {
        return this.anno;
    }

    getComuneBeneficiario(): string {
        return this.comuneBeneficiario;
    }
    
    getMese(): number {
        return this.mese;
    }

    getProvinciaBeneficiario(): string {
        return this.provinciaBeneficiario;
    }

    getTipologiaBiglietto(): string {
        return this.tipoBiglietto;
    }

    getImporto(): number {
        return this.importo;
    }
}