import { Injectable } from "@angular/core";
import { AddressDTO, AppIoDTO, AppIoModel, AppIoService, EmailService, PhoneNumberService, AnagraficaDTO, RichiedenteService, UtenteModel, UtenteService, TIPO_NOTIFICHE, UtenteInterface, UtenteDTO, ValidaUtenteInterface, MobileModel } from "core-model";
import { ErrorFacade } from "./error.facade";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthFacade } from ".";

@Injectable({
    providedIn: 'root'
})
export class AccountValidationFacade {
    constructor(
        private appIoService: AppIoService,
        private authFacade: AuthFacade,
        private errorFacade: ErrorFacade,
        private emailService: EmailService,
        private phoneNumberService: PhoneNumberService,
        private richiedenteService: RichiedenteService,
        private spinner: NgxSpinnerService,
        private utenteService: UtenteService
    ) { }
    /**
     * Invio accettazione privacy
     *
     * @return { Promise<boolean> } A promise that resolves to a boolean indicating if the operation was successful.
     */
    async acceptPrivacy(): Promise<boolean> {
        try {
            await this.richiedenteService.acceptPrivacy();
            return true;
        } catch (error) {
            this.errorFacade.handle(error, null);
        }
        return null;
    }
    /**
     * Chiude gli eventuali alert di errore aperti.
     */
    clearErrorMessage() {
        this.errorFacade.clearErrorMessage();
    }

    /**
     * Invio dei dati di anagrafica dell'utente, il metodo aggiorna il current user
     *
     * @param { AnagraficaDTO } registry - The registry object.
     * @param { AddressDTO } address - The address object.
     * @param { boolean } fuoriRegione
     * @return { Promise<boolean> } A promise that resolves to a boolean indicating if the operation was successful.
     */
    async postAnagrafica(registry: AnagraficaDTO, address: AddressDTO, fuoriRegione: boolean): Promise<boolean> {
        try {
            this.spinner.show();
            const dto: UtenteDTO = await this.richiedenteService.postGenerality(registry, address, fuoriRegione);
            const utente: UtenteInterface = new UtenteModel(dto);
            this.authFacade.saveUser(utente);
            return !!utente;
        } catch (error) {
            this.errorFacade.handle(error, null, 'anagrafica');
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    // TODO ELIMINARE sendSceltaNotifica
    /**
     * Invia il tipo di notifica selezionato e salva la scelta dell'utente.
     *
     * @param { TIPO_NOTIFICHE } type - il tipo di notifica da inviare
     * @return { boolean } true se l'utente viene salvato con successo, altrimenti false
     */
    async sendSceltaNotifica(type: TIPO_NOTIFICHE) {
        try {
            this.spinner.show();
            const dto = await this.richiedenteService.sceltaNotifica(type);
            const utente = new UtenteModel(dto);
            this.authFacade.saveUser(utente);
            return !!utente;
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
     * Richiede l'invio del codice di validazione su AppIO.
     *
     * @return { Promise<UtenteInterface> }
     */
    async requestValidationCodeAppIo(): Promise<UtenteInterface> {
        try {
            this.spinner.show();
            const dto: AppIoDTO = await this.appIoService.requestValidationCode();
            const currentUser = await this.authFacade.getCurretUser();
            currentUser.setAppIo(new AppIoModel(dto));
            this.authFacade.saveUser(currentUser);
            return currentUser;
        } catch (error) {
            this.errorFacade.handle(error, null, 'requestCode');
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
     * Sends an email to the system.
     *
     * @param {string} email - The email to be added.
     * @param {string} confirmEmail - The confirmed email.
     * @return {Promise<anyl>} A promise that resolves when the email is added successfully.
     */
    async sendEmail(email: string, confirmEmail: string): Promise<any> {
        try {
            this.spinner.show();
            return await this.emailService.sendEmail(email, confirmEmail);
        } catch (error) {
            this.errorFacade.handle(error, null, 'email');
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
     * Invia il numero di telefono da validare
     *
     * @param {string} phoneNumber
     * @param {string} confirmPhoneNumber
     * @return { Promise<ValidaUtenteInterface> }
     */
    async sendPhoneNumber(phoneNumber: string, confirmPhoneNumber: string): Promise<ValidaUtenteInterface> {
        try {
            this.spinner.show();
            const dto = await this.phoneNumberService.sendPhoneNumber(phoneNumber, confirmPhoneNumber);
            const lastPhoneNumber = new MobileModel(dto);
            const currentUser: ValidaUtenteInterface = await this.authFacade.getCurretUser(true) as ValidaUtenteInterface;
            currentUser.savePhoneNumber(lastPhoneNumber);
            this.authFacade.saveUser(currentUser);
            return currentUser;
        } catch (error) {
            this.errorFacade.handle(error, null, 'phoneNumber');
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
     * Asynchronously validates an email using the provided UUID.
     *
     * @param { string } code - Codice inviato su AppIO dell'utente soggetto a validazione
     * @return { Promise<UtenteInterface> }
     */
    async validAppIo(code: string): Promise<UtenteInterface> {
        this.spinner.show();
        try {
            await this.appIoService.valid(code);
            // const currentUser = await this.authFacade.getCurretUser();
            // currentUser.setAppIo(new AppIoModel(dto));
            const currentUser = await this.authFacade.checkAndRestoreCurrentUser();
            this.authFacade.saveUser(currentUser);
            return currentUser;
        } catch (error) {
            this.errorFacade.handle(error, null, 'appio');
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
     * Asynchronously validates an email using the provided UUID.
     *
     * @param {string} code - The code of the email to be validated.
     * @return {Promise<boolean>} A promise that resolves to a boolean value indicating
     *         whether the email was successfully validated or not.
     */
    async validEmail(code: string): Promise<boolean> {
        this.spinner.show();
        try {
            return await this.emailService.validEmail(code);
        } catch (error) {
            this.errorFacade.handle(error, null, 'validationCode');
        } finally {
            this.spinner.hide();
        }
        return false;
    }

    /**
         * Vlidates a phone number.
         *
         * @param {string} code - The code to validate the phone number.
         * @return {Promise<boolean>} A promise that resolves to a boolean indicating if the phone number is valid.
         */
    async validPhoneNumber(code: string): Promise<boolean> {
        this.spinner.show();
        try {
            return await this.phoneNumberService.validPhoneNumber(code);
        } catch (error) {
            this.errorFacade.handle(error, null, 'validationCode');
        } finally {
            this.spinner.hide();
        }
        return false;
    }
}
