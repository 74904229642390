
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EmailService {

    private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }

  /**
   * Adds an email to the system.
   *
   * @param {string} email - The email to be added.
   * @param {string} confirmEmail - The confirmed email.
   * @return {Promise<any>} A promise that resolves when the email is added successfully.
   */
  sendEmail(email: string, confirmEmail: string): Promise<any> {
    return new Promise<any>(
      (resolve, reject) => {
        // const formData: FormData = new FormData();
        // formData.append('email', email);
        // formData.append('confirmEmail', confirmEmail);
        // { email: email, confirmEmail: confirmEmail },
        this.http.post(this.url_endpoint + api.email_aggiungi, { email: email, confirmEmail: confirmEmail }, { observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              if (!!resp?.body) {
                resolve(resp?.body);
              } else {
                reject({
                  status: 0,
                  error: resp?.message
                })
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  /**
   * Cambia lo stato di un'email.
   *
   * @param {string} email - L'email.
   * @return {Promise<boolean>} Una promessa che si risolve con un valore booleano.
   */
  cambiaStatoEmail(email: string): Promise<boolean> {
    return new Promise<boolean>(
      (resolve, reject) => {
        const params = new HttpParams()
          .append('email', email)
        this.http.put(this.url_endpoint + api.email_cambia_stato, null, { params: params, observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              if (!!resp?.body) {
                resolve(true);
              } else {
                reject({
                  status: 0,
                  error: resp?.message
                })
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  /**
   * Valida un'email utilizzando il codice fornito.
   *
   * @param {string} code - Il codice dell'email da validare.
   * @return {Promise<string>} Una Promise che si risolve quando la validazione dell'email ha successo, e viene rifiutata se si verifica un errore.
   */
  validEmail(code: string): Promise<boolean> {
    return new Promise<boolean>(
      (resolve, reject) => {
        // const formData: FormData = new FormData();
        // formData.append('uuid', code);
        // formData.append('code', code);
        const data = {
          uuid: code,
          code: code
        }
        this.http.put(this.url_endpoint + api.email_validazione, data, { params: data, observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: HttpResponse<any>) => {
              if (!!resp?.body) {
                resolve(true);
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  /**
   * Rinnova l'attivazione di una email.
   *
   * @param {string} email - L'indirizzo email da rinnovare.
   * @return {Promise<boolean>} Una Promise che restituisce true se l'attivazione è stata rinnovata con successo.
   */
  rinnovaAttivazioneEmail(email: string): Promise<boolean> {
    return new Promise<boolean>(
      (resolve, reject) => {
        const params = new HttpParams()
          .append('email', email);
        this.http.put(this.url_endpoint + api.email_rinnova_attivazione, null, { params: params, observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: HttpResponse<any>) => {
              if (!!resp?.body) {
                resolve(true);
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  /**
   * API per l'abilitazione/disabilitazione della ricezione sulle notifiche di una mail.
   *
   * @param {number} id - L'ID dell'elemento per il quale attivare la notifica via email.
   * @return {Promise<boolean>} Una promise che si risolve a `true` se l'attivazione è avvenuta con successo.
   */
  attivazioneNotificaEmail(id: number): Promise<boolean> {
    return new Promise<boolean>(
      (resolve, reject) => {
        const params = new HttpParams()
          .append('id', id);
        this.http.put(this.url_endpoint + api.email_attivazione_notifica, null, { params: params, observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: HttpResponse<any>) => {
              if (!!resp?.body) {
                resolve(true);
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

}
