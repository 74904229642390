import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ImageNewsDTO } from '../dtos';
import { api } from '../constants';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

    private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }

  ottieniCoprtina(id: number): Promise<ImageNewsDTO> {
    return new Promise((resolve, reject) => {
      const params = new HttpParams().append('id', id);
      this.http.get(this.url_endpoint + api.immagine_copertina, { observe: 'body', params })
        .pipe(take(1))
        .subscribe({
          next: (resp: any) => {
            if (!!resp?.body && resp?.status == 'OK') {
              resolve(resp?.body);
            } else {
              reject(
                {
                  status: 2,
                  error: resp?.message
                }
              );
            }
          },
          error: (error) => {
            reject(error);
          }
        });
    })
  }

  ottieniImmaginiCarousel(id: number): Promise<ImageNewsDTO[]> {
    return new Promise((resolve, reject) => {
      const params = new HttpParams().append('id', id);
      this.http.get(this.url_endpoint + api.immagine_carosello, { observe: 'body', params })
        .pipe(take(1))
        .subscribe({
          next: (resp: any) => {
            if (!!resp?.body && resp?.status == 'OK') {
              resolve(resp?.body);
            } else {
              reject(
                {
                  status: 2,
                  error: resp?.message
                }
              );
            }
          },
          error: (error) => {
            reject(error);
          }
        });
    })
  }
}
