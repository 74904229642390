import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take, tap } from "rxjs";
import { FileDTO } from "../dtos/file.dto";
import { FILE_TYPE } from "../constants";
import { DelegatoDeleganteDTO } from "../dtos";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }

  /**
   * Download il documento della richiesta di gratuità
   *
   * @param { number } idFreeInstance
   * @param { number } idFile
   * @param { FILE_TYPE } fileType
   * @return { Promise<any> }
   */
  download(idFreeInstance: number, idFile: number, fileType: FILE_TYPE): Promise<any> {
    return new Promise<any>(
      (resolve, reject) => {
        const params = new HttpParams()
          .append('idFreeInstance', idFreeInstance)
          .append('idFile', idFile)
          .append('fileType', fileType.toString());
        this.http.get(this.url_endpoint + api.file_scarica_documento, { params, observe: 'response', responseType: 'blob' })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              if (!!resp?.body) {
                resolve(resp?.body);
              } else {
                reject({
                  status: 0,
                  error: resp?.message
                })
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  /**
  * Download il documento della richiesta di gratuità
  *
  * @param { number } idFreeInstance
  * @param { number } idFile
  * @param { FILE_TYPE } fileType
  * @return { Promise<any> }
  */
  downloadFileBackOffice(idFreeInstance: number, idFile: number, fileType: FILE_TYPE): Promise<any> {
    return new Promise<any>(
      (resolve, reject) => {
        const params = new HttpParams()
          .append('idFreeInstance', idFreeInstance)
          .append('idFile', idFile)
          .append('fileType', fileType.toString());
        this.http.get(this.url_endpoint + api.file_recupera_documento, { params, observe: 'response', responseType: 'blob' })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              if (!!resp?.body) {
                resolve(resp?.body);
              } else {
                reject({
                  status: 0,
                  error: resp?.message
                })
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  /**
   * Genera il documento della richiesta di gratuità
   *
   * @param { number } idFreeInstance
   * @param { FILE_TYPE } fileType
   * @return { Promise<any> }
   */
  genera(idFreeInstance: number, fileType: FILE_TYPE): Promise<any> {
    return new Promise<any>(
      (resolve, reject) => {
        const params = new HttpParams()
          .append('idFreeInstance', idFreeInstance)
          .append('templateType', fileType.toString());
        this.http.get(this.url_endpoint + api.file_genera_documento, { params, observe: 'response', responseType: 'blob' })
          .pipe(
            take(1),
            tap(
              (resp: HttpResponse<any>) => {
                if (resp.headers.get('Content-Type').search('application/json') && resp?.body?.status == 'KO') {
                  throw { status: 0, error: resp?.body?.message };
                }
              }
            )
          ).subscribe({
            next: (resp: any) => {
              if (resp?.body) {
                resolve(resp?.body);
              } else {
                reject({
                  status: 0,
                  error: resp?.message
                })
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error)
            }
          });
      });
  }

  /**
   * Genera il documento di delega per il riscatto della richiesta di gratuità
   *
   * @param { number } idFreeInstance
   * @param { DelegatoDeleganteDTO  } delega - La delega da aggiungere.
   * @return { Promise<any> }
   */
  generaDelegaRiscatto(idFreeInstance: number, delega: DelegatoDeleganteDTO): Promise<any> {
    return new Promise<any>(
      (resolve, reject) => {
        const params = new HttpParams()
          .append('idFreeInstance', idFreeInstance);
        this.http.post(this.url_endpoint + api.file_genera_delega_riscatto, delega, { params, observe: 'response', responseType: 'blob' })
          .pipe(
            take(1),
            tap(
              (resp: HttpResponse<any>) => {
                if (resp.headers.get('Content-Type').search('application/json') && resp?.body?.status == 'KO') {
                  throw { status: 0, error: resp?.body?.message };
                }
              }
            )
          ).subscribe({
            next: (resp: any) => {
              if (resp?.body) {
                resolve(resp?.body);
              } else {
                reject({
                  status: 0,
                  error: resp?.message
                })
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error)
            }
          });
      });
  }


  /**
   * Upload un file.
   *
   * @param { number } idGratuita
   * @param { FileDTO } fileDto
   * @param { FILE_TYPE } fileType
   * @return { any }
   */
  upload(idGratuita: number, fileDto: FileDTO, fileType: FILE_TYPE): any {
    fileDto.tipologiaFile = fileType;
    const dto = {
      idGratuita: idGratuita,
      fileData: fileDto
    };
    return this.http.put(this.url_endpoint + api.file_carica_documento, dto, { reportProgress: true, observe: 'events' });
  }

  /**
   * Upload i files di delega e carta d'identità.
   *
   * @param { number } idGratuita
   * @param { FileDTO } dichiarazione
   * @param { FileDTO } datiCartaIdentita
   * @return { any }
   */
  uploadDelega(idGratuita: number, dichiarazione: FileDTO, datiCartaIdentita: FileDTO): any {
    const dto = {
      idGratuita: idGratuita,
      fileData: dichiarazione,
      datiCartaIdentita: datiCartaIdentita
    };
    return this.http.put(this.url_endpoint + api.file_carica_documento, dto, { reportProgress: true, observe: 'events' });
  }
}
