import { CategoriaPercentualeModel, PercentualeBigliettoModel } from "../models";
import { CategoriaPercentualeInterface, FasciaIseePercentualeInterface, PercentualeBigliettoInterface } from "../interfaces";
import { FasciaIseePercentualeConfigurationDTO } from "../dtos";

export class FasciaIseePercentualeModel implements FasciaIseePercentualeInterface {
  protected idForm: string;
  protected id: number;
  protected nomeFascia: string;
  protected dataCreazione: string;
  protected dataProgrammazione: string;
  protected da_attivare_configurazione: boolean;
  protected attivo_configurazione: boolean;
  protected inizioFascia: string;
  protected fineFascia: string;
  protected listaCategorie: Array<CategoriaPercentualeInterface> = [];
  protected percentualiTipologiaBiglietto: Array<PercentualeBigliettoInterface> = [];

  constructor(dto?: FasciaIseePercentualeConfigurationDTO){
    if(dto){
      this.id = dto?.id;
      this.nomeFascia = dto?.nomeFascia;
      this.inizioFascia = dto?.inizioFascia;
      this.dataCreazione = dto?.dataCreazione;
      this.fineFascia = dto?.fineFascia;
      this.attivo_configurazione = dto?.attivoConfigurazione;
      this.da_attivare_configurazione = dto?.daAttivareConfigurazione;
      this.listaCategorie = !! dto?.listaCategorie ? dto?.listaCategorie.map((dto) => new CategoriaPercentualeModel(dto)):[];
      this.percentualiTipologiaBiglietto = !!dto?.percentualiTipologiaBiglietto ? dto?.percentualiTipologiaBiglietto?.map((dto) => new PercentualeBigliettoModel(dto)):[];
    }

  }
  isAttivoConfigurazione(): boolean {
    return this.attivo_configurazione;
  }
  isDaAttivareConfigurazione(): boolean {
    return this.da_attivare_configurazione;
  }
  getId(): number {
    return this.id;
  }
  getDataProgrammazione(): string {
    return this.dataProgrammazione;
  }
  getDataCreazione(): string {
    return this.dataCreazione;
  }


  getIdForm(): string {
    return this.idForm;
  }

  setIdForm(id: string) {
    this.idForm = id;
  }

  getNomeFascia(): string {
    return this.nomeFascia;
  }
  getInizioFascia(): string {
    return this.inizioFascia;
  }
  getFineFascia(): string {
    return this.fineFascia;
  }
  getListaCategorie(): CategoriaPercentualeInterface[] {
    return this.listaCategorie;
  }
  getPercentualiTipologiaBiglietto(): PercentualeBigliettoInterface[] {
    return this.percentualiTipologiaBiglietto;
  }
  setNomeFascia(fascia: string) {
    this.nomeFascia = fascia;
  }
  setInizioFascia(inizio: string) {
    this.inizioFascia = inizio;
  }
  setFineFascia(fine: string) {
    this.fineFascia = fine;
  }
  setListaCategorie(categorie: CategoriaPercentualeInterface[]) {
    this.listaCategorie = categorie;
  }
  setPercentualiTipologiaBiglietto(percentuali: PercentualeBigliettoInterface[]) {
    this.percentualiTipologiaBiglietto = percentuali;
  }

}
