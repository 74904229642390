import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FieldInterface } from '../../interfaces';
import { FiltroProvider } from '../../provider';
@Component({
  selector: 'app-ricerca-avanzata',
  templateUrl: './ricerca-avanzata.component.html',
  styleUrls: ['./ricerca-avanzata.component.scss']
})
export class RicercaAvanzataComponent {

  @Output() reset: EventEmitter<any> = new EventEmitter();
  @Output() search: EventEmitter<any> = new EventEmitter();

  @Input() formValidators: any;
  @Input() maxColumns: number = 4;
  private keyMulti: FieldInterface[] = [];

  @Input() set fields(fields: FieldInterface[]) {

    if(!this.filtroService.hasFormSession()) {
      this.filtroService.setFormSession(this.filtroService.filterAndSortFields(fields));
    }
    else {
      this.filtroService.setFormSession(this.filtroService.getFormSession());
    }

    this.form = this.filtroService.getFormSession();
    this.filterableFields = this.filtroService.filterAndSortFields(fields);
    this.keyMulti.push(...this.filterableFields)
    !!this.formValidators && this.form.setValidators(this.formValidators);

    this.rows = this.filtroService.buildRows(this.rows, this.filterableFields, 0, 0, this.maxColumns);

    // TODO: verificare se serve mantenere il keymulti
    // this.keyMulti.forEach(field => {
    //   this.setMultiselectValues(field);
    // });

    for (const field of this.filterableFields) {
      if (field.controlType === 'multiselect') {
        this.setMultiselectValues(field);
      }
    }

    this.onSubmit();
  }

  private filterableFields: FieldInterface[];
  public rows: FieldInterface[][] = [[]];
  public form: FormGroup;
  public multiselectValues: any = {};

  constructor(private translate: TranslateService,
              private filtroService: FiltroProvider) { }

  public getFormArrayControls(key: string): Array<FormGroup> {
    return this.filtroService.getFormArrayControls(key);
  }

  public setMultiselectValues(field: FieldInterface) {
    const values = [];
    for (const fg of this.getFormArrayControls(field.key)) {
      if (fg.get('selected').value === true) {
        const label = field.options.find((option) => option.value === fg.get('name').value).label;
        values.push(this.translate.instant(label));
      }
    }
    this.multiselectValues[field.key] = values.join(', ');
  }

  public onSubmit() {
    this.filtroService.setCurrentSearch(this.filtroService.buildCurrentSearchValue(this.form));
    this.filtroService.setFormSession(this.form);
    this.search.emit(this.filtroService.getCurrentSearch());
  }

  public onReset() {
    for (const key of Object.keys(this.multiselectValues)) {
      this.multiselectValues[key] = '';
    }
    this.keyMulti = [];
    for (const field of this.filterableFields) {
      if (field.controlType !== 'multiselect') {
        this.form.get(field.key).setValue(null);
      } else {
        for (const innerForm of (this.form.get(field.key) as FormArray).controls) {
          innerForm.get('selected').setValue(false);
        }
      }
    }
    this.reset.emit();
  }
}
