import { PaginationDTO } from "../dtos";
import { PaginationInterface } from "../interfaces";
import { NewsModel } from "./news.model";

export class NewsPaginationModel implements PaginationInterface {

    private content: NewsModel[] = [];
    private pageable: PaginationDTO;
    private last: boolean;
    private numberOfElements: number;
    private totalElements: number;
    private first: boolean;

    constructor(dto: PaginationDTO) {
        this.content = dto?.content?.map((el) => new NewsModel(el));
        this.pageable = dto?.pageable;
        this.last = dto?.last;
        this.first = dto?.first;
        this.numberOfElements = dto?.numberOfElements;
        this.totalElements = dto?.totalElements;
    }

    getContent(): NewsModel[] {
        return this.content
    }
    getNumberOfElements(): number {
        return this.numberOfElements;
    }
    getTotalElements(): number {
        return this.totalElements;
    }
    isLast(): boolean {
        return this.last;
    }
    isFirst(): boolean {
        return this.first;
    }
}