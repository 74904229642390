<div class="container">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-12">
        <h1 id="title1">{{'NAVIGATION.CONTACTS' | translate}}</h1>
        <!-- <p>{{'CONTATTI.DESCRIPTION' | translate}}</p> -->
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <label [for]="'FORM.NOME' | translate" class="h6 text-primary mt-2">{{'FORM.NOME' | translate}}</label>
        <input [id]="'FORM.NOME' | translate" type="text" class="form-group" formControlName="nome"
          [placeholder]="'FORM.NOME' | translate">
      </div>
      <div class="col-6">
        <label [for]="'FORM.COGNOME' | translate" class="h6 text-primary mt-2">{{'FORM.COGNOME' |
          translate}}</label>
        <input [id]="'FORM.COGNOME' | translate" type="text" class="form-group" formControlName="cognome"
          [placeholder]="'FORM.COGNOME' | translate">
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <label [for]="'FORM.EMAIL' | translate" class="h6 text-primary mt-2">{{'FORM.EMAIL' | translate}}</label>
        <input [id]="'FORM.EMAIL' | translate" type="email" class="form-group" formControlName="email"
          [placeholder]="'FORM.EMAIL' | translate">
      </div>
      <div class="col-6 mt-3">
        <label for="topic" class="h6 text-primary mt-2" translate>
          FORM.TOPIC
        </label>
        <select class="form-select multi-line-select mt-1" id="topic" formControlName="topic"
          [title]="'FORM.PLACEHOLDER_TOPIC' | translate" [ariaPlaceholder]="'FORM.PLACEHOLDER_TOPIC' | translate">
          <option [ngValue]="null" disabled translate>FORM.PLACEHOLDER_TOPIC</option>
          <option [ngValue]="'FORM.TOPIC_INFORMAZIONI_PIATTAFORMA' | translate ">{{
            'FORM.TOPIC_INFORMAZIONI_PIATTAFORMA' | translate }}</option>
          <option [ngValue]="'FORM.TOPIC_SEGNALAZIONE_MALFUNZIONAMENTI' | translate ">{{
            'FORM.TOPIC_SEGNALAZIONE_MALFUNZIONAMENTI' | translate }}</option>
          <option [ngValue]="'FORM.TOPIC_RICHIESTE_STAMPA' | translate">{{ 'FORM.TOPIC_RICHIESTE_STAMPA' | translate }}
          </option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <label [for]="'FORM.MESSAGGIO' | translate" class="h6 text-primary mt-2">{{'FORM.MESSAGGIO' |
          translate}}</label>
        <textarea [id]="'FORM.MESSAGGIO' | translate" type="text" class="form-group" formControlName="messaggio"
          [placeholder]="'FORM.MESSAGGIO' | translate" rows="10" style="resize: none;"></textarea>
      </div>
    </div>
    <button type="submit" [disabled]="form.invalid" class="btn btn-primary">{{'FORM.SALVA' | translate}}</button>
  </form>
</div>
