import { RequisitoDatiIndennitaBeneficiarioDTO } from "../dtos/requisito-disabilita.dto";
import { DisabilitaInterface } from "../interfaces";

export class RequisitoDisabilitaModel implements DisabilitaInterface {
    private id?: number;
    protected attivo: boolean;
    private codice: string;
    private descrizione: string;
    protected attivo_configurazione: boolean;
    protected attivazioneNuovaConfigurazione: boolean;
    protected daAttivareConfigurazione: boolean;
    constructor(dto: RequisitoDatiIndennitaBeneficiarioDTO) {
        this.id = dto?.id;
        this.codice = dto?.codice;
        this.attivo = dto?.attivo;
        this.descrizione = dto?.listaTraduzioniDescrizione?.at(0).descrizione;
        this.daAttivareConfigurazione = dto?.daAttivareConfigurazione;
        this.attivo_configurazione = dto?.attivoConfigurazione;
    }
  isAttivo(): boolean {
    return this.attivo;
  }
  isAttivoConfigurazione(): boolean {
    return this.attivo_configurazione;
  }
  isDaAttivareConfigurazione(): boolean {
    return this.daAttivareConfigurazione;
  }

  setAttivazioneConfigurazione(attivazione){
    this.daAttivareConfigurazione = attivazione
  }

  setAttivaConfigurazione(attivazione){
    this.attivo_configurazione = attivazione
  }

  isAttivazioneNuovaConfigurazione(){
    return this.attivazioneNuovaConfigurazione;
  }

  setAttivazioneNuovaConfigurazione(attivazione){
    this.attivazioneNuovaConfigurazione = attivazione
  }

    getId(): number {
        return this.id;
    }

    getCodice(): string {
        return this.codice;
    }

    getDescrizione(): string {
        return this.descrizione;
    }
}
