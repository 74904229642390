import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dropdown-language',
  templateUrl: './dropdown-language.component.html',
  styleUrls: ['./dropdown-language.component.scss']
})
export class DropdownLanguageComponent implements OnInit, OnDestroy {
  @Output() public langEvent = new EventEmitter<string>();
  public lang: string = 'it';

  private locale$: Subscription;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {

    this.locale$ = this.langEvent.asObservable().subscribe((lang) => {
      this.lang = lang;
    });

    this.langEvent.emit(this.translate.currentLang);
    // this.locale$ = this.translate.onLangChange.subscribe(
    //   (event: LangChangeEvent) => {
    //     this.lang = event.lang;
    //    // this.languageService.setLanguage(this.lang);
    //   }
    // );
  }

  ngOnDestroy(): void {
    if (this.locale$?.closed == false) {
      this.locale$?.unsubscribe();
    }
  }

  public onSelect(lang:string) {
    this.langEvent.emit(lang);
  }
}
