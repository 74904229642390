import { GraficiMonitoraggioSpesaDTO, SerieCategoryDTO, SerieLineDTO } from "../dtos";
import { GraficiMonitoraggioSpesaInterface } from "../interfaces";

export class GraficiMonitoraggioSpesaModel implements GraficiMonitoraggioSpesaInterface {
    graficoProvince: SerieCategoryDTO;
    graficoTipiBiglietti: SerieLineDTO[];
    constructor(dto: GraficiMonitoraggioSpesaDTO) {
        this.graficoProvince = dto?.graficoProvince?.at(0);
        this.graficoTipiBiglietti = dto?.graficoTipiBiglietti;
    }

    getValueBigliettoBase(idx: number): number {
        const base = this.graficoTipiBiglietti?.find((d) => d.name == 'BASE');
        return !!base ? base?.data[idx] : 0;
    }

    getValueBigliettoAbbonamento(idx: number): number {
        const base = this.graficoTipiBiglietti?.find((d) => d.name == 'ABBONAMENTO');
        return !!base ? base?.data[idx] : 0;
    }

    getValueProvincia(name: string): number {
        const d = this.graficoProvince?.data?.find((d) => d.name == name);
        return !!d ? d.value : 0;
    }
}