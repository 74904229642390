import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { take } from 'rxjs';
import { api } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

    private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }

  invia(dto: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(this.url_endpoint + api.info_invia, dto, { observe: 'response' })
      .pipe(take(1))
      .subscribe({
        next: (resp: HttpResponse<boolean>) => {
          if (!!resp?.body) {
            resolve(resp?.body);
          } else {
            resolve(null);
          }
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        }
      });
    })
  }
}
