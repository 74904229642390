<div class="row" *ngIf="!!form">
    <div class="col-12">
        <form [formGroup]="form" class="bg-grey rounded-3 p-3 needs-validation">
            <h2 tabindex="0" class="h5" id="titleForm" translate>FORM.RICERCA_AVANZATA</h2>
            <div class="row" *ngFor="let row of rows">
                <div class="col-12 col-sm-12 col-md-3" *ngFor="let field of row" [ngSwitch]="field.controlType">
                    <ng-container *ngSwitchCase="'date'">
                        <label tabindex="0" class="form-label small fw-semibold" [ngClass]="{ 'text-danger': form.get(field.key).errors }" for="{{field.key}}">{{field.label | translate}}</label>
                        <input tabindex="0" type="date" id="{{field.key}}" [formControlName]="field.key" class="form-control mb-3" [ngClass]="{ 'is-invalid': form.get(field.key).errors }" />
                    </ng-container>
                    <ng-container *ngSwitchCase="'select'">
                        <label tabindex="0" class="form-label small fw-semibold" for="{{field.key}}">{{field.label | translate}}</label>
                        <select tabindex="0" class="form-select" [formControlName]="field.key" id="{{field.key}}">
                            <option [ngValue]="null" disabled>{{field.placeholder | translate}}</option>
                            <ng-container *ngFor="let option of field.options">
                                <option [ngValue]="option.value">{{ option.label | translate }}</option>
                            </ng-container>
                        </select>
                    </ng-container>
                    <div *ngSwitchCase="'multiselect'" ngbDropdown>
                        <label tabindex="0" class="form-label small fw-semibold" for="{{field.key}}">{{field.label | translate}}</label>
                        <input tabindex="0" type="text" [value]="multiselectValues[field.key]" class="dropdown-toggle form-select pe-4" id="{{field.key}}" readonly ngbDropdownToggle />
                        <div ngbDropdownMenu [attr.aria-labelledby]="field.key">
                            <ng-container [formArrayName]="field.key">
                                <div class="p-2" *ngFor="let innerForm of getFormArrayControls(field.key); let i = index" [formGroup]="innerForm">
                                    <div class="form-check">
                                        <input tabindex="0" [id]="'check_'+i" class="form-check-input" type="checkbox" formControlName="selected" (change)="setMultiselectValues(field)" [checked]="innerForm.get('selected').value">
                                        <label tabindex="0" class="form-check-label fs-6" [for]="'check_'+i">
                                            {{ field.options[i].label | translate }}
                                        </label>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <ng-container *ngSwitchDefault>
                        <label tabindex="0" class="form-label small fw-semibold" for="{{field.key}}" translate>{{field.label | translate}}</label>
                        <input tabindex="0" type="text" id="{{field.key}}" [formControlName]="field.key" class="form-control mb-3" />
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-0 col-sm-3"></div>
                <div class="col-6 col-sm-3 text-center">
                    <div class="d-grid">
                        <button tabindex="0" class="btn mx-2" type="submit" translate [disabled]="form.invalid" [ngClass]="{ 'disabled': form.invalid, 'btn-primary': form.valid, 'btn-outline-secondary': form.invalid }" (click)="onSubmit()">FORM.SEARCH</button>
                    </div>
                </div>
                <div class="col-6 col-sm-3 text-center">
                    <div class="d-grid">
                        <button tabindex="0" class="btn btn-info mx-2" type="button" (click)="onReset()" translate>FORM.RESET</button>
                    </div>
                </div>
                <div class="col-0 col-sm-3"></div>
            </div>
        </form>
    </div>
</div>
<hr class="border-0 my-3" />