import { ConfigurazioneFinalDTO } from "../dtos";
import { CategoriaInteraface, ConfigurazioneFinalInterface, ConfigurazioneInterface, FasciaIseePercentualeInterface, PercentualeBigliettoInterface } from "../interfaces";
import { CategoriaDisabilitaModel } from "./categoria-disabilita.model";
import { FasciaIseePercentualeModel } from "./fascia-isee-percentuale.model";
import { PercentualeBigliettoModel } from "./percentuale-tipologia-biglietto.model";

export class ConfigurazioneModel implements ConfigurazioneInterface, ConfigurazioneFinalInterface {
  protected listaFasce: Array<FasciaIseePercentualeInterface>;
  protected categorieStorico: Array<CategoriaInteraface>;
  protected percentualiTipologiaBiglietto: Array<PercentualeBigliettoInterface>;
  protected dataProgrammazione: string;
  protected dataAttivazione: string;
  protected dataCreazione: string;
  protected dataDisattivazione: string;
  protected flagAttivaOra: boolean;
  protected isBigliettoSingolo: boolean;
  protected listaFasceIseeStorico: Array<FasciaIseePercentualeInterface>;
  protected isDaAttivareConfigurazion: boolean;
  protected massimaleBiglietti: number;
  protected massimaleImporto: number;
  protected sogliaBudgetIndividuale: any;

  constructor(dto?: ConfigurazioneFinalDTO){
    if(dto){
      this.dataProgrammazione = dto?.dataProgrammazione;
      this.dataAttivazione = dto?.dataAttivazione;
      this.dataCreazione = dto?.dataCreazione;
      this.dataDisattivazione = dto?.dataDisattivazione;
      this.isBigliettoSingolo = dto?.configurazioneEnte?.bigliettoSingoloGiornaliero;
      this.massimaleBiglietti = dto?.sogliaBudgetIndividuale?.massimaleBiglietti;
      this.massimaleImporto = dto?.sogliaBudgetIndividuale?.massimaleImporto;
      this.isDaAttivareConfigurazion = dto?.daAttivareConfigurazione;
      this.listaFasce = dto?.fasceIsee?.map((dto) => new FasciaIseePercentualeModel(dto));
      this.categorieStorico = dto?.categorieStorico?.map((dto) => new CategoriaDisabilitaModel(dto));
      this.listaFasceIseeStorico = dto?.fasceIseeStorico?.map((dto) => new FasciaIseePercentualeModel(dto));
      this.percentualiTipologiaBiglietto = dto?.percentualiTipologiaBiglietto?.map((dto) => new PercentualeBigliettoModel(dto));
    }

  }
  isBigliettoSingoloGiornaliero(): boolean {
    return this.isBigliettoSingolo;
  }
  getCategorieStorico(): CategoriaInteraface[] {
  return this.categorieStorico;
}
  // getCategorieStorico(): CategoriaDisabilitaModel[] {
  //   return this.categorieStorico;
  // }
  getFasciaIseeStorico(): FasciaIseePercentualeInterface[] {
    return this.listaFasceIseeStorico;
  }
  isDaAttivareConfigurazione(): boolean {
    return this.isDaAttivareConfigurazion;
  }
  getDataAttivazione(): string {
    return this.dataAttivazione;
  }
  getDataCreazione(): string {
    return this.dataCreazione;
  }
  getDataDisattivazione(): string {
    return this.dataDisattivazione;
  }
  getaDataProgrammazione(): string {
    return this.dataProgrammazione;
  }
  getFasciaIsee(): FasciaIseePercentualeInterface[] {
    return this.listaFasce;
  }
  getSogliaBudgetIndividuale() {
    return this.sogliaBudgetIndividuale;
  }



  getListaFasce(): FasciaIseePercentualeInterface[] {
    return this.listaFasce;
  }
  getPercentualiTipologiaBiglietto(): PercentualeBigliettoInterface[] {
    return this.percentualiTipologiaBiglietto;
  }
  getDataProgrammazione(): string {
    return this.dataProgrammazione;
  }
  isFlagAttivaOra(): boolean {
    return this.flagAttivaOra;
  }
  getMassimaleBiglietti(): number {
    return this.massimaleBiglietti;
  }
  getMassimaleImporto(): number {
    return this.massimaleImporto;
  }
  setListaFasce(fasce: FasciaIseePercentualeInterface[]) {
    this.listaFasce = fasce;
  }
  setPercentualiTipologiaBiglietto(percentuali: PercentualeBigliettoInterface[]) {
    this.percentualiTipologiaBiglietto = percentuali;
  }
  setDataProgrammazione(data: string) {
    this.dataProgrammazione = data;
  }
  setFlagAttivaOra(flag: boolean) {
    this.flagAttivaOra = flag;
  }
  setMassimaleBiglietti(massimale: number) {
    this.massimaleBiglietti = massimale;
  }
  setMassimaleImporto(massimale: number) {
    this.massimaleImporto = massimale;
  }

}
