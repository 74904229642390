
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take } from "rxjs";
import { CompanionDTO } from "../dtos";
import { ResponseModel } from "../models";


@Injectable({
  providedIn: 'root'
})
export class AccompagnatoreService {
  private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }

  /**
  * Attiva l'accompagnatore.
  *
  * @param {number} idAcccompagnatore - L'ID del compagno da attivare
  * @return {Promise<any>} Una promessa che si risolve al corpo della risposta in caso di successo
  */
  attiva(idAcccompagnatore: number): Promise<any> {
    return new Promise<any>(
      (resolve, reject) => {
        const params = new HttpParams()
          .append('idCompanion', idAcccompagnatore);
        this.http.put(this.url_endpoint + api.accompagnatore_attiva, null, { params: params, observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: HttpResponse<any>) => {
              if (!!resp?.body) {
                resolve(resp?.body);
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  /**
   * Aggiunge un accompagnatore.
   *
   * @param { CompanionDTO } accompagnatore - L'oggetto accompagnatore da aggiungere.
   * @return { Promise<CompanionDTO> }
   */
  aggiungi(accompagnatore: CompanionDTO, tipoOperazione: 'INSERT' | 'UPDATE'): Promise<CompanionDTO> {
    return new Promise<CompanionDTO>(
      (resolve, reject) => {
        accompagnatore.tipoOperazione = tipoOperazione;
        this.http.post(this.url_endpoint + api.accompagnatore_aggiungi, accompagnatore, { observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: HttpResponse<any>) => {
              if (!!resp?.body) {
                resolve(resp?.body);
              }
            },
            error: (error: HttpErrorResponse) => {
              console.log(error);
              if(error?.status == 409 && tipoOperazione == 'INSERT') {
                reject({
                  status: 1,
                  error: error?.error
              })
              } else {
                reject(error);
              }              
            }
          });
      });
  }

  /**
   * Elimina un accompagnatore mediante ID.
   *
   * @param {number} idCompanion - L'ID dell'accompagnatore da eliminare.
   * @return {Promise<ResponseModel>} Una promessa che si risolve nel modello di risposta.
   */
  elimina(idCompanion: number): Promise<ResponseModel> {
    return new Promise<ResponseModel>(
      (resolve, reject) => {
        const params = new HttpParams()
          .append('idCompanion', idCompanion);
        this.http.delete(this.url_endpoint + api.accompagnatore_elimina, { params: params, observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: HttpResponse<any>) => {
              if (!!resp?.body) {
                resolve(new ResponseModel(resp?.body));
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  getAccompagnatori(idFreeInstance: number): Promise<CompanionDTO[]> {
    return new Promise<CompanionDTO[]>(
      (resolve, reject) => {
        const params = new HttpParams().append('idFreeInstance', idFreeInstance);
        this.http.get(this.url_endpoint + api.accompagnatore_ottieni_lista, { params, observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: HttpResponse<any>) => {
              if (!!resp?.body) {
                resolve(resp?.body);
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

    /**
     * Modifica un accompagnatore.
     *
     * @param { CompanionDTO } accompagnatore
     * @return { Promise<CompanionDTO> }
     */
    modifica(accompagnatore: CompanionDTO): Promise<CompanionDTO> {
      return new Promise<CompanionDTO>(
        (resolve, reject) => {
          accompagnatore.tipoOperazione = 'UPDATE';
          this.http.put(this.url_endpoint + api.accompagnatore_modifica, accompagnatore, { observe: 'body' })
            .pipe(take(1))
            .subscribe({
              next: (resp: HttpResponse<any>) => {
                if (!!resp?.body) {
                  resolve(resp?.body);
                }
              },
              error: (error: HttpErrorResponse) => {
                reject(error);
              }
            });
        });
    }

  /**
   * Sospende un accompagnatore.
   *
   * @param {number} idAcccompagnatore - L'ID dell'accompagnatore da sospendere.
   * @return {Promise<CompanionDTO>}
   */
  sospendi(idAcccompagnatore: number): Promise<CompanionDTO> {
    return new Promise<CompanionDTO>(
      (resolve, reject) => {
        const params = new HttpParams()
          .append('idCompanion', idAcccompagnatore);
        this.http.put(this.url_endpoint + api.accompagnatore_sospendi, null, { params: params, observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: HttpResponse<any>) => {
              if (!!resp?.body) {
                resolve(resp.body);
              }
            },
            error: (error) => {
              reject(error);
            }
          });
      });
  }
}
