<div class="table-responsive rounded-3">
    <table class="table table-striped" role="grid">
        <caption class="visually-hidden">{{ caption | translate }}</caption>
        <thead>
            <tr role="row" class="bg-primary">
                <ng-container *ngFor="let column of cols; let i=index">
                    <th scope="col" role="col" class="text-white fs-6 w-16" *ngIf="column?.visible && column?.positionTable > -1">
                        <div class="d-block position-relative">
                            <div class="position-relative d-flex justify-content-between align-items-end">
                                <div>
                                    {{ column.title | translate }}
                                </div>
                                <button *ngIf="column.sortable == null || column.sortable" class="btn p-0" (click)="onSort(column)">
                                    <div class="visually-hidden" translate [translateParams]="{ name: column.title | translate  }" *ngIf="column.sort == SORT.ASC">ACCESSIBILITA.SORT_ASC</div>
                                    <div class="visually-hidden" translate [translateParams]="{ name: column.title | translate  }" *ngIf="column.sort == SORT.DESC">ACCESSIBILITA.SORT_DESC</div>
                                    <div class="visually-hidden" translate [translateParams]="{ name: column.title | translate  }" *ngIf="column.sort == SORT.ANY">ACCESSIBILITA.SORT_ANY</div>
                                    <svg class="icon icon-white">
                                        <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-arrow-up-triangle" [attr.y]="column.sort == SORT.ANY ? SORT.DESC : SORT.ANY" *ngIf="column.sort != SORT.DESC"></use>
                                        <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-arrow-down-triangle" [attr.y]="column.sort == SORT.ANY ? SORT.ASC : SORT.ANY" *ngIf="column.sort != SORT.ASC"></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr scope="row" *ngFor="let row of rows" [ngClass]="!!row['cssClass']?row['cssClass']:''">
                <ng-container *ngFor="let column of cols; let i=index">
                    <td role="col" class="fs-6" *ngIf="column?.visible && column?.positionTable > -1">
                        <ng-container [ngSwitch]="column?.type">
                            <ng-container *ngSwitchCase="'date'">
                                <div [innerHtml]="row[column.key] | date:'dd-MM-yyyy'">
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'route'">
                                <a [routerLink]="getRoute(row, column.route, column.keyId)">{{row[column.key]}}</a>
                            </ng-container>
                            <ng-container *ngSwitchCase="'currency'">
                                {{row[column.key] | currency:'EUR':'symbol':'1.2-2' }}
                            </ng-container>
                            <ng-container *ngSwitchCase="'pipe'">
                                <ng-container [ngSwitch]="column.typePipe">
                                    <ng-container *ngSwitchCase="'currency'">
                                        {{row[column.key] | currency:'EUR':'symbol':'1.2-2' }}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'percent'">
                                        {{row[column.key] / 100 | percent:'1.2-2' }}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'translate'">
                                        {{ (column.translate? column.translate + '.' + row[column.key] : row[column.key]) | translate }}
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                {{row[column.key]}}
                            </ng-container>
                        </ng-container>
                    </td>
                </ng-container>
            </tr>
            <ng-container *ngIf="rows?.length > 0 && page > 1">
                <tr aria-hidden="true" *ngFor="let row of getEmptyRows()">
                    <ng-container *ngFor="let column of cols; let i=index">
                        <td class="fs-6" *ngIf="column?.visible">
                            <span class="invisible">PLACEHOLDER</span>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
            <ng-container *ngIf="!rows && !!loading">
                <tr aria-hidden="true" *ngFor="let row of [1,2,3,4,5]">
                    <ng-container *ngFor="let column of cols; let i=index">
                        <td class="fs-6" *ngIf="column?.visible">
                            <span class="placeholder bg-primary w-100"></span>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
            <ng-container *ngIf="rows?.length == 0 || rows == undefined">
                <tr aria-hidden="true">
                    <td class="fs-6 text-center invisible" [attr.colspan]="getColSpan()">
                        {{ 'TABELLA.NO_RECORDS' | translate }}
                    </td>
                </tr>
                <tr scope="row">
                    <td class="fs-6 text-center text-uppercase fw-bold" [attr.colspan]="getColSpan()">
                        {{ 'TABELLA.NO_RECORDS' | translate }}
                    </td>
                </tr>
                <tr aria-hidden="true">
                    <td class="fs-6 text-center invisible" [attr.colspan]="getColSpan()">
                        {{ 'TABELLA.NO_RECORDS' | translate }}
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
