<div class="it-header-center-wrapper" role="banner">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="it-header-center-content-wrapper ps-0">
          <div class="it-brand-wrapper">
            <a [routerLink]="['/', 'home']" role="link" >
              <img class="icon" [src]="'assets/svg/brands/logo-regione-puglia.svg'" [alt]="'ACCESSIBILITA.LOGO_REGIONE_PUGLIA' | translate" aria-hidden="true">
              <div class="it-brand-text">
                <div class="it-brand-title" tabindex="0">{{ 'BRAND.TITLE' | translate }}</div>
                <div class="it-brand-tagline d-none d-md-block" tabindex="0">{{ 'BRAND.SUBTITLE' | translate }}</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
