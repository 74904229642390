import { Inject, Injectable } from "@angular/core";
import { OperatorService, UtenteInterface, UtenteModel } from "core-model";
import { UtenteService } from "core-model";
import { ErrorFacade } from "./error.facade";
import { AuthFacade } from "./auth.facade";
import { NgxSpinnerService } from "ngx-spinner";
import { DOCUMENT } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class UtenteFacade {
  constructor(private authFacade: AuthFacade,
    private errorFacade: ErrorFacade,
    private utenteService: UtenteService,
    private spinner: NgxSpinnerService,
    private operatoreService: OperatorService,
    @Inject(DOCUMENT) private document: Document) { }

  /**
   * Ritorna l'utente
   * @returns {Promise<Utente>}
   */
  async getUtente(): Promise<UtenteInterface> {
    try {
      const dto = await this.utenteService.getUtente();
      return new UtenteModel(dto);
    } catch (error) {
      this.errorFacade.handle(error);
    }
    return null;
  }

    /**
   * Switches the platform and redirects to the authentication page if the switch is successful.
   *
   * @param {string} piattaforma - The platform to switch to.
   * @return {Promise<boolean>}
   */
    async switchPiattaforma(piattaforma: string): Promise<boolean> {
      try {
        this.spinner.show();
        const resp = await this.operatoreService.switch(piattaforma);
        window.location.href = this.document.location.origin + '/backoffice/authbo';
        return resp;
      } catch (error) {
        this.errorFacade.handle(error);
        return false;
      } finally {
        this.spinner.hide();
      }
    }
}
