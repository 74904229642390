import { DatePipe } from '@angular/common';
import { AnagraficaDTO } from "../dtos";
import { AnagraficaInterface } from "../interfaces";

export class AnagraficaModel implements AnagraficaInterface {
  private codiceFiscale: string;
  private cognome: string;
  private dataAbilitazione: Date;
  private dataAggiornamento: Date;
  private dataCreazione: Date;
  private dataDisabilitazione: Date;
  private dataNascita: Date;
  private id: number;
  private luogoNascita: string;
  private nome: string;
  private provinciaNascita: string;

  constructor(dto: AnagraficaDTO) {
    this.codiceFiscale = dto?.codiceFiscale;
    this.cognome = dto?.cognome;
    this.dataAbilitazione = dto?.dataAbilitazione ? new Date(dto?.dataAbilitazione) : undefined;
    this.dataAggiornamento = dto?.dataAggiornamento ? new Date(dto?.dataAggiornamento) : undefined;
    this.dataDisabilitazione = dto?.dataDisabilitazione ? new Date(dto?.dataDisabilitazione) : undefined;
    this.dataCreazione = dto?.dataCreazione ? new Date(dto?.dataCreazione) : undefined;
    this.dataNascita = !!dto?.dataNascita ? new Date(dto?.dataNascita) : undefined;
    this.id = dto?.id;
    this.luogoNascita = dto?.luogoNascita;
    this.nome = dto?.nome;
    this.provinciaNascita = dto?.provinciaNascita;
  }

  getId(): number {
    return this.id;
  }

  // Metodo get per ottenere il valore di 'codiceFiscale'
  getCodiceFiscale(): string {
    return this.codiceFiscale;
  }

  // Metodo get per ottenere il valore di 'nome'
  getNome(): string {
    return this.nome;
  }

  // Metodo get per ottenere il valore di 'cognome'
  getCognome(): string {
    return this.cognome;
  }

  // Metodo get per ottenere il valore di 'dataNascita'
  getDataNascita(): Date {
    return this.dataNascita;
  }

  // Metodo get per ottenere il valore di 'luogoNascita'
  getLuogoNascita(): string {
    return this.luogoNascita;
  }

  // Metodo get per ottenere il valore di 'provinciaNascita'
  getProvinciaNascita(): string {
    return this.provinciaNascita;
  }


  // Metodo get per ottenere il valore di 'dataAbilitazione'
  getDataAbilitazione(): Date {
    return this.dataAbilitazione;
  }

  // Metodo get per ottenere il valore di 'dataDisabilitazione'
  getDataDisabilitazione(): Date {
    return this.dataDisabilitazione;
  }

  // Metodo get per ottenere il valore di 'dataCreazione'
  getDataCreazione(): Date {
    return this.dataCreazione;
  }

  // Metodo get per ottenere il valore di 'dataAggiornamento'
  getDataAggiornamento(): Date {
    return this.dataAggiornamento;
  }

  isVerificata(): boolean {
    return !!this.nome && !!this.cognome && !!this.codiceFiscale && !!this.dataNascita && !!this.luogoNascita && !!this.provinciaNascita;
  }

  toDTO(): AnagraficaDTO {
    const datepipe: DatePipe = new DatePipe('en-US');
    return {
      codiceFiscale: this.codiceFiscale,
      cognome: this.cognome,
      dataAbilitazione: this.dataAbilitazione?.toJSON(), // datepipe.transform(this.dataAbilitazione, 'yyyy-MM-dd'),
      dataCreazione: this.dataCreazione?.toJSON(), //datepipe.transform(this.dataCreazione, 'yyyy-MM-dd'),
      dataDisabilitazione: this.dataDisabilitazione?.toJSON(), // datepipe.transform(this.dataDisabilitazione, 'yyyy-MM-dd'),
      dataNascita: datepipe.transform(this.dataNascita, 'yyyy-MM-dd'),
      id: this.id,
      luogoNascita: this.luogoNascita,
      nome: this.nome,
      provinciaNascita: this.provinciaNascita
    }
  }
}
