import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/**
 * Questa classe contiene metodi per validare i campi dei form
 */
export class CustomValidators {

    /**
     * Valida i campi del modulo della news.
     *
     * @param {AbstractControl} control - L'oggetto del controllo del form da validare.
     * @return {ValidationErrors | null} - Restituisce null se i campi sono validi.
     */
    static validatorNews(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            if (!control.parent) {
                return null
            }

            const titolo = control.parent.get('titolo');
            const descrizione = control.parent.get('descrizione');
            const html = control.parent.get('html');

            titolo.markAsTouched();
            descrizione.markAsTouched();
            html.markAsTouched();

            titolo.markAsDirty();
            descrizione.markAsDirty();
            html.markAsDirty();

            if (titolo?.value.length <= 0 && descrizione?.value.length <= 0 && (html?.value == null || html?.value.length <= 0)) {
                titolo.setErrors(null);
                descrizione.setErrors(null);
                html.setErrors(null);
            }
            else {
              titolo?.value.length > 0 ? titolo?.setErrors(null) : titolo?.setErrors({ required: true });
              descrizione?.value.length > 0 ? descrizione?.setErrors(null) : descrizione?.setErrors({ required: true });
              html?.value != null ? html?.setErrors(null) : html?.setErrors({ required: true });
            }

            return null
        }
    }

    static validatorAnnualita(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            if (!control.parent) {
                return null
            }

            const dateStart = new Date(control.parent.get('dataInizio').value);
            const dateEnd = new Date(control.parent.get('dataFine').value);
            const date = new Date(control.value);


            if (date < dateStart || date > dateEnd ) {
                control.setErrors({ rangeData: true });
                control.markAsDirty();
                control.markAsTouched();
                return { rangeData: true };
            }
            else {
                control.setErrors(null);
                return null
            }

        }
    }

    static validatorImage(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {

            if (!control.value.alt && control.value.alt?.length <= 0) {
                control.setErrors({ required: true });
                control.markAsDirty();
                control.markAsTouched();
                return { required: true };
            }

            control.setErrors(null);
            return null
        }
    }

}
