import { PaginationDTO } from "../dtos/";
import { FreeInstanceInterface, PaginationInterface } from "../interfaces";
import { FreeInstanceModel } from "./free-instance.model";
import { PaginationModel } from "./pagination.model";

export class FreeInstancePaginationModel extends PaginationModel implements PaginationInterface {
    override content: FreeInstanceInterface[];
    constructor(dto?: PaginationDTO) {
        super(dto);
        this.content = dto?.content?.map( (el) => new FreeInstanceModel(el));
    }

    override getContent(): FreeInstanceInterface[] {
        return this.content;
    }
}