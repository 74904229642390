
import { FreeInstanceModel } from "./free-instance.model";
import { FILE_TYPE } from "../constants";
import { FreeInstanceDTO } from "../dtos";
import { DocumentInterface, RinunciaInterface } from "../interfaces";

export class RinunciaModel extends FreeInstanceModel implements RinunciaInterface {

    constructor(dto: FreeInstanceDTO) {
        super(dto);
    }

    getDocumento(): DocumentInterface {
        return this.file?.find((file) => file?.getTipologiaDocumento() === FILE_TYPE.RENUNCIATION);
    }

    getIdFile(): number {
        this.file.sort((a, b) => a.getDataAttivazione().getTime() - b.getDataAttivazione().getTime());
        const file = this.file?.find((file) => file?.getTipologiaDocumento() === FILE_TYPE.RENUNCIATION);
        return file?.getId();
    }

    getNomeFile(): string {
        this.file.sort((a, b) => a.getDataAttivazione().getTime() - b.getDataAttivazione().getTime());
        const file = this.file.find((file) => file?.getTipologiaDocumento() == FILE_TYPE.RENUNCIATION);
        return file?.getNomeDocumento();
    }

    getNoteRinuncia(): string {
        return this.noteRinuncia;
    }

    getStep(): number {
        if (this.rinunciato == true) {
            if (this.acquisizioneFirmaRinuncia == true) {
                return 4;
            } else {
                return 3;
            }
        }
        return 1;
    }

    hasDocumento(): boolean {
        return !!this.file?.find((file) => file?.getTipologiaDocumento() === FILE_TYPE.RENUNCIATION);
    }

    hasNoteRinuncia(): boolean {
        return this.noteRinuncia != null && this.noteRinuncia != '';
    }

    isRinunciatoCompleted(): boolean {
        return this.rinunciato && !!this.hasDocumento();
    }

    isRinunciatoNotCompleted(): boolean {
        return this.rinunciato && !this.hasDocumento();
    }
}
