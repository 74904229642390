import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { ErrorObserverService } from './error-observer.service';

@NgModule({})
export class ErrorObserverModule {
    constructor(@Optional() @SkipSelf() parentModule?: ErrorObserverModule) {
        if (parentModule) {
            throw new Error(
                'ErrorObserverModule is already loaded. Import it in the AppModule only');
        }
    }
    public static forRoot(errorObserver: ErrorObserverService): ModuleWithProviders<ErrorObserverModule> {
        return {
            ngModule: ErrorObserverModule,
            providers: [
                { provide: ErrorObserverService, useValue: errorObserver }
            ],
        };
    }
}
