<div class="d-flex w-100">
  <div appDragDrop (onFileDropped)="uploadDragDrop($event)" class="upload-dragdrop-image">
    <img src="assets/bootstrap-italia/dist/assets/upload-drag-drop-icon.svg"
      alt="{{'ACCESSIBILITA.UPLOAD_DOCUMENTI' | translate }}" aria-hidden="true">
  </div>
  <div class="upload-dragdrop-text fw-400">
    <h5>{{ titoloUpload | translate }}</h5>
    <p class="text-fifth">
      {{ 'FORM.UPLOAD_DOCUMENTI_OPPURE' | translate }}
      <input #dragfile type="file" [id]="dragDropId" [name]="dragDropId" class="text-fourth fw-400 upload-dragdrop-input"
        (change)="uploadFile($event)" accept="image/*" />
      <label class="text-fourth fw-400" [for]="dragDropId">
        {{ 'FORM.UPLOAD_DOCUMENTI_SELEZIONA_DAL_DESK' | translate }}
      </label>
    </p>
    <p class="pt-2 text-fifth small lh-sm">
      {{ 'FORM.UPLOAD_DOCUMENTI_DESCRIPTION' | translate:{ formato: 'image', dimensioneFile: '10MB' } }}
    </p>
  </div>
</div>
<ul class="upload-file-list list-group-flush">
  <li class="list-group-item" *ngFor="let document of documents?.controls; let i = index">
    <div class="row">
      <div class="col-6">
        <div class="upload-file success mt-4">
          <svg class="icon icon-sm" aria-hidden="true">
            <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-file"></use>
          </svg>
          <p>
            <span class="visually-hidden">{{ 'ACCESSIBILITA.FILE_CARICATO' | translate }}</span>
            <span class="d-inline-block text-truncate align-middle" style="max-width: 150px;">{{
              document.value?.descrizione }}</span>
            <span class="upload-file-weight align-middle">{{ document.value?.dimensione | fileSize }}</span>
          </p>
          <button disabled>
            <span class="visually-hidden">{{ 'ACCESSIBILITA.CARICAMENTO_ULTIMATO' | translate }}</span>
            <svg class="icon" aria-hidden="true">
              <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-check"></use>
            </svg>
          </button>
          <button class="btn" (click)="deleteFile(i)" type="button">
            <span class="visually-hidden">{{ 'ACCESSIBILITA.ELIMINA_FILE_CARICATO' | translate:{ filename:
              document.value?.nomeFile } }}</span>
            <svg class="icon icon-secondary" aria-hidden="true">
              <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-delete"></use>
            </svg>
          </button>
        </div>
      </div>
      <div class="col-6">
        <label for="data" class="h6"
        [ngClass]="{ 'text-danger': document.invalid && (document.dirty || document.touched)}"
        >{{'Testo alternativo' | translate}} *</label>
        <input id="data" type="text" class="form-group" (change)="updateDescription($event, document, i)"
        [ngClass]="{ 'border-danger': document.invalid && (document.dirty || document.touched)}"
          placeholder="Inserisci un testo" [value]="document.value?.alt">
      </div>
    </div>
  </li>
</ul>
