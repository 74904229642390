import { EnteDTO } from "../dtos";
import { EnteInterface } from "../interfaces";

export class EnteModel implements EnteInterface {
    protected alias: string;
    protected attivo: boolean;
    protected dataAttivazione: Date;
    protected dataDisattivazione: Date;
    protected dataCreazione: Date;
    protected dataAggiornamento: Date;
    protected descrizione: string;
    protected id: number;
    protected titoliDiViaggio: Array<any>;
    constructor(dto: EnteDTO) {
        this.alias = dto?.alias;
        this.descrizione = dto?.descrizione;
        this.id = dto?.id;
    }

    getAlias(): string {
        return this.alias;
    }
    getDescrizione(): string {
        return this.descrizione;
    }
    getId(): number {
        return this.id;
    }
}