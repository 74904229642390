import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { take } from 'rxjs';
import { api } from '../constants';
import { ShowcasePageDTO, SubmitShowcasePageDTO } from '../dtos';

@Injectable({
  providedIn: 'root'
})

export class ShowcasePageService {
  private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) { 
    this.url_endpoint = env.environment.url_endpoint;
  }
  aggiungi(showcasePage: SubmitShowcasePageDTO): Promise<ShowcasePageDTO> {
    return new Promise((resolve, reject) => {
      this.http.post(this.url_endpoint + api.showcasePage_aggiungi, showcasePage, { observe: 'body' })
        .pipe(take(1))
        .subscribe({
          next: (resp: HttpResponse<ShowcasePageDTO>) => {
            if (!!resp?.body) {
              resolve(resp?.body);
            } else {
              resolve(null);
            }
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          }
        });
    })
  }

  aggiorna(showcasePage: SubmitShowcasePageDTO, id: number): Promise<ShowcasePageDTO> {
    return new Promise((resolve, reject) => {
      const params = new HttpParams().append('id', id);
      this.http.put(this.url_endpoint + api.showcasePage_aggiorna, showcasePage, { observe: 'body', params })
        .pipe(take(1))
        .subscribe({
          next: (resp: HttpResponse<ShowcasePageDTO>) => {
            if (!!resp?.body) {
              resolve(resp?.body);
            } else {
              resolve(null);
            }
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          }
        });
    })
  }

  lista(): Promise<ShowcasePageDTO[]> {
    return new Promise((resolve, reject) => {
      this.http.get(this.url_endpoint + api.showcasePage_lista, { observe: 'body' })
        .pipe(take(1))
        .subscribe({
          next: (resp: HttpResponse<ShowcasePageDTO[]>) => {
            if (!!resp?.body) {
              resolve(resp?.body);
            } else {
              resolve(null);
            }
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          }
        });
    })
  }

  ottieni(id: number): Promise<ShowcasePageDTO> {
    return new Promise((resolve, reject) => {
      const params = new HttpParams().append('id', id);
      this.http.get(this.url_endpoint + api.showcasePage_ottieni, { observe: 'body', params })
        .pipe(take(1))
        .subscribe({
          next: (resp: HttpResponse<ShowcasePageDTO>) => {
            if (!!resp?.body) {
              resolve(resp?.body);
            } else {
              resolve(null);
            }
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          }
        });
    })
  }
}
