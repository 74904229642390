import { TipologiaBigliettoDTO } from "../dtos";
import { TipologiaBigliettoInterface } from "../interfaces";

export class TipologiaBigliettoModel implements TipologiaBigliettoInterface {

  protected id?: number;
  protected listaTraduzioni?: Array<any>;
  protected percentuale?: number;
  protected tipologiaBiglietto?: string;

  constructor(dto: TipologiaBigliettoDTO) {
    this.id = dto?.id;
    this.listaTraduzioni = dto?.listaTraduzioni;
    this.percentuale = dto?.percentuale;
    this.tipologiaBiglietto = dto?.tipologiaBiglietto;
  }
  setPecentuale(percentuale: number) {
    this.percentuale = percentuale;
  }

  getID(): number {
    return this.id;
  }

  getLabel(): string {
    return this.listaTraduzioni?.at(0)?.etichetta;
  }

  getPercentuale(): number {
    return this.percentuale;
  }
  getTipologiaBiglietto(): string {
    return this.tipologiaBiglietto;
  }

}
