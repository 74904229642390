import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class RequisitoDisabilitaService {

      private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }

    /**
      * Ottiene la lista dei requisiti di disabilita.
      *
      * @return {Promise<any>} A promise that resolves when the search is complete.
      */
    get(): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                this.http.get(this.url_endpoint + api.requisito_disabilita_lista, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (!!resp?.body) {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }
}
