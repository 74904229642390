import { TicketDTO, TicketTypeDTO } from "../dtos";
import { CompanionInterface, EnteInterface, FreeInstanceInterface, TicketInterface } from "../interfaces";
import { CompanionModel } from "./companion.model";
import { EnteModel } from "./ente.model";
import { FreeInstanceModel } from "./free-instance.model";


export class TicketModel implements TicketInterface {
    protected accompagnatore: CompanionInterface;
    protected anomalia: boolean;
    protected id: number;
    protected idTitoloAccompagnatore: string;
    protected idTitoloTitolare: string;
    protected dataCreazione?: string;
    protected dataFineValidita: string;
    protected dataInizioValidita: string;
    protected destinazione: string;
    protected destinazioneCoordinate: string;
    protected enteFinanziatore: EnteInterface;
    protected gratuita: FreeInstanceInterface;
    protected origine: string;
    protected origineCoordinate: string;
    protected percentualeSconto: number;
    protected tipologiaBiglietto?: TicketTypeDTO;
    protected titoloAccompagnatoreAnnullato: boolean;
    protected titoloBeneficiarioAnnullato: boolean;
    protected valoreTitolo: number;
    protected valoreTitoloAggiornato: number;

    constructor(public dto: TicketDTO) {
        this.accompagnatore = dto?.accompagnatore ? new CompanionModel(dto.accompagnatore) : null;
        this.anomalia = dto?.anomalia;
        this.id = dto?.id;
        this.idTitoloAccompagnatore = dto?.idTitoloAccompagnatore;
        this.idTitoloTitolare = dto?.idTitoloTitolare;
        this.dataCreazione = dto?.dataCreazione;
        this.dataFineValidita = dto?.dataFineValidita;
        this.dataInizioValidita = dto?.dataInizioValidita;
        this.destinazione = dto?.destinazione;
        this.destinazioneCoordinate = dto?.destinazioneCoordinate;
        this.gratuita = !!dto?.gratuita ? new FreeInstanceModel(dto.gratuita) : null;
        this.enteFinanziatore = !!dto?.enteFinanziatore ? new EnteModel(dto.enteFinanziatore) : null;
        this.origine = dto?.origine;
        this.origineCoordinate = dto?.origineCoordinate;
        this.percentualeSconto = dto?.percentualeSconto;
        this.tipologiaBiglietto = dto?.tipologiaBiglietto;
        this.titoloAccompagnatoreAnnullato = dto?.titoloAccompagnatoreAnnullato;
        this.titoloBeneficiarioAnnullato = dto?.titoloBeneficiarioAnnullato;
        this.valoreTitolo = dto?.valoreTitolo;
        this.valoreTitoloAggiornato = dto?.valoreTitoloAggiornato;
    }

    getAccompagnatore(): CompanionInterface {
        return this.accompagnatore;
    }

    getCodiceCredenziale(): string {
        return this.gratuita?.getCodiceCredenziale();
    }

    getCodiceFiscaleBeneficiario(): string {
        return this.gratuita?.getCodiceFiscaleBeneficiario();
    }

    getDataEmissione(): string {
        return this.dataCreazione;
    }
    getDataInizioValidita(): string {
        return this.dataInizioValidita;
    }
    getDataFineValidita(): string {
        return this.dataFineValidita;
    }
    getEnteFinanziatore(): string {
        return this.enteFinanziatore.getDescrizione();
    }
    getIdGratutita(): number {
        return this.gratuita?.getId();
    }
    getNomeBeneficiario(): string {
        return this.gratuita?.getNomeBeneficiario();
    }
    getStazioneArrivo(): string {
        return this.destinazione;
    }
    getStazionePartenza(): string {
        return this.origine;
    }
    getTipologia(): string {
        return this.tipologiaBiglietto?.listaTraduzioni?.at(0).etichetta;
    }
}
