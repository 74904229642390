<div tabindex="0" class="container dms-empty-state" aria-live="assertive">
    <div class="row">
        <div class="col-md-6">
            <div class="d-flex align-items-center h-content justify-content-center">
                <img [src]="'assets/svg/generic-error.svg'" class="img-fluid" alt="{{'ERRORE-GENERICO.TITLE' | translate}}">
            </div>
        </div>
        <div class="col-md-6">
            <div class="d-flex align-items-center h-content">
                <div>
                    <h1 tabindex="0" class="text-uppercase mb-5" translate>ERRORE-GENERICO.TITLE</h1>
                    <p tabindex="0" class="mb-5" [innerHTML]="'ERRORE-GENERICO.MESSAGE' | translate"></p>
                    <p tabindex="0" class="mb-5" [innerHTML]="'ERRORE-GENERICO.PER-ASSISTENZA' | translate"></p>
                    <a tabindex="0" role="button" [routerLink]="['/','homepage']" class="btn btn-primary" title="{{ 'NAVIGATION.TORNA_HOMEPAGE' | translate}}">{{ 'NAVIGATION.TORNA_HOMEPAGE' | translate}}</a>
                </div>
            </div>
        </div>
    </div>
</div>