import { Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private languageChangeSubject = new Subject<string>();

  constructor(private translate: TranslateService) {
    this.initLanguageChange();
  }

  private initLanguageChange() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.languageChangeSubject.next(event.lang);
    });
  }

  on(): Observable<string> {
    return this.languageChangeSubject.asObservable();
  }

  getCurrentLanguage(): string {
    return this.translate?.currentLang;
  }

  setLanguage(language: string): void {
    this.translate.use(language);
  }

  getAcceptLanguageHeader(): string {
    return this.translate.currentLang;
  }
}
