
import { FreeInstanceModel } from "./free-instance.model";
import { FILE_TYPE, TIPOLOGIA_RICHIEDENTE } from "../constants";
import { FreeInstanceDTO } from "../dtos";
import { DocumentInterface, ApplicantInterface } from "../interfaces";
import { DraftRiscattoInterface } from "../interfaces/draft-riscatto.interface";
import { ApplicantModel } from "./applicant.model";
import { DelegatoDeleganteDTO } from "../dtos";
export class DraftRiscattoModel extends FreeInstanceModel implements DraftRiscattoInterface {
    protected richiedenteGratuitaList: ApplicantInterface[];

    constructor(dto: FreeInstanceDTO) {
        super(dto);
        this.richiedenteGratuitaList = dto?.richiedentiGratuita?.map((dto) => new ApplicantModel(dto));
    }

    beneficiarioIsDelegante(codiceFiscaleRichiedente: string): boolean {
        if (this.hasBozza(codiceFiscaleRichiedente)) {
            const bozza = this.getBozza(codiceFiscaleRichiedente);
            return this.getCodiceFiscaleBeneficiario() == bozza.getDelegato()?.getCodiceFiscaleDelegante();
        }
        return false;
    }

    getBozza(codiceFiscaleRichiedente: string): ApplicantInterface {
        this.richiedenteGratuitaList.sort((a, b) => b.getDataAttivazione().getTime() - a.getDataAttivazione().getTime());
        const inactiveForChange = this.richiedenteGratuitaList.find((a) => !a.isCambioRichiedente() && a.getRichiedente().getAnagrafica().getCodiceFiscale() == codiceFiscaleRichiedente);
        return inactiveForChange;
    }

    getDelegatoDeleganteDTO(codiceFiscaleRichiedente: string): DelegatoDeleganteDTO {
        if (this.hasBozza(codiceFiscaleRichiedente)) {
            const bozza = this.getBozza(codiceFiscaleRichiedente);
            return {
                codice: this.getRichiedenteGratuita().getCodice(),
                tipologiaRichiedente: bozza?.getTipologiaRichiedente(),
                datiDelegante: {
                    codiceFiscaleDelegante: bozza?.getDelegato()?.getCodiceFiscaleDelegante(),
                    cognomeDelegante: bozza?.getDelegato()?.getCognomeDelegante(),
                    luogoNascitaDelegante: bozza?.getDelegato()?.getLuogoNascitaDelegante(),
                    nomeDelegante: bozza?.getDelegato()?.getNomeDelegante(),
                    provinciaNascitaDelegante: bozza?.getDelegato()?.getProvinciaNascitaDelegante(),
                    relazioneBeneficiarioDelegante: bozza?.getDelegato()?.getRapportoBeneficiarioDelegante()
                }
            };
        } else {
            return { codice: this.getRichiedenteGratuita().getCodice() };
        }
    }

    getCartaIdentitaDelegante(codiceFiscaleRichiedente: string): DocumentInterface {
        return this.file?.find((file) => file?.getTipologiaDocumento() === FILE_TYPE.IDENTITY_CARD && file?.getCodiceFiscaleRichiedente() === codiceFiscaleRichiedente);
    }

    getDocumentoDelega(codiceFiscaleRichiedente: string): DocumentInterface {
        return this.file?.find((file) => file?.getTipologiaDocumento() === FILE_TYPE.DELEGATION && file?.getCodiceFiscaleRichiedente() === codiceFiscaleRichiedente);
    }

    override getRichiedenteGratuita(): ApplicantInterface {
        this.richiedenteGratuitaList.sort((a, b) => b.getDataAttivazione().getTime() - a.getDataAttivazione().getTime());
        const activeForChange = this.richiedenteGratuitaList.find((a) => a.isCambioRichiedente() && a.isAttivo());
        return activeForChange;
    }


    // getStep(codiceFiscaleRichiedente: string): number {
    //     if (this.hasBozza(codiceFiscaleRichiedente)) {
    //         return 5;
    //     }
    //     return 1;
    // }

    hasBozza(codiceFiscaleRichiedente: string): boolean {
        this.richiedenteGratuitaList.sort((a, b) => b.getDataAttivazione().getTime() - a.getDataAttivazione().getTime());        
        const idx= this.richiedenteGratuitaList.findIndex((a) => !a.isCambioRichiedente() && a.getRichiedente().getAnagrafica().getCodiceFiscale() == codiceFiscaleRichiedente);
        return idx > -1;
    }

    hasDocumentoDelega(codiceFiscaleRichiedente: string): boolean {
        if(this.hasBozza(codiceFiscaleRichiedente)) {
            const bozza = this.getBozza(codiceFiscaleRichiedente);
            this.file.sort((a, b) => b.getDataAttivazione().getTime() - a.getDataAttivazione().getTime());  
            return bozza.isAttivo() && !!this.file?.find((file) => file?.getTipologiaDocumento() === FILE_TYPE.DELEGATION && file?.getCodiceFiscaleRichiedente() === codiceFiscaleRichiedente);
        }
        return false;
    }

    isEqualToCodiceFiscaleBeneficiario(codiceFiscale: string): boolean {
        return this.getCodiceFiscaleBeneficiario() === codiceFiscale;
    }

    isTipologiaBeneficiario(): boolean {
        return this.getTipologiaRichiedente() === TIPOLOGIA_RICHIEDENTE.BENEFICIARIO;
    }

    isTipologiaDelefato(): boolean {
        return this.getTipologiaRichiedente() === TIPOLOGIA_RICHIEDENTE.TERZO_DELEGATO;
    }

    isTipologiaFamiliare(): boolean {
        return this.getTipologiaRichiedente() === TIPOLOGIA_RICHIEDENTE.COMPONENTE_NUCLEO_FAMILIARE;
    }
}