import { PaginationDTO } from "../dtos";
import { PaginationInterface, TicketInterface } from "../interfaces";
import { PaginationModel } from "./pagination.model";
import { TicketModel } from "./ticket.model";

export class TicketsPaginationModel extends PaginationModel implements PaginationInterface {
    override content: TicketInterface[];
    constructor(dto?: PaginationDTO) {
        super(dto);
        this.content = dto?.content?.map( (el) => new TicketModel(el));
    }
    override getContent(): TicketInterface[] {
        return this.content;
    }
}