import { CompanionDTO } from "../dtos";
import { AnagraficaInterface, CompanionInterface } from "../interfaces";
import { AnagraficaModel } from "./anagrafica.model";

export class CompanionModel implements CompanionInterface {
    private abilitato: boolean;
    private anagrafica: AnagraficaInterface;
    private id: number;

    constructor(dto: CompanionDTO) {     
        this.abilitato = dto?.abilitato; 
        this.anagrafica = !!dto?.anagrafica ? new AnagraficaModel(dto.anagrafica) : undefined;
        this.id = dto?.id;
    }

    getAnagrafica(): AnagraficaInterface {
        return this.anagrafica;
    }

    getCodiceFiscale(): string {
        return this.anagrafica.getCodiceFiscale();
    }
    getCognome(): string {
        return this.anagrafica.getCognome();
    }
    getDataNascita(): Date {
        return this.anagrafica.getDataNascita();
    }
    getId(): number {
        return this.id;
    }
    getLuogoNascita(): string {
        return this.anagrafica.getLuogoNascita();
    }
    getNome(): string {
        return this.anagrafica.getNome();
    }
    getProvinciaNascita(): string {
        return this.anagrafica.getProvinciaNascita();
    }
    isAbilitato(): boolean {
        return this.abilitato;
    }

    toDTO(): CompanionDTO {
        return {
            abilitato: this.abilitato,
            anagrafica: this.anagrafica.toDTO(),
            id: this.id
        }
    }
}