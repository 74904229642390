import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take } from "rxjs";
import { GraficiMonitoraggioSpesaDTO } from "../dtos";

@Injectable({
    providedIn: 'root'
})
export class GraficiService {

      private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }

    /**
      * Ottiene i dati dei grafici su andamento spesa per provincia e andamento spesa per biglietto
      * 
      * @param {string} startDate
      * @param {string} endDate
      * @return {Promise<GraficiMonitoraggioSpesaDTO>}
      */
    getDatiGrafici(startDate, endDate): Promise<GraficiMonitoraggioSpesaDTO> {
        return new Promise<GraficiMonitoraggioSpesaDTO>(
            (resolve, reject) => {
                const params = new HttpParams()
                    .append('startDate', startDate)
                    .append('endDate', endDate);
                this.http.get(this.url_endpoint + api.grafici_ottieni, { params, observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
      * Ottiene i dati dei grafici su andamento spesa per comune della provincia selezionata
      * 
      * @param {string} aliasProvince
      * @param {string} startDate
      * @param {string} endDate
      * @return {Promise<any>} A promise that resolves when the search is complete.
      */
    getDatiGraficoComune(aliasProvince, startDate, endDate): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                const params = new HttpParams()
                    .append('aliasProvince', aliasProvince)
                    .append('startDate', startDate)
                    .append('endDate', endDate);
                this.http.get(this.url_endpoint + api.grafici_comuni_ottieni, { params, observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
      * Ottiene i dati dei grafici sul budget stanziato per province
      * 
      * @param {string} aliasProvince
      * @param {string} startDate
      * @param {string} endDate
      * @return {Promise<any>} A promise that resolves when the search is complete.
      */
    getDatiGraficoBudgetProvicie(aliasProvince, startDate, endDate): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                const params = new HttpParams()
                    .append('aliasProvince', aliasProvince)
                    .append('startDate', startDate)
                    .append('endDate', endDate);
                this.http.get(this.url_endpoint + api.grafici_budget_province_ottieni, { params, observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }
}