import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './carousel.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { CardNewsModule, SharedModule } from '../../../news/module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [CarouselComponent],
  exports: [CarouselComponent],
  imports: [
    CommonModule,
    NgbCarouselModule,
    SharedModule,
    RouterModule,
    CardNewsModule
  ]
})
export class CarouselModule { }
