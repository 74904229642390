import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FieldInterface } from '../../interfaces';

@Component({
  selector: 'app-componi-tabella',
  templateUrl: './componi-tabella.component.html',
  styleUrls: ['./componi-tabella.component.scss']
})

export class ComponiTabellaComponent {
  @Input() set fields(fields: Array<FieldInterface>) {
    if (!!fields && !this.original) {
      this.original = [];
      fields.sort( (a, b) => a.positionSearch - b.positionSearch);
      fields?.forEach(val => this.original.push(Object.assign({}, val)));
      this.init();
    }
  }

  @Output() onChange = new EventEmitter<Array<FieldInterface>>();

  public original: Array<FieldInterface>;
  public options: Array<FieldInterface>;

  onClick() {
    this.onChange.emit(this.options);
  }

  init() {
    this.options = [];
    this.original.forEach(val => this.options.push(Object.assign({}, val)));
  }

  cancel() {
    this.init();
    this.onChange.emit(this.options);
  }
}
