import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { take } from 'rxjs';
import { NewsDTO, PaginationDTO, SubmitNewsDTO } from '../dtos';
import { api } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

    private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }

  aggiungi(news: SubmitNewsDTO): Promise<NewsDTO> {
    return new Promise((resolve, reject) => {
      this.http.post(this.url_endpoint + api.news_aggiungi, news, { observe: 'body' })
        .pipe(take(1))
        .subscribe({
          next: (resp: any) => {
            if (!!resp?.body && resp?.status == 'OK') {
              resolve(resp?.body);
            } else {
              reject(
                {
                  status: 2,
                  error: resp?.message
                }
              );
            }
          },
          error: (error) => {
            reject(error);
          }
        });
    })
  }

  ottieni(id: number): Promise<NewsDTO> {
    return new Promise((resolve, reject) => {
      const params = new HttpParams().append('id', id);
      this.http.get(this.url_endpoint + api.news_ottieni, { observe: 'body', params })
        .pipe(take(1))
        .subscribe({
          next: (resp: any) => {
            if (!!resp?.body && resp?.status == 'OK') {
              resolve(resp?.body);
            } else {
              reject(
                {
                  status: 2,
                  error: resp?.message
                }
              );
            }
          },
          error: (error) => {
            reject(error);
          }
        });
    })
  }

  lista(page: number = 0, pageSize: number = 10): Promise<PaginationDTO> {
    return new Promise((resolve, reject) => {
      const newsFilter = {
        "numeroPagina": page,
        "numeroElementiPagina": pageSize,
        "ordinamento": {
          "ordinamentoAttributiAscendente": '',
          "ordinamentoAttributiDiscendente": 'startDateOfDisplay'
        }
      }

      this.http.post(this.url_endpoint + api.news_lista, newsFilter, { observe: 'body' })
        .pipe(take(1))
        .subscribe({
          next: (resp: any) => {
            if (!!resp?.body && resp?.status == 'OK') {
              resolve(resp?.body);
            } else {
              reject(
                {
                  status: 2,
                  error: resp?.message
                }
              );
            }
          },
          error: (error) => {
            reject(error);
          }
        });
    })
  }

  listaEditor(page: number = 0, pageSize: number = 10): Promise<PaginationDTO> {
    return new Promise((resolve, reject) => {
      const newsFilter = {
        "numeroPagina": page,
        "numeroElementiPagina": pageSize,
        "ordinamento": {
          "ordinamentoAttributiAscendente": '',
          "ordinamentoAttributiDiscendente": 'startDateOfDisplay'
        }
      }

      this.http.post(this.url_endpoint + api.news_lista_editor, newsFilter, { observe: 'body' })
        .pipe(take(1))
        .subscribe({
          next: (resp: any) => {
            if (!!resp?.body && resp?.status == 'OK') {
              resolve(resp?.body);
            } else {
              reject(
                {
                  status: 2,
                  error: resp?.message
                }
              );
            }
          },
          error: (error) => {
            reject(error);
          }
        });
    })
  }

  rimuovi(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = new HttpParams().append('id', id);
      this.http.delete(this.url_endpoint + api.news_rimuovi, { observe: 'body', params })
        .pipe(take(1))
        .subscribe({
          next: (resp: any) => {
            if (!!resp?.body && resp?.status == 'OK') {
              resolve(resp?.body);
            } else {
              reject(
                {
                  status: 2,
                  error: resp?.message
                }
              );
            }
          },
          error: (error) => {
            reject(error);
          }
        });
    })
  }

  aggiorna(news: SubmitNewsDTO, id: number): Promise<NewsDTO> {
    return new Promise((resolve, reject) => {
      const params = new HttpParams().append('id', id);

      this.http.put(this.url_endpoint + api.news_aggiorna, news, { observe: 'body', params })
        .pipe(take(1))
        .subscribe({
          next: (resp: any) => {
            if (!!resp?.body && resp?.status == 'OK') {
              resolve(resp?.body);
            } else {
              reject(
                {
                  status: 2,
                  error: resp?.message
                }
              );
            }
          },
          error: (error) => {
            reject(error);
          }
        });
    });
  }
}
