import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl } from '@angular/forms';
import { BehaviorSubject, debounceTime} from 'rxjs';
import { Newsfacade } from '../../facades/news.facade';
import { TagsInterface } from '../../../../interfaces';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit, OnDestroy {

  @ViewChild('tagsInput') tagsInput: ElementRef<HTMLInputElement>;

  @Input() set form(form: AbstractControl) {
    if (form) {
      this.tags = form as FormArray
    }
  }

  public tags: FormArray = new FormArray([]);
  public text$: BehaviorSubject<string> = new BehaviorSubject('');
  public listTags: TagsInterface[] = [];
  public searchRegExp = /-/gi;

  constructor(private newsFacade: Newsfacade) {}
  ngOnInit(): void {
    this.text$.pipe(debounceTime(600)).subscribe(async (value) => {
      if (value.length <= 0) {
        this.listTags = []
        return
      }

      const tags = await this.newsFacade.ricercaTags(value.replace(/ /gi, '-'), false)
      this.listTags = tags
    })

  }

  ngOnDestroy(): void {
    if (this.text$.closed == false) {
      this.text$.unsubscribe();
    }
  }

  onChange(event: any) {

    if(typeof event == 'string') {
      this.tags.push(new FormControl(event.trim()));
    }
    else {
      this.tags.push(new FormControl((event as any).target.value.trim()));
    }

    if (!!this.tagsInput?.nativeElement) {
      this.tagsInput.nativeElement.value = '';
    }
  }

  selectTag(label: string) {
    if (!!this.tagsInput?.nativeElement) {
      this.tagsInput.nativeElement.value = label
      this.tagsInput.nativeElement.focus()
      this.text$.next('')
    }
  }

  autoComplete(event: any) {
    this.text$.next(event.target.value)
  }

  removeTag(index: number) {
    this.tags.removeAt(index);
  }
}
