import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take } from "rxjs";
import { BudgetIndividualeDTO, CambioAttivoDTO, DelegatoDeleganteDTO, FreeInstanceDTO, PaginationDTO, SearchQueryDTO } from "../dtos";
import { STATO_CAMBIO_RICHIEDENTE } from "../constants";

@Injectable({
    providedIn: 'root'
})
export class FreeInstanceService {

    private url_endpoint;
    constructor(private http: HttpClient, @Inject('env') env) {
        this.url_endpoint = env.environment.url_endpoint;
    }

    /**
     * Ottiene una lista di gratuità in base alla query di ricerca
     *
     * @param { SearchQueryDTO } searchQuery
     * @return { Promise<PaginationDTO> }
     */
    advancedSearch(searchQuery: SearchQueryDTO): Promise<PaginationDTO> {
        return new Promise<PaginationDTO>(
            (resolve, reject) => {
                this.http.post(this.url_endpoint + api.free_instance_ricerca_avanzata, searchQuery, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject(
                                    {
                                        status: 0,
                                        error: resp?.message
                                    }
                                );
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Ottiene il file con lista di gratuità in base alla query di ricerca
     *
     * @param { 'CSV' | 'PDF' } tipoFile
     * @param { SearchQueryDTO } searchQuery
     * @param { string[] } colonne - nomi delle colonne da ottenere
     * @return { Promise<any> }
     */
    advancedSearchDowload(tipoFile: 'CSV' | 'PDF', searchQuery: SearchQueryDTO, colonne: string[]): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                const dto = {
                    dtoRicerca: searchQuery,
                    colonne: colonne,
                    tipoFile: tipoFile
                };
                this.http.post(this.url_endpoint + api.free_instance_scarica_gratuita, dto, { observe: 'response', responseType: 'blob' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (!!resp?.body) {
                                resolve(resp?.body);
                            }
                            else {
                                reject({
                                    status: 3,
                                    error: null
                                })
                            }

                        },
                        error: () => {
                            reject({
                                status: 3,
                                error: null
                            })
                        }
                    });
            });
    }

    /**
     * Richiede il cambio del richiedente della gratuità specificata
     *
     * @param { number } idFreeInstance
     * @param { STATO_CAMBIO_RICHIEDENTE } changeRequestStatus
     * @param { string } codiceFiscale
     * @return {Promise<any>}
     */
    cambioRichiedente(idFreeInstance: number, changeRequestStatus: STATO_CAMBIO_RICHIEDENTE, codiceFiscale: string) {
        return new Promise<any>(
            (resolve, reject) => {
                const body = {
                    idGratuita: idFreeInstance,
                    statoRichiestaCambio: changeRequestStatus,
                    codiceFiscaleNuovoRichiedente: codiceFiscale,
                    ripetizioneCodiceFiscaleNuovoRichiedente: codiceFiscale
                };
                this.http.put(this.url_endpoint + api.free_instance_cambio_richiedente, body, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject(
                                    {
                                        status: 2,
                                        error: resp?.message
                                    }
                                );
                            }
                        },
                        error: (error) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Richiede il cambio del richiedente della gratuità specificata
     *
     * @param { number } idFreeInstance
     * @param { STATO_CAMBIO_RICHIEDENTE } changeRequestStatus
     * @param { string } codiceFiscale
     * @return {Promise<any>}
     */
    cambioRichiedenteBackOffice(idFreeInstance: number, changeRequestStatus: STATO_CAMBIO_RICHIEDENTE, codiceFiscale: string) {
        return new Promise<any>(
            (resolve, reject) => {
                const body = {
                    idGratuita: idFreeInstance,
                    statoRichiestaCambio: changeRequestStatus,
                    codiceFiscaleNuovoRichiedente: codiceFiscale,
                    ripetizioneCodiceFiscaleNuovoRichiedente: codiceFiscale
                };
                this.http.put(this.url_endpoint + api.free_instance_cambio_richiedente_back_office, body, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject(
                                    {
                                        status: 2,
                                        error: resp?.message
                                    }
                                );
                            }
                        },
                        error: (error) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Verifica se l'utente può riscattare qualche gratuità
     *
     * @return {Promise<boolean>}
     */
    controlloCambiAttivi(): Promise<boolean> {
        return new Promise<boolean>(
            (resolve, reject) => {
                this.http.get(this.url_endpoint + api.free_instance_controllo_cambi_attivi, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject(
                                    {
                                        status: 0,
                                        error: resp?.message
                                    }
                                );
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Genera il documento della richiesta di gratuità
     *
     * @param {number} idFreeInstance
     * @return {Promise<any>}
     */
    generadQrCode(idFreeInstance: number): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                const params = new HttpParams()
                    .append('idFreeInstance', idFreeInstance);
                this.http.get(this.url_endpoint + api.free_instance_genera_qrcode, { params: params, responseType: 'blob' })
                    .pipe(take(1))
                    .subscribe({
                        next: (blob) => {
                            resolve(blob);
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Recupera una grautità tramite il suo ID.
     *
     * @param { number } id
     * @return { Promise<FreeInstanceDTO> }
     */
    getById(id: number): Promise<FreeInstanceDTO> {
        return new Promise<FreeInstanceDTO>(
            (resolve, reject) => {
                const params = new HttpParams()
                    .append('freeInstanceId', id);
                this.http.get(this.url_endpoint + api.free_instance_ottieni, { params: params, observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject(
                                    {
                                        status: 0,
                                        error: resp?.message
                                    }
                                );
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Recupera una grautità tramite il suo ID per il Back Office.
     *
     * @param { number } id
     * @return { Promise<FreeInstanceDTO> }
     */
    getByIdBO(id: number): Promise<FreeInstanceDTO> {
        return new Promise<FreeInstanceDTO>(
            (resolve, reject) => {
                const params = new HttpParams()
                    .append('id', id);
                this.http.get(this.url_endpoint + api.free_instance_ottieni_back_office, { params: params, observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject(
                                    {
                                        status: 0,
                                        error: resp?.message
                                    }
                                );
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Riceve il budget residuo della gratuità
     *
     * @return { Promise<BudgetIndividualeDTOy> }
     */
    ottieniResiduoBudgetIndividuale(id: number): Promise<BudgetIndividualeDTO> {
        return new Promise<BudgetIndividualeDTO>(
            (resolve, reject) => {
                const params = new HttpParams()
                    .append('id', id);
                this.http.get(this.url_endpoint + api.free_instance_ottieni_threshold, { params: params, observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                resolve(null);
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Riscatto della gratuità
     *
     * @param { DelegatoDeleganteDTO } dto
     * @return { Promise<string> }
     */
    riscattaGratuita(dto: DelegatoDeleganteDTO) {
        return new Promise<string>(
            (resolve, reject) => {
                const data = {
                    "codice": dto?.codice,
                    "tipologiaRichiedente": dto?.tipologiaRichiedente,
                    "datiBeneficiarioDelegante": {
                        "anagraficaBeneficiario": dto?.anagraficaBeneficiario,
                        "indirizzoBeneficiario": dto?.indirizzoBeneficiario,
                        "datiDelegante": dto?.datiDelegante
                    },
                    "filesDelega": dto?.filesDelega
                };
                this.http.post(this.url_endpoint + api.free_instance_riscatta_gratuita, data, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.message);
                            } else {
                                reject(
                                    {
                                        status: 2,
                                        error: resp?.message
                                    }
                                );
                            }
                        },
                        error: (error) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Ottiene una lista di gratuità in base alla stringa di ricerca.
     *
     * @param { string } searchString
     * @param { number } page
     * @param { number } pageSize
     * @param { boolean } [onlyActive=false]
     * @return { Promise<PaginationDTO> }
     */
    search(searchString: string, page: number, pageSize: number, onlyActive = false): Promise<PaginationDTO> {
        return new Promise<PaginationDTO>(
            (resolve, reject) => {
                const params = {
                    onlyActive: onlyActive,
                    page: page,
                    pageSize: pageSize,
                    searchString: searchString
                };
                this.http.get(this.url_endpoint + api.free_instance_ricerca, { params: new HttpParams().appendAll(params), observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject(
                                    {
                                        status: 0,
                                        error: resp?.message
                                    }
                                );
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Verifica la validità del codice di riscatto
     *
     * @param { string } code
     * @return {Promise<CambioAttivoDTO>}
     */
    verificaCodiceRiscatto(code: string): Promise<CambioAttivoDTO> {
        return new Promise<CambioAttivoDTO>(
            (resolve, reject) => {
                const params = new HttpParams()
                    .append('code', code);
                this.http.get(this.url_endpoint + api.free_instance_verifica_riscatto, { params: params, observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp.status == 'OK') {
                                const dto: CambioAttivoDTO = {
                                    idGratuita: resp?.body
                                };
                                resolve(dto);
                            } else {
                                reject(
                                    {
                                        status: 0,
                                        error: resp?.message
                                    }
                                );
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }
}
