/**
 * Ritorna il file in base64
 * @param file
 * @returns any
 */
export function getBase64(file): Promise<any> {
  return new Promise<any>(
    (resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result.toString());
        return;
      };
      reader.onerror = function () {
        reject();
        return;
      }
    });
}
