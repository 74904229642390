
import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take } from "rxjs";
import { FreeInstanceDTO, DatiIndennitaBeneficiarioDTO } from "../dtos";


@Injectable({
    providedIn: 'root'
})
export class BeneficiarioService {
    private url_endpoint;
    constructor(private http: HttpClient, @Inject('env') env) {
        this.url_endpoint = env.environment.url_endpoint;
    }

    /**
     * Aggiunge i dati di invalidità del beneficiario alla richiestad i gratuità
     *
     * @param { DatiIndennitaBeneficiarioDTO } beneficiario
     * @return { Promise<FreeInstanceDTO> }
     */
    aggiungi(beneficiario: DatiIndennitaBeneficiarioDTO): Promise<FreeInstanceDTO> {
        return new Promise<FreeInstanceDTO>(
            (resolve, reject) => {
                this.http.post(this.url_endpoint + api.beneficiario_aggiungi, beneficiario, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (!!resp?.body && resp?.status == 'OK') {
                                resolve(resp.body);
                            } else {
                                reject({
                                    status: 1,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Verifica che il beneficiario associato al codice fiscale non abbia una gratuità attiva o sospesa
     *
     * @param { string } fiscalCode
     * @return { Promise<boolean> }
     */
    verifica(fiscalCode: string): Promise<boolean> {
        return new Promise<boolean>(
            (resolve, reject) => {
                const params = new HttpParams()
                    .append('fiscalCode', fiscalCode);
                this.http.get(this.url_endpoint + api.beneficiario_controllo, { params, observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (!!resp?.body && resp?.status == 'OK') {
                                resolve(resp.body);
                            } else {
                                reject({
                                    status: 1,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Verifica l'isee del beneficiario
     *
     * @param { string } fiscalCode
     * @return { Promise<boolean> }
     */
    verificaIsee(fiscalCode: string): Promise<boolean> {
        return new Promise<boolean>(
            (resolve, reject) => {
                const params = new HttpParams()
                    .append('fiscalCode', fiscalCode);
                this.http.get(this.url_endpoint + api.isee_controllo, { params, observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (!!resp?.body && resp?.status == 'OK') {
                                resolve(resp.body);
                            } else {
                                reject({
                                    status: 1,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error) => {
                            reject(error);
                        }
                    });
            });
    }
}
