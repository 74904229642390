import { Injectable, Optional } from "@angular/core";

export class Config {
    session_suffix: string;
}

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    // private url_endpoint: string;
    private session_suffix: string;

    constructor(@Optional() config?: Config) {
        if (config) {
            this.session_suffix = config.session_suffix;
        }
    }
    /**
     * Get session suffix
     */
    get sessionSuffix(): string {
        return this.session_suffix;
    }
}