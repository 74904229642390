import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitKey'
})
export class SplitKeyPipe implements PipeTransform {

  transform(value: unknown, ...args: string[]): unknown {
    const key = args[0].split('.');
    return this.getPropsBykey(value, 0, key);
  }

  getPropsBykey(value: unknown, index: number, key: string[]): any {
    if (!!key[index]) {
      return this.getPropsBykey(value[key[index]], index + 1, key);
    }
    return value;
  }

}
