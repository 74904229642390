import { MobileInterface, ValidaUtenteInterface } from "../interfaces";
import { UtenteModel } from "./utente.model";

export class ValidaUtenteModel extends UtenteModel implements ValidaUtenteInterface {
  hasPrivacyPolicy(): boolean {
    return this.privacy;
  }
  isStepAnagrafica(): boolean {
    return (this.getAllEmail().length == 0 && this.getNumeriCellulari().length == 0 || this.hasPrivacyPolicy() == false);
  }

  isStepEmail(): boolean {
    return this.getAllEmail().length > 0 && this.getNumeriCellulari().length == 0 && this.hasPrivacyPolicy() == true;
  }

  isStepTelefono(): boolean {
    return this.hasLastEmailValid() && this.getNumeriCellulari().length > 0;
  }

  savePhoneNumber(phoneNumber: MobileInterface): void {
    const idx = this.getNumeriCellulari().findIndex((item) => item.getNumero() == phoneNumber.getNumero());
    if(idx < 0) {
      this.getNumeriCellulari().push(phoneNumber);
    } else {
      this.getNumeriCellulari()[idx] = phoneNumber;
    }
  }
}
