import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Newsfacade } from '../../facades';
import { NewsInterface } from '../../../../interfaces';
import { NewsModel, TraduzioniNewsModel } from '../../../../models';
import { ImageNewsDTO, SubmitImageNewsDTO, SubmitNewsDTO, TagsDTO, TraduzioniNewsDTO } from '../../../../dtos';
import { CustomValidators } from '../../../../utils';

@Component({
  selector: 'app-aggiungi-news',
  templateUrl: './aggiungi-news.component.html',
  styleUrls: ['./aggiungi-news.component.scss']
})
export class AggiungiNewsComponent implements OnInit {

  public formTraduzione: FormGroup[] = [];
  public locale = 'it';
  public form: FormGroup;
  public news: NewsInterface;
  public copertina = [];
  public immagini: FormControl[] = [];
  public tags: FormControl[] = [];
  public currentMode: 'create' | 'update' = 'create';

  constructor(private formBuilder: FormBuilder,
              private newsFacade: Newsfacade,
              private transtanslate: TranslateService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.locale = this.transtanslate.getDefaultLang();
    this.news = this.activatedRoute.snapshot.data['news'] as NewsInterface;

    if (this.news instanceof NewsModel) {
      this.currentMode = 'update';
      this.copertina.push(this.setImageForUploadComponent(this.news.getCopertina()));

      this.news.getImmagini().forEach((image: ImageNewsDTO) => {
        this.immagini.push(this.setImageForUploadComponent(image));
      });

      this.news.getTagsNews().forEach((tag: TagsDTO) => {
        this.tags.push(new FormControl(tag.label));
      });

      this.news.getTraduzioniNews().forEach((traduzione: TraduzioniNewsModel) => {
        const newForm = this.formBuilder.group({
          lingua: new FormControl(this.locale, [Validators.required]),
          titolo: new FormControl(traduzione.getTitolo(), [Validators.required]),
          descrizione: new FormControl(traduzione.getDescrizione(), [Validators.maxLength(255), Validators.required]),
          html: new FormControl(traduzione.getHtml(), [Validators.required]),
        })
        this.formTraduzione.push(newForm)
      });

      this.form = this.formBuilder.group({
        traduzioniNews: new FormArray(this.formTraduzione, [Validators.min(1), Validators.required]),
        dataInizioVisualizzazione: new FormControl(this.news.getDataInizioVisualizzazione(), [Validators.required]),
        dataDiScadenza: new FormControl(this.news.getDataDiScadenza(), [Validators.required]),
        immagini: new FormArray(this.immagini),
        copertina:new FormArray(this.copertina, [Validators.required, Validators.min(1)]),
        tags: new FormArray(this.tags),
      });

    }
    else {
      const newForm = this.formBuilder.group({
        lingua: new FormControl(this.locale, [Validators.required]),
        titolo: new FormControl('', [Validators.required]),
        descrizione: new FormControl('', [Validators.maxLength(255),Validators.required]),
        html: new FormControl('', [Validators.required]),
      })

      this.formTraduzione.push(newForm)

      this.form = this.formBuilder.group({
        traduzioniNews: new FormArray(this.formTraduzione, [Validators.min(1), Validators.required]),
        dataInizioVisualizzazione: new FormControl(null, [Validators.required]),
        dataDiScadenza: new FormControl(null, [Validators.required]),
        immagini: new FormArray([]),
        copertina:new FormArray([], [Validators.required, Validators.min(1)]),
        tags: new FormArray([]),
      });

      const currentDate = new Date();
      const expirationDate = new Date();
      expirationDate.setDate(currentDate.getDate() + 365);
      this.form.patchValue({
        dataInizioVisualizzazione: currentDate,
        dataDiScadenza: expirationDate
      });
    }
  }

  async onSubmit() {
    if (this.form.valid) {
      this.form.value.copertina.forEach((element: ImageNewsDTO) => {
        this.form.value.immagini.push(element)
      });

      this.form.get('traduzioniNews').value.forEach((traduzione: TraduzioniNewsDTO, index: number) => {
        const traduzioneModel = new TraduzioniNewsModel(traduzione);
        if (traduzioneModel.isEmpty()) {
          this.formTraduzione.splice(index, 1);
          this.form.get('traduzioniNews').patchValue(this.formTraduzione);
        }
      });

      const newsModel: SubmitNewsDTO = this.form.value;
      newsModel.immagini = newsModel.immagini.map((image: SubmitImageNewsDTO) => {

        const regexp = RegExp(`^(data:${image.estensione};base64,\/?)`, 'g');
        if (!regexp.test(image.base64)) {
          image.base64 = `data:${image.estensione};base64,${image.base64}`
        }
        return image;
      });

      try {
        if (this.currentMode == 'update') {
          await this.newsFacade.aggiorna(newsModel, this.news.getId());
        }
        else {
          await this.newsFacade.aggiungi(newsModel);
        }

      } catch (error) {
        console.error(error);
      }
      this.router.navigate(['/', 'news']);
    } else {
      console.log(this.form.value);
    }
  }

  getCurrentFormByLocale(): FormGroup {
    this.form.get('traduzioniNews').patchValue(this.formTraduzione);
    const currentForm = this.formTraduzione?.find((f) => f.get('lingua').value === this.locale);
    return currentForm
  }

  /**
   * Verifica se i form delle varie lingue siano validi
   * @returns la lingua del form non valido
   */
  getInvalidFormLocale(): Array<string> {
    return this.formTraduzione
    .filter(traduzione => traduzione.invalid)
    .map(traduzione => traduzione.get('lingua').value)
    .map(lingua => {
      switch(lingua) {
        case 'it':
          return 'Italiano'
        case 'en':
          return 'Inglese'
        default:
          return ''
      }
    })
  }

  public setLanguage(lang: any) {
    this.locale = lang;

    const form = this.formTraduzione.filter(f => f.get('lingua').value == lang);

    if (form && form.length <= 0) {
      const newForm = this.formBuilder.group({
        lingua: new FormControl(lang),
        titolo: new FormControl('', [CustomValidators.validatorNews()]),
        descrizione: new FormControl('', [CustomValidators.validatorNews()]),
        html: new FormControl(null, [CustomValidators.validatorNews()]),
      });

      this.formTraduzione.push(newForm);
    }
  }

  checkTranslateForm() {
    return this.formTraduzione.every(traduzione => {
      return traduzione.valid
    })
  }

  setImageForUploadComponent(image: ImageNewsDTO): FormControl<SubmitImageNewsDTO> {
    const fileDto: SubmitImageNewsDTO = {
      base64: image.immagine,
      dimensione: image.dimensioneDocumento,
      estensione: image.estensioneDocumento,
      descrizione: image.alt,
      copertina: image.copertina,
      alt: image.alt,
    };

    return new FormControl(fileDto, [Validators.required]);
  }

  onFormChange(event: Event) {
    this.getCurrentFormByLocale().get((event.target as HTMLFormElement).id).updateValueAndValidity();
  }
}

