<ng-container *ngIf="error && type === 'alert'">
    <div class="alert alert-danger alert-dismissible my-3" role="alert">
        <span class="visually-hidden" translate>ACCESSIBILITA.MESSAGGIO_ERRORE</span>
        <ng-container *ngIf="error?.title">
            <h4 class="alert-heading">{{ error?.title }}</h4>
        </ng-container>
        <ng-container *ngIf="dismissible">
            <button type="button" class="btn-close" data-bs-dismiss="alert" [attr.aria-label]="'ACCESSIBILITA.CLOSE_ALERT' | translate" (click)="clear()">
                <svg class="icon">
                    <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-close"></use>
                </svg>
            </button>
        </ng-container>
        <p [innerHTML]="error?.message"></p>
    </div>
</ng-container>
<ng-container *ngIf="error && type === 'text'">
    <div class="h6 text-danger" role="alert">
        <svg class="icon icon-danger icon-sm" aria-label="[title]">
            <title>{{ 'ACCESSIBILITA.MESSAGGIO_ERRORE' | translate }}</title>
            <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-error"></use>
        </svg>
        {{ error?.message | translate }}
    </div>
</ng-container>
<!-- <ng-container *ngIf="error && type === 'modal'"> -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="title">{{ 'ACCESSIBILITA.MESSAGGIO_ERRORE' | translate }}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body" id="description">
        {{ error?.message | translate }}
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cross click')">{{ 'GENERAL.CLOSE' | translate }}</button>
    </div>
</ng-template>
<!-- </ng-container> -->
