import { Injectable, inject } from '@angular/core';
import { ShowcasePageInterface } from '../../../interfaces';
import { ShowcasePage } from '../../../models';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorFacade } from '../../news/facades';
import { ShowcasePageService } from '../../../services';

@Injectable({
  providedIn: 'root'
})
export class ShowcasePageFacade {
  private showcasePageService = inject(ShowcasePageService);

  constructor(private spinner: NgxSpinnerService,
              private errorFacade: ErrorFacade) { }

  async lista(): Promise<ShowcasePageInterface[]> {
    try {
      this.spinner.show();
      const dto = await this.showcasePageService.lista();
      return dto.map((dto) => new ShowcasePage(dto));
    } catch (error) {
      this.errorFacade.handle(error);
      throw error;
    } finally {
      this.spinner.hide();
    }
  }

  async ottieni(id: number): Promise<ShowcasePageInterface> {
    try {
      this.spinner.show();
      const dto = await this.showcasePageService.ottieni(id);
      return new ShowcasePage(dto);
    } catch (error) {
      this.errorFacade.handle(error, null);
      return null;
    } finally {
      this.spinner.hide();
    }
  }
}
