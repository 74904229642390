
import { ShowcasePageInterface } from "../interfaces/showcase-page.interface";
import { ShowcasePageDTO, TraduzioniVetrinaDTO } from "../dtos";
import { ShowCasePageEnum } from "../constants/showcasePage.enum";

export class ShowcasePage implements ShowcasePageInterface {
    protected id: number;
    protected ordinamento: number;
    protected attivo: boolean;
    protected homepage: boolean;
    protected paginaVetrina: TraduzioniVetrinaDTO[];
    protected dataAggiornamento: Date;
    protected dataCreazione: Date;

    constructor(dto: ShowcasePageDTO) {
        this.id = dto?.id;
        this.attivo = dto?.attivo;
        this.homepage = dto?.homepage;
        this.paginaVetrina = dto?.paginaVetrina;
        this.dataAggiornamento = new Date(dto?.dataAggiornamento);
        this.dataCreazione = new Date(dto?.dataCreazione);
        this.ordinamento = dto?.ordinamento;
    }

    getOrdinamento(): number {
        return this.ordinamento;
    }
    getDataCreazione(): Date {
        return this.dataCreazione;
    }
    
    getDataAggiornamento(): Date {
        return this.dataAggiornamento;
    }
    
    isHome(): boolean {
        return this.homepage;
    }
    
    getId(): number {
        return this.id;
    }

    isActive(): boolean {
        return this.attivo;
    }

    getPaginaVetrina(): TraduzioniVetrinaDTO[] {
        return this.paginaVetrina;
    }

    getPageByLocale(language: string): TraduzioniVetrinaDTO {
        return this.getPaginaVetrina().find(({traduzioni}) => traduzioni.lingua === language);
    }

    getNavbarLabel(): string {
        return this.paginaVetrina[0]?.navbarLabel || null;
    }

    getPath(): string {
        return ShowCasePageEnum[this.getNavbarLabel()];
    }

    hasLanguage(language: string): boolean {
        return !!this.getPaginaVetrina().find(({traduzioni}) => traduzioni.lingua === language);
    }
}