import { PaginationDTO } from "../dtos";
import { TicketInterface, TicketPaginationInterface, TicketRowInterface } from "../interfaces";
import { TicketsPaginationModel } from "./tickets-pagination.model";

export class TicketsTablePaginationModel extends TicketsPaginationModel implements TicketPaginationInterface {
    constructor(dto?: PaginationDTO) {
        super(dto);
    }

    getRows(): Array<TicketRowInterface> {
        return this.content.map((ticket: TicketInterface) => {
            const accompagnatore = ticket.getAccompagnatore();
            return {
                aliasFinancialBody: ticket.getEnteFinanziatore(),
                arrivalPoint: ticket?.getStazioneArrivo(),
                creationDate: ticket?.getDataEmissione(),
                credentialCode: ticket?.getCodiceCredenziale(),
                departurePoint: ticket?.getStazionePartenza(),
                fiscalCodeBeneficiary: ticket?.getCodiceFiscaleBeneficiario(),
                fiscalCodeCompanion: !!accompagnatore ? accompagnatore.getCodiceFiscale() : undefined,
                id: ticket?.getIdGratutita(),
                lastNameBeneficiary: ticket?.getNomeBeneficiario(),
                lastNameCompanion: !!accompagnatore ? accompagnatore.getCognome() + ' ' + accompagnatore.getNome() : undefined,
                ticketType: ticket?.getTipologia()
            }
        });
    }
}