import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwitchFreeInstanceComponent } from './switch-free-instance.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    SwitchFreeInstanceComponent
  ],
  exports: [
    SwitchFreeInstanceComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModalModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class SwitchFreeInstanceModule { }
