import { RendicontazioneProvinciaDTO } from "../dtos";
import { RendicontazioneProvinciaInterface } from "../interfaces";

export class RendicontazioneProvinciaModel implements RendicontazioneProvinciaInterface {
    protected aliasProvincia: string;
    protected annotazioni: string;
    protected budgetRendicontato: number;
    protected dataRendicontazione: string;
    protected id: number;

    constructor(dto: RendicontazioneProvinciaDTO) {
        this.aliasProvincia = dto?.aliasProvincia;
        this.annotazioni = dto?.annotazioni;
        this.budgetRendicontato = dto?.budgetRendicontato;
        this.dataRendicontazione = dto?.dataRendicontazione;
        this.id = dto?.id;
    }

    getAnnotazioni(): string {
        return this.annotazioni;
    }

    getBudget(): number {
        return this.budgetRendicontato;
    }

    getData(): string {
        return this.dataRendicontazione;
    }

    getId(): number {
        return this.id;
    }

    getProvincia(): string {
        return this.aliasProvincia;
    }
}