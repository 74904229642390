import { NgModule } from '@angular/core';
import { DateToStringDirective, GetBase64Directive } from '../directives';



@NgModule({
  declarations: [
    GetBase64Directive,
    DateToStringDirective
  ],
  exports: [
    GetBase64Directive,
    DateToStringDirective
  ]
})
export class SharedModule { }
