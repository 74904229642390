<ngb-carousel class="carousel" *ngIf="news.getImmagini().length > 0">
    <ng-container *ngFor="let image of news.getImmagini(); let i = index">
        <ng-template ngbSlide>
            <div class="w-100 row ps-5 pe-4 my-4">
                <img src="assets/images/placeholder-SGP.png" title="placeholder news regione puglia"
                    class="img-cover mx-auto"
                    alt="placeholder news regione puglia" appGetBase64 [setBase64]="image">
            </div>
        </ng-template>
    </ng-container>
</ngb-carousel>