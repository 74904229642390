import { ConfigurazioneSogliaMensileDTO } from "../dtos";
import { ConfigurazioneSogliaMensileInterface } from "../interfaces";

export class ConfigurazioneSogliaMensileModel implements ConfigurazioneSogliaMensileInterface {
    protected budget: number;
    protected budgetSpent: number;
    protected checked: boolean;
    protected valoreNumericoMese: number;
    constructor(dto: ConfigurazioneSogliaMensileDTO) {
        this.budget = dto?.budget;
        this.budgetSpent = dto?.budgetSpent;
        this.checked = dto?.checked;
        this.valoreNumericoMese = dto?.valoreNumericoMese;
    }
    getBudget(): number {
        return this.budget;
    }
    getBudgetSpeso(): number {
        return this.budgetSpent;
    }
    getMese(): number {
        return this.valoreNumericoMese;
    }
    isChecked(): boolean {
        return this.checked;
    }
}