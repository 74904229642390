import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take } from "rxjs";
import { FamiliareInpsDTO } from "../dtos";

@Injectable({
    providedIn: 'root'
})
export class FamiliareService {

      private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }

    /**
     * Restituisce l'esito del controllo INPS riguardo l'invalidità di un soggetto
     *
     * @param {DatiIndennitaDTO} datiIndennita
     * @return {Promise<ResponseModel>}
     */
    aggiungi(familiare: any): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                this.http.post(this.url_endpoint + api.familiare_aggiungi, familiare, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (!!resp?.body) {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Recupera il nucleo familiare.
     *
     * @param { string } fiscalCode - il codice fiscale da utilizzare per il recupero
     * @param { string } referenceDate - la data di riferimento da utilizzare per il recupero
     * @return {Promise<FamiliareInpsDTO[]>}
     */
    getNucleoFamiliare(fiscalCode: string): Promise<FamiliareInpsDTO[]> {
        return new Promise<FamiliareInpsDTO[]>(
            (resolve, reject) => {
                const params = new HttpParams()
                    .append('fiscalCode', fiscalCode);

                this.http.get(this.url_endpoint + api.familiare_lista, { params: params, observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (!!resp?.body) {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }
}