
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take } from "rxjs";
import { UtenteDTO } from "../dtos";

@Injectable({
  providedIn: 'root'
})
export class UtenteService {

  private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }

  /**
    * Recupera il current user.
    *
    * @return {Promise<UtenteDTO>} Una promise che si risolve nell'oggetto Utente.
    */
  getUtente(): Promise<UtenteDTO> {
    return new Promise<UtenteDTO>(
      (resolve, reject) => {

        this.http.get(this.url_endpoint + api.utente_ottieni, { observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              if (!!resp?.body) {
                resolve(resp?.body);
              } else {
                reject({
                  status: 0,
                  error: resp?.message
                })
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  /**
  * Recupera l'operatore.
  *
  * @return {Promise<UtenteDTO>} Una promise che si risolve nell'oggetto Utente.
  */
  getOperatore(): Promise<UtenteDTO> {
    return new Promise<UtenteDTO>(
      (resolve, reject) => {
        this.http.get(this.url_endpoint + api.operatore_ottieni, { observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              if (resp?.status == 'OK') {
                resolve(resp?.body);
              } else {
                reject(
                  {
                    status: 0,
                    error: resp?.message
                  }
                );
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  /**
    * Logout
    *
    * @return {Promise<boolean>}
    */
  logout(): Promise<boolean> {
    return new Promise<boolean>(
      (resolve, reject) => {

        this.http.get(this.url_endpoint + api.authentication_logout, { observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: HttpResponse<any>) => {
              if (!!resp?.body) {
                resolve(true);
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });

  }
}
