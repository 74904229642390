import { Injectable } from "@angular/core";
import { FreeInstanceService, RiscontroService, FreeInstanceModel, FreeInstanceInterface, FILE_TYPE, FileService } from "core-model";
import { ErrorFacade } from "./error.facade";
import { NgxSpinnerService } from "ngx-spinner";
import * as FileSaver from 'file-saver';

@Injectable({
    providedIn: 'any'
})
export class RiscontroFacade {
    constructor(private errorFacade: ErrorFacade,
        private fileService: FileService,
        private freeInstanceService: FreeInstanceService,
        private riscontroService: RiscontroService,
        private spinner: NgxSpinnerService) { }

    /**
    * Download del documento inviato dall'operatore durante la richiesta integrazioni
    *
    * @param { FreeInstanceInterface } freeInstance
    * @return { Promise<any> }
    */
    async downloadDocumentoIntegrazioni(freeInstance: FreeInstanceInterface): Promise<any> {
        try {
            this.spinner.show();
            const file = await this.fileService.download(freeInstance.getId(), freeInstance.getIdFileRichiestaIntegrazioni(), FILE_TYPE.INTEGRATION);
            const blob = new Blob([file], { type: 'application/pdf' });
            FileSaver.saveAs(blob, freeInstance.getNomeFileRichiestaIntegrazioni());
            return true;
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
    * Download del documento di riscontro inviato dall'utente
    *
    * @param { FreeInstanceInterface } freeInstance
    * @return { Promise<any> }
    */
    async downloadDocumentoRevoca(freeInstance: FreeInstanceInterface): Promise<any> {
        try {
            this.spinner.show();
            const file = await this.fileService.download(freeInstance.getId(), freeInstance.getIdFileRevoca(), FILE_TYPE.REVOCATION);
            const blob = new Blob([file], { type: 'application/pdf' });
            FileSaver.saveAs(blob, freeInstance.getNomeFileRevoca());
            return true;
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
    * Download del documento di riscontro inviato dall'utente
    *
    * @param { FreeInstanceInterface } freeInstance
    * @return { Promise<any> }
    */
    async downloadDocumentoRiscontro(freeInstance: FreeInstanceInterface): Promise<any> {
        try {
            this.spinner.show();
            const file = await this.fileService.download(freeInstance.getId(), freeInstance.getIdFileRiscontroSospensione(), FILE_TYPE.SUSPENSION_RESPONSE);
            const blob = new Blob([file], { type: 'application/pdf' });
            FileSaver.saveAs(blob, freeInstance.getNomeFileRiscontroSospensione());
            return true;
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
    * Download del documento inviato dall'operatore durante la sospensione
    *
    * @param { FreeInstanceInterface } freeInstance
    * @return { Promise<any> }
    */
    async downloadDocumentoSospensione(freeInstance: FreeInstanceInterface): Promise<any> {
        try {
            this.spinner.show();
            const file = await this.fileService.download(freeInstance.getId(), freeInstance.getIdFileSospensione(), FILE_TYPE.SUSPENSION);
            const blob = new Blob([file], { type: 'application/pdf' });
            FileSaver.saveAs(blob, freeInstance.getNomeFileSospensione());
            return true;
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }


    /**
     * Rinuncia
     *
     * @param { number } idGratuita
     * @param { string } noteRinuncia
     * @return { Promise<FreeInstanceInterface> }
     */
    async riscontro(idGratuita: number, noteRiscontro: string, files: any[]): Promise<FreeInstanceInterface> {
        try {
            this.spinner.show();
            await this.riscontroService.invia(idGratuita, noteRiscontro, files);
            const dto = await this.freeInstanceService.getById(idGratuita);
            return !!dto ? new FreeInstanceModel(dto) : null;
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

}