import { Injectable } from '@angular/core';
import { ShowcasePage, ShowcasePageInterface, ShowcasePageObserver, ShowcasePageService } from 'core-model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorFacade } from './error.facade';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShowcasePageFacade {

  constructor(private showcasePageService: ShowcasePageService,
              private spinner: NgxSpinnerService,
              private showcasePageObserver: ShowcasePageObserver,
              private errorFacade: ErrorFacade) { }

  async lista(): Promise<ShowcasePageInterface[]> {
    try {
      this.spinner.show();
      const dto = await this.showcasePageService.lista();
      return dto.map((dto) => new ShowcasePage(dto));
    } catch (error) {
      this.errorFacade.handle(error);
      throw error;
    } finally {
      this.spinner.hide();
    }
  }

  async ottieni(id: number): Promise<ShowcasePageInterface> {
    try {
      this.spinner.show();
      const dto = await this.showcasePageService.ottieni(id);
      return new ShowcasePage(dto);
    } catch (error) {
      this.errorFacade.handle(error, null);
      return null;
    } finally {
      this.spinner.hide();
    }
  }

  setShowCasePage(showCasePage: ShowcasePage): void {
    this.showcasePageObserver.setShowCasePage(showCasePage)
  }

  on(): Observable<ShowcasePage> {
    return this.showcasePageObserver.on();
  }
}
