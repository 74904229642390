<!-- <ng-container *ngIf="isManager">
    <div class="container d-flex flex-row-reverse">
        <button itButton="primary" class="btn btn-primary me-2"
            [routerLink]="['/', 'news', 'aggiungi']">{{'NAVIGATION.AGGIUNGI_NEWS' | translate}}</button>
    </div>
</ng-container> -->

<div class="container mt-2 mb-2 h-content">
    <h1 id="title1">{{ 'NAVIGATION.NEWS' | translate}}</h1>
    <ng-container *ngIf="newsList?.getContent()?.length > 0; else emptyList">
        <div class="row ">
          <ng-container *ngFor="let news of newsList?.getContent()">
              <div class="col-4 mb-4">
                  <app-card-news [news]="news"></app-card-news>
              </div>
          </ng-container>
        </div>
    </ng-container>

    <ngb-pagination class="d-flex justify-content-center"
        size="lg"
        [collectionSize]="newsList?.getTotalElements()"
        *ngIf="newsList?.getTotalElements() > pageSize" [(page)]="page" [maxSize]="5" [pageSize]="pageSize"

        (pageChange)="pageChange()" [ariaLabel]="'ACCESSIBILITA.PAGINAZIONE' | translate">
        <ng-template ngbPaginationPrevious>
            <span class="visually-hidden" translate>ACCESSIBILITA.PAGINA_PRECEDENTE</span>
            <svg class="icon icon-primary">
                <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-chevron-left"></use>
            </svg>
        </ng-template>
        <ng-template ngbPaginationNext>
            <span class="visually-hidden" translate>ACCESSIBILITA.PAGINA_SUCCESSIVA</span>
            <svg class="icon icon-primary">
                <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-chevron-right"></use>
            </svg>
        </ng-template>
    </ngb-pagination>
</div>

<ng-template #emptyList>
    <div class="alert alert-info mt-4" style="height: fit-content;" role="alert" translate="">
        ERROR.EMPTY_NEWS
      </div>
</ng-template>
