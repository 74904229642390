import { PaginationDTO } from "../dtos";
import { PaginationInterface, RendicontazioneProvinciaInterface } from "../interfaces";
import { PaginationModel } from "./pagination.model";
import { RendicontazioneProvinciaModel } from "./rendicontazione-provincia.model";

export class RendicontazioneProvinciaPaginationModel extends PaginationModel implements PaginationInterface {
    override content: RendicontazioneProvinciaInterface[];
    constructor(dto?: PaginationDTO) {
        super(dto);
        this.content = dto?.content?.map( (el) => new RendicontazioneProvinciaModel(el));
    }

    override getContent(): RendicontazioneProvinciaInterface[] {
        return this.content;
    }
}