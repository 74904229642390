import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagsComponent } from './tags.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    TagsComponent
  ],
  exports: [
    TagsComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgbDropdownModule
  ]
})
export class TagsModule { }
