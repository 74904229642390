import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UtenteInterface } from '../interfaces';

@Injectable()
export class CurrentUserObserver {
    protected userLogged: UtenteInterface;
    protected utente$: BehaviorSubject<UtenteInterface>;

    constructor() {
        this.userLogged = null;
        this.utente$ = new BehaviorSubject<UtenteInterface>(this.userLogged);
    }

    public get(): UtenteInterface {
        return this.utente$.value;
    }

    public on(): Observable<UtenteInterface> {
        return this.utente$.asObservable();
    }

    public set(userLogged: UtenteInterface) {
        this.userLogged = userLogged;
        this.utente$.next(this.userLogged);
    }
  }
