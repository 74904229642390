import { TIPOLOGIA_RICHIEDENTE } from "../constants";
import { CambioAttivoDTO } from "../dtos/cambio-attivo.dto";
import { CambioAttivoInterface } from "../interfaces";

export class CambioAttivoModel implements CambioAttivoInterface {
    private idFreeInstance: number;
    private applicantType: TIPOLOGIA_RICHIEDENTE;
    private codice: string;
    constructor(dto: CambioAttivoDTO) {
        this.idFreeInstance = !!dto?.idFreeInstance ? dto.idFreeInstance : dto?.idGratuita;
        this.applicantType = !!dto?.applicantType ? dto.applicantType : dto?.tipoRichiedente;
    }

    getApplicantType(): TIPOLOGIA_RICHIEDENTE {
        return this.applicantType;
    }

    getCodice(): string {
        return this.codice;
    }

    getIdFreeInstance(): number {
        return this.idFreeInstance;
    }

    isBeneficiario(): boolean {
        return this.applicantType === TIPOLOGIA_RICHIEDENTE.BENEFICIARIO;
    }
    isDelegato(): boolean {
        return this.applicantType === TIPOLOGIA_RICHIEDENTE.TERZO_DELEGATO;
    }
    isFamiliare(): boolean {
        return this.applicantType === TIPOLOGIA_RICHIEDENTE.COMPONENTE_NUCLEO_FAMILIARE;
    }
    setCodice(codice: string) {
        this.codice = codice;
    }
}