import { Directive, ElementRef, Input, OnInit } from '@angular/core';

/**
 * questa direttiva serve per convertire la data in una stringa nel formato YYYY-MM-DD
 */
@Directive({
  selector: '[appDateToString]'
})
export class DateToStringDirective implements OnInit {

  private _date: Date;

  @Input() set date (date: Date) {
    if (typeof date === 'string') {
      date = new Date(date)
    }
    this.el.nativeElement.value = date.toISOString().split('T')[0];
    this._date = date;
  }

  get date() {
    return this._date;
  }

  constructor(private el: ElementRef<HTMLInputElement>) {}

  ngOnInit(): void {
    this.el.nativeElement.value = this.date.toISOString().split('T')[0];
  }
}
