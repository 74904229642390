
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take } from "rxjs";
import { MobileDTO } from "../dtos";

@Injectable({
  providedIn: 'root'
})
export class PhoneNumberService {

    private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }


  /**
   * Aggiunge un numero di telefono al current user
   *
   * @param {type} telRequest
   * @return {Promise<MobileDTO>}
   */
  sendPhoneNumber(phoneNumber: string, confirmPhoneNumber: string): Promise<MobileDTO> {
    return new Promise<MobileDTO>(
      (resolve, reject) => {
        this.http.post(this.url_endpoint + api.phone_number_aggiungi, { mobilePhone: phoneNumber, confirmMobilePhone: confirmPhoneNumber }, { observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              if (!!resp?.body) {
                resolve(resp?.body);
              } else {
                reject({
                  status: 0,
                  error: resp?.message
                })
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  /**
   * Attivazione/disattivazione del numero di telefono.
   *
   * @param {string} numero
   * @return {Promise<boolean>}
   */
  cambiaStatoTelefono(numero: string): Promise<boolean> {
    return new Promise<boolean>(
      (resolve, reject) => {
        const params = new HttpParams()
          .append('mobilePhone', numero);
        this.http.put(this.url_endpoint + api.phone_number_cambia_stato, null, { params: params, observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: HttpResponse<any>) => {
              if (!!resp?.body) {
                resolve(true);
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  /**
   * Valida un numero di telefono.
   *
   * @param {string} code - Il codice del numero di telefono da validare.
   * @return {Promise<boolean>} Una promessa che si risolve a true se il numero di telefono è valido, altrimenti false.
   */
  validPhoneNumber(code: string): Promise<boolean> {
    return new Promise<boolean>(
      (resolve, reject) => {
        const params = new HttpParams()
          .append('code', code);

        this.http.put(this.url_endpoint + api.phone_number_validazione, null, { params: params, observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: HttpResponse<any>) => {
              if (!!resp?.body) {
                resolve(true);
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  /**
   * API per l'abilitazione/disabilitazione della ricezione sulle notifiche di un numero di cellulare.
   *
   * @param {number} id - L'ID dell'elemento per il quale attivare le notifiche.
   * @return {Promise<boolean>} Una promise che si risolve in true se la richiesta è stata eseguita con successo, o viene rifiutata con un errore altrimenti.
   */
  attivazioneNotificaCellulare(id: number): Promise<boolean> {
    return new Promise<boolean>(
      (resolve, reject) => {
        const params = new HttpParams()
          .append('id', id);

        this.http.put(this.url_endpoint + api.phone_number_attivazione_notifica, null, { params: params, observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: HttpResponse<any>) => {
              if (!!resp?.body) {
                resolve(true);
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  /**
   * API per il rinnovo del codice di attivazione OTP del cellulare.
   *
   * @param {string} numero - numero telefono da rinnovare.
   * @return {Promise<boolean>} Una Promise che restituisce true se l'attivazione è stata rinnovata con successo.
   */
  rinnovaAttivazioneEmail(numero: string): Promise<boolean> {
    return new Promise<boolean>(
      (resolve, reject) => {
        const params = new HttpParams()
          .append('number', numero);

        this.http.put(this.url_endpoint + api.phone_number_rinnova_attivazione, null, { params: params, observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: HttpResponse<any>) => {
              if (!!resp?.body) {
                resolve(true);
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

}
