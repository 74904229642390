import { MobileDTO } from "../dtos";
import { MobileInterface, UtenteInterface } from "../interfaces";
import { UtenteModel } from "./utente.model";

export class MobileModel implements MobileInterface {
attivoPerNotifica: boolean;
  protected codice: string;
  protected dataAggiornamento: Date;
  protected dataAttivazione: Date;
  protected dataCreazione: Date;
  protected dataDisattivazione: Date;
  protected dataScadenzaCodice: Date;
  protected id: number;
  protected numero: string;
  protected utente: UtenteInterface;
  protected verificato: boolean;

  constructor(dto: MobileDTO) {
    this.attivoPerNotifica = dto?.attivoPerNotifica;
    this.codice = dto?.codice;
    this.dataAggiornamento = dto?.dataAggiornamento ? new Date(dto?.dataAggiornamento) : undefined;
    this.dataAttivazione = dto?.dataAttivazione ? new Date(dto?.dataAttivazione) : undefined;
    this.dataDisattivazione = dto?.dataDisattivazione ? new Date(dto?.dataDisattivazione) : undefined;
    this.dataCreazione = dto?.dataCreazione ? new Date(dto?.dataCreazione) : undefined;
    this.dataScadenzaCodice = dto?.dataScadenzaCodice ? new Date(dto?.dataScadenzaCodice) : undefined;
    this.id = dto?.id;
    this.numero = dto?.numero;
    this.utente = dto?.utente ? new UtenteModel(dto?.utente) : undefined;
    this.verificato = dto?.verificato;
  }

  getId(): number | undefined {
    return this.id;
  }

  getNumero(): string | undefined {
    return this.numero;
  }

  getCodice(): string | undefined {
    return this.codice;
  }

  getDataScadenzaCodice(): Date | undefined {
    return this.dataScadenzaCodice;
  }

  isVerificato(): boolean | undefined {
    return this.verificato;
  }

  isAttivoPerNotifica(): boolean | undefined {
    return this.attivoPerNotifica;
  }

  getDataAttivazione(): Date | undefined {
    return this.dataAttivazione;
  }

  getDataDisattivazione(): Date | undefined {
    return this.dataDisattivazione;
  }

  getDataCreazione(): Date | undefined {
    return this.dataCreazione;
  }

  getDataAggiornamento(): Date | undefined {
    return this.dataAggiornamento;
  }

  getUtente(): UtenteInterface | undefined {
    return this.utente;
  }

  setAttivoPerNotifica(attivoPerNotifica: boolean): void {
    this.attivoPerNotifica = attivoPerNotifica;
  }
  setCodice(codice: string): void {
    this.codice = codice;
  }
  setDataAggiornamento(dataAggiornamento: Date): void {
    this.dataAggiornamento = dataAggiornamento;
  }
  setDataAttivazione(dataAttivazione: Date): void {
    this.dataAttivazione = dataAttivazione;
  }
  setDataCreazione(dataCreazione: Date): void {
    this.dataCreazione = dataCreazione;
  }
  setDataDisattivazione(dataDisattivazione: Date): void {
    this.dataDisattivazione = dataDisattivazione;
  }
  setDataScadenzaCodice(dataScadenzaCodice: Date): void {
    this.dataScadenzaCodice = dataScadenzaCodice;
  }
  setId(id: number): void {
    this.id = id;
  }
  setNumero(numero: string): void {
    this.numero = numero;
  }
  setUtente(utente: UtenteInterface): void {
    this.utente = utente;
  }
  setVerificato(verificato: boolean): void {
    this.verificato = verificato;
  }

  toDTO(): MobileDTO {
    return {
      codice: this.codice,
      dataAggiornamento: this.dataAggiornamento?.toJSON(),
      dataAttivazione: this.dataAttivazione?.toJSON(),
      dataCreazione: this.dataCreazione?.toJSON(),
      dataDisattivazione: this.dataDisattivazione?.toJSON(),
      dataScadenzaCodice: this.dataScadenzaCodice?.toJSON(),
      id: this.id,
      numero: this.numero,
      utente: this.utente?.toDTO(),
      verificato: this.verificato
    }
  }
}
