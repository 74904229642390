export const COMUNI_ITA = [
    {
        codice_regione: "05",
        denominazione: "Abano Terme",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Abbadia Cerreto",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "03",
        denominazione: "Abbadia Lariana",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "09",
        denominazione: "Abbadia San Salvatore",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "20",
        denominazione: "Abbasanta",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "13",
        denominazione: "Abbateggio",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "03",
        denominazione: "Abbiategrasso",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "09",
        denominazione: "Abetone Cutigliano",
        denominazione_provincia: "Pistoia",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PT"
    },
    {
        codice_regione: "17",
        denominazione: "Abriola",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "19",
        denominazione: "Acate",
        denominazione_provincia: "Ragusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "RG"
    },
    {
        codice_regione: "16",
        denominazione: "Accadia",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "01",
        denominazione: "Acceglio",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "17",
        denominazione: "Accettura",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "13",
        denominazione: "Acciano",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "12",
        denominazione: "Accumoli",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "17",
        denominazione: "Acerenza",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "15",
        denominazione: "Acerno",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "15",
        denominazione: "Acerra",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "19",
        denominazione: "Aci Bonaccorsi",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "19",
        denominazione: "Aci Castello",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "19",
        denominazione: "Aci Catena",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "19",
        denominazione: "Aci Sant'Antonio",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "19",
        denominazione: "Acireale",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "12",
        denominazione: "Acquafondata",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "18",
        denominazione: "Acquaformosa",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Acquafredda",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "11",
        denominazione: "Acqualagna",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "03",
        denominazione: "Acquanegra Cremonese",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Acquanegra sul Chiese",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "12",
        denominazione: "Acquapendente",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "18",
        denominazione: "Acquappesa",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "18",
        denominazione: "Acquaro",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "11",
        denominazione: "Acquasanta Terme",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "10",
        denominazione: "Acquasparta",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "14",
        denominazione: "Acquaviva Collecroce",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "14",
        denominazione: "Acquaviva d'Isernia",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "16",
        denominazione: "Acquaviva delle Fonti",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "11",
        denominazione: "Acquaviva Picena",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "19",
        denominazione: "Acquaviva Platani",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "19",
        denominazione: "Acquedolci",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "01",
        denominazione: "Acqui Terme",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "18",
        denominazione: "Acri",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "12",
        denominazione: "Acuto",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "16",
        denominazione: "Adelfia",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "19",
        denominazione: "Adrano",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "03",
        denominazione: "Adrara San Martino",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Adrara San Rocco",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Adria",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "03",
        denominazione: "Adro",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "05",
        denominazione: "Affi",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "12",
        denominazione: "Affile",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "15",
        denominazione: "Afragola",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "18",
        denominazione: "Africo",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "08",
        denominazione: "Agazzano",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "15",
        denominazione: "Agerola",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "20",
        denominazione: "Aggius",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "19",
        denominazione: "Agira",
        denominazione_provincia: "Enna",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "EN"
    },
    {
        codice_regione: "09",
        denominazione: "Agliana",
        denominazione_provincia: "Pistoia",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PT"
    },
    {
        codice_regione: "01",
        denominazione: "Agliano Terme",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Agliè",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "20",
        denominazione: "Aglientu",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "05",
        denominazione: "Agna",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Agnadello",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "18",
        denominazione: "Agnana Calabra",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "14",
        denominazione: "Agnone",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "03",
        denominazione: "Agnosine",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "05",
        denominazione: "Agordo",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "12",
        denominazione: "Agosta",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "03",
        denominazione: "Agra",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Agrate Brianza",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "01",
        denominazione: "Agrate Conturbia",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "19",
        denominazione: "Agrigento",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: true,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "15",
        denominazione: "Agropoli",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "11",
        denominazione: "Agugliano",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "05",
        denominazione: "Agugliaro",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Aicurzio",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "20",
        denominazione: "Aidomaggiore",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "19",
        denominazione: "Aidone",
        denominazione_provincia: "Enna",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "EN"
    },
    {
        codice_regione: "13",
        denominazione: "Aielli",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "18",
        denominazione: "Aiello Calabro",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "06",
        denominazione: "Aiello del Friuli",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "15",
        denominazione: "Aiello del Sabato",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "18",
        denominazione: "Aieta",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "15",
        denominazione: "Ailano",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "01",
        denominazione: "Ailoche",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "01",
        denominazione: "Airasca",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "Airola",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "07",
        denominazione: "Airole",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "03",
        denominazione: "Airuno",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "01",
        denominazione: "Aisone",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "04",
        denominazione: "Ala",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "20",
        denominazione: "Alà dei Sardi",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "01",
        denominazione: "Ala di Stura",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Alagna",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Alagna Valsesia",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "13",
        denominazione: "Alanno",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "07",
        denominazione: "Alassio",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "12",
        denominazione: "Alatri",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "01",
        denominazione: "Alba",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "13",
        denominazione: "Alba Adriatica",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "20",
        denominazione: "Albagiara",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "03",
        denominazione: "Albairate",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "15",
        denominazione: "Albanella",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "17",
        denominazione: "Albano di Lucania",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "12",
        denominazione: "Albano Laziale",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "03",
        denominazione: "Albano Sant'Alessandro",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Albano Vercellese",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "05",
        denominazione: "Albaredo d'Adige",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "03",
        denominazione: "Albaredo per San Marco",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "08",
        denominazione: "Albareto",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "01",
        denominazione: "Albaretto della Torre",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Albavilla",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "07",
        denominazione: "Albenga",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "01",
        denominazione: "Albera Ligure",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "16",
        denominazione: "Alberobello",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "16",
        denominazione: "Alberona",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "03",
        denominazione: "Albese con Cassano",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "05",
        denominazione: "Albettone",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "18",
        denominazione: "Albi",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "04",
        denominazione: "Albiano",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Albiano d'Ivrea",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Albiate",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "18",
        denominazione: "Albidona",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "05",
        denominazione: "Albignasego",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "08",
        denominazione: "Albinea",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "03",
        denominazione: "Albino",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Albiolo",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "07",
        denominazione: "Albisola Superiore",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "07",
        denominazione: "Albissola Marina",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "03",
        denominazione: "Albizzate",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Albonese",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Albosaggia",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "01",
        denominazione: "Albugnano",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "03",
        denominazione: "Albuzzano",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "19",
        denominazione: "Alcamo",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "19",
        denominazione: "Alcara li Fusi",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "04",
        denominazione: "Aldeno",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "04",
        denominazione: "Aldino",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "20",
        denominazione: "Ales",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "01",
        denominazione: "Alessandria",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: true,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "18",
        denominazione: "Alessandria del Carretto",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "19",
        denominazione: "Alessandria della Rocca",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "16",
        denominazione: "Alessano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "16",
        denominazione: "Alezio",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "15",
        denominazione: "Alfano",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "13",
        denominazione: "Alfedena",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "03",
        denominazione: "Alfianello",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Alfiano Natta",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "08",
        denominazione: "Alfonsine",
        denominazione_provincia: "Ravenna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RA"
    },
    {
        codice_regione: "20",
        denominazione: "Alghero",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Algua",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "19",
        denominazione: "Alì",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "19",
        denominazione: "Alì Terme",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "19",
        denominazione: "Alia",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "17",
        denominazione: "Aliano",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "01",
        denominazione: "Alice Bel Colle",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Alice Castello",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "15",
        denominazione: "Alife",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "19",
        denominazione: "Alimena",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "19",
        denominazione: "Aliminusa",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "20",
        denominazione: "Allai",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "05",
        denominazione: "Alleghe",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "02",
        denominazione: "Allein",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "10",
        denominazione: "Allerona",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "16",
        denominazione: "Alliste",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "12",
        denominazione: "Allumiere",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "01",
        denominazione: "Alluvioni Piovera",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Almè",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Almenno San Bartolomeo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Almenno San Salvatore",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Almese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "05",
        denominazione: "Alonte",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "05",
        denominazione: "Alpago",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "01",
        denominazione: "Alpette",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Alpignano",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "08",
        denominazione: "Alseno",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "03",
        denominazione: "Alserio",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "08",
        denominazione: "Alta Val Tidone",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "03",
        denominazione: "Alta Valle Intelvi",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "16",
        denominazione: "Altamura",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "07",
        denominazione: "Altare",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "04",
        denominazione: "Altavalle",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "15",
        denominazione: "Altavilla Irpina",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "19",
        denominazione: "Altavilla Milicia",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "01",
        denominazione: "Altavilla Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "15",
        denominazione: "Altavilla Silentina",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "05",
        denominazione: "Altavilla Vicentina",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "11",
        denominazione: "Altidona",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "18",
        denominazione: "Altilia",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "13",
        denominazione: "Altino",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "05",
        denominazione: "Altissimo",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "05",
        denominazione: "Altivole",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "01",
        denominazione: "Alto",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "08",
        denominazione: "Alto Reno Terme",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "01",
        denominazione: "Alto Sermenza",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "19",
        denominazione: "Altofonte",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "18",
        denominazione: "Altomonte",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "09",
        denominazione: "Altopascio",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "04",
        denominazione: "Altopiano della Vigolana",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "10",
        denominazione: "Alviano",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "15",
        denominazione: "Alvignano",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "12",
        denominazione: "Alvito",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "03",
        denominazione: "Alzano Lombardo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Alzano Scrivia",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Alzate Brianza",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "15",
        denominazione: "Amalfi",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "11",
        denominazione: "Amandola",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "18",
        denominazione: "Amantea",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "06",
        denominazione: "Amaro",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "18",
        denominazione: "Amaroni",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "12",
        denominazione: "Amaseno",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "18",
        denominazione: "Amato",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "12",
        denominazione: "Amatrice",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "03",
        denominazione: "Ambivere",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "04",
        denominazione: "Amblar-Don",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "07",
        denominazione: "Ameglia",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "10",
        denominazione: "Amelia",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "18",
        denominazione: "Amendolara",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "01",
        denominazione: "Ameno",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "15",
        denominazione: "Amorosi",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "06",
        denominazione: "Ampezzo",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "15",
        denominazione: "Anacapri",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "12",
        denominazione: "Anagni",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "13",
        denominazione: "Ancarano",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "11",
        denominazione: "Ancona",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: true,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "18",
        denominazione: "Andali",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "04",
        denominazione: "Andalo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Andalo Valtellino",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "01",
        denominazione: "Andezeno",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "07",
        denominazione: "Andora",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "01",
        denominazione: "Andorno Micca",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "16",
        denominazione: "Andrano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "01",
        denominazione: "Andrate",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "06",
        denominazione: "Andreis",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "15",
        denominazione: "Andretta",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "16",
        denominazione: "Andria",
        denominazione_provincia: "Barletta-Andria-Trani",
        denominazione_regione: "Puglia",
        flag_capoluogo: true,
        sigla_provincia: "BT"
    },
    {
        codice_regione: "04",
        denominazione: "Andriano",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "20",
        denominazione: "Anela",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Anfo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Angera",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "09",
        denominazione: "Anghiari",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "05",
        denominazione: "Angiari",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "03",
        denominazione: "Angolo Terme",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "15",
        denominazione: "Angri",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "01",
        denominazione: "Angrogna",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "12",
        denominazione: "Anguillara Sabazia",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "05",
        denominazione: "Anguillara Veneta",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Annicco",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Annone di Brianza",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "05",
        denominazione: "Annone Veneto",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "18",
        denominazione: "Anoia",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "03",
        denominazione: "Antegnate",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "04",
        denominazione: "Anterivo",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "02",
        denominazione: "Antey-Saint-André",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "12",
        denominazione: "Anticoli Corrado",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "01",
        denominazione: "Antignano",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "19",
        denominazione: "Antillo",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "18",
        denominazione: "Antonimina",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "12",
        denominazione: "Antrodoco",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "01",
        denominazione: "Antrona Schieranco",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "13",
        denominazione: "Anversa degli Abruzzi",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "03",
        denominazione: "Anzano del Parco",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "16",
        denominazione: "Anzano di Puglia",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "17",
        denominazione: "Anzi",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "12",
        denominazione: "Anzio",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "01",
        denominazione: "Anzola d'Ossola",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "08",
        denominazione: "Anzola dell'Emilia",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "02",
        denominazione: "Aosta",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: true,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "11",
        denominazione: "Apecchio",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "15",
        denominazione: "Apice",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "11",
        denominazione: "Apiro",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "15",
        denominazione: "Apollosa",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "03",
        denominazione: "Appiano Gentile",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "04",
        denominazione: "Appiano sulla strada del vino",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "11",
        denominazione: "Appignano",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "11",
        denominazione: "Appignano del Tronto",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "03",
        denominazione: "Aprica",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "07",
        denominazione: "Apricale",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "16",
        denominazione: "Apricena",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "18",
        denominazione: "Aprigliano",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "12",
        denominazione: "Aprilia",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "15",
        denominazione: "Aquara",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "07",
        denominazione: "Aquila d'Arroscia",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "06",
        denominazione: "Aquileia",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "15",
        denominazione: "Aquilonia",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "12",
        denominazione: "Aquino",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "16",
        denominazione: "Aradeo",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "19",
        denominazione: "Aragona",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "01",
        denominazione: "Aramengo",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "06",
        denominazione: "Arba",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "20",
        denominazione: "Arborea",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "01",
        denominazione: "Arborio",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "20",
        denominazione: "Arbus",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "05",
        denominazione: "Arcade",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "12",
        denominazione: "Arce",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "03",
        denominazione: "Arcene",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "11",
        denominazione: "Arcevia",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "13",
        denominazione: "Archi",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "09",
        denominazione: "Arcidosso",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "12",
        denominazione: "Arcinazzo Romano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "03",
        denominazione: "Arcisate",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "04",
        denominazione: "Arco",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "07",
        denominazione: "Arcola",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "05",
        denominazione: "Arcole",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "03",
        denominazione: "Arconate",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Arcore",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "05",
        denominazione: "Arcugnano",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "20",
        denominazione: "Ardara",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "20",
        denominazione: "Ardauli",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "12",
        denominazione: "Ardea",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "03",
        denominazione: "Ardenno",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "03",
        denominazione: "Ardesio",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "18",
        denominazione: "Ardore",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "18",
        denominazione: "Arena",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "03",
        denominazione: "Arena Po",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "07",
        denominazione: "Arenzano",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "03",
        denominazione: "Arese",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "09",
        denominazione: "Arezzo",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: true,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "03",
        denominazione: "Argegno",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "08",
        denominazione: "Argelato",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "08",
        denominazione: "Argenta",
        denominazione_provincia: "Ferrara",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FE"
    },
    {
        codice_regione: "01",
        denominazione: "Argentera",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Arguello",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "18",
        denominazione: "Argusto",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "13",
        denominazione: "Ari",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "15",
        denominazione: "Ariano Irpino",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "05",
        denominazione: "Ariano nel Polesine",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "12",
        denominazione: "Ariccia",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "13",
        denominazione: "Arielli",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "15",
        denominazione: "Arienzo",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "01",
        denominazione: "Arignano",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "20",
        denominazione: "Aritzo",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "01",
        denominazione: "Arizzano",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "12",
        denominazione: "Arlena di Castro",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "03",
        denominazione: "Arluno",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Armeno",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "17",
        denominazione: "Armento",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "07",
        denominazione: "Armo",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "20",
        denominazione: "Armungia",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "02",
        denominazione: "Arnad",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "12",
        denominazione: "Arnara",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "07",
        denominazione: "Arnasco",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "16",
        denominazione: "Arnesano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "01",
        denominazione: "Arola",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "01",
        denominazione: "Arona",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Arosio",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "15",
        denominazione: "Arpaia",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "15",
        denominazione: "Arpaise",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "12",
        denominazione: "Arpino",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "05",
        denominazione: "Arquà Petrarca",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "05",
        denominazione: "Arquà Polesine",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "11",
        denominazione: "Arquata del Tronto",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "01",
        denominazione: "Arquata Scrivia",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "05",
        denominazione: "Arre",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "10",
        denominazione: "Arrone",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "03",
        denominazione: "Arsago Seprio",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "05",
        denominazione: "Arsiè",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "05",
        denominazione: "Arsiero",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "13",
        denominazione: "Arsita",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "12",
        denominazione: "Arsoli",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "06",
        denominazione: "Arta Terme",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "06",
        denominazione: "Artegna",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "12",
        denominazione: "Artena",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "03",
        denominazione: "Artogne",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "02",
        denominazione: "Arvier",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "20",
        denominazione: "Arzachena",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Arzago d'Adda",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "20",
        denominazione: "Arzana",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "15",
        denominazione: "Arzano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "05",
        denominazione: "Arzergrande",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "05",
        denominazione: "Arzignano",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "15",
        denominazione: "Ascea",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "09",
        denominazione: "Asciano",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "11",
        denominazione: "Ascoli Piceno",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: true,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "16",
        denominazione: "Ascoli Satriano",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "12",
        denominazione: "Ascrea",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "05",
        denominazione: "Asiago",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "05",
        denominazione: "Asigliano Veneto",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "01",
        denominazione: "Asigliano Vercellese",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "03",
        denominazione: "Asola",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "05",
        denominazione: "Asolo",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Assago",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "20",
        denominazione: "Assemini",
        denominazione_provincia: "Cagliari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "CA"
    },
    {
        codice_regione: "10",
        denominazione: "Assisi",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "03",
        denominazione: "Asso",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "20",
        denominazione: "Assolo",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "19",
        denominazione: "Assoro",
        denominazione_provincia: "Enna",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "EN"
    },
    {
        codice_regione: "01",
        denominazione: "Asti",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: true,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "20",
        denominazione: "Asuni",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "13",
        denominazione: "Ateleta",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "17",
        denominazione: "Atella",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "15",
        denominazione: "Atena Lucana",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "13",
        denominazione: "Atessa",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "12",
        denominazione: "Atina",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "15",
        denominazione: "Atrani",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "13",
        denominazione: "Atri",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "15",
        denominazione: "Atripalda",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "10",
        denominazione: "Attigliano",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "06",
        denominazione: "Attimis",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "20",
        denominazione: "Atzara",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "19",
        denominazione: "Augusta",
        denominazione_provincia: "Siracusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "SR"
    },
    {
        codice_regione: "15",
        denominazione: "Auletta",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "09",
        denominazione: "Aulla",
        denominazione_provincia: "Massa-Carrara",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "MS"
    },
    {
        codice_regione: "01",
        denominazione: "Aurano",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "07",
        denominazione: "Aurigo",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "05",
        denominazione: "Auronzo di Cadore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "12",
        denominazione: "Ausonia",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "20",
        denominazione: "Austis",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "07",
        denominazione: "Avegno",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "04",
        denominazione: "Avelengo",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "15",
        denominazione: "Avella",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "15",
        denominazione: "Avellino",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: true,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "03",
        denominazione: "Averara",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "15",
        denominazione: "Aversa",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "16",
        denominazione: "Avetrana",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "13",
        denominazione: "Avezzano",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "06",
        denominazione: "Aviano",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "03",
        denominazione: "Aviatico",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Avigliana",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "17",
        denominazione: "Avigliano",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "10",
        denominazione: "Avigliano Umbro",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "04",
        denominazione: "Avio",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "02",
        denominazione: "Avise",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "19",
        denominazione: "Avola",
        denominazione_provincia: "Siracusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "SR"
    },
    {
        codice_regione: "01",
        denominazione: "Avolasca",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "02",
        denominazione: "Ayas",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "02",
        denominazione: "Aymavilles",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "01",
        denominazione: "Azeglio",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Azzanello",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "01",
        denominazione: "Azzano d'Asti",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "06",
        denominazione: "Azzano Decimo",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "03",
        denominazione: "Azzano Mella",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Azzano San Paolo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Azzate",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Azzio",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Azzone",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Baceno",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "15",
        denominazione: "Bacoli",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "07",
        denominazione: "Badalucco",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "20",
        denominazione: "Badesi",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "04",
        denominazione: "Badia",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "05",
        denominazione: "Badia Calavena",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "03",
        denominazione: "Badia Pavese",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "05",
        denominazione: "Badia Polesine",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "09",
        denominazione: "Badia Tedalda",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "18",
        denominazione: "Badolato",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "18",
        denominazione: "Bagaladi",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "19",
        denominazione: "Bagheria",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "08",
        denominazione: "Bagnacavallo",
        denominazione_provincia: "Ravenna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RA"
    },
    {
        codice_regione: "18",
        denominazione: "Bagnara Calabra",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "08",
        denominazione: "Bagnara di Romagna",
        denominazione_provincia: "Ravenna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RA"
    },
    {
        codice_regione: "03",
        denominazione: "Bagnaria",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "06",
        denominazione: "Bagnaria Arsa",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "01",
        denominazione: "Bagnasco",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Bagnatica",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "09",
        denominazione: "Bagni di Lucca",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "09",
        denominazione: "Bagno a Ripoli",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "08",
        denominazione: "Bagno di Romagna",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "14",
        denominazione: "Bagnoli del Trigno",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "05",
        denominazione: "Bagnoli di Sopra",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "15",
        denominazione: "Bagnoli Irpino",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "03",
        denominazione: "Bagnolo Cremasco",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "16",
        denominazione: "Bagnolo del Salento",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "05",
        denominazione: "Bagnolo di Po",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "08",
        denominazione: "Bagnolo in Piano",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "03",
        denominazione: "Bagnolo Mella",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Bagnolo Piemonte",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Bagnolo San Vito",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "09",
        denominazione: "Bagnone",
        denominazione_provincia: "Massa-Carrara",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "MS"
    },
    {
        codice_regione: "12",
        denominazione: "Bagnoregio",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "03",
        denominazione: "Bagolino",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "15",
        denominazione: "Baia e Latina",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "15",
        denominazione: "Baiano",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "01",
        denominazione: "Bairo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "08",
        denominazione: "Baiso",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "07",
        denominazione: "Bajardo",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "01",
        denominazione: "Balangero",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Baldichieri d'Asti",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Baldissero Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Baldissero d'Alba",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Baldissero Torinese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "19",
        denominazione: "Balestrate",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "07",
        denominazione: "Balestrino",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "03",
        denominazione: "Ballabio",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "20",
        denominazione: "Ballao",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "01",
        denominazione: "Balme",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Balmuccia",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "01",
        denominazione: "Balocco",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "13",
        denominazione: "Balsorano",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "17",
        denominazione: "Balvano",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "01",
        denominazione: "Balzola",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "20",
        denominazione: "Banari",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "01",
        denominazione: "Banchette",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Bannio Anzino",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "17",
        denominazione: "Banzi",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "05",
        denominazione: "Baone",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "20",
        denominazione: "Baradili",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "17",
        denominazione: "Baragiano",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "14",
        denominazione: "Baranello",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "15",
        denominazione: "Barano d'Ischia",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "03",
        denominazione: "Baranzate",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Barasso",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "20",
        denominazione: "Baratili San Pietro",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "01",
        denominazione: "Barbania",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "11",
        denominazione: "Barbara",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "05",
        denominazione: "Barbarano Mossano",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "12",
        denominazione: "Barbarano Romano",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "01",
        denominazione: "Barbaresco",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Barbariga",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Barbata",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "09",
        denominazione: "Barberino di Mugello",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "09",
        denominazione: "Barberino Tavarnelle",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "03",
        denominazione: "Barbianello",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "04",
        denominazione: "Barbiano",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "05",
        denominazione: "Barbona",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "19",
        denominazione: "Barcellona Pozzo di Gotto",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "06",
        denominazione: "Barcis",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "02",
        denominazione: "Bard",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "03",
        denominazione: "Bardello con Malgesso e Bregano",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "08",
        denominazione: "Bardi",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "07",
        denominazione: "Bardineto",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "05",
        denominazione: "Bardolino",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "01",
        denominazione: "Bardonecchia",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Bareggio",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Barengo",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "20",
        denominazione: "Baressa",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "13",
        denominazione: "Barete",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "09",
        denominazione: "Barga",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "07",
        denominazione: "Bargagli",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "01",
        denominazione: "Barge",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Barghe",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "16",
        denominazione: "Bari",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: true,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "20",
        denominazione: "Bari Sardo",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "03",
        denominazione: "Bariano",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "08",
        denominazione: "Baricella",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "17",
        denominazione: "Barile",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "13",
        denominazione: "Barisciano",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "03",
        denominazione: "Barlassina",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "16",
        denominazione: "Barletta",
        denominazione_provincia: "Barletta-Andria-Trani",
        denominazione_regione: "Puglia",
        flag_capoluogo: true,
        sigla_provincia: "BT"
    },
    {
        codice_regione: "03",
        denominazione: "Barni",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "01",
        denominazione: "Barolo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Barone Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "Baronissi",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "19",
        denominazione: "Barrafranca",
        denominazione_provincia: "Enna",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "EN"
    },
    {
        codice_regione: "20",
        denominazione: "Barrali",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "13",
        denominazione: "Barrea",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "20",
        denominazione: "Barumini",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "03",
        denominazione: "Barzago",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Barzana",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Barzanò",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Barzio",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "01",
        denominazione: "Basaluzzo",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Bascapè",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "10",
        denominazione: "Baschi",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "13",
        denominazione: "Basciano",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "04",
        denominazione: "Baselga di Pinè",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "15",
        denominazione: "Baselice",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "03",
        denominazione: "Basiano",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "19",
        denominazione: "Basicò",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "03",
        denominazione: "Basiglio",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "06",
        denominazione: "Basiliano",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Bassano Bresciano",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "05",
        denominazione: "Bassano del Grappa",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "12",
        denominazione: "Bassano in Teverina",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "12",
        denominazione: "Bassano Romano",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "12",
        denominazione: "Bassiano",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "01",
        denominazione: "Bassignana",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Bastia Mondovì",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "10",
        denominazione: "Bastia Umbra",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "03",
        denominazione: "Bastida Pancarana",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "08",
        denominazione: "Bastiglia",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "05",
        denominazione: "Battaglia Terme",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "01",
        denominazione: "Battifollo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "15",
        denominazione: "Battipaglia",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Battuda",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "19",
        denominazione: "Baucina",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "20",
        denominazione: "Bauladu",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "20",
        denominazione: "Baunei",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "01",
        denominazione: "Baveno",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "03",
        denominazione: "Bedero Valcuvia",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Bedizzole",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "04",
        denominazione: "Bedollo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "08",
        denominazione: "Bedonia",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "03",
        denominazione: "Bedulita",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Bee",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "01",
        denominazione: "Beinasco",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Beinette",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "18",
        denominazione: "Belcastro",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "05",
        denominazione: "Belfiore",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "11",
        denominazione: "Belforte all'Isauro",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "11",
        denominazione: "Belforte del Chienti",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "01",
        denominazione: "Belforte Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Belgioioso",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Belgirate",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "17",
        denominazione: "Bella",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "03",
        denominazione: "Bellagio",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Bellano",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "13",
        denominazione: "Bellante",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "08",
        denominazione: "Bellaria-Igea Marina",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "12",
        denominazione: "Bellegra",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "01",
        denominazione: "Bellino",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Bellinzago Lombardo",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Bellinzago Novarese",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "15",
        denominazione: "Bellizzi",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "15",
        denominazione: "Bellona",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "15",
        denominazione: "Bellosguardo",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "05",
        denominazione: "Belluno",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: true,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "03",
        denominazione: "Bellusco",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "18",
        denominazione: "Belmonte Calabro",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "12",
        denominazione: "Belmonte Castello",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "14",
        denominazione: "Belmonte del Sannio",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "12",
        denominazione: "Belmonte in Sabina",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "19",
        denominazione: "Belmonte Mezzagno",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "11",
        denominazione: "Belmonte Piceno",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "19",
        denominazione: "Belpasso",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "18",
        denominazione: "Belsito",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "18",
        denominazione: "Belvedere di Spinello",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "01",
        denominazione: "Belvedere Langhe",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "18",
        denominazione: "Belvedere Marittimo",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "11",
        denominazione: "Belvedere Ostrense",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "01",
        denominazione: "Belveglio",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "20",
        denominazione: "Belvì",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "03",
        denominazione: "Bema",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "03",
        denominazione: "Bene Lario",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "01",
        denominazione: "Bene Vagienna",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "18",
        denominazione: "Benestare",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "20",
        denominazione: "Benetutti",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "01",
        denominazione: "Benevello",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "15",
        denominazione: "Benevento",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: true,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "01",
        denominazione: "Benna",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "08",
        denominazione: "Bentivoglio",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "03",
        denominazione: "Berbenno",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Berbenno di Valtellina",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "08",
        denominazione: "Berceto",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "20",
        denominazione: "Berchidda",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Beregazzo con Figliaro",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Bereguardo",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Bergamasco",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Bergamo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: true,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Bergantino",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "07",
        denominazione: "Bergeggi",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "01",
        denominazione: "Bergolo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Berlingo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "17",
        denominazione: "Bernalda",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "03",
        denominazione: "Bernareggio",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "03",
        denominazione: "Bernate Ticino",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Bernezzo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "08",
        denominazione: "Bertinoro",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "06",
        denominazione: "Bertiolo",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Bertonico",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "01",
        denominazione: "Berzano di San Pietro",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Berzano di Tortona",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Berzo Demo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Berzo Inferiore",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Berzo San Fermo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Besana in Brianza",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "03",
        denominazione: "Besano",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Besate",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "04",
        denominazione: "Besenello",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "08",
        denominazione: "Besenzone",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "03",
        denominazione: "Besnate",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Besozzo",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "20",
        denominazione: "Bessude",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "08",
        denominazione: "Bettola",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "10",
        denominazione: "Bettona",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "01",
        denominazione: "Beura-Cardezza",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "10",
        denominazione: "Bevagna",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "07",
        denominazione: "Beverino",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "05",
        denominazione: "Bevilacqua",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "19",
        denominazione: "Biancavilla",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "18",
        denominazione: "Bianchi",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "18",
        denominazione: "Bianco",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "01",
        denominazione: "Biandrate",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Biandronno",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Bianzano",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Bianzè",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "03",
        denominazione: "Bianzone",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "03",
        denominazione: "Biassono",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "08",
        denominazione: "Bibbiano",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "09",
        denominazione: "Bibbiena",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "09",
        denominazione: "Bibbona",
        denominazione_provincia: "Livorno",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LI"
    },
    {
        codice_regione: "01",
        denominazione: "Bibiana",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "16",
        denominazione: "Biccari",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "06",
        denominazione: "Bicinicco",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "20",
        denominazione: "Bidonì",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "01",
        denominazione: "Biella",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: true,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "03",
        denominazione: "Bienno",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "04",
        denominazione: "Bieno",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "09",
        denominazione: "Bientina",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "03",
        denominazione: "Binago",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Binasco",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "16",
        denominazione: "Binetto",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "01",
        denominazione: "Bioglio",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "02",
        denominazione: "Bionaz",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "03",
        denominazione: "Bione",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "20",
        denominazione: "Birori",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "15",
        denominazione: "Bisaccia",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "19",
        denominazione: "Bisacquino",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "16",
        denominazione: "Bisceglie",
        denominazione_provincia: "Barletta-Andria-Trani",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BT"
    },
    {
        codice_regione: "13",
        denominazione: "Bisegna",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "13",
        denominazione: "Bisenti",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "18",
        denominazione: "Bisignano",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "01",
        denominazione: "Bistagno",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Bisuschio",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "16",
        denominazione: "Bitetto",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "16",
        denominazione: "Bitonto",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "16",
        denominazione: "Bitritto",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "20",
        denominazione: "Bitti",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "19",
        denominazione: "Bivona",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "18",
        denominazione: "Bivongi",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "03",
        denominazione: "Bizzarone",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "04",
        denominazione: "Bleggio Superiore",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Blello",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "12",
        denominazione: "Blera",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "03",
        denominazione: "Blessagno",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Blevio",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "19",
        denominazione: "Blufi",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "05",
        denominazione: "Boara Pisani",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "08",
        denominazione: "Bobbio",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "01",
        denominazione: "Bobbio Pellice",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Boca",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "18",
        denominazione: "Bocchigliero",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "01",
        denominazione: "Boccioleto",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "04",
        denominazione: "Bocenago",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Bodio Lomnago",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Boffalora d'Adda",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "03",
        denominazione: "Boffalora sopra Ticino",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "07",
        denominazione: "Bogliasco",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "01",
        denominazione: "Bognanco",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "01",
        denominazione: "Bogogno",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "07",
        denominazione: "Boissano",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "14",
        denominazione: "Bojano",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "07",
        denominazione: "Bolano",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "03",
        denominazione: "Bolgare",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Bollate",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Bollengo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "08",
        denominazione: "Bologna",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: true,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "13",
        denominazione: "Bolognano",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "19",
        denominazione: "Bolognetta",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "11",
        denominazione: "Bolognola",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "20",
        denominazione: "Bolotana",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "12",
        denominazione: "Bolsena",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "03",
        denominazione: "Boltiere",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "04",
        denominazione: "Bolzano",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: true,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "01",
        denominazione: "Bolzano Novarese",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "05",
        denominazione: "Bolzano Vicentino",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "12",
        denominazione: "Bomarzo",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "13",
        denominazione: "Bomba",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "19",
        denominazione: "Bompensiere",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "19",
        denominazione: "Bompietro",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "08",
        denominazione: "Bomporto",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "20",
        denominazione: "Bonarcado",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "07",
        denominazione: "Bonassola",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "03",
        denominazione: "Bonate Sopra",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Bonate Sotto",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Bonavigo",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "08",
        denominazione: "Bondeno",
        denominazione_provincia: "Ferrara",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FE"
    },
    {
        codice_regione: "04",
        denominazione: "Bondone",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "15",
        denominazione: "Bonea",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "14",
        denominazione: "Bonefro",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "03",
        denominazione: "Bonemerse",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "18",
        denominazione: "Bonifati",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "15",
        denominazione: "Bonito",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "20",
        denominazione: "Bonnanaro",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "20",
        denominazione: "Bono",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "20",
        denominazione: "Bonorva",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "01",
        denominazione: "Bonvicino",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "12",
        denominazione: "Borbona",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "05",
        denominazione: "Borca di Cadore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "06",
        denominazione: "Bordano",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "07",
        denominazione: "Bordighera",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "03",
        denominazione: "Bordolano",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "08",
        denominazione: "Bore",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "08",
        denominazione: "Boretto",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "03",
        denominazione: "Borgarello",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Borgaro Torinese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "19",
        denominazione: "Borgetto",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "07",
        denominazione: "Borghetto d'Arroscia",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "01",
        denominazione: "Borghetto di Borbera",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "07",
        denominazione: "Borghetto di Vara",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "03",
        denominazione: "Borghetto Lodigiano",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "07",
        denominazione: "Borghetto Santo Spirito",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "08",
        denominazione: "Borghi",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "18",
        denominazione: "Borgia",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "01",
        denominazione: "Borgiallo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "07",
        denominazione: "Borgio Verezzi",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "09",
        denominazione: "Borgo a Mozzano",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "04",
        denominazione: "Borgo Chiese",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Borgo d'Ale",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "04",
        denominazione: "Borgo d'Anaunia",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Borgo di Terzo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "04",
        denominazione: "Borgo Lares",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Borgo Mantovano",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "11",
        denominazione: "Borgo Pace",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "03",
        denominazione: "Borgo Priolo",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Borgo San Dalmazzo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Borgo San Giacomo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Borgo San Giovanni",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "09",
        denominazione: "Borgo San Lorenzo",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "01",
        denominazione: "Borgo San Martino",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Borgo San Siro",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Borgo Ticino",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "08",
        denominazione: "Borgo Tossignano",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "08",
        denominazione: "Borgo Val di Taro",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "05",
        denominazione: "Borgo Valbelluna",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "04",
        denominazione: "Borgo Valsugana",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "12",
        denominazione: "Borgo Velino",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "05",
        denominazione: "Borgo Veneto",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "01",
        denominazione: "Borgo Vercelli",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "03",
        denominazione: "Borgo Virgilio",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "03",
        denominazione: "Borgocarbonara",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "01",
        denominazione: "Borgofranco d'Ivrea",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Borgolavezzaro",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "01",
        denominazione: "Borgomale",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Borgomanero",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "07",
        denominazione: "Borgomaro",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "01",
        denominazione: "Borgomasino",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Borgomezzavalle",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "01",
        denominazione: "Borgone Susa",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "08",
        denominazione: "Borgonovo Val Tidone",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "01",
        denominazione: "Borgoratto Alessandrino",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Borgoratto Mormorolo",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "05",
        denominazione: "Borgoricco",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "12",
        denominazione: "Borgorose",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "03",
        denominazione: "Borgosatollo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Borgosesia",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "07",
        denominazione: "Bormida",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "03",
        denominazione: "Bormio",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "03",
        denominazione: "Bornasco",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Borno",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "20",
        denominazione: "Boroneddu",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "20",
        denominazione: "Borore",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "13",
        denominazione: "Borrello",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "01",
        denominazione: "Borriana",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "05",
        denominazione: "Borso del Grappa",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "20",
        denominazione: "Bortigali",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "20",
        denominazione: "Bortigiadas",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "20",
        denominazione: "Borutta",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "07",
        denominazione: "Borzonasca",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "20",
        denominazione: "Bosa",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "05",
        denominazione: "Bosaro",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "05",
        denominazione: "Boschi Sant'Anna",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "05",
        denominazione: "Bosco Chiesanuova",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "01",
        denominazione: "Bosco Marengo",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Bosconero",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "Boscoreale",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "15",
        denominazione: "Boscotrecase",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "01",
        denominazione: "Bosia",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Bosio",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Bosisio Parini",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Bosnasco",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Bossico",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Bossolasco",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "18",
        denominazione: "Botricello",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "16",
        denominazione: "Botrugno",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "03",
        denominazione: "Bottanuco",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Botticino",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "20",
        denominazione: "Bottidda",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "18",
        denominazione: "Bova",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "18",
        denominazione: "Bova Marina",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "18",
        denominazione: "Bovalino",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "03",
        denominazione: "Bovegno",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Boves",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Bovezzo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "12",
        denominazione: "Boville Ernica",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "16",
        denominazione: "Bovino",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "03",
        denominazione: "Bovisio-Masciago",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "05",
        denominazione: "Bovolenta",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "05",
        denominazione: "Bovolone",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "01",
        denominazione: "Bozzole",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Bozzolo",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "01",
        denominazione: "Bra",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Bracca",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "12",
        denominazione: "Bracciano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "15",
        denominazione: "Bracigliano",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "04",
        denominazione: "Braies",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "Brallo di Pregola",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "18",
        denominazione: "Brancaleone",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "03",
        denominazione: "Brandico",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Brandizzo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Branzi",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Braone",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Brebbia",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "05",
        denominazione: "Breda di Piave",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "05",
        denominazione: "Breganze",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Bregnano",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Brembate",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Brembate di Sopra",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Brembio",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "03",
        denominazione: "Breme",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "05",
        denominazione: "Brendola",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Brenna",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "04",
        denominazione: "Brennero",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "Breno",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Brenta",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "05",
        denominazione: "Brentino Belluno",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "04",
        denominazione: "Brentonico",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "05",
        denominazione: "Brenzone sul Garda",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "08",
        denominazione: "Brescello",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "03",
        denominazione: "Brescia",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: true,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "04",
        denominazione: "Bresimo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Bressana Bottarone",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "04",
        denominazione: "Bressanone",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "05",
        denominazione: "Bressanvido",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Bresso",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Brezzo di Bedero",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "01",
        denominazione: "Briaglia",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "18",
        denominazione: "Briatico",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "01",
        denominazione: "Bricherasio",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Brienno",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "17",
        denominazione: "Brienza",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "01",
        denominazione: "Briga Alta",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Briga Novarese",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Brignano Gera d'Adda",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Brignano-Frascata",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "16",
        denominazione: "Brindisi",
        denominazione_provincia: "Brindisi",
        denominazione_regione: "Puglia",
        flag_capoluogo: true,
        sigla_provincia: "BR"
    },
    {
        codice_regione: "17",
        denominazione: "Brindisi Montagna",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "03",
        denominazione: "Brinzio",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "01",
        denominazione: "Briona",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Brione",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Briosco",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "08",
        denominazione: "Brisighella",
        denominazione_provincia: "Ravenna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RA"
    },
    {
        codice_regione: "03",
        denominazione: "Brissago-Valtravaglia",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "02",
        denominazione: "Brissogne",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "13",
        denominazione: "Brittoli",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "03",
        denominazione: "Brivio",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "12",
        denominazione: "Broccostella",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "05",
        denominazione: "Brogliano",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "18",
        denominazione: "Brognaturo",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "19",
        denominazione: "Brolo",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "01",
        denominazione: "Brondello",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Broni",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "19",
        denominazione: "Bronte",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "04",
        denominazione: "Bronzolo",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "01",
        denominazione: "Brossasco",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Brosso",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Brovello-Carpugnino",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "01",
        denominazione: "Brozolo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Brugherio",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "05",
        denominazione: "Brugine",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "07",
        denominazione: "Brugnato",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "06",
        denominazione: "Brugnera",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "01",
        denominazione: "Bruino",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Brumano",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Brunate",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Brunello",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "04",
        denominazione: "Brunico",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "01",
        denominazione: "Bruno",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "03",
        denominazione: "Brusaporto",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Brusasco",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "Brusciano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "03",
        denominazione: "Brusimpiano",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "01",
        denominazione: "Brusnengo",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "02",
        denominazione: "Brusson",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "01",
        denominazione: "Bruzolo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "18",
        denominazione: "Bruzzano Zeffirio",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "03",
        denominazione: "Bubbiano",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Bubbio",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "19",
        denominazione: "Buccheri",
        denominazione_provincia: "Siracusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "SR"
    },
    {
        codice_regione: "13",
        denominazione: "Bucchianico",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "15",
        denominazione: "Bucciano",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "03",
        denominazione: "Buccinasco",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "15",
        denominazione: "Buccino",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "09",
        denominazione: "Bucine",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "20",
        denominazione: "Buddusò",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "06",
        denominazione: "Budoia",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "20",
        denominazione: "Budoni",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "08",
        denominazione: "Budrio",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "20",
        denominazione: "Buggerru",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "09",
        denominazione: "Buggiano",
        denominazione_provincia: "Pistoia",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PT"
    },
    {
        codice_regione: "03",
        denominazione: "Buglio in Monte",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "13",
        denominazione: "Bugnara",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "03",
        denominazione: "Buguggiate",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "06",
        denominazione: "Buja",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Bulciago",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Bulgarograsso",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "20",
        denominazione: "Bultei",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "20",
        denominazione: "Bulzi",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "15",
        denominazione: "Buonabitacolo",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "15",
        denominazione: "Buonalbergo",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "09",
        denominazione: "Buonconvento",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "18",
        denominazione: "Buonvicino",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Burago di Molgora",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "20",
        denominazione: "Burcei",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "19",
        denominazione: "Burgio",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "20",
        denominazione: "Burgos",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "01",
        denominazione: "Buriasco",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Burolo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Buronzo",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "20",
        denominazione: "Busachi",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "07",
        denominazione: "Busalla",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "01",
        denominazione: "Busano",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Busca",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Buscate",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "19",
        denominazione: "Buscemi",
        denominazione_provincia: "Siracusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "SR"
    },
    {
        codice_regione: "19",
        denominazione: "Buseto Palizzolo",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "03",
        denominazione: "Busnago",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "03",
        denominazione: "Bussero",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "08",
        denominazione: "Busseto",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "13",
        denominazione: "Bussi sul Tirino",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "14",
        denominazione: "Busso",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "05",
        denominazione: "Bussolengo",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "01",
        denominazione: "Bussoleno",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Busto Arsizio",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Busto Garolfo",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "19",
        denominazione: "Butera",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "09",
        denominazione: "Buti",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "05",
        denominazione: "Buttapietra",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "01",
        denominazione: "Buttigliera Alta",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Buttigliera d'Asti",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "06",
        denominazione: "Buttrio",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "01",
        denominazione: "Cabella Ligure",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Cabiate",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "20",
        denominazione: "Cabras",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "19",
        denominazione: "Caccamo",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "18",
        denominazione: "Caccuri",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "03",
        denominazione: "Cadegliano-Viconago",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "08",
        denominazione: "Cadelbosco di Sopra",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "08",
        denominazione: "Cadeo",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "04",
        denominazione: "Caderzone Terme",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "05",
        denominazione: "Cadoneghe",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Cadorago",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Cadrezzate con Osmate",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "05",
        denominazione: "Caerano di San Marco",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "01",
        denominazione: "Cafasse",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "Caggiano",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "11",
        denominazione: "Cagli",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "20",
        denominazione: "Cagliari",
        denominazione_provincia: "Cagliari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: true,
        sigla_provincia: "CA"
    },
    {
        codice_regione: "03",
        denominazione: "Caglio",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "13",
        denominazione: "Cagnano Amiterno",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "16",
        denominazione: "Cagnano Varano",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "15",
        denominazione: "Caianello",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "15",
        denominazione: "Caiazzo",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "04",
        denominazione: "Caines",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "Caino",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Caiolo",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "15",
        denominazione: "Cairano",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "03",
        denominazione: "Cairate",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "07",
        denominazione: "Cairo Montenotte",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "15",
        denominazione: "Caivano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "15",
        denominazione: "Calabritto",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "05",
        denominazione: "Calalzo di Cadore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "01",
        denominazione: "Calamandrana",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "19",
        denominazione: "Calamonaci",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "20",
        denominazione: "Calangianus",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "18",
        denominazione: "Calanna",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "01",
        denominazione: "Calasca-Castiglione",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "19",
        denominazione: "Calascibetta",
        denominazione_provincia: "Enna",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "EN"
    },
    {
        codice_regione: "13",
        denominazione: "Calascio",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "20",
        denominazione: "Calasetta",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "19",
        denominazione: "Calatabiano",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "19",
        denominazione: "Calatafimi-Segesta",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "12",
        denominazione: "Calcata",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "04",
        denominazione: "Calceranica al Lago",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "09",
        denominazione: "Calci",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "17",
        denominazione: "Calciano",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "09",
        denominazione: "Calcinaia",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "03",
        denominazione: "Calcinate",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Calcinato",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Calcio",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Calco",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "04",
        denominazione: "Caldaro sulla strada del vino",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "11",
        denominazione: "Caldarola",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "08",
        denominazione: "Calderara di Reno",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "04",
        denominazione: "Caldes",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "05",
        denominazione: "Caldiero",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "05",
        denominazione: "Caldogno",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "04",
        denominazione: "Caldonazzo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "08",
        denominazione: "Calendasco",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "09",
        denominazione: "Calenzano",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "08",
        denominazione: "Calestano",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "07",
        denominazione: "Calice al Cornoviglio",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "07",
        denominazione: "Calice Ligure",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "16",
        denominazione: "Calimera",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "15",
        denominazione: "Calitri",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "07",
        denominazione: "Calizzano",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "01",
        denominazione: "Callabiana",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "04",
        denominazione: "Calliano",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Calliano Monferrato",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "03",
        denominazione: "Calolziocorte",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "18",
        denominazione: "Calopezzati",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "01",
        denominazione: "Calosso",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "18",
        denominazione: "Caloveto",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "19",
        denominazione: "Caltabellotta",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "19",
        denominazione: "Caltagirone",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "19",
        denominazione: "Caltanissetta",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: true,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "19",
        denominazione: "Caltavuturo",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "01",
        denominazione: "Caltignaga",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "05",
        denominazione: "Calto",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "05",
        denominazione: "Caltrano",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Calusco d'Adda",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Caluso",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Calvagese della Riviera",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "15",
        denominazione: "Calvanico",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Calvatone",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "17",
        denominazione: "Calvello",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "05",
        denominazione: "Calvene",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Calvenzano",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "17",
        denominazione: "Calvera",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "15",
        denominazione: "Calvi",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "10",
        denominazione: "Calvi dell'Umbria",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "15",
        denominazione: "Calvi Risorta",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "03",
        denominazione: "Calvignano",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Calvignasco",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Calvisano",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "15",
        denominazione: "Calvizzano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "01",
        denominazione: "Camagna Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "09",
        denominazione: "Camaiore",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "01",
        denominazione: "Camandona",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "19",
        denominazione: "Camastra",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "03",
        denominazione: "Cambiago",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Cambiano",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Cambiasca",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "01",
        denominazione: "Camburzano",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "01",
        denominazione: "Camerana",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "11",
        denominazione: "Camerano",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "01",
        denominazione: "Camerano Casasco",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "03",
        denominazione: "Camerata Cornello",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "12",
        denominazione: "Camerata Nuova",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "11",
        denominazione: "Camerata Picena",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "01",
        denominazione: "Cameri",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "11",
        denominazione: "Camerino",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "15",
        denominazione: "Camerota",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "15",
        denominazione: "Camigliano",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "18",
        denominazione: "Camini",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "01",
        denominazione: "Camino",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "06",
        denominazione: "Camino al Tagliamento",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Camisano",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "05",
        denominazione: "Camisano Vicentino",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "19",
        denominazione: "Cammarata",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "07",
        denominazione: "Camogli",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "15",
        denominazione: "Campagna",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "05",
        denominazione: "Campagna Lupia",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "12",
        denominazione: "Campagnano di Roma",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "09",
        denominazione: "Campagnatico",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "03",
        denominazione: "Campagnola Cremasca",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "08",
        denominazione: "Campagnola Emilia",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "18",
        denominazione: "Campana",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Camparada",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "08",
        denominazione: "Campegine",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "10",
        denominazione: "Campello sul Clitunno",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "01",
        denominazione: "Campertogno",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "09",
        denominazione: "Campi Bisenzio",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "16",
        denominazione: "Campi Salentina",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "01",
        denominazione: "Campiglia Cervo",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "05",
        denominazione: "Campiglia dei Berici",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "09",
        denominazione: "Campiglia Marittima",
        denominazione_provincia: "Livorno",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LI"
    },
    {
        codice_regione: "01",
        denominazione: "Campiglione Fenile",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Campione d'Italia",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "04",
        denominazione: "Campitello di Fassa",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "13",
        denominazione: "Campli",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "18",
        denominazione: "Campo Calabro",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "13",
        denominazione: "Campo di Giove",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "04",
        denominazione: "Campo di Trens",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "07",
        denominazione: "Campo Ligure",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "09",
        denominazione: "Campo nell'Elba",
        denominazione_provincia: "Livorno",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LI"
    },
    {
        codice_regione: "05",
        denominazione: "Campo San Martino",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "04",
        denominazione: "Campo Tures",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "14",
        denominazione: "Campobasso",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: true,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "19",
        denominazione: "Campobello di Licata",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "19",
        denominazione: "Campobello di Mazara",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "14",
        denominazione: "Campochiaro",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "05",
        denominazione: "Campodarsego",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "04",
        denominazione: "Campodenno",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "12",
        denominazione: "Campodimele",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "14",
        denominazione: "Campodipietra",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "03",
        denominazione: "Campodolcino",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "05",
        denominazione: "Campodoro",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "19",
        denominazione: "Campofelice di Fitalia",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "19",
        denominazione: "Campofelice di Roccella",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "11",
        denominazione: "Campofilone",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "19",
        denominazione: "Campofiorito",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "06",
        denominazione: "Campoformido",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "19",
        denominazione: "Campofranco",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "08",
        denominazione: "Campogalliano",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "15",
        denominazione: "Campolattaro",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "12",
        denominazione: "Campoli Appennino",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "15",
        denominazione: "Campoli del Monte Taburno",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "14",
        denominazione: "Campolieto",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "05",
        denominazione: "Campolongo Maggiore",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "06",
        denominazione: "Campolongo Tapogliano",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "17",
        denominazione: "Campomaggiore",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "14",
        denominazione: "Campomarino",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "07",
        denominazione: "Campomorone",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "05",
        denominazione: "Camponogara",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "15",
        denominazione: "Campora",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "19",
        denominazione: "Camporeale",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "09",
        denominazione: "Camporgiano",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "07",
        denominazione: "Camporosso",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "11",
        denominazione: "Camporotondo di Fiastrone",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "19",
        denominazione: "Camporotondo Etneo",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "05",
        denominazione: "Camposampiero",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "15",
        denominazione: "Camposano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "08",
        denominazione: "Camposanto",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "03",
        denominazione: "Campospinoso Albaredo",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "13",
        denominazione: "Campotosto",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "08",
        denominazione: "Camugnano",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "04",
        denominazione: "Canal San Bovo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Canale",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "05",
        denominazione: "Canale d'Agordo",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "12",
        denominazione: "Canale Monterano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "05",
        denominazione: "Canaro",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "04",
        denominazione: "Canazei",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "17",
        denominazione: "Cancellara",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "15",
        denominazione: "Cancello ed Arnone",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "05",
        denominazione: "Canda",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "16",
        denominazione: "Candela",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "01",
        denominazione: "Candelo",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "01",
        denominazione: "Candia Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Candia Lomellina",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "05",
        denominazione: "Candiana",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "15",
        denominazione: "Candida",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "18",
        denominazione: "Candidoni",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "01",
        denominazione: "Candiolo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Canegrate",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Canelli",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "12",
        denominazione: "Canepina",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "06",
        denominazione: "Caneva",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "19",
        denominazione: "Canicattì",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "19",
        denominazione: "Canicattini Bagni",
        denominazione_provincia: "Siracusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "SR"
    },
    {
        codice_regione: "12",
        denominazione: "Canino",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "01",
        denominazione: "Canischio",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "13",
        denominazione: "Canistro",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "18",
        denominazione: "Canna",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "15",
        denominazione: "Cannalonga",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "10",
        denominazione: "Cannara",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "01",
        denominazione: "Cannero Riviera",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "03",
        denominazione: "Canneto Pavese",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Canneto sull'Oglio",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "01",
        denominazione: "Cannobio",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "16",
        denominazione: "Cannole",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "18",
        denominazione: "Canolo",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "03",
        denominazione: "Canonica d'Adda",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "16",
        denominazione: "Canosa di Puglia",
        denominazione_provincia: "Barletta-Andria-Trani",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BT"
    },
    {
        codice_regione: "13",
        denominazione: "Canosa Sannita",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "01",
        denominazione: "Canosio",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "08",
        denominazione: "Canossa",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "13",
        denominazione: "Cansano",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "09",
        denominazione: "Cantagallo",
        denominazione_provincia: "Prato",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PO"
    },
    {
        codice_regione: "12",
        denominazione: "Cantalice",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "01",
        denominazione: "Cantalupa",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "12",
        denominazione: "Cantalupo in Sabina",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "01",
        denominazione: "Cantalupo Ligure",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "14",
        denominazione: "Cantalupo nel Sannio",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "01",
        denominazione: "Cantarana",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "03",
        denominazione: "Cantello",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "12",
        denominazione: "Canterano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "11",
        denominazione: "Cantiano",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "01",
        denominazione: "Cantoira",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Cantù",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "13",
        denominazione: "Canzano",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "03",
        denominazione: "Canzo",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "05",
        denominazione: "Caorle",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "08",
        denominazione: "Caorso",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "15",
        denominazione: "Capaccio Paestum",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "19",
        denominazione: "Capaci",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "09",
        denominazione: "Capalbio",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "09",
        denominazione: "Capannoli",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "09",
        denominazione: "Capannori",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "12",
        denominazione: "Capena",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "03",
        denominazione: "Capergnanica",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "13",
        denominazione: "Capestrano",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "03",
        denominazione: "Capiago Intimiano",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "18",
        denominazione: "Capistrano",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "13",
        denominazione: "Capistrello",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "13",
        denominazione: "Capitignano",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "19",
        denominazione: "Capizzi",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "03",
        denominazione: "Capizzone",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "19",
        denominazione: "Capo d'Orlando",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "03",
        denominazione: "Capo di Ponte",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "12",
        denominazione: "Capodimonte",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "15",
        denominazione: "Capodrise",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "09",
        denominazione: "Capoliveri",
        denominazione_provincia: "Livorno",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LI"
    },
    {
        codice_regione: "09",
        denominazione: "Capolona",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "03",
        denominazione: "Caponago",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "13",
        denominazione: "Caporciano",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "15",
        denominazione: "Caposele",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "20",
        denominazione: "Capoterra",
        denominazione_provincia: "Cagliari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "CA"
    },
    {
        codice_regione: "03",
        denominazione: "Capovalle",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "13",
        denominazione: "Cappadocia",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "03",
        denominazione: "Cappella Cantone",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Cappella de' Picenardi",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "05",
        denominazione: "Cappella Maggiore",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "13",
        denominazione: "Cappelle sul Tavo",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "14",
        denominazione: "Capracotta",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "09",
        denominazione: "Capraia e Limite",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "09",
        denominazione: "Capraia Isola",
        denominazione_provincia: "Livorno",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LI"
    },
    {
        codice_regione: "03",
        denominazione: "Capralba",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "12",
        denominazione: "Capranica",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "12",
        denominazione: "Capranica Prenestina",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "16",
        denominazione: "Caprarica di Lecce",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "12",
        denominazione: "Caprarola",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "01",
        denominazione: "Caprauna",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "09",
        denominazione: "Caprese Michelangelo",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "01",
        denominazione: "Caprezzo",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "15",
        denominazione: "Capri",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "19",
        denominazione: "Capri Leone",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "04",
        denominazione: "Capriana",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Capriano del Colle",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Capriata d'Orba",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Capriate San Gervasio",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "15",
        denominazione: "Capriati a Volturno",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "01",
        denominazione: "Caprie",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "Capriglia Irpina",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "01",
        denominazione: "Capriglio",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Caprile",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "03",
        denominazione: "Caprino Bergamasco",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Caprino Veronese",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "03",
        denominazione: "Capriolo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "06",
        denominazione: "Capriva del Friuli",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "15",
        denominazione: "Capua",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "16",
        denominazione: "Capurso",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "18",
        denominazione: "Caraffa del Bianco",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "18",
        denominazione: "Caraffa di Catanzaro",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "01",
        denominazione: "Caraglio",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Caramagna Piemonte",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "13",
        denominazione: "Caramanico Terme",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "16",
        denominazione: "Carapelle",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "13",
        denominazione: "Carapelle Calvisio",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "07",
        denominazione: "Carasco",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "11",
        denominazione: "Carassai",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "03",
        denominazione: "Carate Brianza",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "03",
        denominazione: "Carate Urio",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Caravaggio",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Caravate",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "01",
        denominazione: "Caravino",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "07",
        denominazione: "Caravonica",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "12",
        denominazione: "Carbognano",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "03",
        denominazione: "Carbonara al Ticino",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "15",
        denominazione: "Carbonara di Nola",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "01",
        denominazione: "Carbonara Scrivia",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Carbonate",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "17",
        denominazione: "Carbone",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "05",
        denominazione: "Carbonera",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "20",
        denominazione: "Carbonia",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: true,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "07",
        denominazione: "Carcare",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "01",
        denominazione: "Carcoforo",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "03",
        denominazione: "Cardano al Campo",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "01",
        denominazione: "Cardè",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "20",
        denominazione: "Cardedu",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "18",
        denominazione: "Cardeto",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "18",
        denominazione: "Cardinale",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "15",
        denominazione: "Cardito",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "09",
        denominazione: "Careggine",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "01",
        denominazione: "Carema",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Carenno",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "01",
        denominazione: "Carentino",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "18",
        denominazione: "Careri",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "01",
        denominazione: "Caresana",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "01",
        denominazione: "Caresanablot",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "01",
        denominazione: "Carezzano",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "18",
        denominazione: "Carfizzi",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "20",
        denominazione: "Cargeghe",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "18",
        denominazione: "Cariati",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "15",
        denominazione: "Carife",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "01",
        denominazione: "Carignano",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Carimate",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "15",
        denominazione: "Carinaro",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "19",
        denominazione: "Carini",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "15",
        denominazione: "Carinola",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "01",
        denominazione: "Carisio",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "04",
        denominazione: "Carisolo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "16",
        denominazione: "Carlantino",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "03",
        denominazione: "Carlazzo",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "19",
        denominazione: "Carlentini",
        denominazione_provincia: "Siracusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "SR"
    },
    {
        codice_regione: "06",
        denominazione: "Carlino",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "20",
        denominazione: "Carloforte",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "18",
        denominazione: "Carlopoli",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "01",
        denominazione: "Carmagnola",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "16",
        denominazione: "Carmiano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "09",
        denominazione: "Carmignano",
        denominazione_provincia: "Prato",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PO"
    },
    {
        codice_regione: "05",
        denominazione: "Carmignano di Brenta",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Carnago",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Carnate",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "03",
        denominazione: "Carobbio degli Angeli",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "18",
        denominazione: "Carolei",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Carona",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "19",
        denominazione: "Caronia",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "03",
        denominazione: "Caronno Pertusella",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Caronno Varesino",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "16",
        denominazione: "Carosino",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "16",
        denominazione: "Carovigno",
        denominazione_provincia: "Brindisi",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BR"
    },
    {
        codice_regione: "14",
        denominazione: "Carovilli",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "08",
        denominazione: "Carpaneto Piacentino",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "18",
        denominazione: "Carpanzano",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "11",
        denominazione: "Carpegna",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "03",
        denominazione: "Carpenedolo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Carpeneto",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "08",
        denominazione: "Carpi",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "03",
        denominazione: "Carpiano",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "16",
        denominazione: "Carpignano Salentino",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "01",
        denominazione: "Carpignano Sesia",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "08",
        denominazione: "Carpineti",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "13",
        denominazione: "Carpineto della Nora",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "12",
        denominazione: "Carpineto Romano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "13",
        denominazione: "Carpineto Sinello",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "16",
        denominazione: "Carpino",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "14",
        denominazione: "Carpinone",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "09",
        denominazione: "Carrara",
        denominazione_provincia: "Massa-Carrara",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "MS"
    },
    {
        codice_regione: "05",
        denominazione: "Carrè",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "01",
        denominazione: "Carrega Ligure",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "07",
        denominazione: "Carro",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "07",
        denominazione: "Carrodano",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "01",
        denominazione: "Carrosio",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Carrù",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "13",
        denominazione: "Carsoli",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "05",
        denominazione: "Cartigliano",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "01",
        denominazione: "Cartignano",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "11",
        denominazione: "Cartoceto",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "01",
        denominazione: "Cartosio",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "05",
        denominazione: "Cartura",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Carugate",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Carugo",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "13",
        denominazione: "Carunchio",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "03",
        denominazione: "Carvico",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "04",
        denominazione: "Carzano",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "18",
        denominazione: "Casabona",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "14",
        denominazione: "Casacalenda",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "13",
        denominazione: "Casacanditella",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "15",
        denominazione: "Casagiove",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "01",
        denominazione: "Casal Cermelli",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "15",
        denominazione: "Casal di Principe",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "15",
        denominazione: "Casal Velino",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "13",
        denominazione: "Casalanguida",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "12",
        denominazione: "Casalattico",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "01",
        denominazione: "Casalbeltrame",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "13",
        denominazione: "Casalbordino",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "15",
        denominazione: "Casalbore",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "01",
        denominazione: "Casalborgone",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "Casalbuono",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Casalbuttano ed Uniti",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "14",
        denominazione: "Casalciprano",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "15",
        denominazione: "Casalduni",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "01",
        denominazione: "Casale Corte Cerro",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "03",
        denominazione: "Casale Cremasco-Vidolasco",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "05",
        denominazione: "Casale di Scodosia",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Casale Litta",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "09",
        denominazione: "Casale Marittimo",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "01",
        denominazione: "Casale Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "05",
        denominazione: "Casale sul Sile",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "08",
        denominazione: "Casalecchio di Reno",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "01",
        denominazione: "Casaleggio Boiro",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Casaleggio Novara",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "05",
        denominazione: "Casaleone",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "03",
        denominazione: "Casaletto Ceredano",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Casaletto di Sopra",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Casaletto Lodigiano",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "15",
        denominazione: "Casaletto Spartano",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Casaletto Vaprio",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "08",
        denominazione: "Casalfiumanese",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "08",
        denominazione: "Casalgrande",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "01",
        denominazione: "Casalgrasso",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "18",
        denominazione: "Casali del Manco",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "13",
        denominazione: "Casalincontrada",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "01",
        denominazione: "Casalino",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Casalmaggiore",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Casalmaiocco",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "03",
        denominazione: "Casalmorano",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Casalmoro",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "01",
        denominazione: "Casalnoceto",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "15",
        denominazione: "Casalnuovo di Napoli",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "16",
        denominazione: "Casalnuovo Monterotaro",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "03",
        denominazione: "Casaloldo",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "03",
        denominazione: "Casalpusterlengo",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "03",
        denominazione: "Casalromano",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "05",
        denominazione: "Casalserugo",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "15",
        denominazione: "Casaluce",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "16",
        denominazione: "Casalvecchio di Puglia",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "19",
        denominazione: "Casalvecchio Siculo",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "12",
        denominazione: "Casalvieri",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "01",
        denominazione: "Casalvolone",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Casalzuigno",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "15",
        denominazione: "Casamarciano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "16",
        denominazione: "Casamassima",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "15",
        denominazione: "Casamicciola Terme",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "15",
        denominazione: "Casandrino",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "01",
        denominazione: "Casanova Elvo",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "07",
        denominazione: "Casanova Lerrone",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "03",
        denominazione: "Casanova Lonati",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "12",
        denominazione: "Casape",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "15",
        denominazione: "Casapesenna",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "01",
        denominazione: "Casapinta",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "12",
        denominazione: "Casaprota",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "15",
        denominazione: "Casapulla",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "16",
        denominazione: "Casarano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "03",
        denominazione: "Casargo",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Casarile",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "06",
        denominazione: "Casarsa della Delizia",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "07",
        denominazione: "Casarza Ligure",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "01",
        denominazione: "Casasco",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Casatenovo",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Casatisma",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "15",
        denominazione: "Casavatore",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "03",
        denominazione: "Casazza",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "10",
        denominazione: "Cascia",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "03",
        denominazione: "Casciago",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "09",
        denominazione: "Casciana Terme Lari",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "09",
        denominazione: "Cascina",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "01",
        denominazione: "Cascinette d'Ivrea",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Casei Gerola",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Caselette",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "07",
        denominazione: "Casella",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "15",
        denominazione: "Caselle in Pittari",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Caselle Landi",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "03",
        denominazione: "Caselle Lurani",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "01",
        denominazione: "Caselle Torinese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "Caserta",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: true,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "05",
        denominazione: "Casier",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "18",
        denominazione: "Casignana",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "08",
        denominazione: "Casina",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "03",
        denominazione: "Casirate d'Adda",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Caslino d'Erba",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Casnate con Bernate",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Casnigo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "15",
        denominazione: "Casola di Napoli",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "09",
        denominazione: "Casola in Lunigiana",
        denominazione_provincia: "Massa-Carrara",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "MS"
    },
    {
        codice_regione: "08",
        denominazione: "Casola Valsenio",
        denominazione_provincia: "Ravenna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RA"
    },
    {
        codice_regione: "09",
        denominazione: "Casole d'Elsa",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "13",
        denominazione: "Casoli",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "03",
        denominazione: "Casorate Primo",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Casorate Sempione",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Casorezzo",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "15",
        denominazione: "Casoria",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "01",
        denominazione: "Casorzo Monferrato",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "12",
        denominazione: "Casperia",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "03",
        denominazione: "Caspoggio",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "06",
        denominazione: "Cassacco",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Cassago Brianza",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "18",
        denominazione: "Cassano all'Ionio",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Cassano d'Adda",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "16",
        denominazione: "Cassano delle Murge",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "15",
        denominazione: "Cassano Irpino",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "03",
        denominazione: "Cassano Magnago",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "01",
        denominazione: "Cassano Spinola",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Cassano Valcuvia",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "19",
        denominazione: "Cassaro",
        denominazione_provincia: "Siracusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "SR"
    },
    {
        codice_regione: "03",
        denominazione: "Cassiglio",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Cassina de' Pecchi",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Cassina Rizzardi",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Cassina Valsassina",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "01",
        denominazione: "Cassinasco",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Cassine",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Cassinelle",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Cassinetta di Lugagnano",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "12",
        denominazione: "Cassino",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "05",
        denominazione: "Cassola",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Cassolnovo",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "05",
        denominazione: "Castagnaro",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "09",
        denominazione: "Castagneto Carducci",
        denominazione_provincia: "Livorno",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LI"
    },
    {
        codice_regione: "01",
        denominazione: "Castagneto Po",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Castagnito",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Castagnole delle Lanze",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Castagnole Monferrato",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Castagnole Piemonte",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Castana",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Castano Primo",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Casteggio",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Castegnato",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "05",
        denominazione: "Castegnero",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "15",
        denominazione: "Castel Baronia",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "01",
        denominazione: "Castel Boglione",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "08",
        denominazione: "Castel Bolognese",
        denominazione_provincia: "Ravenna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RA"
    },
    {
        codice_regione: "15",
        denominazione: "Castel Campagnano",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "13",
        denominazione: "Castel Castagna",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "04",
        denominazione: "Castel Condino",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "08",
        denominazione: "Castel d'Aiano",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "03",
        denominazione: "Castel d'Ario",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "05",
        denominazione: "Castel d'Azzano",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "14",
        denominazione: "Castel del Giudice",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "13",
        denominazione: "Castel del Monte",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "09",
        denominazione: "Castel del Piano",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "08",
        denominazione: "Castel del Rio",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "08",
        denominazione: "Castel di Casio",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "13",
        denominazione: "Castel di Ieri",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "19",
        denominazione: "Castel di Iudica",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "11",
        denominazione: "Castel di Lama",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "19",
        denominazione: "Castel di Lucio",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "13",
        denominazione: "Castel di Sangro",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "15",
        denominazione: "Castel di Sasso",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "12",
        denominazione: "Castel di Tora",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "09",
        denominazione: "Castel Focognano",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "13",
        denominazione: "Castel Frentano",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "03",
        denominazione: "Castel Gabbiano",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "12",
        denominazione: "Castel Gandolfo",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "10",
        denominazione: "Castel Giorgio",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "03",
        denominazione: "Castel Goffredo",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "08",
        denominazione: "Castel Guelfo di Bologna",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "04",
        denominazione: "Castel Ivano",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "12",
        denominazione: "Castel Madama",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "08",
        denominazione: "Castel Maggiore",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "03",
        denominazione: "Castel Mella",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "15",
        denominazione: "Castel Morrone",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "10",
        denominazione: "Castel Ritaldi",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "01",
        denominazione: "Castel Rocchero",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "03",
        denominazione: "Castel Rozzone",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "15",
        denominazione: "Castel San Giorgio",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "08",
        denominazione: "Castel San Giovanni",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "15",
        denominazione: "Castel San Lorenzo",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "09",
        denominazione: "Castel San Niccolò",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "12",
        denominazione: "Castel San Pietro Romano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "08",
        denominazione: "Castel San Pietro Terme",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "14",
        denominazione: "Castel San Vincenzo",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "12",
        denominazione: "Castel Sant'Angelo",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "12",
        denominazione: "Castel Sant'Elia",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "10",
        denominazione: "Castel Viscardo",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "07",
        denominazione: "Castel Vittorio",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "15",
        denominazione: "Castel Volturno",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "05",
        denominazione: "Castelbaldo",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Castelbelforte",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "11",
        denominazione: "Castelbellino",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "04",
        denominazione: "Castelbello-Ciardes",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "07",
        denominazione: "Castelbianco",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "14",
        denominazione: "Castelbottaccio",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "19",
        denominazione: "Castelbuono",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "15",
        denominazione: "Castelcivita",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Castelcovati",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "05",
        denominazione: "Castelcucco",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "19",
        denominazione: "Casteldaccia",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "08",
        denominazione: "Casteldelci",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "01",
        denominazione: "Casteldelfino",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Casteldidone",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "11",
        denominazione: "Castelfidardo",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "09",
        denominazione: "Castelfiorentino",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "12",
        denominazione: "Castelforte",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "15",
        denominazione: "Castelfranci",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "09",
        denominazione: "Castelfranco di Sotto",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "08",
        denominazione: "Castelfranco Emilia",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "15",
        denominazione: "Castelfranco in Miscano",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "09",
        denominazione: "Castelfranco Piandiscò",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "05",
        denominazione: "Castelfranco Veneto",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Castelgerundo",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "05",
        denominazione: "Castelgomberto",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "17",
        denominazione: "Castelgrande",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "05",
        denominazione: "Castelguglielmo",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "13",
        denominazione: "Castelguidone",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "01",
        denominazione: "Castell'Alfero",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "08",
        denominazione: "Castell'Arquato",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "09",
        denominazione: "Castell'Azzara",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "19",
        denominazione: "Castell'Umberto",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "15",
        denominazione: "Castellabate",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "13",
        denominazione: "Castellafiume",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "13",
        denominazione: "Castellalto",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "19",
        denominazione: "Castellammare del Golfo",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "15",
        denominazione: "Castellammare di Stabia",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "01",
        denominazione: "Castellamonte",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "16",
        denominazione: "Castellana Grotte",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "19",
        denominazione: "Castellana Sicula",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "16",
        denominazione: "Castellaneta",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "01",
        denominazione: "Castellania Coppi",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Castellanza",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "01",
        denominazione: "Castellar Guidobono",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "08",
        denominazione: "Castellarano",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "07",
        denominazione: "Castellaro",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "01",
        denominazione: "Castellazzo Bormida",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Castellazzo Novarese",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Castelleone",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "11",
        denominazione: "Castelleone di Suasa",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "01",
        denominazione: "Castellero",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Castelletto Cervo",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "01",
        denominazione: "Castelletto d'Erro",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Castelletto d'Orba",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Castelletto di Branduzzo",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Castelletto Merli",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Castelletto Molina",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Castelletto Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Castelletto sopra Ticino",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "01",
        denominazione: "Castelletto Stura",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Castelletto Uzzone",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "13",
        denominazione: "Castelli",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "03",
        denominazione: "Castelli Calepio",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "09",
        denominazione: "Castellina in Chianti",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "09",
        denominazione: "Castellina Marittima",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "01",
        denominazione: "Castellinaldo d'Alba",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "14",
        denominazione: "Castellino del Biferno",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "01",
        denominazione: "Castellino Tanaro",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "12",
        denominazione: "Castelliri",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "03",
        denominazione: "Castello Cabiaglio",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Castello d'Agogna",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "08",
        denominazione: "Castello d'Argile",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "15",
        denominazione: "Castello del Matese",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "03",
        denominazione: "Castello dell'Acqua",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "01",
        denominazione: "Castello di Annone",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "03",
        denominazione: "Castello di Brianza",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "15",
        denominazione: "Castello di Cisterna",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "05",
        denominazione: "Castello di Godego",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "04",
        denominazione: "Castello Tesino",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "04",
        denominazione: "Castello-Molina di Fiemme",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Castellucchio",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "16",
        denominazione: "Castelluccio dei Sauri",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "17",
        denominazione: "Castelluccio Inferiore",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "17",
        denominazione: "Castelluccio Superiore",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "16",
        denominazione: "Castelluccio Valmaggiore",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "01",
        denominazione: "Castelmagno",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Castelmarte",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "05",
        denominazione: "Castelmassa",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "14",
        denominazione: "Castelmauro",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "17",
        denominazione: "Castelmezzano",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "19",
        denominazione: "Castelmola",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "03",
        denominazione: "Castelnovetto",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "05",
        denominazione: "Castelnovo Bariano",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "06",
        denominazione: "Castelnovo del Friuli",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "08",
        denominazione: "Castelnovo di Sotto",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "08",
        denominazione: "Castelnovo ne' Monti",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "04",
        denominazione: "Castelnuovo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Castelnuovo Belbo",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "09",
        denominazione: "Castelnuovo Berardenga",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "03",
        denominazione: "Castelnuovo Bocca d'Adda",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "01",
        denominazione: "Castelnuovo Bormida",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Castelnuovo Bozzente",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "01",
        denominazione: "Castelnuovo Calcea",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "15",
        denominazione: "Castelnuovo Cilento",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "05",
        denominazione: "Castelnuovo del Garda",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "16",
        denominazione: "Castelnuovo della Daunia",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "01",
        denominazione: "Castelnuovo di Ceva",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "15",
        denominazione: "Castelnuovo di Conza",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "12",
        denominazione: "Castelnuovo di Farfa",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "09",
        denominazione: "Castelnuovo di Garfagnana",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "12",
        denominazione: "Castelnuovo di Porto",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "09",
        denominazione: "Castelnuovo di Val di Cecina",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "01",
        denominazione: "Castelnuovo Don Bosco",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "07",
        denominazione: "Castelnuovo Magra",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "01",
        denominazione: "Castelnuovo Nigra",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "12",
        denominazione: "Castelnuovo Parano",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "08",
        denominazione: "Castelnuovo Rangone",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "01",
        denominazione: "Castelnuovo Scrivia",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "15",
        denominazione: "Castelpagano",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "14",
        denominazione: "Castelpetroso",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "14",
        denominazione: "Castelpizzuto",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "11",
        denominazione: "Castelplanio",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "15",
        denominazione: "Castelpoto",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "11",
        denominazione: "Castelraimondo",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "04",
        denominazione: "Castelrotto",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "11",
        denominazione: "Castelsantangelo sul Nera",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "17",
        denominazione: "Castelsaraceno",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "20",
        denominazione: "Castelsardo",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Castelseprio",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "18",
        denominazione: "Castelsilano",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "01",
        denominazione: "Castelspina",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "19",
        denominazione: "Casteltermini",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "03",
        denominazione: "Castelveccana",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "13",
        denominazione: "Castelvecchio Calvisio",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "07",
        denominazione: "Castelvecchio di Rocca Barbena",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "13",
        denominazione: "Castelvecchio Subequo",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "15",
        denominazione: "Castelvenere",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "03",
        denominazione: "Castelverde",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "14",
        denominazione: "Castelverrino",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "15",
        denominazione: "Castelvetere in Val Fortore",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "15",
        denominazione: "Castelvetere sul Calore",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "19",
        denominazione: "Castelvetrano",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "08",
        denominazione: "Castelvetro di Modena",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "08",
        denominazione: "Castelvetro Piacentino",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "03",
        denominazione: "Castelvisconti",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "08",
        denominazione: "Castenaso",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "03",
        denominazione: "Castenedolo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "20",
        denominazione: "Castiadas",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "09",
        denominazione: "Castiglion Fibocchi",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "09",
        denominazione: "Castiglion Fiorentino",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "13",
        denominazione: "Castiglione a Casauria",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "07",
        denominazione: "Castiglione Chiavarese",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "18",
        denominazione: "Castiglione Cosentino",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Castiglione d'Adda",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "09",
        denominazione: "Castiglione d'Orcia",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "08",
        denominazione: "Castiglione dei Pepoli",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "15",
        denominazione: "Castiglione del Genovesi",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "10",
        denominazione: "Castiglione del Lago",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "09",
        denominazione: "Castiglione della Pescaia",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "03",
        denominazione: "Castiglione delle Stiviere",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "09",
        denominazione: "Castiglione di Garfagnana",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "19",
        denominazione: "Castiglione di Sicilia",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "01",
        denominazione: "Castiglione Falletto",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "12",
        denominazione: "Castiglione in Teverina",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "13",
        denominazione: "Castiglione Messer Marino",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "13",
        denominazione: "Castiglione Messer Raimondo",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "03",
        denominazione: "Castiglione Olona",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "01",
        denominazione: "Castiglione Tinella",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Castiglione Torinese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "11",
        denominazione: "Castignano",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "13",
        denominazione: "Castilenti",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "01",
        denominazione: "Castino",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Castione Andevenno",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "03",
        denominazione: "Castione della Presolana",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "06",
        denominazione: "Castions di Strada",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Castiraga Vidardo",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "03",
        denominazione: "Casto",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "11",
        denominazione: "Castorano",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "03",
        denominazione: "Castrezzato",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "16",
        denominazione: "Castri di Lecce",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "16",
        denominazione: "Castrignano de' Greci",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "16",
        denominazione: "Castrignano del Capo",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "03",
        denominazione: "Castro",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "16",
        denominazione: "Castro",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "12",
        denominazione: "Castro dei Volsci",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "08",
        denominazione: "Castrocaro Terme e Terra del Sole",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "12",
        denominazione: "Castrocielo",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "19",
        denominazione: "Castrofilippo",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "18",
        denominazione: "Castrolibero",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Castronno",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "19",
        denominazione: "Castronovo di Sicilia",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "17",
        denominazione: "Castronuovo di Sant'Andrea",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "14",
        denominazione: "Castropignano",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "19",
        denominazione: "Castroreale",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "18",
        denominazione: "Castroregio",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "18",
        denominazione: "Castrovillari",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "19",
        denominazione: "Catania",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: true,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "18",
        denominazione: "Catanzaro",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: true,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "19",
        denominazione: "Catenanuova",
        denominazione_provincia: "Enna",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "EN"
    },
    {
        codice_regione: "13",
        denominazione: "Catignano",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "08",
        denominazione: "Cattolica",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "19",
        denominazione: "Cattolica Eraclea",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "18",
        denominazione: "Caulonia",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "15",
        denominazione: "Cautano",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "15",
        denominazione: "Cava de' Tirreni",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Cava Manara",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Cavaglià",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "01",
        denominazione: "Cavaglietto",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "01",
        denominazione: "Cavaglio d'Agogna",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "01",
        denominazione: "Cavagnolo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "05",
        denominazione: "Cavaion Veronese",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "04",
        denominazione: "Cavalese",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Cavallerleone",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Cavallermaggiore",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "16",
        denominazione: "Cavallino",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "05",
        denominazione: "Cavallino-Treporti",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "01",
        denominazione: "Cavallirio",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "04",
        denominazione: "Cavareno",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Cavargna",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Cavaria con Premezzo",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "05",
        denominazione: "Cavarzere",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "05",
        denominazione: "Cavaso del Tomba",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "06",
        denominazione: "Cavasso Nuovo",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "01",
        denominazione: "Cavatore",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "06",
        denominazione: "Cavazzo Carnico",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "12",
        denominazione: "Cave",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "04",
        denominazione: "Cavedago",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "04",
        denominazione: "Cavedine",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Cavenago d'Adda",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "03",
        denominazione: "Cavenago di Brianza",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "03",
        denominazione: "Cavernago",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "08",
        denominazione: "Cavezzo",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "04",
        denominazione: "Cavizzana",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Cavour",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "08",
        denominazione: "Cavriago",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "03",
        denominazione: "Cavriana",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "09",
        denominazione: "Cavriglia",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "03",
        denominazione: "Cazzago Brabbia",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Cazzago San Martino",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "05",
        denominazione: "Cazzano di Tramigna",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "03",
        denominazione: "Cazzano Sant'Andrea",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "12",
        denominazione: "Ceccano",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "03",
        denominazione: "Cecima",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "09",
        denominazione: "Cecina",
        denominazione_provincia: "Livorno",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LI"
    },
    {
        codice_regione: "03",
        denominazione: "Cedegolo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Cedrasco",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "19",
        denominazione: "Cefalà Diana",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "19",
        denominazione: "Cefalù",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "05",
        denominazione: "Ceggia",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "16",
        denominazione: "Ceglie Messapica",
        denominazione_provincia: "Brindisi",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BR"
    },
    {
        codice_regione: "13",
        denominazione: "Celano",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "13",
        denominazione: "Celenza sul Trigno",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "16",
        denominazione: "Celenza Valfortore",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "18",
        denominazione: "Celico",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Cella Dati",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "01",
        denominazione: "Cella Monte",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "16",
        denominazione: "Cellamare",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "18",
        denominazione: "Cellara",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "01",
        denominazione: "Cellarengo",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "03",
        denominazione: "Cellatica",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "15",
        denominazione: "Celle di Bulgheria",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "01",
        denominazione: "Celle di Macra",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "16",
        denominazione: "Celle di San Vito",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "01",
        denominazione: "Celle Enomondo",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "07",
        denominazione: "Celle Ligure",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "12",
        denominazione: "Celleno",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "12",
        denominazione: "Cellere",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "13",
        denominazione: "Cellino Attanasio",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "16",
        denominazione: "Cellino San Marco",
        denominazione_provincia: "Brindisi",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BR"
    },
    {
        codice_regione: "01",
        denominazione: "Cellio con Breia",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "15",
        denominazione: "Cellole",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "04",
        denominazione: "Cembra Lisignago",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "18",
        denominazione: "Cenadi",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "03",
        denominazione: "Cenate Sopra",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Cenate Sotto",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Cencenighe Agordino",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "03",
        denominazione: "Cene",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Ceneselli",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "07",
        denominazione: "Cengio",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "01",
        denominazione: "Centallo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "08",
        denominazione: "Cento",
        denominazione_provincia: "Ferrara",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FE"
    },
    {
        codice_regione: "15",
        denominazione: "Centola",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "18",
        denominazione: "Centrache",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "03",
        denominazione: "Centro Valle Intelvi",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "19",
        denominazione: "Centuripe",
        denominazione_provincia: "Enna",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "EN"
    },
    {
        codice_regione: "13",
        denominazione: "Cepagatti",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "15",
        denominazione: "Ceppaloni",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "01",
        denominazione: "Ceppo Morelli",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "12",
        denominazione: "Ceprano",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "19",
        denominazione: "Cerami",
        denominazione_provincia: "Enna",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "EN"
    },
    {
        codice_regione: "07",
        denominazione: "Ceranesi",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "01",
        denominazione: "Cerano",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Cerano d'Intelvi",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Ceranova",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "15",
        denominazione: "Ceraso",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "14",
        denominazione: "Cercemaggiore",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "01",
        denominazione: "Cercenasco",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "14",
        denominazione: "Cercepiccola",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "18",
        denominazione: "Cerchiara di Calabria",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "13",
        denominazione: "Cerchio",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "03",
        denominazione: "Cercino",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "06",
        denominazione: "Cercivento",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "15",
        denominazione: "Cercola",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "19",
        denominazione: "Cerda",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "05",
        denominazione: "Cerea",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "05",
        denominazione: "Ceregnano",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "18",
        denominazione: "Cerenzia",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "01",
        denominazione: "Ceres",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Ceresara",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "01",
        denominazione: "Cereseto",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Ceresole Alba",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Ceresole Reale",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Cerete",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Ceretto Lomellina",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Cergnago",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "07",
        denominazione: "Ceriale",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "07",
        denominazione: "Ceriana",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "03",
        denominazione: "Ceriano Laghetto",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "08",
        denominazione: "Cerignale",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "16",
        denominazione: "Cerignola",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "18",
        denominazione: "Cerisano",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Cermenate",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "04",
        denominazione: "Cermes",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "13",
        denominazione: "Cermignano",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "03",
        denominazione: "Cernobbio",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Cernusco Lombardone",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Cernusco sul Naviglio",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Cerreto d'Asti",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "11",
        denominazione: "Cerreto d'Esi",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "10",
        denominazione: "Cerreto di Spoleto",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "01",
        denominazione: "Cerreto Grue",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "09",
        denominazione: "Cerreto Guidi",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "12",
        denominazione: "Cerreto Laziale",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "15",
        denominazione: "Cerreto Sannita",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "01",
        denominazione: "Cerretto Langhe",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Cerrina Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Cerrione",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "03",
        denominazione: "Cerro al Lambro",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "14",
        denominazione: "Cerro al Volturno",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "03",
        denominazione: "Cerro Maggiore",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Cerro Tanaro",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "05",
        denominazione: "Cerro Veronese",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "17",
        denominazione: "Cersosimo",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "09",
        denominazione: "Certaldo",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "03",
        denominazione: "Certosa di Pavia",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "18",
        denominazione: "Cerva",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "12",
        denominazione: "Cervara di Roma",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "05",
        denominazione: "Cervarese Santa Croce",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "12",
        denominazione: "Cervaro",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "01",
        denominazione: "Cervasca",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Cervatto",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "03",
        denominazione: "Cerveno",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Cervere",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Cervesina",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "12",
        denominazione: "Cerveteri",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "08",
        denominazione: "Cervia",
        denominazione_provincia: "Ravenna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RA"
    },
    {
        codice_regione: "18",
        denominazione: "Cervicati",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Cervignano d'Adda",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "06",
        denominazione: "Cervignano del Friuli",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "15",
        denominazione: "Cervinara",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "15",
        denominazione: "Cervino",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "07",
        denominazione: "Cervo",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "18",
        denominazione: "Cerzeto",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "15",
        denominazione: "Cesa",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "03",
        denominazione: "Cesana Brianza",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "01",
        denominazione: "Cesana Torinese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Cesano Boscone",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Cesano Maderno",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "01",
        denominazione: "Cesara",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "19",
        denominazione: "Cesarò",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "03",
        denominazione: "Cesate",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "08",
        denominazione: "Cesena",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "08",
        denominazione: "Cesenatico",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "15",
        denominazione: "Cesinali",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "07",
        denominazione: "Cesio",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "05",
        denominazione: "Cesiomaggiore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "05",
        denominazione: "Cessalto",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "18",
        denominazione: "Cessaniti",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "11",
        denominazione: "Cessapalombo",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "01",
        denominazione: "Cessole",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "15",
        denominazione: "Cetara",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Ceto",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "09",
        denominazione: "Cetona",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "18",
        denominazione: "Cetraro",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "01",
        denominazione: "Ceva",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Cevo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "02",
        denominazione: "Challand-Saint-Anselme",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "02",
        denominazione: "Challand-Saint-Victor",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "02",
        denominazione: "Chambave",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "02",
        denominazione: "Chamois",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "02",
        denominazione: "Champdepraz",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "02",
        denominazione: "Champorcher",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "02",
        denominazione: "Charvensod",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "02",
        denominazione: "Châtillon",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "01",
        denominazione: "Cherasco",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "20",
        denominazione: "Cheremule",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "01",
        denominazione: "Chialamberto",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "05",
        denominazione: "Chiampo",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "15",
        denominazione: "Chianche",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "09",
        denominazione: "Chianciano Terme",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "09",
        denominazione: "Chianni",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "01",
        denominazione: "Chianocco",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "19",
        denominazione: "Chiaramonte Gulfi",
        denominazione_provincia: "Ragusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "RG"
    },
    {
        codice_regione: "20",
        denominazione: "Chiaramonti",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "05",
        denominazione: "Chiarano",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "11",
        denominazione: "Chiaravalle",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "18",
        denominazione: "Chiaravalle Centrale",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "03",
        denominazione: "Chiari",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "17",
        denominazione: "Chiaromonte",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "14",
        denominazione: "Chiauci",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "07",
        denominazione: "Chiavari",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "03",
        denominazione: "Chiavenna",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "01",
        denominazione: "Chiaverano",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "04",
        denominazione: "Chienes",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "01",
        denominazione: "Chieri",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "05",
        denominazione: "Chies d'Alpago",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "03",
        denominazione: "Chiesa in Valmalenco",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "01",
        denominazione: "Chiesanuova",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "09",
        denominazione: "Chiesina Uzzanese",
        denominazione_provincia: "Pistoia",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PT"
    },
    {
        codice_regione: "13",
        denominazione: "Chieti",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: true,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "16",
        denominazione: "Chieuti",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "03",
        denominazione: "Chieve",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Chignolo d'Isola",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Chignolo Po",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "05",
        denominazione: "Chioggia",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "01",
        denominazione: "Chiomonte",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "06",
        denominazione: "Chions",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "06",
        denominazione: "Chiopris-Viscone",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "09",
        denominazione: "Chitignano",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "03",
        denominazione: "Chiuduno",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Chiuppano",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Chiuro",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "04",
        denominazione: "Chiusa",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "01",
        denominazione: "Chiusa di Pesio",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Chiusa di San Michele",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "19",
        denominazione: "Chiusa Sclafani",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "06",
        denominazione: "Chiusaforte",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "07",
        denominazione: "Chiusanico",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "01",
        denominazione: "Chiusano d'Asti",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "15",
        denominazione: "Chiusano di San Domenico",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "07",
        denominazione: "Chiusavecchia",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "09",
        denominazione: "Chiusdino",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "09",
        denominazione: "Chiusi",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "09",
        denominazione: "Chiusi della Verna",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "01",
        denominazione: "Chivasso",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "12",
        denominazione: "Ciampino",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "19",
        denominazione: "Cianciana",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "05",
        denominazione: "Cibiana di Cadore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "07",
        denominazione: "Cicagna",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "18",
        denominazione: "Cicala",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "15",
        denominazione: "Cicciano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "15",
        denominazione: "Cicerale",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "12",
        denominazione: "Ciciliano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "03",
        denominazione: "Cicognolo",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "01",
        denominazione: "Ciconio",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Cigliano",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "01",
        denominazione: "Cigliè",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Cigognola",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Cigole",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Cilavegna",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "05",
        denominazione: "Cimadolmo",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Cimbergo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "18",
        denominazione: "Ciminà",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "19",
        denominazione: "Ciminna",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "15",
        denominazione: "Cimitile",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "06",
        denominazione: "Cimolais",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "04",
        denominazione: "Cimone",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Cinaglio",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "12",
        denominazione: "Cineto Romano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "03",
        denominazione: "Cingia de' Botti",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "11",
        denominazione: "Cingoli",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "09",
        denominazione: "Cinigiano",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "03",
        denominazione: "Cinisello Balsamo",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "19",
        denominazione: "Cinisi",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "03",
        denominazione: "Cino",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "18",
        denominazione: "Cinquefrondi",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "01",
        denominazione: "Cintano",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "04",
        denominazione: "Cinte Tesino",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "05",
        denominazione: "Cinto Caomaggiore",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "05",
        denominazione: "Cinto Euganeo",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "01",
        denominazione: "Cinzano",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "Ciorlano",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "07",
        denominazione: "Cipressa",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "15",
        denominazione: "Circello",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "01",
        denominazione: "Ciriè",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "17",
        denominazione: "Cirigliano",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "03",
        denominazione: "Cirimido",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "18",
        denominazione: "Cirò",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "18",
        denominazione: "Cirò Marina",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "04",
        denominazione: "Cis",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Cisano Bergamasco",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "07",
        denominazione: "Cisano sul Neva",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "03",
        denominazione: "Ciserano",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Cislago",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Cisliano",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "05",
        denominazione: "Cison di Valmarino",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "01",
        denominazione: "Cissone",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Cisterna d'Asti",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "12",
        denominazione: "Cisterna di Latina",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "16",
        denominazione: "Cisternino",
        denominazione_provincia: "Brindisi",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BR"
    },
    {
        codice_regione: "10",
        denominazione: "Citerna",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "10",
        denominazione: "Città della Pieve",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "10",
        denominazione: "Città di Castello",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "13",
        denominazione: "Città Sant'Angelo",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "05",
        denominazione: "Cittadella",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "12",
        denominazione: "Cittaducale",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "18",
        denominazione: "Cittanova",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "12",
        denominazione: "Cittareale",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "03",
        denominazione: "Cittiglio",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Civate",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "07",
        denominazione: "Civezza",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "04",
        denominazione: "Civezzano",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Civiasco",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "06",
        denominazione: "Cividale del Friuli",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Cividate al Piano",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Cividate Camuno",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "18",
        denominazione: "Civita",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "12",
        denominazione: "Civita Castellana",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "13",
        denominazione: "Civita d'Antino",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "14",
        denominazione: "Civitacampomarano",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "13",
        denominazione: "Civitaluparella",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "14",
        denominazione: "Civitanova del Sannio",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "11",
        denominazione: "Civitanova Marche",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "13",
        denominazione: "Civitaquana",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "12",
        denominazione: "Civitavecchia",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "13",
        denominazione: "Civitella Alfedena",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "13",
        denominazione: "Civitella Casanova",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "12",
        denominazione: "Civitella d'Agliano",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "13",
        denominazione: "Civitella del Tronto",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "08",
        denominazione: "Civitella di Romagna",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "09",
        denominazione: "Civitella in Val di Chiana",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "13",
        denominazione: "Civitella Messer Raimondo",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "09",
        denominazione: "Civitella Paganico",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "13",
        denominazione: "Civitella Roveto",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "12",
        denominazione: "Civitella San Paolo",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "03",
        denominazione: "Civo",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "03",
        denominazione: "Claino con Osteno",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "06",
        denominazione: "Claut",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "06",
        denominazione: "Clauzetto",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "01",
        denominazione: "Clavesana",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Claviere",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "04",
        denominazione: "Cles",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "18",
        denominazione: "Cleto",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Clivio",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Clusone",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Coassolo Torinese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Coazze",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Coazzolo",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "03",
        denominazione: "Coccaglio",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Cocconato",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "03",
        denominazione: "Cocquio-Trevisago",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "13",
        denominazione: "Cocullo",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "05",
        denominazione: "Codevigo",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Codevilla",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "08",
        denominazione: "Codigoro",
        denominazione_provincia: "Ferrara",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FE"
    },
    {
        codice_regione: "05",
        denominazione: "Codognè",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Codogno",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "06",
        denominazione: "Codroipo",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "20",
        denominazione: "Codrongianos",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "01",
        denominazione: "Coggiola",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "03",
        denominazione: "Cogliate",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "02",
        denominazione: "Cogne",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "07",
        denominazione: "Cogoleto",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "05",
        denominazione: "Cogollo del Cengio",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "07",
        denominazione: "Cogorno",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "01",
        denominazione: "Colazza",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "05",
        denominazione: "Colceresa",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Colere",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "12",
        denominazione: "Colfelice",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "08",
        denominazione: "Coli",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "03",
        denominazione: "Colico",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "12",
        denominazione: "Collalto Sabino",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "13",
        denominazione: "Collarmele",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "10",
        denominazione: "Collazzone",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "03",
        denominazione: "Colle Brianza",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "14",
        denominazione: "Colle d'Anchise",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "12",
        denominazione: "Colle di Tora",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "09",
        denominazione: "Colle di Val d'Elsa",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "12",
        denominazione: "Colle San Magno",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "15",
        denominazione: "Colle Sannita",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "05",
        denominazione: "Colle Santa Lucia",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "05",
        denominazione: "Colle Umberto",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Collebeato",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "08",
        denominazione: "Collecchio",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "13",
        denominazione: "Collecorvino",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "13",
        denominazione: "Colledara",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "13",
        denominazione: "Colledimacine",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "13",
        denominazione: "Colledimezzo",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "12",
        denominazione: "Colleferro",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "12",
        denominazione: "Collegiove",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "01",
        denominazione: "Collegno",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "13",
        denominazione: "Collelongo",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "12",
        denominazione: "Collepardo",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "16",
        denominazione: "Collepasso",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "13",
        denominazione: "Collepietro",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "01",
        denominazione: "Colleretto Castelnuovo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Colleretto Giacosa",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "09",
        denominazione: "Collesalvetti",
        denominazione_provincia: "Livorno",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LI"
    },
    {
        codice_regione: "19",
        denominazione: "Collesano",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "14",
        denominazione: "Colletorto",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "12",
        denominazione: "Collevecchio",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "14",
        denominazione: "Colli a Volturno",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "11",
        denominazione: "Colli al Metauro",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "11",
        denominazione: "Colli del Tronto",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "12",
        denominazione: "Colli sul Velino",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "03",
        denominazione: "Colli Verdi",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "15",
        denominazione: "Colliano",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "20",
        denominazione: "Collinas",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "03",
        denominazione: "Collio",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Collobiano",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "06",
        denominazione: "Colloredo di Monte Albano",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "11",
        denominazione: "Colmurano",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "17",
        denominazione: "Colobraro",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "05",
        denominazione: "Cologna Veneta",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "03",
        denominazione: "Cologne",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Cologno al Serio",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Cologno Monzese",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "05",
        denominazione: "Colognola ai Colli",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "12",
        denominazione: "Colonna",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "13",
        denominazione: "Colonnella",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "03",
        denominazione: "Colonno",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Colorina",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "08",
        denominazione: "Colorno",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "18",
        denominazione: "Colosimi",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Colturano",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Colverde",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Colzate",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Comabbio",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "08",
        denominazione: "Comacchio",
        denominazione_provincia: "Ferrara",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FE"
    },
    {
        codice_regione: "09",
        denominazione: "Comano",
        denominazione_provincia: "Massa-Carrara",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "MS"
    },
    {
        codice_regione: "04",
        denominazione: "Comano Terme",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Comazzo",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "06",
        denominazione: "Comeglians",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "05",
        denominazione: "Comelico Superiore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "03",
        denominazione: "Comerio",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Comezzano-Cizzago",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Comignago",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "19",
        denominazione: "Comiso",
        denominazione_provincia: "Ragusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "RG"
    },
    {
        codice_regione: "19",
        denominazione: "Comitini",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "15",
        denominazione: "Comiziano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "03",
        denominazione: "Commessaggio",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "04",
        denominazione: "Commezzadura",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Como",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: true,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "08",
        denominazione: "Compiano",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "03",
        denominazione: "Comun Nuovo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "11",
        denominazione: "Comunanza",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "05",
        denominazione: "Cona",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "14",
        denominazione: "Conca Casale",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "15",
        denominazione: "Conca dei Marini",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "15",
        denominazione: "Conca della Campania",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "05",
        denominazione: "Concamarise",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "12",
        denominazione: "Concerviano",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "03",
        denominazione: "Concesio",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "05",
        denominazione: "Concordia Sagittaria",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "08",
        denominazione: "Concordia sulla Secchia",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "03",
        denominazione: "Concorezzo",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "18",
        denominazione: "Condofuri",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "01",
        denominazione: "Condove",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "19",
        denominazione: "Condrò",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "05",
        denominazione: "Conegliano",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Confienza",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "12",
        denominazione: "Configni",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "18",
        denominazione: "Conflenti",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "01",
        denominazione: "Coniolo",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "08",
        denominazione: "Conselice",
        denominazione_provincia: "Ravenna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RA"
    },
    {
        codice_regione: "05",
        denominazione: "Conselve",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "04",
        denominazione: "Contà",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "19",
        denominazione: "Contessa Entellina",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "12",
        denominazione: "Contigliano",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "15",
        denominazione: "Contrada",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "13",
        denominazione: "Controguerra",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "15",
        denominazione: "Controne",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "15",
        denominazione: "Contursi Terme",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "16",
        denominazione: "Conversano",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "15",
        denominazione: "Conza della Campania",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "01",
        denominazione: "Conzano",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "16",
        denominazione: "Copertino",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "03",
        denominazione: "Copiano",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "08",
        denominazione: "Copparo",
        denominazione_provincia: "Ferrara",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FE"
    },
    {
        codice_regione: "03",
        denominazione: "Corana",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "16",
        denominazione: "Corato",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "15",
        denominazione: "Corbara",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Corbetta",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "05",
        denominazione: "Corbola",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "12",
        denominazione: "Corchiano",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "10",
        denominazione: "Corciano",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "06",
        denominazione: "Cordenons",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "05",
        denominazione: "Cordignano",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "06",
        denominazione: "Cordovado",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "09",
        denominazione: "Coreglia Antelminelli",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "07",
        denominazione: "Coreglia Ligure",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "12",
        denominazione: "Coreno Ausonio",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "13",
        denominazione: "Corfinio",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "12",
        denominazione: "Cori",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "08",
        denominazione: "Coriano",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "16",
        denominazione: "Corigliano d'Otranto",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "18",
        denominazione: "Corigliano-Rossano",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "11",
        denominazione: "Corinaldo",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "01",
        denominazione: "Corio",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "19",
        denominazione: "Corleone",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "15",
        denominazione: "Corleto Monforte",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "17",
        denominazione: "Corleto Perticara",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "03",
        denominazione: "Cormano",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "06",
        denominazione: "Cormons",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "03",
        denominazione: "Corna Imagna",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Cornalba",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Cornale e Bastida",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Cornaredo",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Cornate d'Adda",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "04",
        denominazione: "Cornedo all'Isarco",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "05",
        denominazione: "Cornedo Vicentino",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Cornegliano Laudense",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "01",
        denominazione: "Corneliano d'Alba",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "08",
        denominazione: "Corniglio",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "06",
        denominazione: "Corno di Rosazzo",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Corno Giovine",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "03",
        denominazione: "Cornovecchio",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "05",
        denominazione: "Cornuda",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "08",
        denominazione: "Correggio",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "03",
        denominazione: "Correzzana",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "05",
        denominazione: "Correzzola",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Corrido",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "11",
        denominazione: "Corridonia",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "13",
        denominazione: "Corropoli",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "16",
        denominazione: "Corsano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "03",
        denominazione: "Corsico",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Corsione",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "04",
        denominazione: "Cortaccia sulla strada del vino",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "18",
        denominazione: "Cortale",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "01",
        denominazione: "Cortandone",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Cortanze",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Cortazzone",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "08",
        denominazione: "Corte Brugnatella",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "03",
        denominazione: "Corte de' Cortesi con Cignone",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Corte de' Frati",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Corte Franca",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Corte Palasio",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "08",
        denominazione: "Cortemaggiore",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "01",
        denominazione: "Cortemilia",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Corteno Golgi",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Cortenova",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Cortenuova",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Corteolona e Genzone",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Cortiglione",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "05",
        denominazione: "Cortina d'Ampezzo",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "04",
        denominazione: "Cortina sulla strada del vino",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "13",
        denominazione: "Cortino",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "09",
        denominazione: "Cortona",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "13",
        denominazione: "Corvara",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "04",
        denominazione: "Corvara in Badia",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "Corvino San Quirico",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Corzano",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "06",
        denominazione: "Coseano",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "18",
        denominazione: "Cosenza",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: true,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "07",
        denominazione: "Cosio d'Arroscia",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "03",
        denominazione: "Cosio Valtellino",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "18",
        denominazione: "Cosoleto",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "01",
        denominazione: "Cossano Belbo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Cossano Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Cossato",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "07",
        denominazione: "Cosseria",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "11",
        denominazione: "Cossignano",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "01",
        denominazione: "Cossogno",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "20",
        denominazione: "Cossoine",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "01",
        denominazione: "Cossombrato",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "03",
        denominazione: "Costa de' Nobili",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Costa di Mezzate",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Costa di Rovigo",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "03",
        denominazione: "Costa Masnaga",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Costa Serina",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Costa Valle Imagna",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Costa Vescovato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Costa Volpino",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Costabissara",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "10",
        denominazione: "Costacciaro",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "01",
        denominazione: "Costanzana",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "07",
        denominazione: "Costarainera",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "05",
        denominazione: "Costermano sul Garda",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "01",
        denominazione: "Costigliole d'Asti",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Costigliole Saluzzo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "08",
        denominazione: "Cotignola",
        denominazione_provincia: "Ravenna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RA"
    },
    {
        codice_regione: "18",
        denominazione: "Cotronei",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "12",
        denominazione: "Cottanello",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "02",
        denominazione: "Courmayeur",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "03",
        denominazione: "Covo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Cozzo",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "17",
        denominazione: "Craco",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "03",
        denominazione: "Crandola Valsassina",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "01",
        denominazione: "Cravagliana",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "01",
        denominazione: "Cravanzana",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Craveggia",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "05",
        denominazione: "Creazzo",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "13",
        denominazione: "Crecchio",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "03",
        denominazione: "Credaro",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Credera Rubbiano",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Crema",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Cremella",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Cremenaga",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Cremeno",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Cremia",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "01",
        denominazione: "Cremolino",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Cremona",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: true,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Cremosano",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "01",
        denominazione: "Crescentino",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "05",
        denominazione: "Crespadoro",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Crespiatica",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "09",
        denominazione: "Crespina Lorenzana",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "05",
        denominazione: "Crespino",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "01",
        denominazione: "Cressa",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "01",
        denominazione: "Crevacuore",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "08",
        denominazione: "Crevalcore",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "01",
        denominazione: "Crevoladossola",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "15",
        denominazione: "Crispano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "16",
        denominazione: "Crispiano",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "01",
        denominazione: "Crissolo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "07",
        denominazione: "Crocefieschi",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "05",
        denominazione: "Crocetta del Montello",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "01",
        denominazione: "Crodo",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "13",
        denominazione: "Crognaleto",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "18",
        denominazione: "Cropalati",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "18",
        denominazione: "Cropani",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "18",
        denominazione: "Crosia",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Crosio della Valle",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "18",
        denominazione: "Crotone",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: true,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "03",
        denominazione: "Crotta d'Adda",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "01",
        denominazione: "Crova",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "04",
        denominazione: "Croviana",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "18",
        denominazione: "Crucoli",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "03",
        denominazione: "Cuasso al Monte",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "15",
        denominazione: "Cuccaro Vetere",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Cucciago",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "01",
        denominazione: "Cuceglio",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Cuggiono",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Cugliate-Fabiasco",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "20",
        denominazione: "Cuglieri",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "13",
        denominazione: "Cugnoli",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "01",
        denominazione: "Cumiana",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Cumignano sul Naviglio",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Cunardo",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "01",
        denominazione: "Cuneo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: true,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Cunico",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Cuorgnè",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "13",
        denominazione: "Cupello",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "11",
        denominazione: "Cupra Marittima",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "11",
        denominazione: "Cupramontana",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "03",
        denominazione: "Cura Carpignano",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "20",
        denominazione: "Curcuris",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "01",
        denominazione: "Cureggio",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Curiglia con Monteviasco",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "18",
        denominazione: "Curinga",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "01",
        denominazione: "Curino",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "03",
        denominazione: "Curno",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "04",
        denominazione: "Curon Venosta",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "16",
        denominazione: "Cursi",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "05",
        denominazione: "Curtarolo",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Curtatone",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "15",
        denominazione: "Curti",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "03",
        denominazione: "Cusago",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Cusano Milanino",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "15",
        denominazione: "Cusano Mutri",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "03",
        denominazione: "Cusino",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Cusio",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "19",
        denominazione: "Custonaci",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "18",
        denominazione: "Cutro",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "16",
        denominazione: "Cutrofiano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "03",
        denominazione: "Cuveglio",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Cuvio",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Dairago",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Dalmine",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "04",
        denominazione: "Dambel",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "05",
        denominazione: "Danta di Cadore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "03",
        denominazione: "Darfo Boario Terme",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "18",
        denominazione: "Dasà",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "07",
        denominazione: "Davagna",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "03",
        denominazione: "Daverio",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "18",
        denominazione: "Davoli",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "03",
        denominazione: "Dazio",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "20",
        denominazione: "Decimomannu",
        denominazione_provincia: "Cagliari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "CA"
    },
    {
        codice_regione: "20",
        denominazione: "Decimoputzu",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "18",
        denominazione: "Decollatura",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "07",
        denominazione: "Dego",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "07",
        denominazione: "Deiva Marina",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "03",
        denominazione: "Delebio",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "19",
        denominazione: "Delia",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "18",
        denominazione: "Delianuova",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "16",
        denominazione: "Deliceto",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "03",
        denominazione: "Dello",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Demonte",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Denice",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "04",
        denominazione: "Denno",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Dernice",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Derovere",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "10",
        denominazione: "Deruta",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "03",
        denominazione: "Dervio",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "01",
        denominazione: "Desana",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "03",
        denominazione: "Desenzano del Garda",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Desio",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "20",
        denominazione: "Desulo",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "18",
        denominazione: "Diamante",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "07",
        denominazione: "Diano Arentino",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "07",
        denominazione: "Diano Castello",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "01",
        denominazione: "Diano d'Alba",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "07",
        denominazione: "Diano Marina",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "07",
        denominazione: "Diano San Pietro",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "09",
        denominazione: "Dicomano",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "06",
        denominazione: "Dignano",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "04",
        denominazione: "Dimaro Folgarida",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "18",
        denominazione: "Dinami",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "18",
        denominazione: "Dipignano",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "16",
        denominazione: "Diso",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "01",
        denominazione: "Divignano",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Dizzasco",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "04",
        denominazione: "Dobbiaco",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "06",
        denominazione: "Doberdò del Lago",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "01",
        denominazione: "Dogliani",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "13",
        denominazione: "Dogliola",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "06",
        denominazione: "Dogna",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "05",
        denominazione: "Dolcè",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "07",
        denominazione: "Dolceacqua",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "07",
        denominazione: "Dolcedo",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "06",
        denominazione: "Dolegna del Collio",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "20",
        denominazione: "Dolianova",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "05",
        denominazione: "Dolo",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "03",
        denominazione: "Dolzago",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "18",
        denominazione: "Domanico",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Domaso",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "05",
        denominazione: "Domegge di Cadore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "15",
        denominazione: "Domicella",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "01",
        denominazione: "Domodossola",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "20",
        denominazione: "Domus de Maria",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Domusnovas",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "01",
        denominazione: "Donato",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "03",
        denominazione: "Dongo",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "02",
        denominazione: "Donnas",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "20",
        denominazione: "Donori",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Dorgali",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "03",
        denominazione: "Dorio",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "01",
        denominazione: "Dormelletto",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Dorno",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Dorzano",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "03",
        denominazione: "Dosolo",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "03",
        denominazione: "Dossena",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Dosso del Liro",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "02",
        denominazione: "Doues",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "08",
        denominazione: "Dovadola",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "03",
        denominazione: "Dovera",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "08",
        denominazione: "Dozza",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "15",
        denominazione: "Dragoni",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "18",
        denominazione: "Drapia",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "04",
        denominazione: "Drena",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "06",
        denominazione: "Drenchia",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Dresano",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "04",
        denominazione: "Dro",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Dronero",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Druento",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Druogno",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "20",
        denominazione: "Dualchi",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "03",
        denominazione: "Dubino",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "05",
        denominazione: "Due Carrare",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "05",
        denominazione: "Dueville",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "15",
        denominazione: "Dugenta",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "06",
        denominazione: "Duino Aurisina",
        denominazione_provincia: "Trieste",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "TS"
    },
    {
        codice_regione: "03",
        denominazione: "Dumenza",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Duno",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "15",
        denominazione: "Durazzano",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "14",
        denominazione: "Duronia",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "01",
        denominazione: "Dusino San Michele",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "15",
        denominazione: "Eboli",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Edolo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "04",
        denominazione: "Egna",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "13",
        denominazione: "Elice",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "20",
        denominazione: "Elini",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "03",
        denominazione: "Ello",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "20",
        denominazione: "Elmas",
        denominazione_provincia: "Cagliari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "CA"
    },
    {
        codice_regione: "01",
        denominazione: "Elva",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "02",
        denominazione: "Emarèse",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "09",
        denominazione: "Empoli",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "03",
        denominazione: "Endine Gaiano",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Enego",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "06",
        denominazione: "Enemonzo",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "19",
        denominazione: "Enna",
        denominazione_provincia: "Enna",
        denominazione_regione: "Sicilia",
        flag_capoluogo: true,
        sigla_provincia: "EN"
    },
    {
        codice_regione: "01",
        denominazione: "Entracque",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Entratico",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Envie",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "17",
        denominazione: "Episcopia",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "05",
        denominazione: "Eraclea",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "03",
        denominazione: "Erba",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "05",
        denominazione: "Erbè",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "05",
        denominazione: "Erbezzo",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "03",
        denominazione: "Erbusco",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "16",
        denominazione: "Erchie",
        denominazione_provincia: "Brindisi",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BR"
    },
    {
        codice_regione: "15",
        denominazione: "Ercolano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "19",
        denominazione: "Erice",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "07",
        denominazione: "Erli",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "06",
        denominazione: "Erto e Casso",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "20",
        denominazione: "Erula",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Erve",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "11",
        denominazione: "Esanatoglia",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "20",
        denominazione: "Escalaplano",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Escolca",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "03",
        denominazione: "Esine",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Esino Lario",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "12",
        denominazione: "Esperia",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "20",
        denominazione: "Esporlatu",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "05",
        denominazione: "Este",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "20",
        denominazione: "Esterzili",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "02",
        denominazione: "Etroubles",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "03",
        denominazione: "Eupilio",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "01",
        denominazione: "Exilles",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Fabbrica Curone",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "09",
        denominazione: "Fabbriche di Vergemoli",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "08",
        denominazione: "Fabbrico",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "11",
        denominazione: "Fabriano",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "12",
        denominazione: "Fabrica di Roma",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "18",
        denominazione: "Fabrizia",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "10",
        denominazione: "Fabro",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "06",
        denominazione: "Faedis",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Faedo Valtellino",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "08",
        denominazione: "Faenza",
        denominazione_provincia: "Ravenna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RA"
    },
    {
        codice_regione: "16",
        denominazione: "Faeto",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "06",
        denominazione: "Fagagna",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Faggeto Lario",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "16",
        denominazione: "Faggiano",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "13",
        denominazione: "Fagnano Alto",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "18",
        denominazione: "Fagnano Castello",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Fagnano Olona",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "04",
        denominazione: "Fai della Paganella",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "15",
        denominazione: "Faicchio",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "05",
        denominazione: "Falcade",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "15",
        denominazione: "Falciano del Massico",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "18",
        denominazione: "Falconara Albanese",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "11",
        denominazione: "Falconara Marittima",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "19",
        denominazione: "Falcone",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "12",
        denominazione: "Faleria",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "18",
        denominazione: "Falerna",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "11",
        denominazione: "Falerone",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "13",
        denominazione: "Fallo",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "03",
        denominazione: "Faloppio",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "12",
        denominazione: "Falvaterra",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "04",
        denominazione: "Falzes",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "08",
        denominazione: "Fanano",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "06",
        denominazione: "Fanna",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "11",
        denominazione: "Fano",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "13",
        denominazione: "Fano Adriano",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "13",
        denominazione: "Fara Filiorum Petri",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "03",
        denominazione: "Fara Gera d'Adda",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "12",
        denominazione: "Fara in Sabina",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "01",
        denominazione: "Fara Novarese",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Fara Olivana con Sola",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "13",
        denominazione: "Fara San Martino",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "05",
        denominazione: "Fara Vicentino",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "17",
        denominazione: "Fardella",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "01",
        denominazione: "Farigliano",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "13",
        denominazione: "Farindola",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "08",
        denominazione: "Farini",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "12",
        denominazione: "Farnese",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "06",
        denominazione: "Farra d'Isonzo",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "05",
        denominazione: "Farra di Soligo",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "16",
        denominazione: "Fasano",
        denominazione_provincia: "Brindisi",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BR"
    },
    {
        codice_regione: "07",
        denominazione: "Fascia",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "09",
        denominazione: "Fauglia",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "01",
        denominazione: "Faule",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "07",
        denominazione: "Favale di Malvaro",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "19",
        denominazione: "Favara",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "19",
        denominazione: "Favignana",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "01",
        denominazione: "Favria",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Feisoglio",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Feletto",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "08",
        denominazione: "Felino",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "15",
        denominazione: "Felitto",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "01",
        denominazione: "Felizzano",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "05",
        denominazione: "Feltre",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "03",
        denominazione: "Fenegrò",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "01",
        denominazione: "Fenestrelle",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "02",
        denominazione: "Fénis",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "10",
        denominazione: "Ferentillo",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "12",
        denominazione: "Ferentino",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "19",
        denominazione: "Ferla",
        denominazione_provincia: "Siracusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "SR"
    },
    {
        codice_regione: "11",
        denominazione: "Fermignano",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "11",
        denominazione: "Fermo",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: true,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "03",
        denominazione: "Ferno",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "18",
        denominazione: "Feroleto Antico",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "18",
        denominazione: "Feroleto della Chiesa",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "17",
        denominazione: "Ferrandina",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "08",
        denominazione: "Ferrara",
        denominazione_provincia: "Ferrara",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: true,
        sigla_provincia: "FE"
    },
    {
        codice_regione: "05",
        denominazione: "Ferrara di Monte Baldo",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "14",
        denominazione: "Ferrazzano",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "03",
        denominazione: "Ferrera di Varese",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Ferrera Erbognone",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Ferrere",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "08",
        denominazione: "Ferriere",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "18",
        denominazione: "Ferruzzano",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "12",
        denominazione: "Fiamignano",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "01",
        denominazione: "Fiano",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "12",
        denominazione: "Fiano Romano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "11",
        denominazione: "Fiastra",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "04",
        denominazione: "Fiavè",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "19",
        denominazione: "Ficarazzi",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "05",
        denominazione: "Ficarolo",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "19",
        denominazione: "Ficarra",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "10",
        denominazione: "Ficulle",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "08",
        denominazione: "Fidenza",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "04",
        denominazione: "Fiè allo Sciliar",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "04",
        denominazione: "Fierozzo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Fiesco",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "09",
        denominazione: "Fiesole",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "03",
        denominazione: "Fiesse",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "05",
        denominazione: "Fiesso d'Artico",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "05",
        denominazione: "Fiesso Umbertiano",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "03",
        denominazione: "Figino Serenza",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "09",
        denominazione: "Figline e Incisa Valdarno",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "18",
        denominazione: "Figline Vegliaturo",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "12",
        denominazione: "Filacciano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "18",
        denominazione: "Filadelfia",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "03",
        denominazione: "Filago",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "18",
        denominazione: "Filandari",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "09",
        denominazione: "Filattiera",
        denominazione_provincia: "Massa-Carrara",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "MS"
    },
    {
        codice_regione: "12",
        denominazione: "Filettino",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "13",
        denominazione: "Filetto",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "17",
        denominazione: "Filiano",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "03",
        denominazione: "Filighera",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "14",
        denominazione: "Filignano",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "18",
        denominazione: "Filogaso",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "11",
        denominazione: "Filottrano",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "08",
        denominazione: "Finale Emilia",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "07",
        denominazione: "Finale Ligure",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "03",
        denominazione: "Fino del Monte",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Fino Mornasco",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Fiorano al Serio",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Fiorano Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "08",
        denominazione: "Fiorano Modenese",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "08",
        denominazione: "Fiorenzuola d'Arda",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "09",
        denominazione: "Firenze",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: true,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "09",
        denominazione: "Firenzuola",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "18",
        denominazione: "Firmo",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "08",
        denominazione: "Fiscaglia",
        denominazione_provincia: "Ferrara",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FE"
    },
    {
        codice_regione: "15",
        denominazione: "Fisciano",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "12",
        denominazione: "Fiuggi",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "08",
        denominazione: "Fiumalbo",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "18",
        denominazione: "Fiumara",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "06",
        denominazione: "Fiume Veneto",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "19",
        denominazione: "Fiumedinisi",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "18",
        denominazione: "Fiumefreddo Bruzio",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "19",
        denominazione: "Fiumefreddo di Sicilia",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "06",
        denominazione: "Fiumicello Villa Vicentina",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "12",
        denominazione: "Fiumicino",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "11",
        denominazione: "Fiuminata",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "09",
        denominazione: "Fivizzano",
        denominazione_provincia: "Massa-Carrara",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "MS"
    },
    {
        codice_regione: "06",
        denominazione: "Flaibano",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Flero",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "19",
        denominazione: "Floresta",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "19",
        denominazione: "Floridia",
        denominazione_provincia: "Siracusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "SR"
    },
    {
        codice_regione: "20",
        denominazione: "Florinas",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "15",
        denominazione: "Flumeri",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "20",
        denominazione: "Fluminimaggiore",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Flussio",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "01",
        denominazione: "Fobello",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "16",
        denominazione: "Foggia",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: true,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "15",
        denominazione: "Foglianise",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "06",
        denominazione: "Fogliano Redipuglia",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "01",
        denominazione: "Foglizzo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "09",
        denominazione: "Foiano della Chiana",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "15",
        denominazione: "Foiano di Val Fortore",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "04",
        denominazione: "Folgaria",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "11",
        denominazione: "Folignano",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "10",
        denominazione: "Foligno",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "05",
        denominazione: "Follina",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "07",
        denominazione: "Follo",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "09",
        denominazione: "Follonica",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "03",
        denominazione: "Fombio",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "19",
        denominazione: "Fondachelli-Fantina",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "12",
        denominazione: "Fondi",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "20",
        denominazione: "Fonni",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "02",
        denominazione: "Fontainemore",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "12",
        denominazione: "Fontana Liri",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "06",
        denominazione: "Fontanafredda",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "15",
        denominazione: "Fontanarosa",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "08",
        denominazione: "Fontanelice",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "03",
        denominazione: "Fontanella",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "08",
        denominazione: "Fontanellato",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "05",
        denominazione: "Fontanelle",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "01",
        denominazione: "Fontaneto d'Agogna",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "01",
        denominazione: "Fontanetto Po",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "07",
        denominazione: "Fontanigorda",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "01",
        denominazione: "Fontanile",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "05",
        denominazione: "Fontaniva",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "05",
        denominazione: "Fonte",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "12",
        denominazione: "Fonte Nuova",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "13",
        denominazione: "Fontecchio",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "12",
        denominazione: "Fontechiari",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "15",
        denominazione: "Fontegreca",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "03",
        denominazione: "Fonteno",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "08",
        denominazione: "Fontevivo",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "05",
        denominazione: "Fonzaso",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "03",
        denominazione: "Foppolo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "12",
        denominazione: "Forano",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "11",
        denominazione: "Force",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "15",
        denominazione: "Forchia",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "03",
        denominazione: "Forcola",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "20",
        denominazione: "Fordongianus",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "17",
        denominazione: "Forenza",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "03",
        denominazione: "Foresto Sparso",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "06",
        denominazione: "Forgaria nel Friuli",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "15",
        denominazione: "Forino",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "15",
        denominazione: "Forio",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "08",
        denominazione: "Forlì",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: true,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "14",
        denominazione: "Forlì del Sannio",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "08",
        denominazione: "Forlimpopoli",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "01",
        denominazione: "Formazza",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "12",
        denominazione: "Formello",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "12",
        denominazione: "Formia",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "15",
        denominazione: "Formicola",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "03",
        denominazione: "Formigara",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "08",
        denominazione: "Formigine",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "01",
        denominazione: "Formigliana",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "04",
        denominazione: "Fornace",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "14",
        denominazione: "Fornelli",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "06",
        denominazione: "Forni Avoltri",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "06",
        denominazione: "Forni di Sopra",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "06",
        denominazione: "Forni di Sotto",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "01",
        denominazione: "Forno Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "08",
        denominazione: "Fornovo di Taro",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "03",
        denominazione: "Fornovo San Giovanni",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "09",
        denominazione: "Forte dei Marmi",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "04",
        denominazione: "Fortezza",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "Fortunago",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "19",
        denominazione: "Forza d'Agrò",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "09",
        denominazione: "Fosciandora",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "09",
        denominazione: "Fosdinovo",
        denominazione_provincia: "Massa-Carrara",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "MS"
    },
    {
        codice_regione: "13",
        denominazione: "Fossa",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "13",
        denominazione: "Fossacesia",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "05",
        denominazione: "Fossalta di Piave",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "05",
        denominazione: "Fossalta di Portogruaro",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "14",
        denominazione: "Fossalto",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "01",
        denominazione: "Fossano",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "10",
        denominazione: "Fossato di Vico",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "18",
        denominazione: "Fossato Serralta",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "05",
        denominazione: "Fossò",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "11",
        denominazione: "Fossombrone",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "05",
        denominazione: "Foza",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "01",
        denominazione: "Frabosa Soprana",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Frabosa Sottana",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Fraconalto",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "16",
        denominazione: "Fragagnano",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "15",
        denominazione: "Fragneto l'Abate",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "15",
        denominazione: "Fragneto Monforte",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "13",
        denominazione: "Fraine",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "07",
        denominazione: "Framura",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "13",
        denominazione: "Francavilla al Mare",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "18",
        denominazione: "Francavilla Angitola",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "01",
        denominazione: "Francavilla Bisio",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "11",
        denominazione: "Francavilla d'Ete",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "19",
        denominazione: "Francavilla di Sicilia",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "16",
        denominazione: "Francavilla Fontana",
        denominazione_provincia: "Brindisi",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BR"
    },
    {
        codice_regione: "17",
        denominazione: "Francavilla in Sinni",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "18",
        denominazione: "Francavilla Marittima",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "18",
        denominazione: "Francica",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "19",
        denominazione: "Francofonte",
        denominazione_provincia: "Siracusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "SR"
    },
    {
        codice_regione: "15",
        denominazione: "Francolise",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "01",
        denominazione: "Frascaro",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Frascarolo",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "12",
        denominazione: "Frascati",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "18",
        denominazione: "Frascineto",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "04",
        denominazione: "Frassilongo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "05",
        denominazione: "Frassinelle Polesine",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "01",
        denominazione: "Frassinello Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Frassineto Po",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Frassinetto",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Frassino",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "08",
        denominazione: "Frassinoro",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "12",
        denominazione: "Frasso Sabino",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "15",
        denominazione: "Frasso Telesino",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "05",
        denominazione: "Fratta Polesine",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "10",
        denominazione: "Fratta Todina",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "15",
        denominazione: "Frattamaggiore",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "15",
        denominazione: "Frattaminore",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "11",
        denominazione: "Fratte Rosa",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "19",
        denominazione: "Frazzanò",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "05",
        denominazione: "Fregona",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "13",
        denominazione: "Fresagrandinaria",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "01",
        denominazione: "Fresonara",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "15",
        denominazione: "Frigento",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "15",
        denominazione: "Frignano",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "01",
        denominazione: "Frinco",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "13",
        denominazione: "Frisa",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "06",
        denominazione: "Frisanco",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "01",
        denominazione: "Front",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "11",
        denominazione: "Frontino",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "11",
        denominazione: "Frontone",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "12",
        denominazione: "Frosinone",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: true,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "14",
        denominazione: "Frosolone",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "01",
        denominazione: "Frossasco",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Frugarolo",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Fubine Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "09",
        denominazione: "Fucecchio",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "03",
        denominazione: "Fuipiano Valle Imagna",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Fumane",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "12",
        denominazione: "Fumone",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "04",
        denominazione: "Funes",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "13",
        denominazione: "Furci",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "19",
        denominazione: "Furci Siculo",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "19",
        denominazione: "Furnari",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "15",
        denominazione: "Furore",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "20",
        denominazione: "Furtei",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "18",
        denominazione: "Fuscaldo",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "08",
        denominazione: "Fusignano",
        denominazione_provincia: "Ravenna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RA"
    },
    {
        codice_regione: "03",
        denominazione: "Fusine",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "15",
        denominazione: "Futani",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Gabbioneta-Binanuova",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "01",
        denominazione: "Gabiano",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "11",
        denominazione: "Gabicce Mare",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "02",
        denominazione: "Gaby",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "03",
        denominazione: "Gadesco-Pieve Delmona",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "20",
        denominazione: "Gadoni",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "12",
        denominazione: "Gaeta",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "19",
        denominazione: "Gaggi",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "03",
        denominazione: "Gaggiano",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "08",
        denominazione: "Gaggio Montano",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "01",
        denominazione: "Gaglianico",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "13",
        denominazione: "Gagliano Aterno",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "19",
        denominazione: "Gagliano Castelferrato",
        denominazione_provincia: "Enna",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "EN"
    },
    {
        codice_regione: "16",
        denominazione: "Gagliano del Capo",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "18",
        denominazione: "Gagliato",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "11",
        denominazione: "Gagliole",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "05",
        denominazione: "Gaiarine",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "05",
        denominazione: "Gaiba",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "01",
        denominazione: "Gaiola",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "09",
        denominazione: "Gaiole in Chianti",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "20",
        denominazione: "Gairo",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "04",
        denominazione: "Gais",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "19",
        denominazione: "Galati Mamertino",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "16",
        denominazione: "Galatina",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "16",
        denominazione: "Galatone",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "18",
        denominazione: "Galatro",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "03",
        denominazione: "Galbiate",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "08",
        denominazione: "Galeata",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "03",
        denominazione: "Galgagnano",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "03",
        denominazione: "Gallarate",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "12",
        denominazione: "Gallese",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "01",
        denominazione: "Galliate",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Galliate Lombardo",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Galliavola",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "09",
        denominazione: "Gallicano",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "12",
        denominazione: "Gallicano nel Lazio",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "17",
        denominazione: "Gallicchio",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "08",
        denominazione: "Galliera",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "05",
        denominazione: "Galliera Veneta",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "12",
        denominazione: "Gallinaro",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "05",
        denominazione: "Gallio",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "16",
        denominazione: "Gallipoli",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "15",
        denominazione: "Gallo Matese",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "19",
        denominazione: "Gallodoro",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "15",
        denominazione: "Galluccio",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "20",
        denominazione: "Galtellì",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "05",
        denominazione: "Galzignano Terme",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "01",
        denominazione: "Gamalero",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Gambara",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Gambarana",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Gambasca",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "09",
        denominazione: "Gambassi Terme",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "14",
        denominazione: "Gambatesa",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "05",
        denominazione: "Gambellara",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "13",
        denominazione: "Gamberale",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "08",
        denominazione: "Gambettola",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "03",
        denominazione: "Gambolò",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Gandellino",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Gandino",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Gandosso",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "19",
        denominazione: "Gangi",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "17",
        denominazione: "Garaguso",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "01",
        denominazione: "Garbagna",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Garbagna Novarese",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Garbagnate Milanese",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Garbagnate Monastero",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "05",
        denominazione: "Garda",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "03",
        denominazione: "Gardone Riviera",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Gardone Val Trompia",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Garessio",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Gargallo",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "04",
        denominazione: "Gargazzone",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "Gargnano",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Garlasco",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Garlate",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "07",
        denominazione: "Garlenda",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "04",
        denominazione: "Garniga Terme",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Garzeno",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "01",
        denominazione: "Garzigliana",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "18",
        denominazione: "Gasperina",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "01",
        denominazione: "Gassino Torinese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "08",
        denominazione: "Gattatico",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "08",
        denominazione: "Gatteo",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "01",
        denominazione: "Gattico-Veruno",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "01",
        denominazione: "Gattinara",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "03",
        denominazione: "Gavardo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "05",
        denominazione: "Gavello",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "03",
        denominazione: "Gaverina Terme",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Gavi",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "12",
        denominazione: "Gavignano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "03",
        denominazione: "Gavirate",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "20",
        denominazione: "Gavoi",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "09",
        denominazione: "Gavorrano",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "03",
        denominazione: "Gazoldo degli Ippoliti",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "03",
        denominazione: "Gazzada Schianno",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Gazzaniga",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Gazzo",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "05",
        denominazione: "Gazzo Veronese",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "08",
        denominazione: "Gazzola",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "03",
        denominazione: "Gazzuolo",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "19",
        denominazione: "Gela",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "08",
        denominazione: "Gemmano",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "06",
        denominazione: "Gemona del Friuli",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Gemonio",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "12",
        denominazione: "Genazzano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "11",
        denominazione: "Genga",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "03",
        denominazione: "Genivolta",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "01",
        denominazione: "Genola",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "20",
        denominazione: "Genoni",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "07",
        denominazione: "Genova",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: true,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "20",
        denominazione: "Genuri",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "17",
        denominazione: "Genzano di Lucania",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "12",
        denominazione: "Genzano di Roma",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "03",
        denominazione: "Gera Lario",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "18",
        denominazione: "Gerace",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "19",
        denominazione: "Geraci Siculo",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "12",
        denominazione: "Gerano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "03",
        denominazione: "Gerenzago",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Gerenzano",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "20",
        denominazione: "Gergei",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "01",
        denominazione: "Germagnano",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Germagno",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "03",
        denominazione: "Germignaga",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "18",
        denominazione: "Gerocarne",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "03",
        denominazione: "Gerola Alta",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "03",
        denominazione: "Gerre de' Caprioli",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "20",
        denominazione: "Gesico",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "03",
        denominazione: "Gessate",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "13",
        denominazione: "Gessopalena",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "20",
        denominazione: "Gesturi",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "15",
        denominazione: "Gesualdo",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "03",
        denominazione: "Ghedi",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Ghemme",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "01",
        denominazione: "Ghiffa",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "20",
        denominazione: "Ghilarza",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "03",
        denominazione: "Ghisalba",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Ghislarengo",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "05",
        denominazione: "Giacciano con Baruchella",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "01",
        denominazione: "Giaglione",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Gianico",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "10",
        denominazione: "Giano dell'Umbria",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "15",
        denominazione: "Giano Vetusto",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "19",
        denominazione: "Giardinello",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "19",
        denominazione: "Giardini-Naxos",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "01",
        denominazione: "Giarole",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "19",
        denominazione: "Giarratana",
        denominazione_provincia: "Ragusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "RG"
    },
    {
        codice_regione: "19",
        denominazione: "Giarre",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "20",
        denominazione: "Giave",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "01",
        denominazione: "Giaveno",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "05",
        denominazione: "Giavera del Montello",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "20",
        denominazione: "Giba",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "19",
        denominazione: "Gibellina",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "01",
        denominazione: "Gifflenga",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "18",
        denominazione: "Giffone",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "15",
        denominazione: "Giffoni Sei Casali",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "15",
        denominazione: "Giffoni Valle Piana",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "01",
        denominazione: "Gignese",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "02",
        denominazione: "Gignod",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "14",
        denominazione: "Gildone",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "18",
        denominazione: "Gimigliano",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "17",
        denominazione: "Ginestra",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "15",
        denominazione: "Ginestra degli Schiavoni",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "16",
        denominazione: "Ginosa",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "15",
        denominazione: "Gioi",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "13",
        denominazione: "Gioia dei Marsi",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "16",
        denominazione: "Gioia del Colle",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "15",
        denominazione: "Gioia Sannitica",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "18",
        denominazione: "Gioia Tauro",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "18",
        denominazione: "Gioiosa Ionica",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "19",
        denominazione: "Gioiosa Marea",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "10",
        denominazione: "Giove",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "16",
        denominazione: "Giovinazzo",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "04",
        denominazione: "Giovo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "20",
        denominazione: "Girasole",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "18",
        denominazione: "Girifalco",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "13",
        denominazione: "Gissi",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "16",
        denominazione: "Giuggianello",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "15",
        denominazione: "Giugliano in Campania",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "19",
        denominazione: "Giuliana",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "12",
        denominazione: "Giuliano di Roma",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "13",
        denominazione: "Giuliano Teatino",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "13",
        denominazione: "Giulianova",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "15",
        denominazione: "Giungano",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "16",
        denominazione: "Giurdignano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "03",
        denominazione: "Giussago",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Giussano",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "07",
        denominazione: "Giustenice",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "04",
        denominazione: "Giustino",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "07",
        denominazione: "Giusvalla",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "01",
        denominazione: "Givoletto",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "18",
        denominazione: "Gizzeria",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "04",
        denominazione: "Glorenza",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "05",
        denominazione: "Godega di Sant'Urbano",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Godiasco Salice Terme",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "19",
        denominazione: "Godrano",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "03",
        denominazione: "Goito",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "03",
        denominazione: "Golasecca",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Golferenzo",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "20",
        denominazione: "Golfo Aranci",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Gombito",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "06",
        denominazione: "Gonars",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "20",
        denominazione: "Goni",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Gonnesa",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Gonnoscodina",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "20",
        denominazione: "Gonnosfanadiga",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Gonnosnò",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "20",
        denominazione: "Gonnostramatza",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "03",
        denominazione: "Gonzaga",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "03",
        denominazione: "Gordona",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "12",
        denominazione: "Gorga",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "05",
        denominazione: "Gorgo al Monticano",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "17",
        denominazione: "Gorgoglione",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "03",
        denominazione: "Gorgonzola",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "13",
        denominazione: "Goriano Sicoli",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "06",
        denominazione: "Gorizia",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: true,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "03",
        denominazione: "Gorla Maggiore",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Gorla Minore",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Gorlago",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Gorle",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Gornate Olona",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Gorno",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "08",
        denominazione: "Goro",
        denominazione_provincia: "Ferrara",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FE"
    },
    {
        codice_regione: "07",
        denominazione: "Gorreto",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "01",
        denominazione: "Gorzegno",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "05",
        denominazione: "Gosaldo",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "08",
        denominazione: "Gossolengo",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "01",
        denominazione: "Gottasecca",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Gottolengo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Govone",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Gozzano",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "11",
        denominazione: "Gradara",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "06",
        denominazione: "Gradisca d'Isonzo",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "06",
        denominazione: "Grado",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "12",
        denominazione: "Gradoli",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "03",
        denominazione: "Graffignana",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "12",
        denominazione: "Graffignano",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "01",
        denominazione: "Graglia",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "15",
        denominazione: "Gragnano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "08",
        denominazione: "Gragnano Trebbiense",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "19",
        denominazione: "Grammichele",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "01",
        denominazione: "Grana Monferrato",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "08",
        denominazione: "Granarolo dell'Emilia",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "03",
        denominazione: "Grandate",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Grandola ed Uniti",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "19",
        denominazione: "Graniti",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "01",
        denominazione: "Granozzo con Monticello",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Grantola",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "05",
        denominazione: "Grantorto",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "05",
        denominazione: "Granze",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "17",
        denominazione: "Grassano",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "03",
        denominazione: "Grassobbio",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "19",
        denominazione: "Gratteri",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "03",
        denominazione: "Gravedona ed Uniti",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Gravellona Lomellina",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Gravellona Toce",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "01",
        denominazione: "Gravere",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "19",
        denominazione: "Gravina di Catania",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "16",
        denominazione: "Gravina in Puglia",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "15",
        denominazione: "Grazzanise",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "01",
        denominazione: "Grazzano Badoglio",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "12",
        denominazione: "Greccio",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "15",
        denominazione: "Greci",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "01",
        denominazione: "Greggio",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "01",
        denominazione: "Gremiasco",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "02",
        denominazione: "Gressan",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "02",
        denominazione: "Gressoney-La-Trinité",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "02",
        denominazione: "Gressoney-Saint-Jean",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "09",
        denominazione: "Greve in Chianti",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "03",
        denominazione: "Grezzago",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "05",
        denominazione: "Grezzana",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "03",
        denominazione: "Griante",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "15",
        denominazione: "Gricignano di Aversa",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "01",
        denominazione: "Grignasco",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "04",
        denominazione: "Grigno",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "06",
        denominazione: "Grimacco",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "18",
        denominazione: "Grimaldi",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "01",
        denominazione: "Grinzane Cavour",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "05",
        denominazione: "Grisignano di Zocco",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "18",
        denominazione: "Grisolia",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "08",
        denominazione: "Grizzana Morandi",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "01",
        denominazione: "Grognardo",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Gromo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Grondona",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Grone",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Grontardo",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Gropello Cairoli",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "08",
        denominazione: "Gropparello",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "01",
        denominazione: "Groscavallo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Grosio",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "03",
        denominazione: "Grosotto",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "09",
        denominazione: "Grosseto",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: true,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "01",
        denominazione: "Grosso",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "12",
        denominazione: "Grottaferrata",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "16",
        denominazione: "Grottaglie",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "15",
        denominazione: "Grottaminarda",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "11",
        denominazione: "Grottammare",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "11",
        denominazione: "Grottazzolina",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "19",
        denominazione: "Grotte",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "12",
        denominazione: "Grotte di Castro",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "18",
        denominazione: "Grotteria",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "17",
        denominazione: "Grottole",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "15",
        denominazione: "Grottolella",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "05",
        denominazione: "Gruaro",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "01",
        denominazione: "Grugliasco",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Grumello Cremonese ed Uniti",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Grumello del Monte",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "17",
        denominazione: "Grumento Nova",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "16",
        denominazione: "Grumo Appula",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "15",
        denominazione: "Grumo Nevano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "05",
        denominazione: "Grumolo delle Abbadesse",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "16",
        denominazione: "Guagnano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "11",
        denominazione: "Gualdo",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "10",
        denominazione: "Gualdo Cattaneo",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "10",
        denominazione: "Gualdo Tadino",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "08",
        denominazione: "Gualtieri",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "19",
        denominazione: "Gualtieri Sicaminò",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "20",
        denominazione: "Guamaggiore",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "03",
        denominazione: "Guanzate",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "12",
        denominazione: "Guarcino",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "05",
        denominazione: "Guarda Veneta",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "01",
        denominazione: "Guardabosone",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "03",
        denominazione: "Guardamiglio",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "18",
        denominazione: "Guardavalle",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "10",
        denominazione: "Guardea",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "15",
        denominazione: "Guardia Lombardi",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "17",
        denominazione: "Guardia Perticara",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "18",
        denominazione: "Guardia Piemontese",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "15",
        denominazione: "Guardia Sanframondi",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "13",
        denominazione: "Guardiagrele",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "14",
        denominazione: "Guardialfiera",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "14",
        denominazione: "Guardiaregia",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "09",
        denominazione: "Guardistallo",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "01",
        denominazione: "Guarene",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "20",
        denominazione: "Guasila",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "08",
        denominazione: "Guastalla",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "01",
        denominazione: "Guazzora",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "10",
        denominazione: "Gubbio",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "03",
        denominazione: "Gudo Visconti",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "14",
        denominazione: "Guglionesi",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "03",
        denominazione: "Guidizzolo",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "12",
        denominazione: "Guidonia Montecelio",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "08",
        denominazione: "Guiglia",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "13",
        denominazione: "Guilmi",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "01",
        denominazione: "Gurro",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "20",
        denominazione: "Guspini",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "03",
        denominazione: "Gussago",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Gussola",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "02",
        denominazione: "Hône",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "03",
        denominazione: "Idro",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "20",
        denominazione: "Iglesias",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "01",
        denominazione: "Igliano",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "20",
        denominazione: "Ilbono",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "05",
        denominazione: "Illasi",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "20",
        denominazione: "Illorai",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Imbersago",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "04",
        denominazione: "Imer",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "08",
        denominazione: "Imola",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "07",
        denominazione: "Imperia",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: true,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "09",
        denominazione: "Impruneta",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "03",
        denominazione: "Inarzo",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "01",
        denominazione: "Incisa Scapaccino",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "03",
        denominazione: "Incudine",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Induno Olona",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "01",
        denominazione: "Ingria",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Intragna",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "03",
        denominazione: "Introbio",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "02",
        denominazione: "Introd",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "13",
        denominazione: "Introdacqua",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "03",
        denominazione: "Inverigo",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Inverno e Monteleone",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Inverso Pinasca",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Inveruno",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Invorio",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Inzago",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "18",
        denominazione: "Ionadi",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "20",
        denominazione: "Irgoli",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "03",
        denominazione: "Irma",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "17",
        denominazione: "Irsina",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "01",
        denominazione: "Isasca",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "18",
        denominazione: "Isca sullo Ionio",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "15",
        denominazione: "Ischia",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "12",
        denominazione: "Ischia di Castro",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "16",
        denominazione: "Ischitella",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "03",
        denominazione: "Iseo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "04",
        denominazione: "Isera",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "14",
        denominazione: "Isernia",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: true,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "20",
        denominazione: "Isili",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "19",
        denominazione: "Isnello",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "01",
        denominazione: "Isola d'Asti",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "07",
        denominazione: "Isola del Cantone",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "09",
        denominazione: "Isola del Giglio",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "13",
        denominazione: "Isola del Gran Sasso d'Italia",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "12",
        denominazione: "Isola del Liri",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "11",
        denominazione: "Isola del Piano",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "05",
        denominazione: "Isola della Scala",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "19",
        denominazione: "Isola delle Femmine",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "18",
        denominazione: "Isola di Capo Rizzuto",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "03",
        denominazione: "Isola di Fondra",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Isola Dovarese",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "05",
        denominazione: "Isola Rizza",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "01",
        denominazione: "Isola Sant'Antonio",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "05",
        denominazione: "Isola Vicentina",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "01",
        denominazione: "Isolabella",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "07",
        denominazione: "Isolabona",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "16",
        denominazione: "Isole Tremiti",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "03",
        denominazione: "Isorella",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "15",
        denominazione: "Ispani",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "19",
        denominazione: "Ispica",
        denominazione_provincia: "Ragusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "RG"
    },
    {
        codice_regione: "03",
        denominazione: "Ispra",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "01",
        denominazione: "Issiglio",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "02",
        denominazione: "Issime",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "03",
        denominazione: "Isso",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "02",
        denominazione: "Issogne",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "05",
        denominazione: "Istrana",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "19",
        denominazione: "Itala",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "12",
        denominazione: "Itri",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "20",
        denominazione: "Ittireddu",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "20",
        denominazione: "Ittiri",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "01",
        denominazione: "Ivrea",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Izano",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "18",
        denominazione: "Jacurso",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "14",
        denominazione: "Jelsi",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "12",
        denominazione: "Jenne",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "03",
        denominazione: "Jerago con Orago",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "20",
        denominazione: "Jerzu",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "11",
        denominazione: "Jesi",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "05",
        denominazione: "Jesolo",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "08",
        denominazione: "Jolanda di Savoia",
        denominazione_provincia: "Ferrara",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FE"
    },
    {
        codice_regione: "18",
        denominazione: "Joppolo",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "19",
        denominazione: "Joppolo Giancaxio",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "02",
        denominazione: "Jovençan",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "13",
        denominazione: "L'Aquila",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: true,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "01",
        denominazione: "La Cassa",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "La Loggia",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "20",
        denominazione: "La Maddalena",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "02",
        denominazione: "La Magdeleine",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "01",
        denominazione: "La Morra",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "02",
        denominazione: "La Salle",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "07",
        denominazione: "La Spezia",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: true,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "02",
        denominazione: "La Thuile",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "04",
        denominazione: "La Valle",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "05",
        denominazione: "La Valle Agordina",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "03",
        denominazione: "La Valletta Brianza",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "12",
        denominazione: "Labico",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "12",
        denominazione: "Labro",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "03",
        denominazione: "Lacchiarella",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "15",
        denominazione: "Lacco Ameno",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "15",
        denominazione: "Lacedonia",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "04",
        denominazione: "Laces",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "20",
        denominazione: "Laconi",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "12",
        denominazione: "Ladispoli",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "20",
        denominazione: "Laerru",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "18",
        denominazione: "Laganadi",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "05",
        denominazione: "Laghi",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Laglio",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "01",
        denominazione: "Lagnasco",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "18",
        denominazione: "Lago",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "17",
        denominazione: "Lagonegro",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "08",
        denominazione: "Lagosanto",
        denominazione_provincia: "Ferrara",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FE"
    },
    {
        codice_regione: "04",
        denominazione: "Lagundo",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "07",
        denominazione: "Laigueglia",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "03",
        denominazione: "Lainate",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Laino",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "18",
        denominazione: "Laino Borgo",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "18",
        denominazione: "Laino Castello",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "04",
        denominazione: "Laion",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "04",
        denominazione: "Laives",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "09",
        denominazione: "Lajatico",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "03",
        denominazione: "Lallio",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "13",
        denominazione: "Lama dei Peligni",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "08",
        denominazione: "Lama Mocogno",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "03",
        denominazione: "Lambrugo",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "18",
        denominazione: "Lamezia Terme",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "05",
        denominazione: "Lamon",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "19",
        denominazione: "Lampedusa e Linosa",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "09",
        denominazione: "Lamporecchio",
        denominazione_provincia: "Pistoia",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PT"
    },
    {
        codice_regione: "01",
        denominazione: "Lamporo",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "04",
        denominazione: "Lana",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "13",
        denominazione: "Lanciano",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "01",
        denominazione: "Landiona",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Landriano",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "08",
        denominazione: "Langhirano",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "03",
        denominazione: "Langosco",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "20",
        denominazione: "Lanusei",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "12",
        denominazione: "Lanuvio",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "03",
        denominazione: "Lanzada",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "01",
        denominazione: "Lanzo Torinese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "11",
        denominazione: "Lapedona",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "15",
        denominazione: "Lapio",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "18",
        denominazione: "Lappano",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "09",
        denominazione: "Larciano",
        denominazione_provincia: "Pistoia",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PT"
    },
    {
        codice_regione: "03",
        denominazione: "Lardirago",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "12",
        denominazione: "Lariano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "14",
        denominazione: "Larino",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "20",
        denominazione: "Las Plassas",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "04",
        denominazione: "Lasa",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "19",
        denominazione: "Lascari",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "03",
        denominazione: "Lasnigo",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "05",
        denominazione: "Lastebasse",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "09",
        denominazione: "Lastra a Signa",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "12",
        denominazione: "Latera",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "09",
        denominazione: "Laterina Pergine Valdarno",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "16",
        denominazione: "Laterza",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "16",
        denominazione: "Latiano",
        denominazione_provincia: "Brindisi",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BR"
    },
    {
        codice_regione: "12",
        denominazione: "Latina",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: true,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "06",
        denominazione: "Latisana",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "17",
        denominazione: "Latronico",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "18",
        denominazione: "Lattarico",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "06",
        denominazione: "Lauco",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "15",
        denominazione: "Laureana Cilento",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "18",
        denominazione: "Laureana di Borrello",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "04",
        denominazione: "Lauregno",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "17",
        denominazione: "Laurenzana",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "17",
        denominazione: "Lauria",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "01",
        denominazione: "Lauriano",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "Laurino",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "15",
        denominazione: "Laurito",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "15",
        denominazione: "Lauro",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "07",
        denominazione: "Lavagna",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "05",
        denominazione: "Lavagno",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "04",
        denominazione: "Lavarone",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "17",
        denominazione: "Lavello",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "03",
        denominazione: "Lavena Ponte Tresa",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Laveno-Mombello",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Lavenone",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "15",
        denominazione: "Laviano",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "04",
        denominazione: "Lavis",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "05",
        denominazione: "Lazise",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "03",
        denominazione: "Lazzate",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "16",
        denominazione: "Lecce",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: true,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "13",
        denominazione: "Lecce nei Marsi",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "03",
        denominazione: "Lecco",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: true,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "04",
        denominazione: "Ledro",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Leffe",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Leggiuno",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "05",
        denominazione: "Legnago",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "03",
        denominazione: "Legnano",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "05",
        denominazione: "Legnaro",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "20",
        denominazione: "Lei",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "01",
        denominazione: "Leini",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "07",
        denominazione: "Leivi",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "01",
        denominazione: "Lemie",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "05",
        denominazione: "Lendinara",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "19",
        denominazione: "Leni",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "03",
        denominazione: "Lenna",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Leno",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "12",
        denominazione: "Lenola",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "01",
        denominazione: "Lenta",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "03",
        denominazione: "Lentate sul Seveso",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "13",
        denominazione: "Lentella",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "19",
        denominazione: "Lentini",
        denominazione_provincia: "Siracusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "SR"
    },
    {
        codice_regione: "12",
        denominazione: "Leonessa",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "19",
        denominazione: "Leonforte",
        denominazione_provincia: "Enna",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "EN"
    },
    {
        codice_regione: "16",
        denominazione: "Leporano",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "16",
        denominazione: "Lequile",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "01",
        denominazione: "Lequio Berria",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Lequio Tanaro",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "19",
        denominazione: "Lercara Friddi",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "07",
        denominazione: "Lerici",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "01",
        denominazione: "Lerma",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Lesa",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "01",
        denominazione: "Lesegno",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "08",
        denominazione: "Lesignano de' Bagni",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "16",
        denominazione: "Lesina",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "03",
        denominazione: "Lesmo",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "01",
        denominazione: "Lessolo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Lessona",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "06",
        denominazione: "Lestizza",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "15",
        denominazione: "Letino",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "19",
        denominazione: "Letojanni",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "15",
        denominazione: "Lettere",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "13",
        denominazione: "Lettomanoppello",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "13",
        denominazione: "Lettopalena",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "07",
        denominazione: "Levanto",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "03",
        denominazione: "Levate",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "16",
        denominazione: "Leverano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "01",
        denominazione: "Levice",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "04",
        denominazione: "Levico Terme",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Levone",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Lezzeno",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "15",
        denominazione: "Liberi",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "19",
        denominazione: "Librizzi",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "19",
        denominazione: "Licata",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "09",
        denominazione: "Licciana Nardi",
        denominazione_provincia: "Massa-Carrara",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "MS"
    },
    {
        codice_regione: "12",
        denominazione: "Licenza",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "19",
        denominazione: "Licodia Eubea",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "03",
        denominazione: "Lierna",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "01",
        denominazione: "Lignana",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "06",
        denominazione: "Lignano Sabbiadoro",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "02",
        denominazione: "Lillianes",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "05",
        denominazione: "Limana",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "15",
        denominazione: "Limatola",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "18",
        denominazione: "Limbadi",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "03",
        denominazione: "Limbiate",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "05",
        denominazione: "Limena",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Limido Comasco",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "19",
        denominazione: "Limina",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "01",
        denominazione: "Limone Piemonte",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Limone sul Garda",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "14",
        denominazione: "Limosano",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "03",
        denominazione: "Linarolo",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "19",
        denominazione: "Linguaglossa",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "15",
        denominazione: "Lioni",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "19",
        denominazione: "Lipari",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "03",
        denominazione: "Lipomo",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Lirio",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Liscate",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "13",
        denominazione: "Liscia",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "10",
        denominazione: "Lisciano Niccone",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "01",
        denominazione: "Lisio",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Lissone",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "15",
        denominazione: "Liveri",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "03",
        denominazione: "Livigno",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "05",
        denominazione: "Livinallongo del Col di Lana",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "03",
        denominazione: "Livo",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "04",
        denominazione: "Livo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "09",
        denominazione: "Livorno",
        denominazione_provincia: "Livorno",
        denominazione_regione: "Toscana",
        flag_capoluogo: true,
        sigla_provincia: "LI"
    },
    {
        codice_regione: "01",
        denominazione: "Livorno Ferraris",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "03",
        denominazione: "Livraga",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "16",
        denominazione: "Lizzanello",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "16",
        denominazione: "Lizzano",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "08",
        denominazione: "Lizzano in Belvedere",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "07",
        denominazione: "Loano",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "01",
        denominazione: "Loazzolo",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Locana",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Locate di Triulzi",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Locate Varesino",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Locatello",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "20",
        denominazione: "Loceri",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "16",
        denominazione: "Locorotondo",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "18",
        denominazione: "Locri",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "20",
        denominazione: "Loculi",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "20",
        denominazione: "Lodè",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "03",
        denominazione: "Lodi",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: true,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "03",
        denominazione: "Lodi Vecchio",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "20",
        denominazione: "Lodine",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "03",
        denominazione: "Lodrino",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Lograto",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "08",
        denominazione: "Loiano",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "20",
        denominazione: "Loiri Porto San Paolo",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Lomagna",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Lomazzo",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "01",
        denominazione: "Lombardore",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Lombriasco",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Lomello",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "04",
        denominazione: "Lona-Lases",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Lonate Ceppino",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Lonate Pozzolo",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Lonato del Garda",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "09",
        denominazione: "Londa",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "14",
        denominazione: "Longano",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "05",
        denominazione: "Longare",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "05",
        denominazione: "Longarone",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "03",
        denominazione: "Longhena",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "19",
        denominazione: "Longi",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "08",
        denominazione: "Longiano",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "18",
        denominazione: "Longobardi",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "18",
        denominazione: "Longobucco",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Longone al Segrino",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "12",
        denominazione: "Longone Sabino",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "05",
        denominazione: "Lonigo",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "01",
        denominazione: "Loranzè",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "05",
        denominazione: "Loreggia",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "01",
        denominazione: "Loreglia",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "05",
        denominazione: "Lorenzago di Cadore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "05",
        denominazione: "Loreo",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "11",
        denominazione: "Loreto",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "13",
        denominazione: "Loreto Aprutino",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "05",
        denominazione: "Loria",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "09",
        denominazione: "Loro Ciuffenna",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "11",
        denominazione: "Loro Piceno",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "07",
        denominazione: "Lorsica",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "03",
        denominazione: "Losine",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "20",
        denominazione: "Lotzorai",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "03",
        denominazione: "Lovere",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Lovero",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "03",
        denominazione: "Lozio",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Lozza",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "05",
        denominazione: "Lozzo Atestino",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "05",
        denominazione: "Lozzo di Cadore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "01",
        denominazione: "Lozzolo",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "01",
        denominazione: "Lu e Cuccaro Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "12",
        denominazione: "Lubriano",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "09",
        denominazione: "Lucca",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: true,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "19",
        denominazione: "Lucca Sicula",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "16",
        denominazione: "Lucera",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "09",
        denominazione: "Lucignano",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "07",
        denominazione: "Lucinasco",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "14",
        denominazione: "Lucito",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "13",
        denominazione: "Luco dei Marsi",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "13",
        denominazione: "Lucoli",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "08",
        denominazione: "Lugagnano Val d'Arda",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "10",
        denominazione: "Lugnano in Teverina",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "08",
        denominazione: "Lugo",
        denominazione_provincia: "Ravenna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RA"
    },
    {
        codice_regione: "05",
        denominazione: "Lugo di Vicenza",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Luino",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Luisago",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "20",
        denominazione: "Lula",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "07",
        denominazione: "Lumarzo",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "03",
        denominazione: "Lumezzane",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "20",
        denominazione: "Lunamatrona",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "11",
        denominazione: "Lunano",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "03",
        denominazione: "Lungavilla",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "18",
        denominazione: "Lungro",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "07",
        denominazione: "Luni",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "15",
        denominazione: "Luogosano",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "20",
        denominazione: "Luogosanto",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "14",
        denominazione: "Lupara",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "03",
        denominazione: "Lurago d'Erba",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Lurago Marinone",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Lurano",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "20",
        denominazione: "Luras",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Lurate Caccivio",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "15",
        denominazione: "Lusciano",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "04",
        denominazione: "Luserna",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Luserna San Giovanni",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Lusernetta",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "06",
        denominazione: "Lusevera",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "05",
        denominazione: "Lusia",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "05",
        denominazione: "Lusiana Conco",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "01",
        denominazione: "Lusigliè",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "04",
        denominazione: "Luson",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "15",
        denominazione: "Lustra",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Luvinate",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Luzzana",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "08",
        denominazione: "Luzzara",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "18",
        denominazione: "Luzzi",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Maccagno con Pino e Veddasca",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Maccastorna",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "14",
        denominazione: "Macchia d'Isernia",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "14",
        denominazione: "Macchia Valfortore",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "14",
        denominazione: "Macchiagodena",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "01",
        denominazione: "Macello",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "11",
        denominazione: "Macerata",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: true,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "15",
        denominazione: "Macerata Campania",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "11",
        denominazione: "Macerata Feltria",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "03",
        denominazione: "Macherio",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "03",
        denominazione: "Maclodio",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "20",
        denominazione: "Macomer",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "01",
        denominazione: "Macra",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Macugnaga",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "15",
        denominazione: "Maddaloni",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "03",
        denominazione: "Madesimo",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "03",
        denominazione: "Madignano",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Madone",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Madonna del Sasso",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "04",
        denominazione: "Madruzzo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "12",
        denominazione: "Maenza",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "14",
        denominazione: "Mafalda",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "03",
        denominazione: "Magasa",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Magenta",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Maggiora",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Magherno",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "10",
        denominazione: "Magione",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "18",
        denominazione: "Magisano",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "01",
        denominazione: "Magliano Alfieri",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Magliano Alpi",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "13",
        denominazione: "Magliano de' Marsi",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "11",
        denominazione: "Magliano di Tenna",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "09",
        denominazione: "Magliano in Toscana",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "12",
        denominazione: "Magliano Romano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "12",
        denominazione: "Magliano Sabina",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "15",
        denominazione: "Magliano Vetere",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "16",
        denominazione: "Maglie",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "07",
        denominazione: "Magliolo",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "01",
        denominazione: "Maglione",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Magnacavallo",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "03",
        denominazione: "Magnago",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Magnano",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "06",
        denominazione: "Magnano in Riviera",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "20",
        denominazione: "Magomadas",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "04",
        denominazione: "Magrè sulla strada del vino",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "Magreglio",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "18",
        denominazione: "Maida",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "18",
        denominazione: "Maierà",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "18",
        denominazione: "Maierato",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "11",
        denominazione: "Maiolati Spontini",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "08",
        denominazione: "Maiolo",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "15",
        denominazione: "Maiori",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Mairago",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "03",
        denominazione: "Mairano",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "07",
        denominazione: "Maissana",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "06",
        denominazione: "Majano",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Malagnino",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "08",
        denominazione: "Malalbergo",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "06",
        denominazione: "Malborghetto Valbruna",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "05",
        denominazione: "Malcesine",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "04",
        denominazione: "Malé",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Malegno",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Maleo",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "01",
        denominazione: "Malesco",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "19",
        denominazione: "Maletto",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "19",
        denominazione: "Malfa",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "03",
        denominazione: "Malgrate",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "18",
        denominazione: "Malito",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "07",
        denominazione: "Mallare",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "04",
        denominazione: "Malles Venosta",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "Malnate",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "05",
        denominazione: "Malo",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Malonno",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "11",
        denominazione: "Maltignano",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "19",
        denominazione: "Malvagna",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "01",
        denominazione: "Malvicino",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "18",
        denominazione: "Malvito",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "18",
        denominazione: "Mammola",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "20",
        denominazione: "Mamoiada",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "09",
        denominazione: "Manciano",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "19",
        denominazione: "Mandanici",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "20",
        denominazione: "Mandas",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "18",
        denominazione: "Mandatoriccio",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "12",
        denominazione: "Mandela",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "03",
        denominazione: "Mandello del Lario",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "01",
        denominazione: "Mandello Vitta",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "16",
        denominazione: "Manduria",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "03",
        denominazione: "Manerba del Garda",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Manerbio",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "16",
        denominazione: "Manfredonia",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "01",
        denominazione: "Mango",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "18",
        denominazione: "Mangone",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "19",
        denominazione: "Maniace",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "06",
        denominazione: "Maniago",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "15",
        denominazione: "Manocalzati",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "13",
        denominazione: "Manoppello",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "05",
        denominazione: "Mansuè",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "01",
        denominazione: "Manta",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Mantello",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "03",
        denominazione: "Mantova",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: true,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "06",
        denominazione: "Manzano",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "12",
        denominazione: "Manziana",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "03",
        denominazione: "Mapello",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Mappano",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "20",
        denominazione: "Mara",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "20",
        denominazione: "Maracalagonis",
        denominazione_provincia: "Cagliari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "CA"
    },
    {
        codice_regione: "08",
        denominazione: "Maranello",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "15",
        denominazione: "Marano di Napoli",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "05",
        denominazione: "Marano di Valpolicella",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "12",
        denominazione: "Marano Equo",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "06",
        denominazione: "Marano Lagunare",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "18",
        denominazione: "Marano Marchesato",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "18",
        denominazione: "Marano Principato",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "08",
        denominazione: "Marano sul Panaro",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "01",
        denominazione: "Marano Ticino",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "05",
        denominazione: "Marano Vicentino",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "01",
        denominazione: "Maranzana",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "17",
        denominazione: "Maratea",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "03",
        denominazione: "Marcallo con Casone",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Marcaria",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "18",
        denominazione: "Marcedusa",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "12",
        denominazione: "Marcellina",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "18",
        denominazione: "Marcellinara",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "12",
        denominazione: "Marcetelli",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "03",
        denominazione: "Marcheno",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Marchirolo",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "09",
        denominazione: "Marciana",
        denominazione_provincia: "Livorno",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LI"
    },
    {
        codice_regione: "09",
        denominazione: "Marciana Marina",
        denominazione_provincia: "Livorno",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LI"
    },
    {
        codice_regione: "15",
        denominazione: "Marcianise",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "09",
        denominazione: "Marciano della Chiana",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "03",
        denominazione: "Marcignago",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "05",
        denominazione: "Marcon",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "04",
        denominazione: "Marebbe",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "01",
        denominazione: "Marene",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "05",
        denominazione: "Mareno di Piave",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "01",
        denominazione: "Marentino",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Maretto",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Margarita",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "16",
        denominazione: "Margherita di Savoia",
        denominazione_provincia: "Barletta-Andria-Trani",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BT"
    },
    {
        codice_regione: "03",
        denominazione: "Margno",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Mariana Mantovana",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "03",
        denominazione: "Mariano Comense",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "06",
        denominazione: "Mariano del Friuli",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "19",
        denominazione: "Marianopoli",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "15",
        denominazione: "Mariglianella",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "15",
        denominazione: "Marigliano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "18",
        denominazione: "Marina di Gioiosa Ionica",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "19",
        denominazione: "Marineo",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "12",
        denominazione: "Marino",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "04",
        denominazione: "Marlengo",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "09",
        denominazione: "Marliana",
        denominazione_provincia: "Pistoia",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PT"
    },
    {
        codice_regione: "03",
        denominazione: "Marmentino",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Marmirolo",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "01",
        denominazione: "Marmora",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Marnate",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Marone",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "18",
        denominazione: "Maropati",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "05",
        denominazione: "Marostica",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "09",
        denominazione: "Marradi",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "20",
        denominazione: "Marrubiu",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "01",
        denominazione: "Marsaglia",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "19",
        denominazione: "Marsala",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "10",
        denominazione: "Marsciano",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "17",
        denominazione: "Marsico Nuovo",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "17",
        denominazione: "Marsicovetere",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "12",
        denominazione: "Marta",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "16",
        denominazione: "Martano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "05",
        denominazione: "Martellago",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "04",
        denominazione: "Martello",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "06",
        denominazione: "Martignacco",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Martignana di Po",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "16",
        denominazione: "Martignano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "16",
        denominazione: "Martina Franca",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "03",
        denominazione: "Martinengo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Martiniana Po",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "13",
        denominazione: "Martinsicuro",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "18",
        denominazione: "Martirano",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "18",
        denominazione: "Martirano Lombardo",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "20",
        denominazione: "Martis",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "18",
        denominazione: "Martone",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "03",
        denominazione: "Marudo",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "16",
        denominazione: "Maruggio",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "08",
        denominazione: "Marzabotto",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "03",
        denominazione: "Marzano",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "15",
        denominazione: "Marzano Appio",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "15",
        denominazione: "Marzano di Nola",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "18",
        denominazione: "Marzi",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Marzio",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "20",
        denominazione: "Masainas",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "03",
        denominazione: "Masate",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "19",
        denominazione: "Mascali",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "19",
        denominazione: "Mascalucia",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "17",
        denominazione: "Maschito",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "03",
        denominazione: "Masciago Primo",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "05",
        denominazione: "Maser",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "01",
        denominazione: "Masera",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "05",
        denominazione: "Maserà di Padova",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "05",
        denominazione: "Maserada sul Piave",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "05",
        denominazione: "Masi",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "08",
        denominazione: "Masi Torello",
        denominazione_provincia: "Ferrara",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FE"
    },
    {
        codice_regione: "01",
        denominazione: "Masio",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Maslianico",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "07",
        denominazione: "Masone",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "09",
        denominazione: "Massa",
        denominazione_provincia: "Massa-Carrara",
        denominazione_regione: "Toscana",
        flag_capoluogo: true,
        sigla_provincia: "MS"
    },
    {
        codice_regione: "13",
        denominazione: "Massa d'Albe",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "15",
        denominazione: "Massa di Somma",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "09",
        denominazione: "Massa e Cozzile",
        denominazione_provincia: "Pistoia",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PT"
    },
    {
        codice_regione: "11",
        denominazione: "Massa Fermana",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "08",
        denominazione: "Massa Lombarda",
        denominazione_provincia: "Ravenna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RA"
    },
    {
        codice_regione: "15",
        denominazione: "Massa Lubrense",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "09",
        denominazione: "Massa Marittima",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "10",
        denominazione: "Massa Martana",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "16",
        denominazione: "Massafra",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "03",
        denominazione: "Massalengo",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "05",
        denominazione: "Massanzago",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "09",
        denominazione: "Massarosa",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "01",
        denominazione: "Massazza",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "01",
        denominazione: "Massello",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Masserano",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "11",
        denominazione: "Massignano",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "04",
        denominazione: "Massimeno",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "07",
        denominazione: "Massimino",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "01",
        denominazione: "Massino Visconti",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "01",
        denominazione: "Massiola",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "20",
        denominazione: "Masullas",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "11",
        denominazione: "Matelica",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "17",
        denominazione: "Matera",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: true,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "01",
        denominazione: "Mathi",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "16",
        denominazione: "Matino",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "14",
        denominazione: "Matrice",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "01",
        denominazione: "Mattie",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "16",
        denominazione: "Mattinata",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "19",
        denominazione: "Mazara del Vallo",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "03",
        denominazione: "Mazzano",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "12",
        denominazione: "Mazzano Romano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "19",
        denominazione: "Mazzarino",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "19",
        denominazione: "Mazzarrà Sant'Andrea",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "19",
        denominazione: "Mazzarrone",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "01",
        denominazione: "Mazzè",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "04",
        denominazione: "Mazzin",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Mazzo di Valtellina",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "01",
        denominazione: "Meana di Susa",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "20",
        denominazione: "Meana Sardo",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "03",
        denominazione: "Meda",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "03",
        denominazione: "Mede",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "06",
        denominazione: "Medea",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "08",
        denominazione: "Medesano",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "08",
        denominazione: "Medicina",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "03",
        denominazione: "Mediglia",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Medolago",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Medole",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "08",
        denominazione: "Medolla",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "05",
        denominazione: "Meduna di Livenza",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "06",
        denominazione: "Meduno",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "05",
        denominazione: "Megliadino San Vitale",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "01",
        denominazione: "Meina",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "05",
        denominazione: "Melara",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "01",
        denominazione: "Melazzo",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "08",
        denominazione: "Meldola",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "07",
        denominazione: "Mele",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "03",
        denominazione: "Melegnano",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "16",
        denominazione: "Melendugno",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "03",
        denominazione: "Meleti",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "17",
        denominazione: "Melfi",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "18",
        denominazione: "Melicuccà",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "18",
        denominazione: "Melicucco",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "19",
        denominazione: "Melilli",
        denominazione_provincia: "Siracusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "SR"
    },
    {
        codice_regione: "18",
        denominazione: "Melissa",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "16",
        denominazione: "Melissano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "15",
        denominazione: "Melito di Napoli",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "18",
        denominazione: "Melito di Porto Salvo",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "15",
        denominazione: "Melito Irpino",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "15",
        denominazione: "Melizzano",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "01",
        denominazione: "Melle",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Mello",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "16",
        denominazione: "Melpignano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "04",
        denominazione: "Meltina",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "Melzo",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Menaggio",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Menconico",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "07",
        denominazione: "Mendatica",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "18",
        denominazione: "Mendicino",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "19",
        denominazione: "Menfi",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "12",
        denominazione: "Mentana",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "05",
        denominazione: "Meolo",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "01",
        denominazione: "Merana",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "04",
        denominazione: "Merano",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "Merate",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Mercallo",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "11",
        denominazione: "Mercatello sul Metauro",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "11",
        denominazione: "Mercatino Conca",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "15",
        denominazione: "Mercato San Severino",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "08",
        denominazione: "Mercato Saraceno",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "01",
        denominazione: "Mercenasco",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "Mercogliano",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "06",
        denominazione: "Mereto di Tomba",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "11",
        denominazione: "Mergo",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "01",
        denominazione: "Mergozzo",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "19",
        denominazione: "Merì",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "05",
        denominazione: "Merlara",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Merlino",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "03",
        denominazione: "Merone",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "16",
        denominazione: "Mesagne",
        denominazione_provincia: "Brindisi",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BR"
    },
    {
        codice_regione: "03",
        denominazione: "Mese",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "03",
        denominazione: "Mesenzana",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Mesero",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "08",
        denominazione: "Mesola",
        denominazione_provincia: "Ferrara",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FE"
    },
    {
        codice_regione: "18",
        denominazione: "Mesoraca",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "19",
        denominazione: "Messina",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: true,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "05",
        denominazione: "Mestrino",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "15",
        denominazione: "Meta",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "03",
        denominazione: "Mezzago",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "04",
        denominazione: "Mezzana",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Mezzana Bigli",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Mezzana Mortigliengo",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "03",
        denominazione: "Mezzana Rabattone",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "05",
        denominazione: "Mezzane di Sotto",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "07",
        denominazione: "Mezzanego",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "03",
        denominazione: "Mezzanino",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "04",
        denominazione: "Mezzano",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Mezzenile",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "04",
        denominazione: "Mezzocorona",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "19",
        denominazione: "Mezzojuso",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "03",
        denominazione: "Mezzoldo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "04",
        denominazione: "Mezzolombardo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Mezzomerico",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "01",
        denominazione: "Miagliano",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "05",
        denominazione: "Miane",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "01",
        denominazione: "Miasino",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "01",
        denominazione: "Miazzina",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "12",
        denominazione: "Micigliano",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "16",
        denominazione: "Miggiano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "13",
        denominazione: "Miglianico",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "18",
        denominazione: "Miglierina",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "17",
        denominazione: "Miglionico",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "07",
        denominazione: "Mignanego",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "15",
        denominazione: "Mignano Monte Lungo",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "03",
        denominazione: "Milano",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: true,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "19",
        denominazione: "Milazzo",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "19",
        denominazione: "Milena",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "18",
        denominazione: "Mileto",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "20",
        denominazione: "Milis",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "19",
        denominazione: "Militello in Val di Catania",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "19",
        denominazione: "Militello Rosmarino",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "07",
        denominazione: "Millesimo",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "19",
        denominazione: "Milo",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "03",
        denominazione: "Milzano",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "19",
        denominazione: "Mineo",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "05",
        denominazione: "Minerbe",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "08",
        denominazione: "Minerbio",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "16",
        denominazione: "Minervino di Lecce",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "16",
        denominazione: "Minervino Murge",
        denominazione_provincia: "Barletta-Andria-Trani",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BT"
    },
    {
        codice_regione: "15",
        denominazione: "Minori",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "12",
        denominazione: "Minturno",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "09",
        denominazione: "Minucciano",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "07",
        denominazione: "Mioglia",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "05",
        denominazione: "Mira",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "15",
        denominazione: "Mirabella Eclano",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "19",
        denominazione: "Mirabella Imbaccari",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "01",
        denominazione: "Mirabello Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "14",
        denominazione: "Mirabello Sannitico",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "03",
        denominazione: "Miradolo Terme",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "14",
        denominazione: "Miranda",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "08",
        denominazione: "Mirandola",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "05",
        denominazione: "Mirano",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "19",
        denominazione: "Mirto",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "08",
        denominazione: "Misano Adriatico",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "03",
        denominazione: "Misano di Gera d'Adda",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "19",
        denominazione: "Misiliscemi",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "19",
        denominazione: "Misilmeri",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "03",
        denominazione: "Misinto",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "03",
        denominazione: "Missaglia",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "17",
        denominazione: "Missanello",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "19",
        denominazione: "Misterbianco",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "19",
        denominazione: "Mistretta",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "01",
        denominazione: "Moasca",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "07",
        denominazione: "Moconesi",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "08",
        denominazione: "Modena",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: true,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "19",
        denominazione: "Modica",
        denominazione_provincia: "Ragusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "RG"
    },
    {
        codice_regione: "08",
        denominazione: "Modigliana",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "20",
        denominazione: "Modolo",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "16",
        denominazione: "Modugno",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "04",
        denominazione: "Moena",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Moggio",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "06",
        denominazione: "Moggio Udinese",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Moglia",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "11",
        denominazione: "Mogliano",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "05",
        denominazione: "Mogliano Veneto",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "20",
        denominazione: "Mogorella",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "20",
        denominazione: "Mogoro",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "15",
        denominazione: "Moiano",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "06",
        denominazione: "Moimacco",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "19",
        denominazione: "Moio Alcantara",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "03",
        denominazione: "Moio de' Calvi",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "15",
        denominazione: "Moio della Civitella",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "01",
        denominazione: "Moiola",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "16",
        denominazione: "Mola di Bari",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "01",
        denominazione: "Molare",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "09",
        denominazione: "Molazzana",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "16",
        denominazione: "Molfetta",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "13",
        denominazione: "Molina Aterno",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "15",
        denominazione: "Molinara",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "08",
        denominazione: "Molinella",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "07",
        denominazione: "Molini di Triora",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "01",
        denominazione: "Molino dei Torti",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "14",
        denominazione: "Molise",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "17",
        denominazione: "Moliterno",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "01",
        denominazione: "Mollia",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "18",
        denominazione: "Molochio",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "03",
        denominazione: "Molteno",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Moltrasio",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "04",
        denominazione: "Molveno",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Mombaldone",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Mombarcaro",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "11",
        denominazione: "Mombaroccio",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "01",
        denominazione: "Mombaruzzo",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Mombasiglio",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Mombello di Torino",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Mombello Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Mombercelli",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Momo",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "01",
        denominazione: "Mompantero",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "12",
        denominazione: "Mompeo",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "01",
        denominazione: "Momperone",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "14",
        denominazione: "Monacilioni",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "01",
        denominazione: "Monale",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "18",
        denominazione: "Monasterace",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "01",
        denominazione: "Monastero Bormida",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Monastero di Lanzo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Monastero di Vasco",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Monasterolo Casotto",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Monasterolo del Castello",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Monasterolo di Savigliano",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "05",
        denominazione: "Monastier di Treviso",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "20",
        denominazione: "Monastir",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "01",
        denominazione: "Moncalieri",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Moncalvo",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Moncenisio",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Moncestino",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Monchiero",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "08",
        denominazione: "Monchio delle Corti",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "01",
        denominazione: "Moncrivello",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "01",
        denominazione: "Moncucco Torinese",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "08",
        denominazione: "Mondaino",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "11",
        denominazione: "Mondavio",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "11",
        denominazione: "Mondolfo",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "01",
        denominazione: "Mondovì",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "15",
        denominazione: "Mondragone",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "07",
        denominazione: "Moneglia",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "01",
        denominazione: "Monesiglio",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "06",
        denominazione: "Monfalcone",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "01",
        denominazione: "Monforte d'Alba",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "19",
        denominazione: "Monforte San Giorgio",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "05",
        denominazione: "Monfumo",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "01",
        denominazione: "Mongardino",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "08",
        denominazione: "Monghidoro",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "18",
        denominazione: "Mongiana",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "01",
        denominazione: "Mongiardino Ligure",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "19",
        denominazione: "Mongiuffi Melia",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "01",
        denominazione: "Mongrando",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "18",
        denominazione: "Mongrassano",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "04",
        denominazione: "Monguelfo-Tesido",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "Monguzzo",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Moniga del Garda",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Monleale",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Monno",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "16",
        denominazione: "Monopoli",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "19",
        denominazione: "Monreale",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "06",
        denominazione: "Monrupino",
        denominazione_provincia: "Trieste",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "TS"
    },
    {
        codice_regione: "11",
        denominazione: "Monsampietro Morico",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "11",
        denominazione: "Monsampolo del Tronto",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "11",
        denominazione: "Monsano",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "05",
        denominazione: "Monselice",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "20",
        denominazione: "Monserrato",
        denominazione_provincia: "Cagliari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "CA"
    },
    {
        codice_regione: "09",
        denominazione: "Monsummano Terme",
        denominazione_provincia: "Pistoia",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PT"
    },
    {
        codice_regione: "01",
        denominazione: "Montà",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Montabone",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Montacuto",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Montafia",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "14",
        denominazione: "Montagano",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "03",
        denominazione: "Montagna in Valtellina",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "04",
        denominazione: "Montagna sulla Strada del Vino",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "05",
        denominazione: "Montagnana",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "19",
        denominazione: "Montagnareale",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "15",
        denominazione: "Montaguto",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "09",
        denominazione: "Montaione",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "19",
        denominazione: "Montalbano Elicona",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "17",
        denominazione: "Montalbano Jonico",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "09",
        denominazione: "Montalcino",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "01",
        denominazione: "Montaldeo",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Montaldo Bormida",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Montaldo di Mondovì",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Montaldo Roero",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Montaldo Scarampi",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Montaldo Torinese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "09",
        denominazione: "Montale",
        denominazione_provincia: "Pistoia",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PT"
    },
    {
        codice_regione: "01",
        denominazione: "Montalenghe",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "19",
        denominazione: "Montallegro",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "07",
        denominazione: "Montalto Carpasio",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "11",
        denominazione: "Montalto delle Marche",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "12",
        denominazione: "Montalto di Castro",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "01",
        denominazione: "Montalto Dora",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Montalto Pavese",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "18",
        denominazione: "Montalto Uffugo",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "01",
        denominazione: "Montanaro",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Montanaso Lombardo",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "01",
        denominazione: "Montanera",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "15",
        denominazione: "Montano Antilia",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Montano Lucino",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "11",
        denominazione: "Montappone",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "14",
        denominazione: "Montaquila",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "12",
        denominazione: "Montasola",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "18",
        denominazione: "Montauro",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "13",
        denominazione: "Montazzoli",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "09",
        denominazione: "Monte Argentario",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "10",
        denominazione: "Monte Castello di Vibio",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "11",
        denominazione: "Monte Cavallo",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "11",
        denominazione: "Monte Cerignone",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "12",
        denominazione: "Monte Compatri",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "03",
        denominazione: "Monte Cremasco",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "05",
        denominazione: "Monte di Malo",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "15",
        denominazione: "Monte di Procida",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "11",
        denominazione: "Monte Giberto",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "11",
        denominazione: "Monte Grimano Terme",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "03",
        denominazione: "Monte Isola",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Monte Marenzo",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "11",
        denominazione: "Monte Porzio",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "12",
        denominazione: "Monte Porzio Catone",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "11",
        denominazione: "Monte Rinaldo",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "11",
        denominazione: "Monte Roberto",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "12",
        denominazione: "Monte Romano",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "12",
        denominazione: "Monte San Biagio",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "15",
        denominazione: "Monte San Giacomo",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "12",
        denominazione: "Monte San Giovanni Campano",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "12",
        denominazione: "Monte San Giovanni in Sabina",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "11",
        denominazione: "Monte San Giusto",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "11",
        denominazione: "Monte San Martino",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "11",
        denominazione: "Monte San Pietrangeli",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "08",
        denominazione: "Monte San Pietro",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "09",
        denominazione: "Monte San Savino",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "11",
        denominazione: "Monte San Vito",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "16",
        denominazione: "Monte Sant'Angelo",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "10",
        denominazione: "Monte Santa Maria Tiberina",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "11",
        denominazione: "Monte Urano",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "11",
        denominazione: "Monte Vidon Combatte",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "11",
        denominazione: "Monte Vidon Corrado",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "03",
        denominazione: "Montebello della Battaglia",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "13",
        denominazione: "Montebello di Bertona",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "18",
        denominazione: "Montebello Jonico",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "13",
        denominazione: "Montebello sul Sangro",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "05",
        denominazione: "Montebello Vicentino",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "05",
        denominazione: "Montebelluna",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "07",
        denominazione: "Montebruno",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "12",
        denominazione: "Montebuono",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "11",
        denominazione: "Montecalvo in Foglia",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "15",
        denominazione: "Montecalvo Irpino",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "03",
        denominazione: "Montecalvo Versiggia",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "09",
        denominazione: "Montecarlo",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "11",
        denominazione: "Montecarotto",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "11",
        denominazione: "Montecassiano",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "01",
        denominazione: "Montecastello",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "10",
        denominazione: "Montecastrilli",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "09",
        denominazione: "Montecatini Val di Cecina",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "09",
        denominazione: "Montecatini-Terme",
        denominazione_provincia: "Pistoia",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PT"
    },
    {
        codice_regione: "05",
        denominazione: "Montecchia di Crosara",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "10",
        denominazione: "Montecchio",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "08",
        denominazione: "Montecchio Emilia",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "05",
        denominazione: "Montecchio Maggiore",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "05",
        denominazione: "Montecchio Precalcino",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "01",
        denominazione: "Montechiaro d'Acqui",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Montechiaro d'Asti",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "08",
        denominazione: "Montechiarugolo",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "14",
        denominazione: "Montecilfone",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "08",
        denominazione: "Montecopiolo",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "15",
        denominazione: "Montecorice",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "15",
        denominazione: "Montecorvino Pugliano",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "15",
        denominazione: "Montecorvino Rovella",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "11",
        denominazione: "Montecosaro",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "01",
        denominazione: "Montecrestese",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "08",
        denominazione: "Montecreto",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "11",
        denominazione: "Montedinove",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "19",
        denominazione: "Montedoro",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "15",
        denominazione: "Montefalcione",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "10",
        denominazione: "Montefalco",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "11",
        denominazione: "Montefalcone Appennino",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "15",
        denominazione: "Montefalcone di Val Fortore",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "14",
        denominazione: "Montefalcone nel Sannio",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "11",
        denominazione: "Montefano",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "11",
        denominazione: "Montefelcino",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "13",
        denominazione: "Monteferrante",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "12",
        denominazione: "Montefiascone",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "13",
        denominazione: "Montefino",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "08",
        denominazione: "Montefiore Conca",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "11",
        denominazione: "Montefiore dell'Aso",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "08",
        denominazione: "Montefiorino",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "12",
        denominazione: "Monteflavio",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "15",
        denominazione: "Monteforte Cilento",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "05",
        denominazione: "Monteforte d'Alpone",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "15",
        denominazione: "Monteforte Irpino",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "11",
        denominazione: "Montefortino",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "10",
        denominazione: "Montefranco",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "15",
        denominazione: "Montefredane",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "15",
        denominazione: "Montefusco",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "10",
        denominazione: "Montegabbione",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "05",
        denominazione: "Montegalda",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "05",
        denominazione: "Montegaldella",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "11",
        denominazione: "Montegallo",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "01",
        denominazione: "Montegioco",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "18",
        denominazione: "Montegiordano",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "11",
        denominazione: "Montegiorgio",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "11",
        denominazione: "Montegranaro",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "08",
        denominazione: "Montegridolfo",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "03",
        denominazione: "Montegrino Valtravaglia",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "01",
        denominazione: "Montegrosso d'Asti",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "07",
        denominazione: "Montegrosso Pian Latte",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "05",
        denominazione: "Montegrotto Terme",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "16",
        denominazione: "Monteiasi",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "11",
        denominazione: "Montelabbate",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "12",
        denominazione: "Montelanico",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "13",
        denominazione: "Montelapiano",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "10",
        denominazione: "Monteleone d'Orvieto",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "11",
        denominazione: "Monteleone di Fermo",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "16",
        denominazione: "Monteleone di Puglia",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "10",
        denominazione: "Monteleone di Spoleto",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "20",
        denominazione: "Monteleone Rocca Doria",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "12",
        denominazione: "Monteleone Sabino",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "19",
        denominazione: "Montelepre",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "12",
        denominazione: "Montelibretti",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "15",
        denominazione: "Montella",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "03",
        denominazione: "Montello",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "14",
        denominazione: "Montelongo",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "11",
        denominazione: "Montelparo",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "01",
        denominazione: "Montelupo Albese",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "09",
        denominazione: "Montelupo Fiorentino",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "11",
        denominazione: "Montelupone",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "19",
        denominazione: "Montemaggiore Belsito",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "01",
        denominazione: "Montemagno Monferrato",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Montemale di Cuneo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "15",
        denominazione: "Montemarano",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "11",
        denominazione: "Montemarciano",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "01",
        denominazione: "Montemarzino",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "16",
        denominazione: "Montemesola",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "03",
        denominazione: "Montemezzo",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "09",
        denominazione: "Montemignaio",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "15",
        denominazione: "Montemiletto",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "17",
        denominazione: "Montemilone",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "14",
        denominazione: "Montemitro",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "11",
        denominazione: "Montemonaco",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "09",
        denominazione: "Montemurlo",
        denominazione_provincia: "Prato",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PO"
    },
    {
        codice_regione: "17",
        denominazione: "Montemurro",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "06",
        denominazione: "Montenars",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "14",
        denominazione: "Montenero di Bisaccia",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "12",
        denominazione: "Montenero Sabino",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "14",
        denominazione: "Montenero Val Cocchiara",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "13",
        denominazione: "Montenerodomo",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "13",
        denominazione: "Monteodorisio",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "18",
        denominazione: "Montepaone",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "16",
        denominazione: "Monteparano",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "11",
        denominazione: "Monteprandone",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "09",
        denominazione: "Montepulciano",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "09",
        denominazione: "Monterchi",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "13",
        denominazione: "Montereale",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "06",
        denominazione: "Montereale Valcellina",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "08",
        denominazione: "Monterenzio",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "09",
        denominazione: "Monteriggioni",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "14",
        denominazione: "Monteroduni",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "09",
        denominazione: "Monteroni d'Arbia",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "16",
        denominazione: "Monteroni di Lecce",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "12",
        denominazione: "Monterosi",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "07",
        denominazione: "Monterosso al Mare",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "19",
        denominazione: "Monterosso Almo",
        denominazione_provincia: "Ragusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "RG"
    },
    {
        codice_regione: "18",
        denominazione: "Monterosso Calabro",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "01",
        denominazione: "Monterosso Grana",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "12",
        denominazione: "Monterotondo",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "09",
        denominazione: "Monterotondo Marittimo",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "11",
        denominazione: "Monterubbiano",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "16",
        denominazione: "Montesano Salentino",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "15",
        denominazione: "Montesano sulla Marcellana",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "15",
        denominazione: "Montesarchio",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "17",
        denominazione: "Montescaglioso",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "03",
        denominazione: "Montescano",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Montescheno",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "09",
        denominazione: "Montescudaio",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "08",
        denominazione: "Montescudo-Monte Colombo",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "08",
        denominazione: "Montese",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "03",
        denominazione: "Montesegale",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "13",
        denominazione: "Montesilvano",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "09",
        denominazione: "Montespertoli",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "01",
        denominazione: "Monteu da Po",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Monteu Roero",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "19",
        denominazione: "Montevago",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "09",
        denominazione: "Montevarchi",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "03",
        denominazione: "Montevecchia",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "15",
        denominazione: "Monteverde",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "09",
        denominazione: "Monteverdi Marittimo",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "05",
        denominazione: "Monteviale",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "01",
        denominazione: "Montezemolo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "20",
        denominazione: "Monti",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "08",
        denominazione: "Montiano",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "03",
        denominazione: "Monticelli Brusati",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "08",
        denominazione: "Monticelli d'Ongina",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "03",
        denominazione: "Monticelli Pavese",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Monticello Brianza",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "05",
        denominazione: "Monticello Conte Otto",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "01",
        denominazione: "Monticello d'Alba",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Montichiari",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "09",
        denominazione: "Monticiano",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "09",
        denominazione: "Montieri",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "01",
        denominazione: "Montiglio Monferrato",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "09",
        denominazione: "Montignoso",
        denominazione_provincia: "Massa-Carrara",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "MS"
    },
    {
        codice_regione: "03",
        denominazione: "Montirone",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "02",
        denominazione: "Montjovet",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "03",
        denominazione: "Montodine",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "07",
        denominazione: "Montoggio",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "10",
        denominazione: "Montone",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "12",
        denominazione: "Montopoli di Sabina",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "09",
        denominazione: "Montopoli in Val d'Arno",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "03",
        denominazione: "Montorfano",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "13",
        denominazione: "Montorio al Vomano",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "14",
        denominazione: "Montorio nei Frentani",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "12",
        denominazione: "Montorio Romano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "15",
        denominazione: "Montoro",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "05",
        denominazione: "Montorso Vicentino",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "11",
        denominazione: "Montottone",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "20",
        denominazione: "Montresta",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "03",
        denominazione: "Montù Beccaria",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Monvalle",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Monza",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: true,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "03",
        denominazione: "Monzambano",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "08",
        denominazione: "Monzuno",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "18",
        denominazione: "Morano Calabro",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "01",
        denominazione: "Morano sul Po",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Moransengo-Tonengo",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "06",
        denominazione: "Moraro",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "03",
        denominazione: "Morazzone",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Morbegno",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "01",
        denominazione: "Morbello",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "16",
        denominazione: "Morciano di Leuca",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "08",
        denominazione: "Morciano di Romagna",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "15",
        denominazione: "Morcone",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "08",
        denominazione: "Mordano",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "03",
        denominazione: "Morengo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "20",
        denominazione: "Mores",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "11",
        denominazione: "Moresco",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "01",
        denominazione: "Moretta",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "08",
        denominazione: "Morfasso",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "05",
        denominazione: "Morgano",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "02",
        denominazione: "Morgex",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "20",
        denominazione: "Morgongiori",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "04",
        denominazione: "Mori",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "05",
        denominazione: "Moriago della Battaglia",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "12",
        denominazione: "Moricone",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "15",
        denominazione: "Morigerati",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Morimondo",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "13",
        denominazione: "Morino",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "01",
        denominazione: "Moriondo Torinese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "12",
        denominazione: "Morlupo",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "18",
        denominazione: "Mormanno",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Mornago",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "01",
        denominazione: "Mornese",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Mornico al Serio",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Mornico Losana",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "12",
        denominazione: "Morolo",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "01",
        denominazione: "Morozzo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "15",
        denominazione: "Morra De Sanctis",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "11",
        denominazione: "Morro d'Alba",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "13",
        denominazione: "Morro d'Oro",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "12",
        denominazione: "Morro Reatino",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "14",
        denominazione: "Morrone del Sannio",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "11",
        denominazione: "Morrovalle",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "06",
        denominazione: "Morsano al Tagliamento",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "01",
        denominazione: "Morsasco",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Mortara",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "06",
        denominazione: "Mortegliano",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Morterone",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "06",
        denominazione: "Moruzzo",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Moscazzano",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "15",
        denominazione: "Moschiano",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "13",
        denominazione: "Mosciano Sant'Angelo",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "13",
        denominazione: "Moscufo",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "04",
        denominazione: "Moso in Passiria",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "06",
        denominazione: "Mossa",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "03",
        denominazione: "Motta Baluffi",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "19",
        denominazione: "Motta Camastra",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "19",
        denominazione: "Motta d'Affermo",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "01",
        denominazione: "Motta de' Conti",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "05",
        denominazione: "Motta di Livenza",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "16",
        denominazione: "Motta Montecorvino",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "18",
        denominazione: "Motta San Giovanni",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "19",
        denominazione: "Motta Sant'Anastasia",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "18",
        denominazione: "Motta Santa Lucia",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "03",
        denominazione: "Motta Visconti",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "18",
        denominazione: "Mottafollone",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "01",
        denominazione: "Mottalciata",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "03",
        denominazione: "Motteggiana",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "16",
        denominazione: "Mottola",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "13",
        denominazione: "Mozzagrogna",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "03",
        denominazione: "Mozzanica",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Mozzate",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "05",
        denominazione: "Mozzecane",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "03",
        denominazione: "Mozzo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "11",
        denominazione: "Muccia",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "06",
        denominazione: "Muggia",
        denominazione_provincia: "Trieste",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "TS"
    },
    {
        codice_regione: "03",
        denominazione: "Muggiò",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "15",
        denominazione: "Mugnano del Cardinale",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "15",
        denominazione: "Mugnano di Napoli",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "03",
        denominazione: "Mulazzano",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "09",
        denominazione: "Mulazzo",
        denominazione_provincia: "Massa-Carrara",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "MS"
    },
    {
        codice_regione: "03",
        denominazione: "Mura",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "20",
        denominazione: "Muravera",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "01",
        denominazione: "Murazzano",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Murello",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "07",
        denominazione: "Murialdo",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "01",
        denominazione: "Murisengo",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "09",
        denominazione: "Murlo",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "16",
        denominazione: "Muro Leccese",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "17",
        denominazione: "Muro Lucano",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "20",
        denominazione: "Muros",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Muscoline",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "20",
        denominazione: "Musei",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "05",
        denominazione: "Musile di Piave",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "03",
        denominazione: "Musso",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "05",
        denominazione: "Mussolente",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "19",
        denominazione: "Mussomeli",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "06",
        denominazione: "Muzzana del Turgnano",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "01",
        denominazione: "Muzzano",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "04",
        denominazione: "Nago-Torbole",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "04",
        denominazione: "Nalles",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "05",
        denominazione: "Nanto",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "15",
        denominazione: "Napoli",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: true,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "20",
        denominazione: "Narbolia",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "20",
        denominazione: "Narcao",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "16",
        denominazione: "Nardò",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "18",
        denominazione: "Nardodipace",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "10",
        denominazione: "Narni",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "19",
        denominazione: "Naro",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "01",
        denominazione: "Narzole",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "07",
        denominazione: "Nasino",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "19",
        denominazione: "Naso",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "04",
        denominazione: "Naturno",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "Nave",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "13",
        denominazione: "Navelli",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "04",
        denominazione: "Naz-Sciaves",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "12",
        denominazione: "Nazzano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "07",
        denominazione: "Ne",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "01",
        denominazione: "Nebbiuno",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "05",
        denominazione: "Negrar di Valpolicella",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "07",
        denominazione: "Neirone",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "01",
        denominazione: "Neive",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Nembro",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "12",
        denominazione: "Nemi",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "17",
        denominazione: "Nemoli",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "20",
        denominazione: "Neoneli",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "12",
        denominazione: "Nepi",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "13",
        denominazione: "Nereto",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "12",
        denominazione: "Nerola",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "05",
        denominazione: "Nervesa della Battaglia",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Nerviano",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "12",
        denominazione: "Nespolo",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "03",
        denominazione: "Nesso",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "01",
        denominazione: "Netro",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "12",
        denominazione: "Nettuno",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "16",
        denominazione: "Neviano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "08",
        denominazione: "Neviano degli Arduini",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "01",
        denominazione: "Neviglie",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Niardo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Nibbiola",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Nibionno",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "01",
        denominazione: "Nichelino",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "19",
        denominazione: "Nicolosi",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "03",
        denominazione: "Nicorvo",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "19",
        denominazione: "Nicosia",
        denominazione_provincia: "Enna",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "EN"
    },
    {
        codice_regione: "18",
        denominazione: "Nicotera",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "01",
        denominazione: "Niella Belbo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Niella Tanaro",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "06",
        denominazione: "Nimis",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "19",
        denominazione: "Niscemi",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "19",
        denominazione: "Nissoria",
        denominazione_provincia: "Enna",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "EN"
    },
    {
        codice_regione: "19",
        denominazione: "Nizza di Sicilia",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "01",
        denominazione: "Nizza Monferrato",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "05",
        denominazione: "Noale",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "01",
        denominazione: "Noasca",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "18",
        denominazione: "Nocara",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "13",
        denominazione: "Nocciano",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "15",
        denominazione: "Nocera Inferiore",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "15",
        denominazione: "Nocera Superiore",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "18",
        denominazione: "Nocera Terinese",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "10",
        denominazione: "Nocera Umbra",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "08",
        denominazione: "Noceto",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "16",
        denominazione: "Noci",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "16",
        denominazione: "Nociglia",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "17",
        denominazione: "Noepoli",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "05",
        denominazione: "Nogara",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "04",
        denominazione: "Nogaredo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "05",
        denominazione: "Nogarole Rocca",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "05",
        denominazione: "Nogarole Vicentino",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "16",
        denominazione: "Noicattaro",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "15",
        denominazione: "Nola",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "01",
        denominazione: "Nole",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "07",
        denominazione: "Noli",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "01",
        denominazione: "Nomaglio",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "04",
        denominazione: "Nomi",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "08",
        denominazione: "Nonantola",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "01",
        denominazione: "None",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Nonio",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "20",
        denominazione: "Noragugume",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "20",
        denominazione: "Norbello",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "10",
        denominazione: "Norcia",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "12",
        denominazione: "Norma",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "03",
        denominazione: "Nosate",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "13",
        denominazione: "Notaresco",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "19",
        denominazione: "Noto",
        denominazione_provincia: "Siracusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "SR"
    },
    {
        codice_regione: "04",
        denominazione: "Nova Levante",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "Nova Milanese",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "04",
        denominazione: "Nova Ponente",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "17",
        denominazione: "Nova Siri",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "08",
        denominazione: "Novafeltria",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "04",
        denominazione: "Novaledo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Novalesa",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Novara",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: true,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "19",
        denominazione: "Novara di Sicilia",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "03",
        denominazione: "Novate Mezzola",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "03",
        denominazione: "Novate Milanese",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "05",
        denominazione: "Nove",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Novedrate",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "04",
        denominazione: "Novella",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "08",
        denominazione: "Novellara",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "01",
        denominazione: "Novello",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "05",
        denominazione: "Noventa di Piave",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "05",
        denominazione: "Noventa Padovana",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "05",
        denominazione: "Noventa Vicentina",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "08",
        denominazione: "Novi di Modena",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "01",
        denominazione: "Novi Ligure",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "15",
        denominazione: "Novi Velia",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Noviglio",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "16",
        denominazione: "Novoli",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "01",
        denominazione: "Nucetto",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "20",
        denominazione: "Nughedu San Nicolò",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "20",
        denominazione: "Nughedu Santa Vittoria",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "20",
        denominazione: "Nule",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "20",
        denominazione: "Nulvi",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "11",
        denominazione: "Numana",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "20",
        denominazione: "Nuoro",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: true,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "20",
        denominazione: "Nurachi",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "20",
        denominazione: "Nuragus",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Nurallao",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Nuraminis",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Nureci",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "20",
        denominazione: "Nurri",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "02",
        denominazione: "Nus",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "15",
        denominazione: "Nusco",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "03",
        denominazione: "Nuvolento",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Nuvolera",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "20",
        denominazione: "Nuxis",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "01",
        denominazione: "Occhieppo Inferiore",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "01",
        denominazione: "Occhieppo Superiore",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "05",
        denominazione: "Occhiobello",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "01",
        denominazione: "Occimiano",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "13",
        denominazione: "Ocre",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "01",
        denominazione: "Odalengo Grande",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Odalengo Piccolo",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "05",
        denominazione: "Oderzo",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Odolo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "13",
        denominazione: "Ofena",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "11",
        denominazione: "Offagna",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "03",
        denominazione: "Offanengo",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "11",
        denominazione: "Offida",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "03",
        denominazione: "Offlaga",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Oggebbio",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "03",
        denominazione: "Oggiona con Santo Stefano",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Oggiono",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "01",
        denominazione: "Oglianico",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "Ogliastro Cilento",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "20",
        denominazione: "Olbia",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "01",
        denominazione: "Olcenengo",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "01",
        denominazione: "Oldenico",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "01",
        denominazione: "Oleggio",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "01",
        denominazione: "Oleggio Castello",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Olevano di Lomellina",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "12",
        denominazione: "Olevano Romano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "15",
        denominazione: "Olevano sul Tusciano",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Olgiate Comasco",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Olgiate Molgora",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Olgiate Olona",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Olginate",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "20",
        denominazione: "Oliena",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "03",
        denominazione: "Oliva Gessi",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "18",
        denominazione: "Olivadi",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "19",
        denominazione: "Oliveri",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "15",
        denominazione: "Oliveto Citra",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Oliveto Lario",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "17",
        denominazione: "Oliveto Lucano",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "07",
        denominazione: "Olivetta San Michele",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "01",
        denominazione: "Olivola",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "20",
        denominazione: "Ollastra",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "20",
        denominazione: "Ollolai",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "02",
        denominazione: "Ollomont",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "20",
        denominazione: "Olmedo",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Olmeneta",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Olmo al Brembo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Olmo Gentile",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "03",
        denominazione: "Oltre il Colle",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Oltressenda Alta",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Oltrona di San Mamette",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "20",
        denominazione: "Olzai",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "03",
        denominazione: "Ome",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Omegna",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "15",
        denominazione: "Omignano",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "20",
        denominazione: "Onanì",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "12",
        denominazione: "Onano",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "01",
        denominazione: "Oncino",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Oneta",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "20",
        denominazione: "Onifai",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "20",
        denominazione: "Oniferi",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "03",
        denominazione: "Ono San Pietro",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Onore",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "07",
        denominazione: "Onzo",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "03",
        denominazione: "Opera",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "13",
        denominazione: "Opi",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "05",
        denominazione: "Oppeano",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "17",
        denominazione: "Oppido Lucano",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "18",
        denominazione: "Oppido Mamertina",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "04",
        denominazione: "Ora",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "20",
        denominazione: "Orani",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "14",
        denominazione: "Oratino",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "01",
        denominazione: "Orbassano",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "09",
        denominazione: "Orbetello",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "09",
        denominazione: "Orciano Pisano",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "07",
        denominazione: "Orco Feglino",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "16",
        denominazione: "Ordona",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "07",
        denominazione: "Orero",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "05",
        denominazione: "Orgiano",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "20",
        denominazione: "Orgosolo",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "16",
        denominazione: "Oria",
        denominazione_provincia: "Brindisi",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BR"
    },
    {
        codice_regione: "13",
        denominazione: "Oricola",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "03",
        denominazione: "Origgio",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Orino",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Orio al Serio",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Orio Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Orio Litta",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "18",
        denominazione: "Oriolo",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "12",
        denominazione: "Oriolo Romano",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "20",
        denominazione: "Oristano",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: true,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "01",
        denominazione: "Ormea",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "05",
        denominazione: "Ormelle",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Ornago",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "01",
        denominazione: "Ornavasso",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "03",
        denominazione: "Ornica",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "20",
        denominazione: "Orosei",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "20",
        denominazione: "Orotelli",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "15",
        denominazione: "Orria",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "20",
        denominazione: "Orroli",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "05",
        denominazione: "Orsago",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "01",
        denominazione: "Orsara Bormida",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "16",
        denominazione: "Orsara di Puglia",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "03",
        denominazione: "Orsenigo",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "13",
        denominazione: "Orsogna",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "18",
        denominazione: "Orsomarso",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "15",
        denominazione: "Orta di Atella",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "16",
        denominazione: "Orta Nova",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "01",
        denominazione: "Orta San Giulio",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "20",
        denominazione: "Ortacesus",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "12",
        denominazione: "Orte",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "16",
        denominazione: "Ortelle",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "11",
        denominazione: "Ortezzano",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "09",
        denominazione: "Ortignano Raggiolo",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "04",
        denominazione: "Ortisei",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "13",
        denominazione: "Ortona",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "13",
        denominazione: "Ortona dei Marsi",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "07",
        denominazione: "Ortovero",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "13",
        denominazione: "Ortucchio",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "20",
        denominazione: "Ortueri",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "20",
        denominazione: "Orune",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "10",
        denominazione: "Orvieto",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "12",
        denominazione: "Orvinio",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "03",
        denominazione: "Orzinuovi",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Orzivecchi",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Osasco",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Osasio",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "20",
        denominazione: "Oschiri",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "20",
        denominazione: "Osidda",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "07",
        denominazione: "Osiglia",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "20",
        denominazione: "Osilo",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "11",
        denominazione: "Osimo",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "20",
        denominazione: "Osini",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "03",
        denominazione: "Osio Sopra",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Osio Sotto",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Osnago",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "06",
        denominazione: "Osoppo",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "07",
        denominazione: "Ospedaletti",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "04",
        denominazione: "Ospedaletto",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "15",
        denominazione: "Ospedaletto d'Alpinolo",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "05",
        denominazione: "Ospedaletto Euganeo",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Ospedaletto Lodigiano",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "05",
        denominazione: "Ospitale di Cadore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "03",
        denominazione: "Ospitaletto",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Ossago Lodigiano",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "04",
        denominazione: "Ossana",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "20",
        denominazione: "Ossi",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Ossimo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Ossona",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Ostana",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "08",
        denominazione: "Ostellato",
        denominazione_provincia: "Ferrara",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FE"
    },
    {
        codice_regione: "03",
        denominazione: "Ostiano",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Ostiglia",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "11",
        denominazione: "Ostra",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "11",
        denominazione: "Ostra Vetere",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "16",
        denominazione: "Ostuni",
        denominazione_provincia: "Brindisi",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BR"
    },
    {
        codice_regione: "16",
        denominazione: "Otranto",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "10",
        denominazione: "Otricoli",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "20",
        denominazione: "Ottana",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "15",
        denominazione: "Ottati",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "15",
        denominazione: "Ottaviano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "01",
        denominazione: "Ottiglio",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Ottobiano",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "08",
        denominazione: "Ottone",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "01",
        denominazione: "Oulx",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Ovada",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "06",
        denominazione: "Ovaro",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "01",
        denominazione: "Oviglio",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "13",
        denominazione: "Ovindoli",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "20",
        denominazione: "Ovodda",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "02",
        denominazione: "Oyace",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "01",
        denominazione: "Ozegna",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "20",
        denominazione: "Ozieri",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "08",
        denominazione: "Ozzano dell'Emilia",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "01",
        denominazione: "Ozzano Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Ozzero",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "20",
        denominazione: "Pabillonis",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "19",
        denominazione: "Pace del Mela",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "19",
        denominazione: "Paceco",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "13",
        denominazione: "Pacentro",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "19",
        denominazione: "Pachino",
        denominazione_provincia: "Siracusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "SR"
    },
    {
        codice_regione: "10",
        denominazione: "Paciano",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "03",
        denominazione: "Padenghe sul Garda",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Paderna",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Paderno d'Adda",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Paderno Dugnano",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Paderno Franciacorta",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Paderno Ponchielli",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "05",
        denominazione: "Padova",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: true,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "20",
        denominazione: "Padria",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "20",
        denominazione: "Padru",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "15",
        denominazione: "Padula",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "15",
        denominazione: "Paduli",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "01",
        denominazione: "Paesana",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "05",
        denominazione: "Paese",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "15",
        denominazione: "Pagani",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "12",
        denominazione: "Paganico Sabino",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "03",
        denominazione: "Pagazzano",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "19",
        denominazione: "Pagliara",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "13",
        denominazione: "Paglieta",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "06",
        denominazione: "Pagnacco",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "01",
        denominazione: "Pagno",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Pagnona",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "15",
        denominazione: "Pago del Vallo di Lauro",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "15",
        denominazione: "Pago Veiano",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "03",
        denominazione: "Paisco Loveno",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Paitone",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Paladina",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "08",
        denominazione: "Palagano",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "16",
        denominazione: "Palagianello",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "16",
        denominazione: "Palagiano",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "19",
        denominazione: "Palagonia",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "09",
        denominazione: "Palaia",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "08",
        denominazione: "Palanzano",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "14",
        denominazione: "Palata",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "20",
        denominazione: "Palau",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Palazzago",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "19",
        denominazione: "Palazzo Adriano",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "01",
        denominazione: "Palazzo Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Palazzo Pignano",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "17",
        denominazione: "Palazzo San Gervasio",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "19",
        denominazione: "Palazzolo Acreide",
        denominazione_provincia: "Siracusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "SR"
    },
    {
        codice_regione: "06",
        denominazione: "Palazzolo dello Stella",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Palazzolo sull'Oglio",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Palazzolo Vercellese",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "09",
        denominazione: "Palazzuolo sul Senio",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "13",
        denominazione: "Palena",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "18",
        denominazione: "Palermiti",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "19",
        denominazione: "Palermo",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: true,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "12",
        denominazione: "Palestrina",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "03",
        denominazione: "Palestro",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "12",
        denominazione: "Paliano",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "18",
        denominazione: "Palizzi",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "18",
        denominazione: "Pallagorio",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "01",
        denominazione: "Pallanzeno",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "07",
        denominazione: "Pallare",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "15",
        denominazione: "Palma Campania",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "19",
        denominazione: "Palma di Montechiaro",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "06",
        denominazione: "Palmanova",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "16",
        denominazione: "Palmariggi",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "20",
        denominazione: "Palmas Arborea",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "18",
        denominazione: "Palmi",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "11",
        denominazione: "Palmiano",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "13",
        denominazione: "Palmoli",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "16",
        denominazione: "Palo del Colle",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "12",
        denominazione: "Palombara Sabina",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "13",
        denominazione: "Palombaro",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "15",
        denominazione: "Palomonte",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Palosco",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Palù",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "04",
        denominazione: "Palù del Fersina",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "18",
        denominazione: "Paludi",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "06",
        denominazione: "Paluzza",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "01",
        denominazione: "Pamparato",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Pancalieri",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Pancarana",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "04",
        denominazione: "Panchià",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Pandino",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "18",
        denominazione: "Panettieri",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "10",
        denominazione: "Panicale",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "15",
        denominazione: "Pannarano",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "16",
        denominazione: "Panni",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "19",
        denominazione: "Pantelleria",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "03",
        denominazione: "Pantigliate",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "18",
        denominazione: "Paola",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "15",
        denominazione: "Paolisi",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "18",
        denominazione: "Papasidero",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "05",
        denominazione: "Papozze",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "03",
        denominazione: "Parabiago",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "16",
        denominazione: "Parabita",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "03",
        denominazione: "Paratico",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "04",
        denominazione: "Parcines",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "01",
        denominazione: "Parella",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "18",
        denominazione: "Parenti",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "15",
        denominazione: "Parete",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "01",
        denominazione: "Pareto",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "18",
        denominazione: "Parghelia",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "03",
        denominazione: "Parlasco",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "08",
        denominazione: "Parma",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: true,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "01",
        denominazione: "Parodi Ligure",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Paroldo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "15",
        denominazione: "Parolise",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "03",
        denominazione: "Parona",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "10",
        denominazione: "Parrano",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "03",
        denominazione: "Parre",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "19",
        denominazione: "Partanna",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "19",
        denominazione: "Partinico",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "01",
        denominazione: "Paruzzaro",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Parzanica",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "06",
        denominazione: "Pasian di Prato",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "06",
        denominazione: "Pasiano di Pordenone",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "03",
        denominazione: "Paspardo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Passerano Marmorito",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "10",
        denominazione: "Passignano sul Trasimeno",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "03",
        denominazione: "Passirano",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "12",
        denominazione: "Pastena",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "15",
        denominazione: "Pastorano",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "05",
        denominazione: "Pastrengo",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "01",
        denominazione: "Pasturana",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Pasturo",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "17",
        denominazione: "Paterno",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "19",
        denominazione: "Paternò",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "18",
        denominazione: "Paterno Calabro",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "15",
        denominazione: "Paternopoli",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "12",
        denominazione: "Patrica",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "20",
        denominazione: "Pattada",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "19",
        denominazione: "Patti",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "16",
        denominazione: "Patù",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "20",
        denominazione: "Pau",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "06",
        denominazione: "Paularo",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "20",
        denominazione: "Pauli Arbarei",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Paulilatino",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "03",
        denominazione: "Paullo",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "15",
        denominazione: "Paupisi",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "01",
        denominazione: "Pavarolo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Pavia",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: true,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "06",
        denominazione: "Pavia di Udine",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "01",
        denominazione: "Pavone Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Pavone del Mella",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "08",
        denominazione: "Pavullo nel Frignano",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "18",
        denominazione: "Pazzano",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "09",
        denominazione: "Peccioli",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "01",
        denominazione: "Pecetto di Valenza",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Pecetto Torinese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "19",
        denominazione: "Pedara",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "11",
        denominazione: "Pedaso",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "05",
        denominazione: "Pedavena",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "05",
        denominazione: "Pedemonte",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "05",
        denominazione: "Pederobba",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Pedesina",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "18",
        denominazione: "Pedivigliano",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Pedrengo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Peglio",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "11",
        denominazione: "Peglio",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "03",
        denominazione: "Pegognaga",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "03",
        denominazione: "Peia",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "04",
        denominazione: "Peio",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "09",
        denominazione: "Pelago",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "01",
        denominazione: "Pella",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "08",
        denominazione: "Pellegrino Parmense",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "15",
        denominazione: "Pellezzano",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "04",
        denominazione: "Pellizzano",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "04",
        denominazione: "Pelugo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Penango",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "10",
        denominazione: "Penna in Teverina",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "11",
        denominazione: "Penna San Giovanni",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "13",
        denominazione: "Penna Sant'Andrea",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "08",
        denominazione: "Pennabilli",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "13",
        denominazione: "Pennadomo",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "13",
        denominazione: "Pennapiedimonte",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "13",
        denominazione: "Penne",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "18",
        denominazione: "Pentone",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "13",
        denominazione: "Perano",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "05",
        denominazione: "Perarolo di Cadore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "04",
        denominazione: "Perca",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "12",
        denominazione: "Percile",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "20",
        denominazione: "Perdasdefogu",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "20",
        denominazione: "Perdaxius",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "15",
        denominazione: "Perdifumo",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "13",
        denominazione: "Pereto",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "20",
        denominazione: "Perfugas",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "04",
        denominazione: "Pergine Valsugana",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "11",
        denominazione: "Pergola",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "07",
        denominazione: "Perinaldo",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "15",
        denominazione: "Perito",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Perledo",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "01",
        denominazione: "Perletto",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Perlo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "02",
        denominazione: "Perloz",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "05",
        denominazione: "Pernumia",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Pero",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Perosa Argentina",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Perosa Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Perrero",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Persico Dosimo",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "01",
        denominazione: "Pertengo",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "03",
        denominazione: "Pertica Alta",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Pertica Bassa",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "15",
        denominazione: "Pertosa",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "01",
        denominazione: "Pertusio",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "10",
        denominazione: "Perugia",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: true,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "11",
        denominazione: "Pesaro",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: true,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "09",
        denominazione: "Pescaglia",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "05",
        denominazione: "Pescantina",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "13",
        denominazione: "Pescara",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: true,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "03",
        denominazione: "Pescarolo ed Uniti",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "13",
        denominazione: "Pescasseroli",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "03",
        denominazione: "Pescate",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "14",
        denominazione: "Pesche",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "16",
        denominazione: "Peschici",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "03",
        denominazione: "Peschiera Borromeo",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "05",
        denominazione: "Peschiera del Garda",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "09",
        denominazione: "Pescia",
        denominazione_provincia: "Pistoia",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PT"
    },
    {
        codice_regione: "13",
        denominazione: "Pescina",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "15",
        denominazione: "Pesco Sannita",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "13",
        denominazione: "Pescocostanzo",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "14",
        denominazione: "Pescolanciano",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "17",
        denominazione: "Pescopagano",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "14",
        denominazione: "Pescopennataro",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "12",
        denominazione: "Pescorocchiano",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "13",
        denominazione: "Pescosansonesco",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "12",
        denominazione: "Pescosolido",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "03",
        denominazione: "Pessano con Bornago",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Pessina Cremonese",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "01",
        denominazione: "Pessinetto",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "14",
        denominazione: "Petacciato",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "18",
        denominazione: "Petilia Policastro",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "15",
        denominazione: "Petina",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "19",
        denominazione: "Petralia Soprana",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "19",
        denominazione: "Petralia Sottana",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "12",
        denominazione: "Petrella Salto",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "14",
        denominazione: "Petrella Tifernina",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "11",
        denominazione: "Petriano",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "11",
        denominazione: "Petriolo",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "11",
        denominazione: "Petritoli",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "18",
        denominazione: "Petrizzi",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "18",
        denominazione: "Petronà",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "19",
        denominazione: "Petrosino",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "15",
        denominazione: "Petruro Irpino",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "01",
        denominazione: "Pettenasco",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "01",
        denominazione: "Pettinengo",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "19",
        denominazione: "Pettineo",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "14",
        denominazione: "Pettoranello del Molise",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "13",
        denominazione: "Pettorano sul Gizio",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "05",
        denominazione: "Pettorazza Grimani",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "01",
        denominazione: "Peveragno",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Pezzana",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "03",
        denominazione: "Pezzaze",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Pezzolo Valle Uzzone",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "08",
        denominazione: "Piacenza",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: true,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "05",
        denominazione: "Piacenza d'Adige",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Piadena Drizzona",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "15",
        denominazione: "Piaggine",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Pian Camuno",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "07",
        denominazione: "Piana Crixia",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "19",
        denominazione: "Piana degli Albanesi",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "15",
        denominazione: "Piana di Monte Verna",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "09",
        denominazione: "Piancastagnaio",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "03",
        denominazione: "Piancogno",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "11",
        denominazione: "Piandimeleto",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "18",
        denominazione: "Piane Crati",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "13",
        denominazione: "Pianella",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "03",
        denominazione: "Pianello del Lario",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "08",
        denominazione: "Pianello Val Tidone",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "03",
        denominazione: "Pianengo",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "01",
        denominazione: "Pianezza",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "05",
        denominazione: "Pianezze",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "01",
        denominazione: "Pianfei",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Pianico",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Pianiga",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "15",
        denominazione: "Piano di Sorrento",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "18",
        denominazione: "Pianopoli",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "08",
        denominazione: "Pianoro",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "12",
        denominazione: "Piansano",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "03",
        denominazione: "Piantedo",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "03",
        denominazione: "Piario",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Piasco",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Piateda",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "01",
        denominazione: "Piatto",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "09",
        denominazione: "Piazza al Serchio",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "19",
        denominazione: "Piazza Armerina",
        denominazione_provincia: "Enna",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "EN"
    },
    {
        codice_regione: "03",
        denominazione: "Piazza Brembana",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Piazzatorre",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Piazzola sul Brenta",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Piazzolo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "13",
        denominazione: "Picciano",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "17",
        denominazione: "Picerno",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "12",
        denominazione: "Picinisco",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "12",
        denominazione: "Pico",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "01",
        denominazione: "Piea",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Piedicavallo",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "19",
        denominazione: "Piedimonte Etneo",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "15",
        denominazione: "Piedimonte Matese",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "12",
        denominazione: "Piedimonte San Germano",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "01",
        denominazione: "Piedimulera",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "10",
        denominazione: "Piegaro",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "09",
        denominazione: "Pienza",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "03",
        denominazione: "Pieranica",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Pietra de' Giorgi",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "07",
        denominazione: "Pietra Ligure",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "01",
        denominazione: "Pietra Marazzi",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "14",
        denominazione: "Pietrabbondante",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "07",
        denominazione: "Pietrabruna",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "13",
        denominazione: "Pietracamela",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "14",
        denominazione: "Pietracatella",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "14",
        denominazione: "Pietracupa",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "15",
        denominazione: "Pietradefusi",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "13",
        denominazione: "Pietraferrazzana",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "18",
        denominazione: "Pietrafitta",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "17",
        denominazione: "Pietragalla",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "10",
        denominazione: "Pietralunga",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "15",
        denominazione: "Pietramelara",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "16",
        denominazione: "Pietramontecorvino",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "13",
        denominazione: "Pietranico",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "18",
        denominazione: "Pietrapaola",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "17",
        denominazione: "Pietrapertosa",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "19",
        denominazione: "Pietraperzia",
        denominazione_provincia: "Enna",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "EN"
    },
    {
        codice_regione: "01",
        denominazione: "Pietraporzio",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "15",
        denominazione: "Pietraroja",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "11",
        denominazione: "Pietrarubbia",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "09",
        denominazione: "Pietrasanta",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "15",
        denominazione: "Pietrastornina",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "15",
        denominazione: "Pietravairano",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "15",
        denominazione: "Pietrelcina",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "09",
        denominazione: "Pieve a Nievole",
        denominazione_provincia: "Pistoia",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PT"
    },
    {
        codice_regione: "03",
        denominazione: "Pieve Albignola",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Pieve d'Olmi",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Pieve del Cairo",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "05",
        denominazione: "Pieve del Grappa",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "04",
        denominazione: "Pieve di Bono-Prezzo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "05",
        denominazione: "Pieve di Cadore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "08",
        denominazione: "Pieve di Cento",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "05",
        denominazione: "Pieve di Soligo",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "07",
        denominazione: "Pieve di Teco",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "03",
        denominazione: "Pieve Emanuele",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Pieve Fissiraga",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "09",
        denominazione: "Pieve Fosciana",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "07",
        denominazione: "Pieve Ligure",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "03",
        denominazione: "Pieve Porto Morone",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Pieve San Giacomo",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "09",
        denominazione: "Pieve Santo Stefano",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "04",
        denominazione: "Pieve Tesino",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "11",
        denominazione: "Pieve Torina",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "01",
        denominazione: "Pieve Vergonte",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "08",
        denominazione: "Pievepelago",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "12",
        denominazione: "Piglio",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "07",
        denominazione: "Pigna",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "12",
        denominazione: "Pignataro Interamna",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "15",
        denominazione: "Pignataro Maggiore",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "17",
        denominazione: "Pignola",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "07",
        denominazione: "Pignone",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "03",
        denominazione: "Pigra",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "01",
        denominazione: "Pila",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "20",
        denominazione: "Pimentel",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "15",
        denominazione: "Pimonte",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "03",
        denominazione: "Pinarolo Po",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Pinasca",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "05",
        denominazione: "Pincara",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "01",
        denominazione: "Pinerolo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "13",
        denominazione: "Pineto",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "01",
        denominazione: "Pino d'Asti",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Pino Torinese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "06",
        denominazione: "Pinzano al Tagliamento",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "04",
        denominazione: "Pinzolo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "11",
        denominazione: "Piobbico",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "01",
        denominazione: "Piobesi d'Alba",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Piobesi Torinese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Piode",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "03",
        denominazione: "Pioltello",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "09",
        denominazione: "Piombino",
        denominazione_provincia: "Livorno",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LI"
    },
    {
        codice_regione: "05",
        denominazione: "Piombino Dese",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "11",
        denominazione: "Pioraco",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "01",
        denominazione: "Piossasco",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Piovà Massaia",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "05",
        denominazione: "Piove di Sacco",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "05",
        denominazione: "Piovene Rocchette",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "08",
        denominazione: "Piozzano",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "01",
        denominazione: "Piozzo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "19",
        denominazione: "Piraino",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "09",
        denominazione: "Pisa",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: true,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "01",
        denominazione: "Pisano",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "01",
        denominazione: "Piscina",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "20",
        denominazione: "Piscinas",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "15",
        denominazione: "Pisciotta",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Pisogne",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "12",
        denominazione: "Pisoniano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "17",
        denominazione: "Pisticci",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "09",
        denominazione: "Pistoia",
        denominazione_provincia: "Pistoia",
        denominazione_regione: "Toscana",
        flag_capoluogo: true,
        sigla_provincia: "PT"
    },
    {
        codice_regione: "09",
        denominazione: "Pitigliano",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "03",
        denominazione: "Piubega",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "03",
        denominazione: "Piuro",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "01",
        denominazione: "Piverone",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Pizzale",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Pizzighettone",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "18",
        denominazione: "Pizzo",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "13",
        denominazione: "Pizzoferrato",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "13",
        denominazione: "Pizzoli",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "14",
        denominazione: "Pizzone",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "18",
        denominazione: "Pizzoni",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "18",
        denominazione: "Placanica",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "18",
        denominazione: "Plataci",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "18",
        denominazione: "Platania",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "18",
        denominazione: "Platì",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "04",
        denominazione: "Plaus",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "Plesio",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "20",
        denominazione: "Ploaghe",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "07",
        denominazione: "Plodio",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "01",
        denominazione: "Pocapaglia",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "06",
        denominazione: "Pocenia",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "09",
        denominazione: "Podenzana",
        denominazione_provincia: "Massa-Carrara",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "MS"
    },
    {
        codice_regione: "08",
        denominazione: "Podenzano",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "12",
        denominazione: "Pofi",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "16",
        denominazione: "Poggiardo",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "09",
        denominazione: "Poggibonsi",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "09",
        denominazione: "Poggio a Caiano",
        denominazione_provincia: "Prato",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PO"
    },
    {
        codice_regione: "12",
        denominazione: "Poggio Bustone",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "12",
        denominazione: "Poggio Catino",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "16",
        denominazione: "Poggio Imperiale",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "12",
        denominazione: "Poggio Mirteto",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "12",
        denominazione: "Poggio Moiano",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "12",
        denominazione: "Poggio Nativo",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "13",
        denominazione: "Poggio Picenze",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "08",
        denominazione: "Poggio Renatico",
        denominazione_provincia: "Ferrara",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FE"
    },
    {
        codice_regione: "03",
        denominazione: "Poggio Rusco",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "12",
        denominazione: "Poggio San Lorenzo",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "11",
        denominazione: "Poggio San Marcello",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "11",
        denominazione: "Poggio San Vicino",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "14",
        denominazione: "Poggio Sannita",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "08",
        denominazione: "Poggio Torriana",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "10",
        denominazione: "Poggiodomo",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "13",
        denominazione: "Poggiofiorito",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "15",
        denominazione: "Poggiomarino",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "19",
        denominazione: "Poggioreale",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "16",
        denominazione: "Poggiorsini",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "03",
        denominazione: "Poggiridenti",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "03",
        denominazione: "Pogliano Milanese",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Pognana Lario",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Pognano",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Pogno",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "01",
        denominazione: "Poirino",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "05",
        denominazione: "Pojana Maggiore",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Polaveno",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "06",
        denominazione: "Polcenigo",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "05",
        denominazione: "Polesella",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "08",
        denominazione: "Polesine Zibello",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "12",
        denominazione: "Poli",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "18",
        denominazione: "Polia",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "17",
        denominazione: "Policoro",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "16",
        denominazione: "Polignano a Mare",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "08",
        denominazione: "Polinago",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "10",
        denominazione: "Polino",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "18",
        denominazione: "Polistena",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "19",
        denominazione: "Polizzi Generosa",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "15",
        denominazione: "Polla",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "02",
        denominazione: "Pollein",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "15",
        denominazione: "Pollena Trocchia",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "11",
        denominazione: "Pollenza",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "15",
        denominazione: "Pollica",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "19",
        denominazione: "Pollina",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "01",
        denominazione: "Pollone",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "13",
        denominazione: "Pollutri",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "01",
        denominazione: "Polonghera",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Polpenazze del Garda",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "05",
        denominazione: "Polverara",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "11",
        denominazione: "Polverigi",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "09",
        denominazione: "Pomarance",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "01",
        denominazione: "Pomaretto",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "17",
        denominazione: "Pomarico",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "01",
        denominazione: "Pomaro Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "04",
        denominazione: "Pomarolo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Pombia",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "12",
        denominazione: "Pomezia",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "15",
        denominazione: "Pomigliano d'Arco",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "15",
        denominazione: "Pompei",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "07",
        denominazione: "Pompeiana",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "03",
        denominazione: "Pompiano",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Pomponesco",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "20",
        denominazione: "Pompu",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "03",
        denominazione: "Poncarale",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Ponderano",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "03",
        denominazione: "Ponna",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "09",
        denominazione: "Ponsacco",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "05",
        denominazione: "Ponso",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "01",
        denominazione: "Pont Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "02",
        denominazione: "Pont-Saint-Martin",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "09",
        denominazione: "Pontassieve",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "02",
        denominazione: "Pontboset",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "15",
        denominazione: "Ponte",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "09",
        denominazione: "Ponte Buggianese",
        denominazione_provincia: "Pistoia",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PT"
    },
    {
        codice_regione: "08",
        denominazione: "Ponte dell'Olio",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "03",
        denominazione: "Ponte di Legno",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "05",
        denominazione: "Ponte di Piave",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "04",
        denominazione: "Ponte Gardena",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "Ponte in Valtellina",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "03",
        denominazione: "Ponte Lambro",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "05",
        denominazione: "Ponte nelle Alpi",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "03",
        denominazione: "Ponte Nizza",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Ponte Nossa",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Ponte San Nicolò",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Ponte San Pietro",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "06",
        denominazione: "Pontebba",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "15",
        denominazione: "Pontecagnano Faiano",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "05",
        denominazione: "Pontecchio Polesine",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "01",
        denominazione: "Pontechianale",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "12",
        denominazione: "Pontecorvo",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "01",
        denominazione: "Pontecurone",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "07",
        denominazione: "Pontedassio",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "09",
        denominazione: "Pontedera",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "15",
        denominazione: "Pontelandolfo",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "15",
        denominazione: "Pontelatone",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "05",
        denominazione: "Pontelongo",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "08",
        denominazione: "Pontenure",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "03",
        denominazione: "Ponteranica",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Pontestura",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Pontevico",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "02",
        denominazione: "Pontey",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "01",
        denominazione: "Ponti",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Ponti sul Mincio",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "03",
        denominazione: "Pontida",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "12",
        denominazione: "Pontinia",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "07",
        denominazione: "Pontinvrea",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "03",
        denominazione: "Pontirolo Nuovo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Pontoglio",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "09",
        denominazione: "Pontremoli",
        denominazione_provincia: "Massa-Carrara",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "MS"
    },
    {
        codice_regione: "12",
        denominazione: "Ponza",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "11",
        denominazione: "Ponzano di Fermo",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "01",
        denominazione: "Ponzano Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "12",
        denominazione: "Ponzano Romano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "05",
        denominazione: "Ponzano Veneto",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "01",
        denominazione: "Ponzone",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "13",
        denominazione: "Popoli Terme",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "09",
        denominazione: "Poppi",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "10",
        denominazione: "Porano",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "09",
        denominazione: "Porcari",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "06",
        denominazione: "Porcia",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "06",
        denominazione: "Pordenone",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: true,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "03",
        denominazione: "Porlezza",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "07",
        denominazione: "Pornassio",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "06",
        denominazione: "Porpetto",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "01",
        denominazione: "Portacomaro",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "03",
        denominazione: "Portalbera",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Porte",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "04",
        denominazione: "Porte di Rendena",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "15",
        denominazione: "Portici",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "15",
        denominazione: "Portico di Caserta",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "08",
        denominazione: "Portico e San Benedetto",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "18",
        denominazione: "Portigliola",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "09",
        denominazione: "Porto Azzurro",
        denominazione_provincia: "Livorno",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LI"
    },
    {
        codice_regione: "03",
        denominazione: "Porto Ceresio",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "16",
        denominazione: "Porto Cesareo",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "19",
        denominazione: "Porto Empedocle",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "03",
        denominazione: "Porto Mantovano",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "11",
        denominazione: "Porto Recanati",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "11",
        denominazione: "Porto San Giorgio",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "11",
        denominazione: "Porto Sant'Elpidio",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "05",
        denominazione: "Porto Tolle",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "20",
        denominazione: "Porto Torres",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Porto Valtravaglia",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "05",
        denominazione: "Porto Viro",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "05",
        denominazione: "Portobuffolè",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "14",
        denominazione: "Portocannone",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "09",
        denominazione: "Portoferraio",
        denominazione_provincia: "Livorno",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LI"
    },
    {
        codice_regione: "07",
        denominazione: "Portofino",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "05",
        denominazione: "Portogruaro",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "08",
        denominazione: "Portomaggiore",
        denominazione_provincia: "Ferrara",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FE"
    },
    {
        codice_regione: "19",
        denominazione: "Portopalo di Capo Passero",
        denominazione_provincia: "Siracusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "SR"
    },
    {
        codice_regione: "20",
        denominazione: "Portoscuso",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "07",
        denominazione: "Portovenere",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "01",
        denominazione: "Portula",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "20",
        denominazione: "Posada",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "05",
        denominazione: "Posina",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "15",
        denominazione: "Positano",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "05",
        denominazione: "Possagno",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "12",
        denominazione: "Posta",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "12",
        denominazione: "Posta Fibreno",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "04",
        denominazione: "Postal",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "Postalesio",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "15",
        denominazione: "Postiglione",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "01",
        denominazione: "Postua",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "17",
        denominazione: "Potenza",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: true,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "11",
        denominazione: "Potenza Picena",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "05",
        denominazione: "Pove del Grappa",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "05",
        denominazione: "Povegliano",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "05",
        denominazione: "Povegliano Veronese",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "08",
        denominazione: "Poviglio",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "06",
        denominazione: "Povoletto",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "12",
        denominazione: "Pozzaglia Sabina",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "03",
        denominazione: "Pozzaglio ed Uniti",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "19",
        denominazione: "Pozzallo",
        denominazione_provincia: "Ragusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "RG"
    },
    {
        codice_regione: "14",
        denominazione: "Pozzilli",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "03",
        denominazione: "Pozzo d'Adda",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Pozzol Groppo",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Pozzolengo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "05",
        denominazione: "Pozzoleone",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "01",
        denominazione: "Pozzolo Formigaro",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "20",
        denominazione: "Pozzomaggiore",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "05",
        denominazione: "Pozzonovo",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "15",
        denominazione: "Pozzuoli",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "06",
        denominazione: "Pozzuolo del Friuli",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Pozzuolo Martesana",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Pradalunga",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "06",
        denominazione: "Pradamano",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "01",
        denominazione: "Pradleves",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Pragelato",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "18",
        denominazione: "Praia a Mare",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "15",
        denominazione: "Praiano",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Pralboino",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Prali",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Pralormo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Pralungo",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "05",
        denominazione: "Pramaggiore",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "01",
        denominazione: "Pramollo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Prarolo",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "01",
        denominazione: "Prarostino",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Prasco",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Prascorsano",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Prata Camportaccio",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "13",
        denominazione: "Prata d'Ansidonia",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "06",
        denominazione: "Prata di Pordenone",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "15",
        denominazione: "Prata di Principato Ultra",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "15",
        denominazione: "Prata Sannita",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "15",
        denominazione: "Pratella",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "01",
        denominazione: "Pratiglione",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "09",
        denominazione: "Prato",
        denominazione_provincia: "Prato",
        denominazione_regione: "Toscana",
        flag_capoluogo: true,
        sigla_provincia: "PO"
    },
    {
        codice_regione: "04",
        denominazione: "Prato allo Stelvio",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "06",
        denominazione: "Prato Carnico",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "01",
        denominazione: "Prato Sesia",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "13",
        denominazione: "Pratola Peligna",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "15",
        denominazione: "Pratola Serra",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "09",
        denominazione: "Pratovecchio Stia",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "06",
        denominazione: "Pravisdomini",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "01",
        denominazione: "Pray",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "01",
        denominazione: "Prazzo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "02",
        denominazione: "Pré-Saint-Didier",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "06",
        denominazione: "Precenicco",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "10",
        denominazione: "Preci",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "04",
        denominazione: "Predaia",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "08",
        denominazione: "Predappio",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "04",
        denominazione: "Predazzo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "04",
        denominazione: "Predoi",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "Predore",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Predosa",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "05",
        denominazione: "Preganziol",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Pregnana Milanese",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "07",
        denominazione: "Prelà",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "03",
        denominazione: "Premana",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "06",
        denominazione: "Premariacco",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "01",
        denominazione: "Premeno",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "01",
        denominazione: "Premia",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "08",
        denominazione: "Premilcuore",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "03",
        denominazione: "Premolo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Premosello-Chiovenda",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "06",
        denominazione: "Preone",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "06",
        denominazione: "Prepotto",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Preseglie",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "15",
        denominazione: "Presenzano",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "03",
        denominazione: "Presezzo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "16",
        denominazione: "Presicce-Acquarica",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "05",
        denominazione: "Pressana",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "13",
        denominazione: "Pretoro",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "03",
        denominazione: "Prevalle",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "13",
        denominazione: "Prezza",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "01",
        denominazione: "Priero",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "15",
        denominazione: "Prignano Cilento",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "08",
        denominazione: "Prignano sulla Secchia",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "03",
        denominazione: "Primaluna",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "04",
        denominazione: "Primiero San Martino di Castrozza",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Priocca",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Priola",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "19",
        denominazione: "Priolo Gargallo",
        denominazione_provincia: "Siracusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "SR"
    },
    {
        codice_regione: "12",
        denominazione: "Priverno",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "19",
        denominazione: "Prizzi",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "12",
        denominazione: "Proceno",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "15",
        denominazione: "Procida",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "07",
        denominazione: "Propata",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "03",
        denominazione: "Proserpio",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "12",
        denominazione: "Prossedi",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "03",
        denominazione: "Provaglio d'Iseo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Provaglio Val Sabbia",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "04",
        denominazione: "Proves",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "14",
        denominazione: "Provvidenti",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "01",
        denominazione: "Prunetto",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Puegnago del Garda",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "15",
        denominazione: "Puglianello",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "20",
        denominazione: "Pula",
        denominazione_provincia: "Cagliari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "CA"
    },
    {
        codice_regione: "06",
        denominazione: "Pulfero",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "16",
        denominazione: "Pulsano",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "03",
        denominazione: "Pumenengo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Pusiano",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "20",
        denominazione: "Putifigari",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "16",
        denominazione: "Putignano",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "15",
        denominazione: "Quadrelle",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "13",
        denominazione: "Quadri",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "01",
        denominazione: "Quagliuzzo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "Qualiano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "01",
        denominazione: "Quaranti",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Quaregna Cerreto",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "01",
        denominazione: "Quargnento",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Quarna Sopra",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "01",
        denominazione: "Quarna Sotto",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "01",
        denominazione: "Quarona",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "09",
        denominazione: "Quarrata",
        denominazione_provincia: "Pistoia",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PT"
    },
    {
        codice_regione: "02",
        denominazione: "Quart",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "15",
        denominazione: "Quarto",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "05",
        denominazione: "Quarto d'Altino",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "20",
        denominazione: "Quartu Sant'Elena",
        denominazione_provincia: "Cagliari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "CA"
    },
    {
        codice_regione: "20",
        denominazione: "Quartucciu",
        denominazione_provincia: "Cagliari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "CA"
    },
    {
        codice_regione: "01",
        denominazione: "Quassolo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Quattordio",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "08",
        denominazione: "Quattro Castella",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "07",
        denominazione: "Quiliano",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "01",
        denominazione: "Quincinetto",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "Quindici",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "03",
        denominazione: "Quingentole",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "03",
        denominazione: "Quintano",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "05",
        denominazione: "Quinto di Treviso",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "01",
        denominazione: "Quinto Vercellese",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "05",
        denominazione: "Quinto Vicentino",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Quinzano d'Oglio",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Quistello",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "04",
        denominazione: "Rabbi",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "16",
        denominazione: "Racale",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "19",
        denominazione: "Racalmuto",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "01",
        denominazione: "Racconigi",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "19",
        denominazione: "Raccuja",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "04",
        denominazione: "Racines",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "09",
        denominazione: "Radda in Chianti",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "19",
        denominazione: "Raddusa",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "09",
        denominazione: "Radicofani",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "09",
        denominazione: "Radicondoli",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "19",
        denominazione: "Raffadali",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "19",
        denominazione: "Ragalna",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "06",
        denominazione: "Ragogna",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "19",
        denominazione: "Ragusa",
        denominazione_provincia: "Ragusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: true,
        sigla_provincia: "RG"
    },
    {
        codice_regione: "13",
        denominazione: "Raiano",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "19",
        denominazione: "Ramacca",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "03",
        denominazione: "Rancio Valcuvia",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Ranco",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "19",
        denominazione: "Randazzo",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "03",
        denominazione: "Ranica",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Ranzanico",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "07",
        denominazione: "Ranzo",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "11",
        denominazione: "Rapagnano",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "07",
        denominazione: "Rapallo",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "13",
        denominazione: "Rapino",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "09",
        denominazione: "Rapolano Terme",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "17",
        denominazione: "Rapolla",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "17",
        denominazione: "Rapone",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "01",
        denominazione: "Rassa",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "04",
        denominazione: "Rasun-Anterselva",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "Rasura",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "19",
        denominazione: "Ravanusa",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "08",
        denominazione: "Ravarino",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "06",
        denominazione: "Ravascletto",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "15",
        denominazione: "Ravello",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "08",
        denominazione: "Ravenna",
        denominazione_provincia: "Ravenna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: true,
        sigla_provincia: "RA"
    },
    {
        codice_regione: "06",
        denominazione: "Raveo",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "15",
        denominazione: "Raviscanina",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "01",
        denominazione: "Re",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "03",
        denominazione: "Rea",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "19",
        denominazione: "Realmonte",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "06",
        denominazione: "Reana del Rojale",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "01",
        denominazione: "Reano",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "Recale",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "11",
        denominazione: "Recanati",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "07",
        denominazione: "Recco",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "01",
        denominazione: "Recetto",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "05",
        denominazione: "Recoaro Terme",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Redavalle",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Redondesco",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "01",
        denominazione: "Refrancore",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "05",
        denominazione: "Refrontolo",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "19",
        denominazione: "Regalbuto",
        denominazione_provincia: "Enna",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "EN"
    },
    {
        codice_regione: "09",
        denominazione: "Reggello",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "18",
        denominazione: "Reggio di Calabria",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: true,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "08",
        denominazione: "Reggio nell'Emilia",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: true,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "08",
        denominazione: "Reggiolo",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "15",
        denominazione: "Reino",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "19",
        denominazione: "Reitano",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "06",
        denominazione: "Remanzacco",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Remedello",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Renate",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "18",
        denominazione: "Rende",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "04",
        denominazione: "Renon",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "05",
        denominazione: "Resana",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Rescaldina",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "06",
        denominazione: "Resia",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "06",
        denominazione: "Resiutta",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "19",
        denominazione: "Resuttano",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "03",
        denominazione: "Retorbido",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Revello",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Revigliasco d'Asti",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "05",
        denominazione: "Revine Lago",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Rezzago",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Rezzato",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "07",
        denominazione: "Rezzo",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "07",
        denominazione: "Rezzoaglio",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "02",
        denominazione: "Rhêmes-Notre-Dame",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "02",
        denominazione: "Rhêmes-Saint-Georges",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "03",
        denominazione: "Rho",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "18",
        denominazione: "Riace",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "07",
        denominazione: "Rialto",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "12",
        denominazione: "Riano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "15",
        denominazione: "Riardo",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "19",
        denominazione: "Ribera",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "01",
        denominazione: "Ribordone",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "18",
        denominazione: "Ricadi",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "01",
        denominazione: "Ricaldone",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "14",
        denominazione: "Riccia",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "08",
        denominazione: "Riccione",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "07",
        denominazione: "Riccò del Golfo di Spezia",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "03",
        denominazione: "Ricengo",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "15",
        denominazione: "Ricigliano",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "05",
        denominazione: "Riese Pio X",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "19",
        denominazione: "Riesi",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "12",
        denominazione: "Rieti",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: true,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "04",
        denominazione: "Rifiano",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "01",
        denominazione: "Rifreddo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "12",
        denominazione: "Rignano Flaminio",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "16",
        denominazione: "Rignano Garganico",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "09",
        denominazione: "Rignano sull'Arno",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "06",
        denominazione: "Rigolato",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "01",
        denominazione: "Rimella",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "08",
        denominazione: "Rimini",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: true,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "09",
        denominazione: "Rio",
        denominazione_provincia: "Livorno",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LI"
    },
    {
        codice_regione: "04",
        denominazione: "Rio di Pusteria",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "08",
        denominazione: "Rio Saliceto",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "12",
        denominazione: "Riofreddo",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "20",
        denominazione: "Riola Sardo",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "08",
        denominazione: "Riolo Terme",
        denominazione_provincia: "Ravenna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RA"
    },
    {
        codice_regione: "08",
        denominazione: "Riolunato",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "07",
        denominazione: "Riomaggiore",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "17",
        denominazione: "Rionero in Vulture",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "14",
        denominazione: "Rionero Sannitico",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "13",
        denominazione: "Ripa Teatina",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "14",
        denominazione: "Ripabottoni",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "17",
        denominazione: "Ripacandida",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "14",
        denominazione: "Ripalimosani",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "03",
        denominazione: "Ripalta Arpina",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Ripalta Cremasca",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Ripalta Guerina",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "09",
        denominazione: "Riparbella",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "11",
        denominazione: "Ripatransone",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "11",
        denominazione: "Ripe San Ginesio",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "12",
        denominazione: "Ripi",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "19",
        denominazione: "Riposto",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "01",
        denominazione: "Rittana",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "04",
        denominazione: "Riva del Garda",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "08",
        denominazione: "Riva del Po",
        denominazione_provincia: "Ferrara",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FE"
    },
    {
        codice_regione: "03",
        denominazione: "Riva di Solto",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "07",
        denominazione: "Riva Ligure",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "01",
        denominazione: "Riva presso Chieri",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Rivalba",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Rivalta Bormida",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Rivalta di Torino",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "05",
        denominazione: "Rivamonte Agordino",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "03",
        denominazione: "Rivanazzano Terme",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Rivara",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Rivarolo Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Rivarolo del Re ed Uniti",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Rivarolo Mantovano",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "01",
        denominazione: "Rivarone",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Rivarossa",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Rive",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "06",
        denominazione: "Rive d'Arcano",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "17",
        denominazione: "Rivello",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "08",
        denominazione: "Rivergaro",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "06",
        denominazione: "Rivignano Teor",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "13",
        denominazione: "Rivisondoli",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "12",
        denominazione: "Rivodutri",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "01",
        denominazione: "Rivoli",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "05",
        denominazione: "Rivoli Veronese",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "03",
        denominazione: "Rivolta d'Adda",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "18",
        denominazione: "Rizziconi",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "05",
        denominazione: "Roana",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "01",
        denominazione: "Roaschia",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Roascio",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Roasio",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "01",
        denominazione: "Roatto",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Robassomero",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Robbiate",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Robbio",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Robecchetto con Induno",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Robecco d'Oglio",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Robecco Pavese",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Robecco sul Naviglio",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Robella",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Robilante",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Roburent",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Rocca Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "12",
        denominazione: "Rocca Canterano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "01",
        denominazione: "Rocca Cigliè",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Rocca d'Arazzo",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "12",
        denominazione: "Rocca d'Arce",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "15",
        denominazione: "Rocca d'Evandro",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "01",
        denominazione: "Rocca de' Baldi",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Rocca de' Giorgi",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "13",
        denominazione: "Rocca di Botte",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "13",
        denominazione: "Rocca di Cambio",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "12",
        denominazione: "Rocca di Cave",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "13",
        denominazione: "Rocca di Mezzo",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "18",
        denominazione: "Rocca di Neto",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "12",
        denominazione: "Rocca di Papa",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "01",
        denominazione: "Rocca Grimalda",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "18",
        denominazione: "Rocca Imperiale",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "12",
        denominazione: "Rocca Massima",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "13",
        denominazione: "Rocca Pia",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "05",
        denominazione: "Rocca Pietore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "12",
        denominazione: "Rocca Priora",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "08",
        denominazione: "Rocca San Casciano",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "15",
        denominazione: "Rocca San Felice",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "13",
        denominazione: "Rocca San Giovanni",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "13",
        denominazione: "Rocca Santa Maria",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "12",
        denominazione: "Rocca Santo Stefano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "12",
        denominazione: "Rocca Sinibalda",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "03",
        denominazione: "Rocca Susella",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "15",
        denominazione: "Roccabascerana",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "18",
        denominazione: "Roccabernarda",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "08",
        denominazione: "Roccabianca",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "01",
        denominazione: "Roccabruna",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "13",
        denominazione: "Roccacasale",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "15",
        denominazione: "Roccadaspide",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "19",
        denominazione: "Roccafiorita",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "11",
        denominazione: "Roccafluvione",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "18",
        denominazione: "Roccaforte del Greco",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "01",
        denominazione: "Roccaforte Ligure",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Roccaforte Mondovì",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "16",
        denominazione: "Roccaforzata",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "03",
        denominazione: "Roccafranca",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "12",
        denominazione: "Roccagiovine",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "15",
        denominazione: "Roccagloriosa",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "12",
        denominazione: "Roccagorga",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "09",
        denominazione: "Roccalbegna",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "19",
        denominazione: "Roccalumera",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "14",
        denominazione: "Roccamandolfi",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "19",
        denominazione: "Roccamena",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "15",
        denominazione: "Roccamonfina",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "13",
        denominazione: "Roccamontepiano",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "13",
        denominazione: "Roccamorice",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "17",
        denominazione: "Roccanova",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "12",
        denominazione: "Roccantica",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "19",
        denominazione: "Roccapalumba",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "15",
        denominazione: "Roccapiemonte",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "15",
        denominazione: "Roccarainola",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "13",
        denominazione: "Roccaraso",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "15",
        denominazione: "Roccaromana",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "13",
        denominazione: "Roccascalegna",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "12",
        denominazione: "Roccasecca",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "12",
        denominazione: "Roccasecca dei Volsci",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "14",
        denominazione: "Roccasicura",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "01",
        denominazione: "Roccasparvera",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "13",
        denominazione: "Roccaspinalveti",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "09",
        denominazione: "Roccastrada",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "19",
        denominazione: "Roccavaldina",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "01",
        denominazione: "Roccaverano",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "07",
        denominazione: "Roccavignale",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "01",
        denominazione: "Roccavione",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "14",
        denominazione: "Roccavivara",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "18",
        denominazione: "Roccella Ionica",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "19",
        denominazione: "Roccella Valdemone",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "14",
        denominazione: "Rocchetta a Volturno",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "01",
        denominazione: "Rocchetta Belbo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "07",
        denominazione: "Rocchetta di Vara",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "15",
        denominazione: "Rocchetta e Croce",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "01",
        denominazione: "Rocchetta Ligure",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "07",
        denominazione: "Rocchetta Nervina",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "01",
        denominazione: "Rocchetta Palafea",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "16",
        denominazione: "Rocchetta Sant'Antonio",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "01",
        denominazione: "Rocchetta Tanaro",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "03",
        denominazione: "Rodano",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Roddi",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Roddino",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Rodello",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "04",
        denominazione: "Rodengo",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "Rodengo Saiano",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Rodero",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "16",
        denominazione: "Rodi Garganico",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "19",
        denominazione: "Rodì Milici",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "03",
        denominazione: "Rodigo",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "03",
        denominazione: "Roè Volciano",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "15",
        denominazione: "Rofrano",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Rogeno",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "18",
        denominazione: "Roggiano Gravina",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "18",
        denominazione: "Roghudi",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "18",
        denominazione: "Rogliano",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Rognano",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Rogno",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Rogolo",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "12",
        denominazione: "Roiate",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "13",
        denominazione: "Roio del Sangro",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "02",
        denominazione: "Roisan",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "01",
        denominazione: "Roletto",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "08",
        denominazione: "Rolo",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "12",
        denominazione: "Roma",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: true,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "15",
        denominazione: "Romagnano al Monte",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "01",
        denominazione: "Romagnano Sesia",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Romagnese",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "20",
        denominazione: "Romana",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Romanengo",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "01",
        denominazione: "Romano Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "05",
        denominazione: "Romano d'Ezzelino",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Romano di Lombardia",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "06",
        denominazione: "Romans d'Isonzo",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "18",
        denominazione: "Rombiolo",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "04",
        denominazione: "Romeno",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Romentino",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "19",
        denominazione: "Rometta",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "05",
        denominazione: "Roncà",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "05",
        denominazione: "Roncade",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Roncadelle",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Roncaro",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "04",
        denominazione: "Roncegno Terme",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Roncello",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "06",
        denominazione: "Ronchi dei Legionari",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "04",
        denominazione: "Ronchi Valsugana",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "06",
        denominazione: "Ronchis",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "12",
        denominazione: "Ronciglione",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "05",
        denominazione: "Ronco all'Adige",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "01",
        denominazione: "Ronco Biellese",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "03",
        denominazione: "Ronco Briantino",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "01",
        denominazione: "Ronco Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "07",
        denominazione: "Ronco Scrivia",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "03",
        denominazione: "Roncobello",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Roncoferraro",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "08",
        denominazione: "Roncofreddo",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "03",
        denominazione: "Roncola",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "07",
        denominazione: "Rondanina",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "01",
        denominazione: "Rondissone",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Ronsecco",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "04",
        denominazione: "Ronzo-Chienis",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "04",
        denominazione: "Ronzone",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Roppolo",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "01",
        denominazione: "Rorà",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "05",
        denominazione: "Rosà",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "18",
        denominazione: "Rosarno",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "03",
        denominazione: "Rosasco",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Rosate",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Rosazza",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "13",
        denominazione: "Rosciano",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "15",
        denominazione: "Roscigno",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "18",
        denominazione: "Rose",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "13",
        denominazione: "Rosello",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "18",
        denominazione: "Roseto Capo Spulico",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "13",
        denominazione: "Roseto degli Abruzzi",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "16",
        denominazione: "Roseto Valfortore",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "09",
        denominazione: "Rosignano Marittimo",
        denominazione_provincia: "Livorno",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LI"
    },
    {
        codice_regione: "01",
        denominazione: "Rosignano Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "05",
        denominazione: "Rosolina",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "19",
        denominazione: "Rosolini",
        denominazione_provincia: "Siracusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "SR"
    },
    {
        codice_regione: "11",
        denominazione: "Rosora",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "01",
        denominazione: "Rossa",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "01",
        denominazione: "Rossana",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "05",
        denominazione: "Rossano Veneto",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "07",
        denominazione: "Rossiglione",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "01",
        denominazione: "Rosta",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Rota d'Imagna",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "18",
        denominazione: "Rota Greca",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "11",
        denominazione: "Rotella",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "14",
        denominazione: "Rotello",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "17",
        denominazione: "Rotonda",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "17",
        denominazione: "Rotondella",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "15",
        denominazione: "Rotondi",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "08",
        denominazione: "Rottofreno",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "05",
        denominazione: "Rotzo",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "01",
        denominazione: "Roure",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Rovasenda",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "03",
        denominazione: "Rovato",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "07",
        denominazione: "Rovegno",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "03",
        denominazione: "Rovellasca",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Rovello Porro",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Roverbella",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "05",
        denominazione: "Roverchiara",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "04",
        denominazione: "Roverè della Luna",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "05",
        denominazione: "Roverè Veronese",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "05",
        denominazione: "Roveredo di Guà",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "06",
        denominazione: "Roveredo in Piano",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "04",
        denominazione: "Rovereto",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Rovescala",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Rovetta",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "12",
        denominazione: "Roviano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "05",
        denominazione: "Rovigo",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: true,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "18",
        denominazione: "Rovito",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "05",
        denominazione: "Rovolon",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Rozzano",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "05",
        denominazione: "Rubano",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "01",
        denominazione: "Rubiana",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "08",
        denominazione: "Rubiera",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "06",
        denominazione: "Ruda",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Rudiano",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Rueglio",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "16",
        denominazione: "Ruffano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "01",
        denominazione: "Ruffia",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "04",
        denominazione: "Ruffrè-Mendola",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "09",
        denominazione: "Rufina",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "20",
        denominazione: "Ruinas",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "04",
        denominazione: "Rumo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "17",
        denominazione: "Ruoti",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "08",
        denominazione: "Russi",
        denominazione_provincia: "Ravenna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RA"
    },
    {
        codice_regione: "16",
        denominazione: "Rutigliano",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "15",
        denominazione: "Rutino",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "15",
        denominazione: "Ruviano",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "17",
        denominazione: "Ruvo del Monte",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "16",
        denominazione: "Ruvo di Puglia",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "12",
        denominazione: "Sabaudia",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "03",
        denominazione: "Sabbio Chiese",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Sabbioneta",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "15",
        denominazione: "Sacco",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "05",
        denominazione: "Saccolongo",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "06",
        denominazione: "Sacile",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "12",
        denominazione: "Sacrofano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "20",
        denominazione: "Sadali",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Sagama",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "01",
        denominazione: "Sagliano Micca",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "06",
        denominazione: "Sagrado",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "04",
        denominazione: "Sagron Mis",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "02",
        denominazione: "Saint-Christophe",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "02",
        denominazione: "Saint-Denis",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "02",
        denominazione: "Saint-Marcel",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "02",
        denominazione: "Saint-Nicolas",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "02",
        denominazione: "Saint-Oyen",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "02",
        denominazione: "Saint-Pierre",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "02",
        denominazione: "Saint-Rhémy-en-Bosses",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "02",
        denominazione: "Saint-Vincent",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "08",
        denominazione: "Sala Baganza",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "01",
        denominazione: "Sala Biellese",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "08",
        denominazione: "Sala Bolognese",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "03",
        denominazione: "Sala Comacina",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "15",
        denominazione: "Sala Consilina",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "01",
        denominazione: "Sala Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "17",
        denominazione: "Salandra",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "19",
        denominazione: "Salaparuta",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "05",
        denominazione: "Salara",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "01",
        denominazione: "Salasco",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "01",
        denominazione: "Salassa",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Salbertrand",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "05",
        denominazione: "Salcedo",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "14",
        denominazione: "Salcito",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "01",
        denominazione: "Sale",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Sale delle Langhe",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Sale Marasino",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Sale San Giovanni",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "19",
        denominazione: "Salemi",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "15",
        denominazione: "Salento",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "01",
        denominazione: "Salerano Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Salerano sul Lambro",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "15",
        denominazione: "Salerno",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: true,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "05",
        denominazione: "Salgareda",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "01",
        denominazione: "Sali Vercellese",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "16",
        denominazione: "Salice Salentino",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "01",
        denominazione: "Saliceto",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "12",
        denominazione: "Salisano",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "05",
        denominazione: "Salizzole",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "13",
        denominazione: "Salle",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "01",
        denominazione: "Salmour",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Salò",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "04",
        denominazione: "Salorno sulla strada del vino",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "08",
        denominazione: "Salsomaggiore Terme",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "03",
        denominazione: "Saltrio",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "08",
        denominazione: "Saludecio",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "01",
        denominazione: "Saluggia",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "01",
        denominazione: "Salussola",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "01",
        denominazione: "Saluzzo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "16",
        denominazione: "Salve",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "03",
        denominazione: "Salvirola",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "15",
        denominazione: "Salvitelle",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "01",
        denominazione: "Salza di Pinerolo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "Salza Irpina",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "05",
        denominazione: "Salzano",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "03",
        denominazione: "Samarate",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "20",
        denominazione: "Samassi",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Samatzai",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "19",
        denominazione: "Sambuca di Sicilia",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "09",
        denominazione: "Sambuca Pistoiese",
        denominazione_provincia: "Pistoia",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PT"
    },
    {
        codice_regione: "12",
        denominazione: "Sambuci",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "01",
        denominazione: "Sambuco",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "16",
        denominazione: "Sammichele di Bari",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "18",
        denominazione: "Samo",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "03",
        denominazione: "Samolaco",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "01",
        denominazione: "Samone",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "04",
        denominazione: "Samone",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Sampeyre",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "20",
        denominazione: "Samugheo",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "07",
        denominazione: "San Bartolomeo al Mare",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "15",
        denominazione: "San Bartolomeo in Galdo",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "03",
        denominazione: "San Bartolomeo Val Cavargna",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "18",
        denominazione: "San Basile",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "20",
        denominazione: "San Basilio",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "03",
        denominazione: "San Bassano",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "05",
        denominazione: "San Bellino",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "01",
        denominazione: "San Benedetto Belbo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "13",
        denominazione: "San Benedetto dei Marsi",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "11",
        denominazione: "San Benedetto del Tronto",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "13",
        denominazione: "San Benedetto in Perillis",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "03",
        denominazione: "San Benedetto Po",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "18",
        denominazione: "San Benedetto Ullano",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "08",
        denominazione: "San Benedetto Val di Sambro",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "01",
        denominazione: "San Benigno Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "San Bernardino Verbano",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "07",
        denominazione: "San Biagio della Cima",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "05",
        denominazione: "San Biagio di Callalta",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "19",
        denominazione: "San Biagio Platani",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "12",
        denominazione: "San Biagio Saracinisco",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "14",
        denominazione: "San Biase",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "05",
        denominazione: "San Bonifacio",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "13",
        denominazione: "San Buono",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "18",
        denominazione: "San Calogero",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "04",
        denominazione: "San Candido",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "06",
        denominazione: "San Canzian d'Isonzo",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "01",
        denominazione: "San Carlo Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "09",
        denominazione: "San Casciano dei Bagni",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "09",
        denominazione: "San Casciano in Val di Pesa",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "16",
        denominazione: "San Cassiano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "19",
        denominazione: "San Cataldo",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "12",
        denominazione: "San Cesareo",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "16",
        denominazione: "San Cesario di Lecce",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "08",
        denominazione: "San Cesario sul Panaro",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "17",
        denominazione: "San Chirico Nuovo",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "17",
        denominazione: "San Chirico Raparo",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "19",
        denominazione: "San Cipirello",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "15",
        denominazione: "San Cipriano d'Aversa",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "15",
        denominazione: "San Cipriano Picentino",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "San Cipriano Po",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "08",
        denominazione: "San Clemente",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "03",
        denominazione: "San Colombano al Lambro",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "San Colombano Belmonte",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "07",
        denominazione: "San Colombano Certenoli",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "19",
        denominazione: "San Cono",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "18",
        denominazione: "San Cosmo Albanese",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "17",
        denominazione: "San Costantino Albanese",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "18",
        denominazione: "San Costantino Calabro",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "11",
        denominazione: "San Costanzo",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "01",
        denominazione: "San Cristoforo",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "San Damiano al Colle",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "San Damiano d'Asti",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "San Damiano Macra",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "06",
        denominazione: "San Daniele del Friuli",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "San Daniele Po",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "18",
        denominazione: "San Demetrio Corone",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "13",
        denominazione: "San Demetrio ne' Vestini",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "01",
        denominazione: "San Didero",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "05",
        denominazione: "San Donà di Piave",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "16",
        denominazione: "San Donaci",
        denominazione_provincia: "Brindisi",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BR"
    },
    {
        codice_regione: "16",
        denominazione: "San Donato di Lecce",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "18",
        denominazione: "San Donato di Ninea",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "San Donato Milanese",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "12",
        denominazione: "San Donato Val di Comino",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "06",
        denominazione: "San Dorligo della Valle",
        denominazione_provincia: "Trieste",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "TS"
    },
    {
        codice_regione: "17",
        denominazione: "San Fele",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "15",
        denominazione: "San Felice a Cancello",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "12",
        denominazione: "San Felice Circeo",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "03",
        denominazione: "San Felice del Benaco",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "14",
        denominazione: "San Felice del Molise",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "08",
        denominazione: "San Felice sul Panaro",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "18",
        denominazione: "San Ferdinando",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "16",
        denominazione: "San Ferdinando di Puglia",
        denominazione_provincia: "Barletta-Andria-Trani",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BT"
    },
    {
        codice_regione: "03",
        denominazione: "San Fermo della Battaglia",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "18",
        denominazione: "San Fili",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "19",
        denominazione: "San Filippo del Mela",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "05",
        denominazione: "San Fior",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "San Fiorano",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "06",
        denominazione: "San Floriano del Collio",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "18",
        denominazione: "San Floro",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "01",
        denominazione: "San Francesco al Campo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "19",
        denominazione: "San Fratello",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "20",
        denominazione: "San Gavino Monreale",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "10",
        denominazione: "San Gemini",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "04",
        denominazione: "San Genesio Atesino",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "San Genesio ed Uniti",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "15",
        denominazione: "San Gennaro Vesuviano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "01",
        denominazione: "San Germano Chisone",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "San Germano Vercellese",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "03",
        denominazione: "San Gervasio Bresciano",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "14",
        denominazione: "San Giacomo degli Schiavoni",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "03",
        denominazione: "San Giacomo delle Segnate",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "03",
        denominazione: "San Giacomo Filippo",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "01",
        denominazione: "San Giacomo Vercellese",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "01",
        denominazione: "San Gillio",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "09",
        denominazione: "San Gimignano",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "11",
        denominazione: "San Ginesio",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "15",
        denominazione: "San Giorgio a Cremano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "12",
        denominazione: "San Giorgio a Liri",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "18",
        denominazione: "San Giorgio Albanese",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "San Giorgio Bigarello",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "01",
        denominazione: "San Giorgio Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "San Giorgio del Sannio",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "06",
        denominazione: "San Giorgio della Richinvelda",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "05",
        denominazione: "San Giorgio delle Pertiche",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "San Giorgio di Lomellina",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "06",
        denominazione: "San Giorgio di Nogaro",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "08",
        denominazione: "San Giorgio di Piano",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "05",
        denominazione: "San Giorgio in Bosco",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "16",
        denominazione: "San Giorgio Ionico",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "15",
        denominazione: "San Giorgio La Molara",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "17",
        denominazione: "San Giorgio Lucano",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "01",
        denominazione: "San Giorgio Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "18",
        denominazione: "San Giorgio Morgeto",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "08",
        denominazione: "San Giorgio Piacentino",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "01",
        denominazione: "San Giorgio Scarampi",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "03",
        denominazione: "San Giorgio su Legnano",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "San Giorio di Susa",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "San Giovanni a Piro",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "06",
        denominazione: "San Giovanni al Natisone",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "San Giovanni Bianco",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "San Giovanni del Dosso",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "04",
        denominazione: "San Giovanni di Fassa",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "18",
        denominazione: "San Giovanni di Gerace",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "19",
        denominazione: "San Giovanni Gemini",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "05",
        denominazione: "San Giovanni Ilarione",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "03",
        denominazione: "San Giovanni in Croce",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "18",
        denominazione: "San Giovanni in Fiore",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "14",
        denominazione: "San Giovanni in Galdo",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "08",
        denominazione: "San Giovanni in Marignano",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "08",
        denominazione: "San Giovanni in Persiceto",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "12",
        denominazione: "San Giovanni Incarico",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "19",
        denominazione: "San Giovanni la Punta",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "13",
        denominazione: "San Giovanni Lipioni",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "05",
        denominazione: "San Giovanni Lupatoto",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "16",
        denominazione: "San Giovanni Rotondo",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "20",
        denominazione: "San Giovanni Suergiu",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "13",
        denominazione: "San Giovanni Teatino",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "09",
        denominazione: "San Giovanni Valdarno",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "14",
        denominazione: "San Giuliano del Sannio",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "14",
        denominazione: "San Giuliano di Puglia",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "03",
        denominazione: "San Giuliano Milanese",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "09",
        denominazione: "San Giuliano Terme",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "19",
        denominazione: "San Giuseppe Jato",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "15",
        denominazione: "San Giuseppe Vesuviano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "10",
        denominazione: "San Giustino",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "01",
        denominazione: "San Giusto Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "09",
        denominazione: "San Godenzo",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "18",
        denominazione: "San Gregorio d'Ippona",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "12",
        denominazione: "San Gregorio da Sassola",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "19",
        denominazione: "San Gregorio di Catania",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "15",
        denominazione: "San Gregorio Magno",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "15",
        denominazione: "San Gregorio Matese",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "05",
        denominazione: "San Gregorio nelle Alpi",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "08",
        denominazione: "San Lazzaro di Savena",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "08",
        denominazione: "San Leo",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "06",
        denominazione: "San Leonardo",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "04",
        denominazione: "San Leonardo in Passiria",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "15",
        denominazione: "San Leucio del Sannio",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "15",
        denominazione: "San Lorenzello",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "18",
        denominazione: "San Lorenzo",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "07",
        denominazione: "San Lorenzo al Mare",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "18",
        denominazione: "San Lorenzo Bellizzi",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "18",
        denominazione: "San Lorenzo del Vallo",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "04",
        denominazione: "San Lorenzo di Sebato",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "04",
        denominazione: "San Lorenzo Dorsino",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "11",
        denominazione: "San Lorenzo in Campo",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "06",
        denominazione: "San Lorenzo Isontino",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "15",
        denominazione: "San Lorenzo Maggiore",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "12",
        denominazione: "San Lorenzo Nuovo",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "18",
        denominazione: "San Luca",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "18",
        denominazione: "San Lucido",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "15",
        denominazione: "San Lupo",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "18",
        denominazione: "San Mango d'Aquino",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "15",
        denominazione: "San Mango Piemonte",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "15",
        denominazione: "San Mango sul Calore",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "15",
        denominazione: "San Marcellino",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "11",
        denominazione: "San Marcello",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "09",
        denominazione: "San Marcello Piteglio",
        denominazione_provincia: "Pistoia",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PT"
    },
    {
        codice_regione: "18",
        denominazione: "San Marco Argentano",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "19",
        denominazione: "San Marco d'Alunzio",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "15",
        denominazione: "San Marco dei Cavoti",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "15",
        denominazione: "San Marco Evangelista",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "16",
        denominazione: "San Marco in Lamis",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "16",
        denominazione: "San Marco la Catola",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "06",
        denominazione: "San Martino al Tagliamento",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "01",
        denominazione: "San Martino Alfieri",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "05",
        denominazione: "San Martino Buon Albergo",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "01",
        denominazione: "San Martino Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "17",
        denominazione: "San Martino d'Agri",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "03",
        denominazione: "San Martino dall'Argine",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "03",
        denominazione: "San Martino del Lago",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "18",
        denominazione: "San Martino di Finita",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "05",
        denominazione: "San Martino di Lupari",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "05",
        denominazione: "San Martino di Venezze",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "04",
        denominazione: "San Martino in Badia",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "04",
        denominazione: "San Martino in Passiria",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "14",
        denominazione: "San Martino in Pensilis",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "08",
        denominazione: "San Martino in Rio",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "03",
        denominazione: "San Martino in Strada",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "15",
        denominazione: "San Martino Sannita",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "03",
        denominazione: "San Martino Siccomario",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "13",
        denominazione: "San Martino sulla Marrucina",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "15",
        denominazione: "San Martino Valle Caudina",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "16",
        denominazione: "San Marzano di San Giuseppe",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "01",
        denominazione: "San Marzano Oliveto",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "15",
        denominazione: "San Marzano sul Sarno",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "14",
        denominazione: "San Massimo",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "01",
        denominazione: "San Maurizio Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "San Maurizio d'Opaglio",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "19",
        denominazione: "San Mauro Castelverde",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "15",
        denominazione: "San Mauro Cilento",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "05",
        denominazione: "San Mauro di Saline",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "17",
        denominazione: "San Mauro Forte",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "15",
        denominazione: "San Mauro la Bruca",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "18",
        denominazione: "San Mauro Marchesato",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "08",
        denominazione: "San Mauro Pascoli",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "01",
        denominazione: "San Mauro Torinese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "05",
        denominazione: "San Michele al Tagliamento",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "04",
        denominazione: "San Michele all'Adige",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "19",
        denominazione: "San Michele di Ganzaria",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "15",
        denominazione: "San Michele di Serino",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "01",
        denominazione: "San Michele Mondovì",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "16",
        denominazione: "San Michele Salentino",
        denominazione_provincia: "Brindisi",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BR"
    },
    {
        codice_regione: "09",
        denominazione: "San Miniato",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "15",
        denominazione: "San Nazzaro",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "01",
        denominazione: "San Nazzaro Sesia",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "San Nazzaro Val Cavargna",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "16",
        denominazione: "San Nicandro Garganico",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "18",
        denominazione: "San Nicola Arcella",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "15",
        denominazione: "San Nicola Baronia",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "18",
        denominazione: "San Nicola da Crissa",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "18",
        denominazione: "San Nicola dell'Alto",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "15",
        denominazione: "San Nicola la Strada",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "15",
        denominazione: "San Nicola Manfredi",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "20",
        denominazione: "San Nicolò d'Arcidano",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "05",
        denominazione: "San Nicolò di Comelico",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "20",
        denominazione: "San Nicolò Gerrei",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "04",
        denominazione: "San Pancrazio",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "16",
        denominazione: "San Pancrazio Salentino",
        denominazione_provincia: "Brindisi",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BR"
    },
    {
        codice_regione: "03",
        denominazione: "San Paolo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "17",
        denominazione: "San Paolo Albanese",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "15",
        denominazione: "San Paolo Bel Sito",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "03",
        denominazione: "San Paolo d'Argon",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "16",
        denominazione: "San Paolo di Civitate",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "11",
        denominazione: "San Paolo di Jesi",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "01",
        denominazione: "San Paolo Solbrito",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "03",
        denominazione: "San Pellegrino Terme",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "06",
        denominazione: "San Pier d'Isonzo",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "19",
        denominazione: "San Pier Niceto",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "19",
        denominazione: "San Piero Patti",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "18",
        denominazione: "San Pietro a Maida",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "06",
        denominazione: "San Pietro al Natisone",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "15",
        denominazione: "San Pietro al Tanagro",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "18",
        denominazione: "San Pietro Apostolo",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "14",
        denominazione: "San Pietro Avellana",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "19",
        denominazione: "San Pietro Clarenza",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "05",
        denominazione: "San Pietro di Cadore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "18",
        denominazione: "San Pietro di Caridà",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "05",
        denominazione: "San Pietro di Feletto",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "05",
        denominazione: "San Pietro di Morubio",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "18",
        denominazione: "San Pietro in Amantea",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "05",
        denominazione: "San Pietro in Cariano",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "08",
        denominazione: "San Pietro in Casale",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "08",
        denominazione: "San Pietro in Cerro",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "05",
        denominazione: "San Pietro in Gu",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "18",
        denominazione: "San Pietro in Guarano",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "16",
        denominazione: "San Pietro in Lama",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "15",
        denominazione: "San Pietro Infine",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "01",
        denominazione: "San Pietro Mosezzo",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "05",
        denominazione: "San Pietro Mussolino",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "01",
        denominazione: "San Pietro Val Lemina",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "16",
        denominazione: "San Pietro Vernotico",
        denominazione_provincia: "Brindisi",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BR"
    },
    {
        codice_regione: "05",
        denominazione: "San Pietro Viminario",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "13",
        denominazione: "San Pio delle Camere",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "08",
        denominazione: "San Polo d'Enza",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "12",
        denominazione: "San Polo dei Cavalieri",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "05",
        denominazione: "San Polo di Piave",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "14",
        denominazione: "San Polo Matese",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "01",
        denominazione: "San Ponso",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "08",
        denominazione: "San Possidonio",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "15",
        denominazione: "San Potito Sannitico",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "15",
        denominazione: "San Potito Ultra",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "15",
        denominazione: "San Prisco",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "18",
        denominazione: "San Procopio",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "08",
        denominazione: "San Prospero",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "09",
        denominazione: "San Quirico d'Orcia",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "06",
        denominazione: "San Quirino",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "01",
        denominazione: "San Raffaele Cimena",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "18",
        denominazione: "San Roberto",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "03",
        denominazione: "San Rocco al Porto",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "09",
        denominazione: "San Romano in Garfagnana",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "15",
        denominazione: "San Rufo",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "19",
        denominazione: "San Salvatore di Fitalia",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "01",
        denominazione: "San Salvatore Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "15",
        denominazione: "San Salvatore Telesino",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "13",
        denominazione: "San Salvo",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "15",
        denominazione: "San Sebastiano al Vesuvio",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "01",
        denominazione: "San Sebastiano Curone",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "San Sebastiano da Po",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "San Secondo di Pinerolo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "08",
        denominazione: "San Secondo Parmense",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "17",
        denominazione: "San Severino Lucano",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "11",
        denominazione: "San Severino Marche",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "16",
        denominazione: "San Severo",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "03",
        denominazione: "San Siro",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "15",
        denominazione: "San Sossio Baronia",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "18",
        denominazione: "San Sostene",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "18",
        denominazione: "San Sosti",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "20",
        denominazione: "San Sperate",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "05",
        denominazione: "San Stino di Livenza",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "15",
        denominazione: "San Tammaro",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "19",
        denominazione: "San Teodoro",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "20",
        denominazione: "San Teodoro",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "05",
        denominazione: "San Tomaso Agordino",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "13",
        denominazione: "San Valentino in Abruzzo Citeriore",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "15",
        denominazione: "San Valentino Torio",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "10",
        denominazione: "San Venanzo",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "05",
        denominazione: "San Vendemiano",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "20",
        denominazione: "San Vero Milis",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "09",
        denominazione: "San Vincenzo",
        denominazione_provincia: "Livorno",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LI"
    },
    {
        codice_regione: "18",
        denominazione: "San Vincenzo La Costa",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "13",
        denominazione: "San Vincenzo Valle Roveto",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "15",
        denominazione: "San Vitaliano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "20",
        denominazione: "San Vito",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "06",
        denominazione: "San Vito al Tagliamento",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "06",
        denominazione: "San Vito al Torre",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "13",
        denominazione: "San Vito Chietino",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "16",
        denominazione: "San Vito dei Normanni",
        denominazione_provincia: "Brindisi",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BR"
    },
    {
        codice_regione: "05",
        denominazione: "San Vito di Cadore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "06",
        denominazione: "San Vito di Fagagna",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "05",
        denominazione: "San Vito di Leguzzano",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "19",
        denominazione: "San Vito Lo Capo",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "12",
        denominazione: "San Vito Romano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "18",
        denominazione: "San Vito sullo Ionio",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "12",
        denominazione: "San Vittore del Lazio",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "03",
        denominazione: "San Vittore Olona",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "05",
        denominazione: "San Zeno di Montagna",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "03",
        denominazione: "San Zeno Naviglio",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "San Zenone al Lambro",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "San Zenone al Po",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "05",
        denominazione: "San Zenone degli Ezzelini",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "16",
        denominazione: "Sanarica",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "01",
        denominazione: "Sandigliano",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "05",
        denominazione: "Sandrigo",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "01",
        denominazione: "Sanfrè",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Sanfront",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Sangano",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Sangiano",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "18",
        denominazione: "Sangineto",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "05",
        denominazione: "Sanguinetto",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "20",
        denominazione: "Sanluri",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "03",
        denominazione: "Sannazzaro de' Burgondi",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "16",
        denominazione: "Sannicandro di Bari",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "16",
        denominazione: "Sannicola",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "07",
        denominazione: "Sanremo",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "09",
        denominazione: "Sansepolcro",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "14",
        denominazione: "Sant'Agapito",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "08",
        denominazione: "Sant'Agata Bolognese",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "15",
        denominazione: "Sant'Agata de' Goti",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "18",
        denominazione: "Sant'Agata del Bianco",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "18",
        denominazione: "Sant'Agata di Esaro",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "19",
        denominazione: "Sant'Agata di Militello",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "16",
        denominazione: "Sant'Agata di Puglia",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "08",
        denominazione: "Sant'Agata Feltria",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "01",
        denominazione: "Sant'Agata Fossili",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "19",
        denominazione: "Sant'Agata li Battiati",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "08",
        denominazione: "Sant'Agata sul Santerno",
        denominazione_provincia: "Ravenna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RA"
    },
    {
        codice_regione: "15",
        denominazione: "Sant'Agnello",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "01",
        denominazione: "Sant'Albano Stura",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Sant'Alessio con Vialone",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "18",
        denominazione: "Sant'Alessio in Aspromonte",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "19",
        denominazione: "Sant'Alessio Siculo",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "19",
        denominazione: "Sant'Alfio",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "01",
        denominazione: "Sant'Ambrogio di Torino",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "05",
        denominazione: "Sant'Ambrogio di Valpolicella",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "12",
        denominazione: "Sant'Ambrogio sul Garigliano",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "15",
        denominazione: "Sant'Anastasia",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "10",
        denominazione: "Sant'Anatolia di Narco",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "18",
        denominazione: "Sant'Andrea Apostolo dello Ionio",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "12",
        denominazione: "Sant'Andrea del Garigliano",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "15",
        denominazione: "Sant'Andrea di Conza",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "20",
        denominazione: "Sant'Andrea Frius",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "15",
        denominazione: "Sant'Angelo a Cupolo",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "15",
        denominazione: "Sant'Angelo a Fasanella",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "15",
        denominazione: "Sant'Angelo a Scala",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "15",
        denominazione: "Sant'Angelo all'Esca",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "15",
        denominazione: "Sant'Angelo d'Alife",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "15",
        denominazione: "Sant'Angelo dei Lombardi",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "14",
        denominazione: "Sant'Angelo del Pesco",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "19",
        denominazione: "Sant'Angelo di Brolo",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "05",
        denominazione: "Sant'Angelo di Piove di Sacco",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "11",
        denominazione: "Sant'Angelo in Pontano",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "11",
        denominazione: "Sant'Angelo in Vado",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "17",
        denominazione: "Sant'Angelo Le Fratte",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "14",
        denominazione: "Sant'Angelo Limosano",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "03",
        denominazione: "Sant'Angelo Lodigiano",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "03",
        denominazione: "Sant'Angelo Lomellina",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "19",
        denominazione: "Sant'Angelo Muxaro",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "12",
        denominazione: "Sant'Angelo Romano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "20",
        denominazione: "Sant'Anna Arresi",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "05",
        denominazione: "Sant'Anna d'Alfaedo",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "15",
        denominazione: "Sant'Antimo",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "20",
        denominazione: "Sant'Antioco",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "01",
        denominazione: "Sant'Antonino di Susa",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "Sant'Antonio Abate",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "20",
        denominazione: "Sant'Antonio di Gallura",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "12",
        denominazione: "Sant'Apollinare",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "17",
        denominazione: "Sant'Arcangelo",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "15",
        denominazione: "Sant'Arcangelo Trimonte",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "15",
        denominazione: "Sant'Arpino",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "15",
        denominazione: "Sant'Arsenio",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "13",
        denominazione: "Sant'Egidio alla Vibrata",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "15",
        denominazione: "Sant'Egidio del Monte Albino",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "05",
        denominazione: "Sant'Elena",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "14",
        denominazione: "Sant'Elena Sannita",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "14",
        denominazione: "Sant'Elia a Pianisi",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "12",
        denominazione: "Sant'Elia Fiumerapido",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "11",
        denominazione: "Sant'Elpidio a Mare",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "13",
        denominazione: "Sant'Eufemia a Maiella",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "18",
        denominazione: "Sant'Eufemia d'Aspromonte",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "13",
        denominazione: "Sant'Eusanio del Sangro",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "13",
        denominazione: "Sant'Eusanio Forconese",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "08",
        denominazione: "Sant'Ilario d'Enza",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "18",
        denominazione: "Sant'Ilario dello Ionio",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "11",
        denominazione: "Sant'Ippolito",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "07",
        denominazione: "Sant'Olcese",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "13",
        denominazione: "Sant'Omero",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "03",
        denominazione: "Sant'Omobono Terme",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "18",
        denominazione: "Sant'Onofrio",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "12",
        denominazione: "Sant'Oreste",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "04",
        denominazione: "Sant'Orsola Terme",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "05",
        denominazione: "Sant'Urbano",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Santa Brigida",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "18",
        denominazione: "Santa Caterina Albanese",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "18",
        denominazione: "Santa Caterina dello Ionio",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "19",
        denominazione: "Santa Caterina Villarmosa",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "16",
        denominazione: "Santa Cesarea Terme",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "18",
        denominazione: "Santa Cristina d'Aspromonte",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "03",
        denominazione: "Santa Cristina e Bissone",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "19",
        denominazione: "Santa Cristina Gela",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "04",
        denominazione: "Santa Cristina Valgardena",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "19",
        denominazione: "Santa Croce Camerina",
        denominazione_provincia: "Ragusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "RG"
    },
    {
        codice_regione: "15",
        denominazione: "Santa Croce del Sannio",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "14",
        denominazione: "Santa Croce di Magliano",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "09",
        denominazione: "Santa Croce sull'Arno",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "18",
        denominazione: "Santa Domenica Talao",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "19",
        denominazione: "Santa Domenica Vittoria",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "19",
        denominazione: "Santa Elisabetta",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "09",
        denominazione: "Santa Fiora",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "19",
        denominazione: "Santa Flavia",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "03",
        denominazione: "Santa Giuletta",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "20",
        denominazione: "Santa Giusta",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "05",
        denominazione: "Santa Giustina",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "05",
        denominazione: "Santa Giustina in Colle",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "09",
        denominazione: "Santa Luce",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "19",
        denominazione: "Santa Lucia del Mela",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "05",
        denominazione: "Santa Lucia di Piave",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "15",
        denominazione: "Santa Lucia di Serino",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "19",
        denominazione: "Santa Margherita di Belice",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "03",
        denominazione: "Santa Margherita di Staffora",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "07",
        denominazione: "Santa Margherita Ligure",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "09",
        denominazione: "Santa Maria a Monte",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "15",
        denominazione: "Santa Maria a Vico",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "15",
        denominazione: "Santa Maria Capua Vetere",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "20",
        denominazione: "Santa Maria Coghinas",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "18",
        denominazione: "Santa Maria del Cedro",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "14",
        denominazione: "Santa Maria del Molise",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "03",
        denominazione: "Santa Maria della Versa",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "19",
        denominazione: "Santa Maria di Licodia",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "05",
        denominazione: "Santa Maria di Sala",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "03",
        denominazione: "Santa Maria Hoè",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "13",
        denominazione: "Santa Maria Imbaro",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "15",
        denominazione: "Santa Maria la Carità",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "15",
        denominazione: "Santa Maria la Fossa",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "06",
        denominazione: "Santa Maria la Longa",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "01",
        denominazione: "Santa Maria Maggiore",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "11",
        denominazione: "Santa Maria Nuova",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "15",
        denominazione: "Santa Marina",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "19",
        denominazione: "Santa Marina Salina",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "12",
        denominazione: "Santa Marinella",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "19",
        denominazione: "Santa Ninfa",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "15",
        denominazione: "Santa Paolina",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "18",
        denominazione: "Santa Severina",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "08",
        denominazione: "Santa Sofia",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "18",
        denominazione: "Santa Sofia d'Epiro",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "19",
        denominazione: "Santa Teresa di Riva",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "20",
        denominazione: "Santa Teresa Gallura",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "19",
        denominazione: "Santa Venerina",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "01",
        denominazione: "Santa Vittoria d'Alba",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "11",
        denominazione: "Santa Vittoria in Matenano",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "20",
        denominazione: "Santadi",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "08",
        denominazione: "Santarcangelo di Romagna",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "13",
        denominazione: "Sante Marie",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "01",
        denominazione: "Santena",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "16",
        denominazione: "Santeramo in Colle",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "01",
        denominazione: "Santhià",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "12",
        denominazione: "Santi Cosma e Damiano",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "07",
        denominazione: "Santo Stefano al Mare",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "01",
        denominazione: "Santo Stefano Belbo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "07",
        denominazione: "Santo Stefano d'Aveto",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "15",
        denominazione: "Santo Stefano del Sole",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "05",
        denominazione: "Santo Stefano di Cadore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "19",
        denominazione: "Santo Stefano di Camastra",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "07",
        denominazione: "Santo Stefano di Magra",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "18",
        denominazione: "Santo Stefano di Rogliano",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "13",
        denominazione: "Santo Stefano di Sessanio",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "18",
        denominazione: "Santo Stefano in Aspromonte",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "03",
        denominazione: "Santo Stefano Lodigiano",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "19",
        denominazione: "Santo Stefano Quisquina",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "01",
        denominazione: "Santo Stefano Roero",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Santo Stefano Ticino",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "15",
        denominazione: "Santomenna",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "12",
        denominazione: "Santopadre",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "05",
        denominazione: "Santorso",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "20",
        denominazione: "Santu Lussurgiu",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "15",
        denominazione: "Sanza",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "04",
        denominazione: "Sanzeno",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "05",
        denominazione: "Saonara",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "19",
        denominazione: "Saponara",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "06",
        denominazione: "Sappada",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "15",
        denominazione: "Sapri",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "18",
        denominazione: "Saracena",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "12",
        denominazione: "Saracinesco",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "05",
        denominazione: "Sarcedo",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "17",
        denominazione: "Sarconi",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "20",
        denominazione: "Sardara",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "01",
        denominazione: "Sardigliano",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "05",
        denominazione: "Sarego",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "04",
        denominazione: "Sarentino",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "01",
        denominazione: "Sarezzano",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Sarezzo",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "08",
        denominazione: "Sarmato",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "05",
        denominazione: "Sarmede",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "11",
        denominazione: "Sarnano",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "03",
        denominazione: "Sarnico",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "15",
        denominazione: "Sarno",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "04",
        denominazione: "Sarnonico",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Saronno",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "02",
        denominazione: "Sarre",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "20",
        denominazione: "Sarroch",
        denominazione_provincia: "Cagliari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "CA"
    },
    {
        codice_regione: "08",
        denominazione: "Sarsina",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "09",
        denominazione: "Sarteano",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "03",
        denominazione: "Sartirana Lomellina",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "20",
        denominazione: "Sarule",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "07",
        denominazione: "Sarzana",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "15",
        denominazione: "Sassano",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "20",
        denominazione: "Sassari",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: true,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "07",
        denominazione: "Sassello",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "09",
        denominazione: "Sassetta",
        denominazione_provincia: "Livorno",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LI"
    },
    {
        codice_regione: "15",
        denominazione: "Sassinoro",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "17",
        denominazione: "Sasso di Castalda",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "08",
        denominazione: "Sasso Marconi",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "11",
        denominazione: "Sassocorvaro Auditore",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "08",
        denominazione: "Sassofeltrio",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "11",
        denominazione: "Sassoferrato",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "08",
        denominazione: "Sassuolo",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "18",
        denominazione: "Satriano",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "17",
        denominazione: "Satriano di Lucania",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "06",
        denominazione: "Sauris",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "01",
        denominazione: "Sauze d'Oulx",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Sauze di Cesana",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "16",
        denominazione: "Sava",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "18",
        denominazione: "Savelli",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "15",
        denominazione: "Saviano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "01",
        denominazione: "Savigliano",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "15",
        denominazione: "Savignano Irpino",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "08",
        denominazione: "Savignano sul Panaro",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "08",
        denominazione: "Savignano sul Rubicone",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "07",
        denominazione: "Savignone",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "03",
        denominazione: "Saviore dell'Adamello",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "19",
        denominazione: "Savoca",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "06",
        denominazione: "Savogna",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "06",
        denominazione: "Savogna d'Isonzo",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "17",
        denominazione: "Savoia di Lucania",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "07",
        denominazione: "Savona",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: true,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "13",
        denominazione: "Scafa",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "15",
        denominazione: "Scafati",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "01",
        denominazione: "Scagnello",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "15",
        denominazione: "Scala",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "18",
        denominazione: "Scala Coeli",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Scaldasole",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "18",
        denominazione: "Scalea",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "01",
        denominazione: "Scalenghe",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "19",
        denominazione: "Scaletta Zanclea",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "15",
        denominazione: "Scampitella",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "18",
        denominazione: "Scandale",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "08",
        denominazione: "Scandiano",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "09",
        denominazione: "Scandicci",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "03",
        denominazione: "Scandolara Ravara",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Scandolara Ripa d'Oglio",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "12",
        denominazione: "Scandriglia",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "13",
        denominazione: "Scanno",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "20",
        denominazione: "Scano di Montiferro",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "09",
        denominazione: "Scansano",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "17",
        denominazione: "Scanzano Jonico",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "03",
        denominazione: "Scanzorosciate",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "14",
        denominazione: "Scapoli",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "09",
        denominazione: "Scarlino",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "01",
        denominazione: "Scarmagno",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Scarnafigi",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "09",
        denominazione: "Scarperia e San Piero",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "04",
        denominazione: "Scena",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "13",
        denominazione: "Scerni",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "10",
        denominazione: "Scheggia e Pascelupo",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "10",
        denominazione: "Scheggino",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "13",
        denominazione: "Schiavi di Abruzzo",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "05",
        denominazione: "Schiavon",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Schignano",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Schilpario",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Schio",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Schivenoglia",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "19",
        denominazione: "Sciacca",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "19",
        denominazione: "Sciara",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "19",
        denominazione: "Scicli",
        denominazione_provincia: "Ragusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "RG"
    },
    {
        codice_regione: "18",
        denominazione: "Scido",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "18",
        denominazione: "Scigliano",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "18",
        denominazione: "Scilla",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "19",
        denominazione: "Scillato",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "01",
        denominazione: "Sciolze",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "Scisciano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "19",
        denominazione: "Sclafani Bagni",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "13",
        denominazione: "Scontrone",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "01",
        denominazione: "Scopa",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "01",
        denominazione: "Scopello",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "13",
        denominazione: "Scoppito",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "19",
        denominazione: "Scordia",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "16",
        denominazione: "Scorrano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "05",
        denominazione: "Scorzè",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "13",
        denominazione: "Scurcola Marsicana",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "04",
        denominazione: "Scurelle",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Scurzolengo",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "07",
        denominazione: "Seborga",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "13",
        denominazione: "Secinaro",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "16",
        denominazione: "Seclì",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "03",
        denominazione: "Secugnago",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "06",
        denominazione: "Sedegliano",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "05",
        denominazione: "Sedico",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "20",
        denominazione: "Sedilo",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "20",
        denominazione: "Sedini",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Sedriano",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Sedrina",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "11",
        denominazione: "Sefro",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "20",
        denominazione: "Segariu",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "09",
        denominazione: "Seggiano",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "12",
        denominazione: "Segni",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "04",
        denominazione: "Segonzano",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Segrate",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "05",
        denominazione: "Segusino",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "20",
        denominazione: "Selargius",
        denominazione_provincia: "Cagliari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "CA"
    },
    {
        codice_regione: "12",
        denominazione: "Selci",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "20",
        denominazione: "Selegas",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "04",
        denominazione: "Sella Giudicarie",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "10",
        denominazione: "Sellano",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "03",
        denominazione: "Sellero",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "18",
        denominazione: "Sellia",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "18",
        denominazione: "Sellia Marina",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "04",
        denominazione: "Selva dei Molini",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "05",
        denominazione: "Selva di Cadore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "05",
        denominazione: "Selva di Progno",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "04",
        denominazione: "Selva di Val Gardena",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "05",
        denominazione: "Selvazzano Dentro",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Selvino",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "20",
        denominazione: "Semestene",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Semiana",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "18",
        denominazione: "Seminara",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "09",
        denominazione: "Semproniano",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "03",
        denominazione: "Senago",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "04",
        denominazione: "Senale-San Felice",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "04",
        denominazione: "Senales",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "20",
        denominazione: "Seneghe",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "15",
        denominazione: "Senerchia",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "03",
        denominazione: "Seniga",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "11",
        denominazione: "Senigallia",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "20",
        denominazione: "Senis",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "17",
        denominazione: "Senise",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "03",
        denominazione: "Senna Comasco",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Senna Lodigiana",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "20",
        denominazione: "Sennariolo",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "20",
        denominazione: "Sennori",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "20",
        denominazione: "Senorbì",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "14",
        denominazione: "Sepino",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "06",
        denominazione: "Sequals",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "09",
        denominazione: "Seravezza",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "20",
        denominazione: "Serdiana",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "03",
        denominazione: "Seregno",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "05",
        denominazione: "Seren del Grappa",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "03",
        denominazione: "Sergnano",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Seriate",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Serina",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "15",
        denominazione: "Serino",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "03",
        denominazione: "Serle",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Sermide e Felonica",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "12",
        denominazione: "Sermoneta",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "05",
        denominazione: "Sernaglia della Battaglia",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Sernio",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "01",
        denominazione: "Serole",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "18",
        denominazione: "Serra d'Aiello",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "11",
        denominazione: "Serra de' Conti",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "07",
        denominazione: "Serra Riccò",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "18",
        denominazione: "Serra San Bruno",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "11",
        denominazione: "Serra San Quirico",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "11",
        denominazione: "Serra Sant'Abbondio",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "16",
        denominazione: "Serracapriola",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "19",
        denominazione: "Serradifalco",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "01",
        denominazione: "Serralunga d'Alba",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Serralunga di Crea",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "20",
        denominazione: "Serramanna",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "08",
        denominazione: "Serramazzoni",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "15",
        denominazione: "Serramezzana",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "13",
        denominazione: "Serramonacesca",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "11",
        denominazione: "Serrapetrona",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "15",
        denominazione: "Serrara Fontana",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "18",
        denominazione: "Serrastretta",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "18",
        denominazione: "Serrata",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "03",
        denominazione: "Serravalle a Po",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "11",
        denominazione: "Serravalle di Chienti",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "01",
        denominazione: "Serravalle Langhe",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "09",
        denominazione: "Serravalle Pistoiese",
        denominazione_provincia: "Pistoia",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PT"
    },
    {
        codice_regione: "01",
        denominazione: "Serravalle Scrivia",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Serravalle Sesia",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "15",
        denominazione: "Serre",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "20",
        denominazione: "Serrenti",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Serri",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "12",
        denominazione: "Serrone",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "18",
        denominazione: "Sersale",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "11",
        denominazione: "Servigliano",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "15",
        denominazione: "Sessa Aurunca",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "15",
        denominazione: "Sessa Cilento",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "01",
        denominazione: "Sessame",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "14",
        denominazione: "Sessano del Molise",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "07",
        denominazione: "Sesta Godano",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "09",
        denominazione: "Sestino",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "04",
        denominazione: "Sesto",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "06",
        denominazione: "Sesto al Reghena",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "03",
        denominazione: "Sesto Calende",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "14",
        denominazione: "Sesto Campano",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "03",
        denominazione: "Sesto ed Uniti",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "09",
        denominazione: "Sesto Fiorentino",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "03",
        denominazione: "Sesto San Giovanni",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "08",
        denominazione: "Sestola",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "07",
        denominazione: "Sestri Levante",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "01",
        denominazione: "Sestriere",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "20",
        denominazione: "Sestu",
        denominazione_provincia: "Cagliari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "CA"
    },
    {
        codice_regione: "03",
        denominazione: "Settala",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "12",
        denominazione: "Settefrati",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "01",
        denominazione: "Settime",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "03",
        denominazione: "Settimo Milanese",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Settimo Rottaro",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "20",
        denominazione: "Settimo San Pietro",
        denominazione_provincia: "Cagliari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "CA"
    },
    {
        codice_regione: "01",
        denominazione: "Settimo Torinese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Settimo Vittone",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "18",
        denominazione: "Settingiano",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "20",
        denominazione: "Setzu",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Seui",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Seulo",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "03",
        denominazione: "Seveso",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "01",
        denominazione: "Sezzadio",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "12",
        denominazione: "Sezze",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "04",
        denominazione: "Sfruz",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "06",
        denominazione: "Sgonico",
        denominazione_provincia: "Trieste",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "TS"
    },
    {
        codice_regione: "12",
        denominazione: "Sgurgola",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "20",
        denominazione: "Siamaggiore",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "20",
        denominazione: "Siamanna",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "15",
        denominazione: "Siano",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "20",
        denominazione: "Siapiccia",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "15",
        denominazione: "Sicignano degli Alburni",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "19",
        denominazione: "Siculiana",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "20",
        denominazione: "Siddi",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "18",
        denominazione: "Siderno",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "09",
        denominazione: "Siena",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: true,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "10",
        denominazione: "Sigillo",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "09",
        denominazione: "Signa",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "04",
        denominazione: "Silandro",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "20",
        denominazione: "Silanus",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "05",
        denominazione: "Silea",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "20",
        denominazione: "Siligo",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "20",
        denominazione: "Siliqua",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Silius",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "09",
        denominazione: "Sillano Giuncugnano",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "01",
        denominazione: "Sillavengo",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "01",
        denominazione: "Silvano d'Orba",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Silvano Pietra",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "13",
        denominazione: "Silvi",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "20",
        denominazione: "Simala",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "20",
        denominazione: "Simaxis",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "18",
        denominazione: "Simbario",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "18",
        denominazione: "Simeri Crichi",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "19",
        denominazione: "Sinagra",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "09",
        denominazione: "Sinalunga",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "20",
        denominazione: "Sindia",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "20",
        denominazione: "Sini",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "01",
        denominazione: "Sinio",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "20",
        denominazione: "Siniscola",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "20",
        denominazione: "Sinnai",
        denominazione_provincia: "Cagliari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "CA"
    },
    {
        codice_regione: "18",
        denominazione: "Sinopoli",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "19",
        denominazione: "Siracusa",
        denominazione_provincia: "Siracusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: true,
        sigla_provincia: "SR"
    },
    {
        codice_regione: "15",
        denominazione: "Sirignano",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "20",
        denominazione: "Siris",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "03",
        denominazione: "Sirmione",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "11",
        denominazione: "Sirolo",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "03",
        denominazione: "Sirone",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Sirtori",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "08",
        denominazione: "Sissa Trecasali",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "20",
        denominazione: "Siurgus Donigala",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "03",
        denominazione: "Siziano",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Sizzano",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "04",
        denominazione: "Sluderno",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "11",
        denominazione: "Smerillo",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "05",
        denominazione: "Soave",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "06",
        denominazione: "Socchieve",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "20",
        denominazione: "Soddì",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "08",
        denominazione: "Sogliano al Rubicone",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "16",
        denominazione: "Sogliano Cavour",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "01",
        denominazione: "Soglio",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "03",
        denominazione: "Soiano del Lago",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "05",
        denominazione: "Solagna",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "19",
        denominazione: "Solarino",
        denominazione_provincia: "Siracusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "SR"
    },
    {
        codice_regione: "03",
        denominazione: "Solaro",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "08",
        denominazione: "Solarolo",
        denominazione_provincia: "Ravenna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RA"
    },
    {
        codice_regione: "03",
        denominazione: "Solarolo Rainerio",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "20",
        denominazione: "Solarussa",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "03",
        denominazione: "Solbiate Arno",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Solbiate con Cagno",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Solbiate Olona",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "07",
        denominazione: "Soldano",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "20",
        denominazione: "Soleminis",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "01",
        denominazione: "Solero",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "05",
        denominazione: "Solesino",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "16",
        denominazione: "Soleto",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "03",
        denominazione: "Solferino",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "08",
        denominazione: "Soliera",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "08",
        denominazione: "Solignano",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "15",
        denominazione: "Solofra",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "01",
        denominazione: "Solonghello",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "15",
        denominazione: "Solopaca",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "03",
        denominazione: "Solto Collina",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Solza",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Somaglia",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "01",
        denominazione: "Somano",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Somma Lombardo",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "15",
        denominazione: "Somma Vesuviana",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "05",
        denominazione: "Sommacampagna",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "01",
        denominazione: "Sommariva del Bosco",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Sommariva Perno",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "19",
        denominazione: "Sommatino",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "03",
        denominazione: "Sommo",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "05",
        denominazione: "Sona",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "03",
        denominazione: "Soncino",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Sondalo",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "03",
        denominazione: "Sondrio",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: true,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "03",
        denominazione: "Songavazzo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Sonico",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "12",
        denominazione: "Sonnino",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "12",
        denominazione: "Sora",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "04",
        denominazione: "Soraga di Fassa",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "08",
        denominazione: "Soragna",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "09",
        denominazione: "Sorano",
        denominazione_provincia: "Grosseto",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "GR"
    },
    {
        codice_regione: "18",
        denominazione: "Sorbo San Basile",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "15",
        denominazione: "Sorbo Serpico",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "08",
        denominazione: "Sorbolo Mezzani",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "01",
        denominazione: "Sordevolo",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "03",
        denominazione: "Sordio",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "03",
        denominazione: "Soresina",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "05",
        denominazione: "Sorgà",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "20",
        denominazione: "Sorgono",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "07",
        denominazione: "Sori",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "18",
        denominazione: "Sorianello",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "18",
        denominazione: "Soriano Calabro",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "12",
        denominazione: "Soriano nel Cimino",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "03",
        denominazione: "Sorico",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "01",
        denominazione: "Soriso",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Sorisole",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Sormano",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "20",
        denominazione: "Sorradile",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "15",
        denominazione: "Sorrento",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "20",
        denominazione: "Sorso",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "19",
        denominazione: "Sortino",
        denominazione_provincia: "Siracusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "SR"
    },
    {
        codice_regione: "03",
        denominazione: "Sospiro",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "05",
        denominazione: "Sospirolo",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "05",
        denominazione: "Sossano",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "01",
        denominazione: "Sostegno",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "03",
        denominazione: "Sotto il Monte Giovanni XXIII",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "04",
        denominazione: "Sover",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "18",
        denominazione: "Soverato",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "03",
        denominazione: "Sovere",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "18",
        denominazione: "Soveria Mannelli",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "18",
        denominazione: "Soveria Simeri",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "05",
        denominazione: "Soverzene",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "09",
        denominazione: "Sovicille",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "03",
        denominazione: "Sovico",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "05",
        denominazione: "Sovramonte",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "01",
        denominazione: "Sozzago",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "19",
        denominazione: "Spadafora",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "18",
        denominazione: "Spadola",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "15",
        denominazione: "Sparanise",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "01",
        denominazione: "Sparone",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "16",
        denominazione: "Specchia",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "10",
        denominazione: "Spello",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "19",
        denominazione: "Sperlinga",
        denominazione_provincia: "Enna",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "EN"
    },
    {
        codice_regione: "12",
        denominazione: "Sperlonga",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "15",
        denominazione: "Sperone",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "03",
        denominazione: "Spessa",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "18",
        denominazione: "Spezzano Albanese",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "18",
        denominazione: "Spezzano della Sila",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "04",
        denominazione: "Spiazzo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Spigno Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "12",
        denominazione: "Spigno Saturnia",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "08",
        denominazione: "Spilamberto",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "06",
        denominazione: "Spilimbergo",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "18",
        denominazione: "Spilinga",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "03",
        denominazione: "Spinadesco",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "16",
        denominazione: "Spinazzola",
        denominazione_provincia: "Barletta-Andria-Trani",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BT"
    },
    {
        codice_regione: "05",
        denominazione: "Spinea",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "03",
        denominazione: "Spineda",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "14",
        denominazione: "Spinete",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "01",
        denominazione: "Spineto Scrivia",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "11",
        denominazione: "Spinetoli",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "03",
        denominazione: "Spino d'Adda",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Spinone al Lago",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "17",
        denominazione: "Spinoso",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "03",
        denominazione: "Spirano",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "10",
        denominazione: "Spoleto",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "13",
        denominazione: "Spoltore",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "16",
        denominazione: "Spongano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "04",
        denominazione: "Spormaggiore",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "04",
        denominazione: "Sporminore",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "07",
        denominazione: "Spotorno",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "05",
        denominazione: "Spresiano",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Spriana",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "18",
        denominazione: "Squillace",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "16",
        denominazione: "Squinzano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "11",
        denominazione: "Staffolo",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "03",
        denominazione: "Stagno Lombardo",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "18",
        denominazione: "Staiti",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "18",
        denominazione: "Stalettì",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "05",
        denominazione: "Stanghella",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "06",
        denominazione: "Staranzano",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "16",
        denominazione: "Statte",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "01",
        denominazione: "Stazzano",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "09",
        denominazione: "Stazzema",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "03",
        denominazione: "Stazzona",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "18",
        denominazione: "Stefanaconi",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "07",
        denominazione: "Stella",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "15",
        denominazione: "Stella Cilento",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "07",
        denominazione: "Stellanello",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "04",
        denominazione: "Stelvio",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "04",
        denominazione: "Stenico",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "16",
        denominazione: "Sternatia",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "03",
        denominazione: "Stezzano",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Stienta",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "17",
        denominazione: "Stigliano",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "18",
        denominazione: "Stignano",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "18",
        denominazione: "Stilo",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "12",
        denominazione: "Stimigliano",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "20",
        denominazione: "Stintino",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "15",
        denominazione: "Stio",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "16",
        denominazione: "Stornara",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "16",
        denominazione: "Stornarella",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "04",
        denominazione: "Storo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "05",
        denominazione: "Stra",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "03",
        denominazione: "Stradella",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Strambinello",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Strambino",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "12",
        denominazione: "Strangolagalli",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "06",
        denominazione: "Stregna",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "04",
        denominazione: "Strembo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Stresa",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "01",
        denominazione: "Strevi",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "15",
        denominazione: "Striano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "01",
        denominazione: "Strona",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "10",
        denominazione: "Stroncone",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "18",
        denominazione: "Strongoli",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "01",
        denominazione: "Stroppiana",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "01",
        denominazione: "Stroppo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Strozza",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "15",
        denominazione: "Sturno",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "03",
        denominazione: "Suardi",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "09",
        denominazione: "Subbiano",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "12",
        denominazione: "Subiaco",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "15",
        denominazione: "Succivo",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "03",
        denominazione: "Sueglio",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "20",
        denominazione: "Suelli",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "03",
        denominazione: "Suello",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Suisio",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Sulbiate",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "13",
        denominazione: "Sulmona",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "03",
        denominazione: "Sulzano",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Sumirago",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "15",
        denominazione: "Summonte",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "20",
        denominazione: "Suni",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "01",
        denominazione: "Suno",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "16",
        denominazione: "Supersano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "12",
        denominazione: "Supino",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "16",
        denominazione: "Surano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "16",
        denominazione: "Surbo",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "01",
        denominazione: "Susa",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "05",
        denominazione: "Susegana",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Sustinente",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "19",
        denominazione: "Sutera",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "12",
        denominazione: "Sutri",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "06",
        denominazione: "Sutrio",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "09",
        denominazione: "Suvereto",
        denominazione_provincia: "Livorno",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LI"
    },
    {
        codice_regione: "03",
        denominazione: "Suzzara",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "03",
        denominazione: "Taceno",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "20",
        denominazione: "Tadasuni",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "07",
        denominazione: "Taggia",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "13",
        denominazione: "Tagliacozzo",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "05",
        denominazione: "Taglio di Po",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "01",
        denominazione: "Tagliolo Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "05",
        denominazione: "Taibon Agordino",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "03",
        denominazione: "Taino",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "06",
        denominazione: "Taipana",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "08",
        denominazione: "Talamello",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "03",
        denominazione: "Talamona",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "20",
        denominazione: "Talana",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "03",
        denominazione: "Taleggio",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "09",
        denominazione: "Talla",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "06",
        denominazione: "Talmassons",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "05",
        denominazione: "Tambre",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "19",
        denominazione: "Taormina",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "12",
        denominazione: "Tarano",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "13",
        denominazione: "Taranta Peligna",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "01",
        denominazione: "Tarantasca",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "16",
        denominazione: "Taranto",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: true,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "06",
        denominazione: "Tarcento",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "12",
        denominazione: "Tarquinia",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "18",
        denominazione: "Tarsia",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Tartano",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "06",
        denominazione: "Tarvisio",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "05",
        denominazione: "Tarzo",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "01",
        denominazione: "Tassarolo",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "15",
        denominazione: "Taurano",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "15",
        denominazione: "Taurasi",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "18",
        denominazione: "Taurianova",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "16",
        denominazione: "Taurisano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "06",
        denominazione: "Tavagnacco",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "01",
        denominazione: "Tavagnasco",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Tavazzano con Villavesco",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "14",
        denominazione: "Tavenna",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "18",
        denominazione: "Taverna",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "03",
        denominazione: "Tavernerio",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Tavernola Bergamasca",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Tavernole sul Mella",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "16",
        denominazione: "Taviano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "01",
        denominazione: "Tavigliano",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "11",
        denominazione: "Tavoleto",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "11",
        denominazione: "Tavullia",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "17",
        denominazione: "Teana",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "15",
        denominazione: "Teano",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "15",
        denominazione: "Teggiano",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Teglio",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "05",
        denominazione: "Teglio Veneto",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "15",
        denominazione: "Telese Terme",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "03",
        denominazione: "Telgate",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "20",
        denominazione: "Telti",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "04",
        denominazione: "Telve",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "04",
        denominazione: "Telve di Sopra",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "20",
        denominazione: "Tempio Pausania",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Temù",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "04",
        denominazione: "Tenna",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "04",
        denominazione: "Tenno",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "05",
        denominazione: "Teolo",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "15",
        denominazione: "Teora",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "13",
        denominazione: "Teramo",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: true,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "01",
        denominazione: "Terdobbiate",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "12",
        denominazione: "Terelle",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "04",
        denominazione: "Terento",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "08",
        denominazione: "Terenzo",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "20",
        denominazione: "Tergu",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "04",
        denominazione: "Terlano",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "16",
        denominazione: "Terlizzi",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "19",
        denominazione: "Terme Vigliatore",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "04",
        denominazione: "Termeno sulla strada del vino",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "19",
        denominazione: "Termini Imerese",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "14",
        denominazione: "Termoli",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "03",
        denominazione: "Ternate",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "01",
        denominazione: "Ternengo",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "10",
        denominazione: "Terni",
        denominazione_provincia: "Terni",
        denominazione_regione: "Umbria",
        flag_capoluogo: true,
        sigla_provincia: "TR"
    },
    {
        codice_regione: "03",
        denominazione: "Terno d'Isola",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "12",
        denominazione: "Terracina",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "04",
        denominazione: "Terragnolo",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "20",
        denominazione: "Terralba",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "18",
        denominazione: "Terranova da Sibari",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Terranova dei Passerini",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "17",
        denominazione: "Terranova di Pollino",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "18",
        denominazione: "Terranova Sappo Minulio",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "09",
        denominazione: "Terranuova Bracciolini",
        denominazione_provincia: "Arezzo",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "AR"
    },
    {
        codice_regione: "19",
        denominazione: "Terrasini",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "05",
        denominazione: "Terrassa Padovana",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "18",
        denominazione: "Terravecchia",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "05",
        denominazione: "Terrazzo",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "04",
        denominazione: "Terre d'Adige",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "08",
        denominazione: "Terre del Reno",
        denominazione_provincia: "Ferrara",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FE"
    },
    {
        codice_regione: "11",
        denominazione: "Terre Roveresche",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "09",
        denominazione: "Terricciola",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "01",
        denominazione: "Terruggia",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "20",
        denominazione: "Tertenia",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "15",
        denominazione: "Terzigno",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "01",
        denominazione: "Terzo",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "06",
        denominazione: "Terzo d'Aquileia",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "04",
        denominazione: "Terzolas",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "07",
        denominazione: "Terzorio",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "04",
        denominazione: "Tesero",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "04",
        denominazione: "Tesimo",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "12",
        denominazione: "Tessennano",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "07",
        denominazione: "Testico",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "20",
        denominazione: "Teti",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "20",
        denominazione: "Teulada",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "15",
        denominazione: "Teverola",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "05",
        denominazione: "Tezze sul Brenta",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "05",
        denominazione: "Thiene",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "20",
        denominazione: "Thiesi",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "20",
        denominazione: "Tiana",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "03",
        denominazione: "Ticengo",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "01",
        denominazione: "Ticineto",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "16",
        denominazione: "Tiggiano",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "07",
        denominazione: "Tiglieto",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "01",
        denominazione: "Tigliole",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "03",
        denominazione: "Tignale",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "20",
        denominazione: "Tinnura",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "13",
        denominazione: "Tione degli Abruzzi",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "04",
        denominazione: "Tione di Trento",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "03",
        denominazione: "Tirano",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "04",
        denominazione: "Tires",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "18",
        denominazione: "Tiriolo",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "04",
        denominazione: "Tirolo",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "20",
        denominazione: "Tissi",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "17",
        denominazione: "Tito",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "12",
        denominazione: "Tivoli",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "08",
        denominazione: "Tizzano Val Parma",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "08",
        denominazione: "Toano",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "15",
        denominazione: "Tocco Caudio",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "13",
        denominazione: "Tocco da Casauria",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "01",
        denominazione: "Toceno",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "10",
        denominazione: "Todi",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "12",
        denominazione: "Toffia",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "07",
        denominazione: "Toirano",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "11",
        denominazione: "Tolentino",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "12",
        denominazione: "Tolfa",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "01",
        denominazione: "Tollegno",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "13",
        denominazione: "Tollo",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "06",
        denominazione: "Tolmezzo",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "17",
        denominazione: "Tolve",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "05",
        denominazione: "Tombolo",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "04",
        denominazione: "Ton",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "20",
        denominazione: "Tonara",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "01",
        denominazione: "Tonco",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "05",
        denominazione: "Tonezza del Cimone",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "15",
        denominazione: "Tora e Piccilli",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "18",
        denominazione: "Torano Castello",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "13",
        denominazione: "Torano Nuovo",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "03",
        denominazione: "Torbole Casaglia",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "04",
        denominazione: "Torcegno",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "15",
        denominazione: "Torchiara",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "16",
        denominazione: "Torchiarolo",
        denominazione_provincia: "Brindisi",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BR"
    },
    {
        codice_regione: "15",
        denominazione: "Torella dei Lombardi",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "14",
        denominazione: "Torella del Sannio",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "10",
        denominazione: "Torgiano",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "02",
        denominazione: "Torgnon",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "01",
        denominazione: "Torino",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: true,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "13",
        denominazione: "Torino di Sangro",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "16",
        denominazione: "Toritto",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "03",
        denominazione: "Torlino Vimercati",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "01",
        denominazione: "Tornaco",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "13",
        denominazione: "Tornareccio",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "03",
        denominazione: "Tornata",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "13",
        denominazione: "Tornimparte",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "03",
        denominazione: "Torno",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "08",
        denominazione: "Tornolo",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "14",
        denominazione: "Toro",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "20",
        denominazione: "Torpè",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "15",
        denominazione: "Torraca",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "20",
        denominazione: "Torralba",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Torrazza Coste",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Torrazza Piemonte",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Torrazzo",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "15",
        denominazione: "Torre Annunziata",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "03",
        denominazione: "Torre Beretti e Castellaro",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Torre Boldone",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Torre Bormida",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "12",
        denominazione: "Torre Cajetani",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "01",
        denominazione: "Torre Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Torre d'Arese",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Torre d'Isola",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Torre de' Busi",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Torre de' Negri",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "13",
        denominazione: "Torre de' Passeri",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "03",
        denominazione: "Torre de' Picenardi",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "03",
        denominazione: "Torre de' Roveri",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "15",
        denominazione: "Torre del Greco",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "05",
        denominazione: "Torre di Mosto",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "18",
        denominazione: "Torre di Ruggiero",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "03",
        denominazione: "Torre di Santa Maria",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "15",
        denominazione: "Torre Le Nocelle",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "01",
        denominazione: "Torre Mondovì",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "15",
        denominazione: "Torre Orsaia",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Torre Pallavicina",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Torre Pellice",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Torre San Giorgio",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "11",
        denominazione: "Torre San Patrizio",
        denominazione_provincia: "Fermo",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "FM"
    },
    {
        codice_regione: "16",
        denominazione: "Torre Santa Susanna",
        denominazione_provincia: "Brindisi",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BR"
    },
    {
        codice_regione: "06",
        denominazione: "Torreano",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "05",
        denominazione: "Torrebelvicino",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "13",
        denominazione: "Torrebruna",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "15",
        denominazione: "Torrecuso",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "05",
        denominazione: "Torreglia",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "19",
        denominazione: "Torregrotta",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "16",
        denominazione: "Torremaggiore",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "19",
        denominazione: "Torrenova",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "01",
        denominazione: "Torresina",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "19",
        denominazione: "Torretta",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "03",
        denominazione: "Torrevecchia Pia",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "13",
        denominazione: "Torrevecchia Teatina",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "05",
        denominazione: "Torri del Benaco",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "05",
        denominazione: "Torri di Quartesolo",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "12",
        denominazione: "Torri in Sabina",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "12",
        denominazione: "Torrice",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "16",
        denominazione: "Torricella",
        denominazione_provincia: "Taranto",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "TA"
    },
    {
        codice_regione: "03",
        denominazione: "Torricella del Pizzo",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "12",
        denominazione: "Torricella in Sabina",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "13",
        denominazione: "Torricella Peligna",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "13",
        denominazione: "Torricella Sicura",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "03",
        denominazione: "Torricella Verzate",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "07",
        denominazione: "Torriglia",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "08",
        denominazione: "Torrile",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "15",
        denominazione: "Torrioni",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "09",
        denominazione: "Torrita di Siena",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "12",
        denominazione: "Torrita Tiberina",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "20",
        denominazione: "Tortolì",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "01",
        denominazione: "Tortona",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "18",
        denominazione: "Tortora",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "15",
        denominazione: "Tortorella",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "13",
        denominazione: "Tortoreto",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "19",
        denominazione: "Tortorici",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "06",
        denominazione: "Torviscosa",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "03",
        denominazione: "Toscolano-Maderno",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "13",
        denominazione: "Tossicia",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "03",
        denominazione: "Tovo di Sant'Agata",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "07",
        denominazione: "Tovo San Giacomo",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "19",
        denominazione: "Trabia",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "03",
        denominazione: "Tradate",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "20",
        denominazione: "Tramatza",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "04",
        denominazione: "Trambileno",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "15",
        denominazione: "Tramonti",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "06",
        denominazione: "Tramonti di Sopra",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "06",
        denominazione: "Tramonti di Sotto",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "17",
        denominazione: "Tramutola",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "01",
        denominazione: "Trana",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "16",
        denominazione: "Trani",
        denominazione_provincia: "Barletta-Andria-Trani",
        denominazione_regione: "Puglia",
        flag_capoluogo: true,
        sigla_provincia: "BT"
    },
    {
        codice_regione: "03",
        denominazione: "Traona",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "19",
        denominazione: "Trapani",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: true,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "19",
        denominazione: "Trappeto",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "01",
        denominazione: "Trarego Viggiona",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "13",
        denominazione: "Trasacco",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "06",
        denominazione: "Trasaghis",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "01",
        denominazione: "Trasquera",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "20",
        denominazione: "Tratalias",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "03",
        denominazione: "Travacò Siccomario",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Travagliato",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Travedona-Monate",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "01",
        denominazione: "Traversella",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "08",
        denominazione: "Traversetolo",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "01",
        denominazione: "Traves",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "06",
        denominazione: "Travesio",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "08",
        denominazione: "Travo",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "04",
        denominazione: "Tre Ville",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "05",
        denominazione: "Trebaseleghe",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "18",
        denominazione: "Trebisacce",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "15",
        denominazione: "Trecase",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "19",
        denominazione: "Trecastagni",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "11",
        denominazione: "Trecastelli",
        denominazione_provincia: "Ancona",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AN"
    },
    {
        codice_regione: "01",
        denominazione: "Trecate",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "17",
        denominazione: "Trecchina",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "05",
        denominazione: "Trecenta",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "08",
        denominazione: "Tredozio",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "13",
        denominazione: "Treglio",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "05",
        denominazione: "Tregnago",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "11",
        denominazione: "Treia",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "01",
        denominazione: "Treiso",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "19",
        denominazione: "Tremestieri Etneo",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "03",
        denominazione: "Tremezzina",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Tremosine sul Garda",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "15",
        denominazione: "Trentinara",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "04",
        denominazione: "Trento",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: true,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "15",
        denominazione: "Trentola Ducenta",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "03",
        denominazione: "Trenzano",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "06",
        denominazione: "Treppo Grande",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "06",
        denominazione: "Treppo Ligosullo",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "16",
        denominazione: "Trepuzzi",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "09",
        denominazione: "Trequanda",
        denominazione_provincia: "Siena",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "SI"
    },
    {
        codice_regione: "09",
        denominazione: "Tresana",
        denominazione_provincia: "Massa-Carrara",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "MS"
    },
    {
        codice_regione: "03",
        denominazione: "Trescore Balneario",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Trescore Cremasco",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "08",
        denominazione: "Tresignana",
        denominazione_provincia: "Ferrara",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FE"
    },
    {
        codice_regione: "03",
        denominazione: "Tresivio",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "20",
        denominazione: "Tresnuraghes",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "05",
        denominazione: "Trevenzuolo",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "10",
        denominazione: "Trevi",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "12",
        denominazione: "Trevi nel Lazio",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "15",
        denominazione: "Trevico",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "03",
        denominazione: "Treviglio",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Trevignano",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "12",
        denominazione: "Trevignano Romano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "01",
        denominazione: "Treville",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Treviolo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Treviso",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: true,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Treviso Bresciano",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Trezzano Rosa",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Trezzano sul Naviglio",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Trezzo sull'Adda",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Trezzo Tinella",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Trezzone",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "05",
        denominazione: "Tribano",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Tribiano",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "07",
        denominazione: "Tribogna",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "17",
        denominazione: "Tricarico",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "16",
        denominazione: "Tricase",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "01",
        denominazione: "Tricerro",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "06",
        denominazione: "Tricesimo",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "20",
        denominazione: "Triei",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "06",
        denominazione: "Trieste",
        denominazione_provincia: "Trieste",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: true,
        sigla_provincia: "TS"
    },
    {
        codice_regione: "16",
        denominazione: "Triggiano",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "03",
        denominazione: "Trigolo",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "01",
        denominazione: "Trinità",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "20",
        denominazione: "Trinità d'Agultu e Vignola",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "16",
        denominazione: "Trinitapoli",
        denominazione_provincia: "Barletta-Andria-Trani",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BT"
    },
    {
        codice_regione: "01",
        denominazione: "Trino",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "07",
        denominazione: "Triora",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "19",
        denominazione: "Tripi",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "01",
        denominazione: "Trisobbio",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "05",
        denominazione: "Trissino",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Triuggio",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "14",
        denominazione: "Trivento",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "12",
        denominazione: "Trivigliano",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "06",
        denominazione: "Trivignano Udinese",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "17",
        denominazione: "Trivigno",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "03",
        denominazione: "Trivolzio",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "04",
        denominazione: "Trodena nel parco naturale",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "01",
        denominazione: "Trofarello",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "16",
        denominazione: "Troia",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "19",
        denominazione: "Troina",
        denominazione_provincia: "Enna",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "EN"
    },
    {
        codice_regione: "03",
        denominazione: "Tromello",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Trontano",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "03",
        denominazione: "Tronzano Lago Maggiore",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "01",
        denominazione: "Tronzano Vercellese",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "18",
        denominazione: "Tropea",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "03",
        denominazione: "Trovo",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Truccazzano",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "04",
        denominazione: "Tubre",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "14",
        denominazione: "Tufara",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "13",
        denominazione: "Tufillo",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "15",
        denominazione: "Tufino",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "15",
        denominazione: "Tufo",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "16",
        denominazione: "Tuglie",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "20",
        denominazione: "Tuili",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Tula",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "10",
        denominazione: "Tuoro sul Trasimeno",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "12",
        denominazione: "Turania",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "03",
        denominazione: "Turano Lodigiano",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "03",
        denominazione: "Turate",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Turbigo",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "16",
        denominazione: "Turi",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "20",
        denominazione: "Turri",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "06",
        denominazione: "Turriaco",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "13",
        denominazione: "Turrivalignani",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "17",
        denominazione: "Tursi",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "19",
        denominazione: "Tusa",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "12",
        denominazione: "Tuscania",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "03",
        denominazione: "Ubiale Clanezzo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Uboldo",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "19",
        denominazione: "Ucria",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "06",
        denominazione: "Udine",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: true,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "16",
        denominazione: "Ugento",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "16",
        denominazione: "Uggiano la Chiesa",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "20",
        denominazione: "Ulà Tirso",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "20",
        denominazione: "Ulassai",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "04",
        denominazione: "Ultimo",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "10",
        denominazione: "Umbertide",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "18",
        denominazione: "Umbriatico",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "03",
        denominazione: "Urago d'Oglio",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "20",
        denominazione: "Uras",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "05",
        denominazione: "Urbana",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "11",
        denominazione: "Urbania",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "07",
        denominazione: "Urbe",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "11",
        denominazione: "Urbino",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "11",
        denominazione: "Urbisaglia",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "03",
        denominazione: "Urgnano",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "20",
        denominazione: "Uri",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "14",
        denominazione: "Ururi",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "20",
        denominazione: "Urzulei",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "07",
        denominazione: "Uscio",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "20",
        denominazione: "Usellus",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "20",
        denominazione: "Usini",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Usmate Velate",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "20",
        denominazione: "Ussana",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Ussaramanna",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Ussassai",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "01",
        denominazione: "Usseaux",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Usseglio",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "11",
        denominazione: "Ussita",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "19",
        denominazione: "Ustica",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "20",
        denominazione: "Uta",
        denominazione_provincia: "Cagliari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "CA"
    },
    {
        codice_regione: "09",
        denominazione: "Uzzano",
        denominazione_provincia: "Pistoia",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PT"
    },
    {
        codice_regione: "18",
        denominazione: "Vaccarizzo Albanese",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "12",
        denominazione: "Vacone",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "13",
        denominazione: "Vacri",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "04",
        denominazione: "Vadena",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "07",
        denominazione: "Vado Ligure",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "09",
        denominazione: "Vagli Sotto",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "09",
        denominazione: "Vaglia",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "17",
        denominazione: "Vaglio Basilicata",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "01",
        denominazione: "Vaglio Serra",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "09",
        denominazione: "Vaiano",
        denominazione_provincia: "Prato",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PO"
    },
    {
        codice_regione: "03",
        denominazione: "Vaiano Cremasco",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "01",
        denominazione: "Vaie",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Vailate",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "15",
        denominazione: "Vairano Patenora",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "06",
        denominazione: "Vajont",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "03",
        denominazione: "Val Brembilla",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Val della Torre",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Val di Chy",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Val di Nizza",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "04",
        denominazione: "Val di Vizze",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "05",
        denominazione: "Val di Zoldo",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "05",
        denominazione: "Val Liona",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Val Masino",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "03",
        denominazione: "Val Rezzo",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Valbondione",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Valbrembo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Valbrenta",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "07",
        denominazione: "Valbrevenna",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "03",
        denominazione: "Valbrona",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "01",
        denominazione: "Valchiusa",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "05",
        denominazione: "Valdagno",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "04",
        denominazione: "Valdaone",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "04",
        denominazione: "Valdaora",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "05",
        denominazione: "Valdastico",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "01",
        denominazione: "Valdengo",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "19",
        denominazione: "Valderice",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "03",
        denominazione: "Valdidentro",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "01",
        denominazione: "Valdieri",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Valdilana",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "19",
        denominazione: "Valdina",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "03",
        denominazione: "Valdisotto",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "05",
        denominazione: "Valdobbiadene",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "01",
        denominazione: "Valduggia",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "03",
        denominazione: "Valeggio",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "05",
        denominazione: "Valeggio sul Mincio",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "12",
        denominazione: "Valentano",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "01",
        denominazione: "Valenza",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "16",
        denominazione: "Valenzano",
        denominazione_provincia: "Bari",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BA"
    },
    {
        codice_regione: "03",
        denominazione: "Valera Fratta",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "10",
        denominazione: "Valfabbrica",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "01",
        denominazione: "Valfenera",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "04",
        denominazione: "Valfloriana",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "11",
        denominazione: "Valfornace",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "03",
        denominazione: "Valfurva",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "03",
        denominazione: "Valganna",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "01",
        denominazione: "Valgioie",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Valgoglio",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Valgrana",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Valgreghentino",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "02",
        denominazione: "Valgrisenche",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "19",
        denominazione: "Valguarnera Caropepe",
        denominazione_provincia: "Enna",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "EN"
    },
    {
        codice_regione: "05",
        denominazione: "Vallada Agordina",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "01",
        denominazione: "Vallanzengo",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "04",
        denominazione: "Vallarsa",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "15",
        denominazione: "Vallata",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "15",
        denominazione: "Valle Agricola",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "04",
        denominazione: "Valle Aurina",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "01",
        denominazione: "Valle Cannobina",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "13",
        denominazione: "Valle Castellana",
        denominazione_provincia: "Teramo",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "TE"
    },
    {
        codice_regione: "15",
        denominazione: "Valle dell'Angelo",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "05",
        denominazione: "Valle di Cadore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "04",
        denominazione: "Valle di Casies",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "15",
        denominazione: "Valle di Maddaloni",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "03",
        denominazione: "Valle Lomellina",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Valle Salimbene",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Valle San Nicolao",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "07",
        denominazione: "Vallebona",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "12",
        denominazione: "Vallecorsa",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "07",
        denominazione: "Vallecrosia",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "19",
        denominazione: "Valledolmo",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "20",
        denominazione: "Valledoria",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "18",
        denominazione: "Vallefiorita",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "11",
        denominazione: "Vallefoglia",
        denominazione_provincia: "Pesaro e Urbino",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "PU"
    },
    {
        codice_regione: "04",
        denominazione: "Vallelaghi",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "18",
        denominazione: "Vallelonga",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "19",
        denominazione: "Vallelunga Pratameno",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "12",
        denominazione: "Vallemaio",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "12",
        denominazione: "Vallepietra",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "12",
        denominazione: "Vallerano",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "20",
        denominazione: "Vallermosa",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "12",
        denominazione: "Vallerotonda",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "15",
        denominazione: "Vallesaccarda",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "03",
        denominazione: "Valleve",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Valli del Pasubio",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "12",
        denominazione: "Vallinfreda",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "03",
        denominazione: "Vallio Terme",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "15",
        denominazione: "Vallo della Lucania",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "10",
        denominazione: "Vallo di Nera",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "01",
        denominazione: "Vallo Torinese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Valloriate",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Valmacca",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "03",
        denominazione: "Valmadrera",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "12",
        denominazione: "Valmontone",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "03",
        denominazione: "Valmorea",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "08",
        denominazione: "Valmozzola",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "03",
        denominazione: "Valnegra",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "02",
        denominazione: "Valpelline",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "01",
        denominazione: "Valperga",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Valprato Soana",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "08",
        denominazione: "Valsamoggia",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "02",
        denominazione: "Valsavarenche",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "17",
        denominazione: "Valsinni",
        denominazione_provincia: "Matera",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "MT"
    },
    {
        codice_regione: "03",
        denominazione: "Valsolda",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "01",
        denominazione: "Valstrona",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "10",
        denominazione: "Valtopina",
        denominazione_provincia: "Perugia",
        denominazione_regione: "Umbria",
        flag_capoluogo: false,
        sigla_provincia: "PG"
    },
    {
        codice_regione: "03",
        denominazione: "Valtorta",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "02",
        denominazione: "Valtournenche",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "15",
        denominazione: "Valva",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Valvarrone",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "06",
        denominazione: "Valvasone Arzene",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "19",
        denominazione: "Valverde",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "03",
        denominazione: "Valvestino",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "04",
        denominazione: "Vandoies",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "Vanzaghello",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Vanzago",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Vanzone con San Carlo",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "03",
        denominazione: "Vaprio d'Adda",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Vaprio d'Agogna",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "01",
        denominazione: "Varallo",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "01",
        denominazione: "Varallo Pombia",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "03",
        denominazione: "Varano Borghi",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "08",
        denominazione: "Varano de' Melegari",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "18",
        denominazione: "Varapodio",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "07",
        denominazione: "Varazze",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "12",
        denominazione: "Varco Sabino",
        denominazione_provincia: "Rieti",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RI"
    },
    {
        codice_regione: "03",
        denominazione: "Varedo",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "03",
        denominazione: "Varenna",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Varese",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: true,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "07",
        denominazione: "Varese Ligure",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "01",
        denominazione: "Varisella",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "06",
        denominazione: "Varmo",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "04",
        denominazione: "Varna",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "08",
        denominazione: "Varsi",
        denominazione_provincia: "Parma",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PR"
    },
    {
        codice_regione: "03",
        denominazione: "Varzi",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Varzo",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "12",
        denominazione: "Vasanello",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "07",
        denominazione: "Vasia",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "13",
        denominazione: "Vasto",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "14",
        denominazione: "Vastogirardi",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "01",
        denominazione: "Vauda Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "18",
        denominazione: "Vazzano",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "05",
        denominazione: "Vazzola",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "09",
        denominazione: "Vecchiano",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "03",
        denominazione: "Vedano al Lambro",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "03",
        denominazione: "Vedano Olona",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "05",
        denominazione: "Vedelago",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Vedeseta",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Veduggio con Colzano",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "05",
        denominazione: "Veggiano",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "16",
        denominazione: "Veglie",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "01",
        denominazione: "Veglio",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "12",
        denominazione: "Vejano",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "03",
        denominazione: "Veleso",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Velezzo Lomellina",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "12",
        denominazione: "Velletri",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "03",
        denominazione: "Vellezzo Bellini",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "05",
        denominazione: "Velo d'Astico",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "05",
        denominazione: "Velo Veronese",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "04",
        denominazione: "Velturno",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "14",
        denominazione: "Venafro",
        denominazione_provincia: "Isernia",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "IS"
    },
    {
        codice_regione: "01",
        denominazione: "Venaria Reale",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "11",
        denominazione: "Venarotta",
        denominazione_provincia: "Ascoli Piceno",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "AP"
    },
    {
        codice_regione: "01",
        denominazione: "Venasca",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Venaus",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "07",
        denominazione: "Vendone",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "03",
        denominazione: "Venegono Inferiore",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Venegono Superiore",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "19",
        denominazione: "Venetico",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "05",
        denominazione: "Venezia",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: true,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "03",
        denominazione: "Veniano",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "17",
        denominazione: "Venosa",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "08",
        denominazione: "Ventasso",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "15",
        denominazione: "Venticano",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "07",
        denominazione: "Ventimiglia",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "19",
        denominazione: "Ventimiglia di Sicilia",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "12",
        denominazione: "Ventotene",
        denominazione_provincia: "Latina",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "LT"
    },
    {
        codice_regione: "06",
        denominazione: "Venzone",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "04",
        denominazione: "Verano",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "03",
        denominazione: "Verano Brianza",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "01",
        denominazione: "Verbania",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: true,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "18",
        denominazione: "Verbicaro",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "03",
        denominazione: "Vercana",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Verceia",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "01",
        denominazione: "Vercelli",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: true,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "03",
        denominazione: "Vercurago",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Verdellino",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Verdello",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Verderio",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "01",
        denominazione: "Verduno",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "08",
        denominazione: "Vergato",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "08",
        denominazione: "Verghereto",
        denominazione_provincia: "Forlì-Cesena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FC"
    },
    {
        codice_regione: "03",
        denominazione: "Vergiate",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Vermezzo con Zelo",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "04",
        denominazione: "Vermiglio",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Vernante",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "08",
        denominazione: "Vernasca",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "03",
        denominazione: "Vernate",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "07",
        denominazione: "Vernazza",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "09",
        denominazione: "Vernio",
        denominazione_provincia: "Prato",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PO"
    },
    {
        codice_regione: "16",
        denominazione: "Vernole",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "03",
        denominazione: "Verolanuova",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "03",
        denominazione: "Verolavecchia",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Verolengo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "12",
        denominazione: "Veroli",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "05",
        denominazione: "Verona",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: true,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "05",
        denominazione: "Veronella",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "02",
        denominazione: "Verrayes",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "02",
        denominazione: "Verrès",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "03",
        denominazione: "Verretto",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Verrone",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "03",
        denominazione: "Verrua Po",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Verrua Savoia",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Vertemate con Minoprio",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Vertova",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "08",
        denominazione: "Verucchio",
        denominazione_provincia: "Rimini",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RN"
    },
    {
        codice_regione: "03",
        denominazione: "Vervio",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "06",
        denominazione: "Verzegnis",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "18",
        denominazione: "Verzino",
        denominazione_provincia: "Crotone",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "KR"
    },
    {
        codice_regione: "01",
        denominazione: "Verzuolo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "05",
        denominazione: "Vescovana",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Vescovato",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "01",
        denominazione: "Vesime",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Vespolate",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "07",
        denominazione: "Vessalico",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "05",
        denominazione: "Vestenanova",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "01",
        denominazione: "Vestignè",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Vestone",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "12",
        denominazione: "Vetralla",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "08",
        denominazione: "Vetto",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "01",
        denominazione: "Vezza d'Alba",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Vezza d'Oglio",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "07",
        denominazione: "Vezzano Ligure",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "08",
        denominazione: "Vezzano sul Crostolo",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "07",
        denominazione: "Vezzi Portio",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "03",
        denominazione: "Viadana",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "03",
        denominazione: "Viadanica",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "19",
        denominazione: "Viagrande",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "01",
        denominazione: "Viale",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Vialfrè",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "08",
        denominazione: "Viano",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "09",
        denominazione: "Viareggio",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "01",
        denominazione: "Viarigi",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "18",
        denominazione: "Vibo Valentia",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: true,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "15",
        denominazione: "Vibonati",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "12",
        denominazione: "Vicalvi",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "19",
        denominazione: "Vicari",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "09",
        denominazione: "Vicchio",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "05",
        denominazione: "Vicenza",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: true,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "16",
        denominazione: "Vico del Gargano",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "15",
        denominazione: "Vico Equense",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "12",
        denominazione: "Vico nel Lazio",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "01",
        denominazione: "Vicoforte",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "13",
        denominazione: "Vicoli",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "01",
        denominazione: "Vicolungo",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "09",
        denominazione: "Vicopisano",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "12",
        denominazione: "Vicovaro",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "20",
        denominazione: "Viddalba",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "03",
        denominazione: "Vidigulfo",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "05",
        denominazione: "Vidor",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "01",
        denominazione: "Vidracco",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "16",
        denominazione: "Vieste",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "17",
        denominazione: "Vietri di Potenza",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "15",
        denominazione: "Vietri sul Mare",
        denominazione_provincia: "Salerno",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "SA"
    },
    {
        codice_regione: "03",
        denominazione: "Viganò",
        denominazione_provincia: "Lecco",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LC"
    },
    {
        codice_regione: "03",
        denominazione: "Vigano San Martino",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "08",
        denominazione: "Vigarano Mainarda",
        denominazione_provincia: "Ferrara",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FE"
    },
    {
        codice_regione: "05",
        denominazione: "Vigasio",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "03",
        denominazione: "Vigevano",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "17",
        denominazione: "Viggianello",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "17",
        denominazione: "Viggiano",
        denominazione_provincia: "Potenza",
        denominazione_regione: "Basilicata",
        flag_capoluogo: false,
        sigla_provincia: "PZ"
    },
    {
        codice_regione: "03",
        denominazione: "Viggiù",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "01",
        denominazione: "Vigliano Biellese",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "01",
        denominazione: "Vigliano d'Asti",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "01",
        denominazione: "Vignale Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "12",
        denominazione: "Vignanello",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "03",
        denominazione: "Vignate",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "08",
        denominazione: "Vignola",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "04",
        denominazione: "Vignola-Falesina",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "01",
        denominazione: "Vignole Borbera",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Vignolo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Vignone",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "05",
        denominazione: "Vigo di Cadore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "05",
        denominazione: "Vigodarzere",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Vigolo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "08",
        denominazione: "Vigolzone",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "01",
        denominazione: "Vigone",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "05",
        denominazione: "Vigonovo",
        denominazione_provincia: "Venezia",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VE"
    },
    {
        codice_regione: "05",
        denominazione: "Vigonza",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "01",
        denominazione: "Viguzzolo",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "05",
        denominazione: "Villa Bartolomea",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "09",
        denominazione: "Villa Basilica",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "03",
        denominazione: "Villa Biscossi",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Villa Carcina",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "16",
        denominazione: "Villa Castelli",
        denominazione_provincia: "Brindisi",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "BR"
    },
    {
        codice_regione: "13",
        denominazione: "Villa Celiera",
        denominazione_provincia: "Pescara",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "PE"
    },
    {
        codice_regione: "09",
        denominazione: "Villa Collemandina",
        denominazione_provincia: "Lucca",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "LU"
    },
    {
        codice_regione: "03",
        denominazione: "Villa Cortese",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "03",
        denominazione: "Villa d'Adda",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Villa d'Almè",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Villa d'Ogna",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "01",
        denominazione: "Villa del Bosco",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "05",
        denominazione: "Villa del Conte",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "15",
        denominazione: "Villa di Briano",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "03",
        denominazione: "Villa di Chiavenna",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "03",
        denominazione: "Villa di Serio",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Villa di Tirano",
        denominazione_provincia: "Sondrio",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "SO"
    },
    {
        codice_regione: "05",
        denominazione: "Villa Estense",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "07",
        denominazione: "Villa Faraldi",
        denominazione_provincia: "Imperia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "IM"
    },
    {
        codice_regione: "03",
        denominazione: "Villa Guardia",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "04",
        denominazione: "Villa Lagarina",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "12",
        denominazione: "Villa Latina",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "15",
        denominazione: "Villa Literno",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "08",
        denominazione: "Villa Minozzo",
        denominazione_provincia: "Reggio nell'Emilia",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "RE"
    },
    {
        codice_regione: "18",
        denominazione: "Villa San Giovanni",
        denominazione_provincia: "Reggio Calabria",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "RC"
    },
    {
        codice_regione: "12",
        denominazione: "Villa San Giovanni in Tuscia",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "20",
        denominazione: "Villa San Pietro",
        denominazione_provincia: "Cagliari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "CA"
    },
    {
        codice_regione: "01",
        denominazione: "Villa San Secondo",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "13",
        denominazione: "Villa Sant'Angelo",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "20",
        denominazione: "Villa Sant'Antonio",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "12",
        denominazione: "Villa Santa Lucia",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "13",
        denominazione: "Villa Santa Lucia degli Abruzzi",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "13",
        denominazione: "Villa Santa Maria",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "06",
        denominazione: "Villa Santina",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "12",
        denominazione: "Villa Santo Stefano",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "20",
        denominazione: "Villa Verde",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "04",
        denominazione: "Villabassa",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "19",
        denominazione: "Villabate",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "03",
        denominazione: "Villachiara",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "20",
        denominazione: "Villacidro",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "01",
        denominazione: "Villadeati",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "05",
        denominazione: "Villadose",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "01",
        denominazione: "Villadossola",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "01",
        denominazione: "Villafalletto",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Villafranca d'Asti",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "05",
        denominazione: "Villafranca di Verona",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "09",
        denominazione: "Villafranca in Lunigiana",
        denominazione_provincia: "Massa-Carrara",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "MS"
    },
    {
        codice_regione: "05",
        denominazione: "Villafranca Padovana",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "01",
        denominazione: "Villafranca Piemonte",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "19",
        denominazione: "Villafranca Sicula",
        denominazione_provincia: "Agrigento",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "AG"
    },
    {
        codice_regione: "19",
        denominazione: "Villafranca Tirrena",
        denominazione_provincia: "Messina",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "ME"
    },
    {
        codice_regione: "19",
        denominazione: "Villafrati",
        denominazione_provincia: "Palermo",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "PA"
    },
    {
        codice_regione: "05",
        denominazione: "Villaga",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "20",
        denominazione: "Villagrande Strisaili",
        denominazione_provincia: "Nuoro",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "NU"
    },
    {
        codice_regione: "13",
        denominazione: "Villalago",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "19",
        denominazione: "Villalba",
        denominazione_provincia: "Caltanissetta",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CL"
    },
    {
        codice_regione: "13",
        denominazione: "Villalfonsina",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "01",
        denominazione: "Villalvernia",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "13",
        denominazione: "Villamagna",
        denominazione_provincia: "Chieti",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "CH"
    },
    {
        codice_regione: "15",
        denominazione: "Villamaina",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "20",
        denominazione: "Villamar",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "05",
        denominazione: "Villamarzana",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "20",
        denominazione: "Villamassargia",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "01",
        denominazione: "Villamiroglio",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "04",
        denominazione: "Villandro",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "01",
        denominazione: "Villanova Biellese",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "01",
        denominazione: "Villanova Canavese",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "07",
        denominazione: "Villanova d'Albenga",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "03",
        denominazione: "Villanova d'Ardenghi",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Villanova d'Asti",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "15",
        denominazione: "Villanova del Battista",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "05",
        denominazione: "Villanova del Ghebbo",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "03",
        denominazione: "Villanova del Sillaro",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "05",
        denominazione: "Villanova di Camposampiero",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "05",
        denominazione: "Villanova Marchesana",
        denominazione_provincia: "Rovigo",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "RO"
    },
    {
        codice_regione: "01",
        denominazione: "Villanova Mondovì",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Villanova Monferrato",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "20",
        denominazione: "Villanova Monteleone",
        denominazione_provincia: "Sassari",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SS"
    },
    {
        codice_regione: "01",
        denominazione: "Villanova Solaro",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "08",
        denominazione: "Villanova sull'Arda",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "20",
        denominazione: "Villanova Truschedu",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "20",
        denominazione: "Villanova Tulo",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Villanovaforru",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Villanovafranca",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "03",
        denominazione: "Villanterio",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Villanuova sul Clisi",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "20",
        denominazione: "Villaperuccio",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "18",
        denominazione: "Villapiana",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "20",
        denominazione: "Villaputzu",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "01",
        denominazione: "Villar Dora",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Villar Focchiardo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Villar Pellice",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Villar Perosa",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Villar San Costanzo",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "01",
        denominazione: "Villarbasse",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Villarboit",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "01",
        denominazione: "Villareggia",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "Villaricca",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "01",
        denominazione: "Villaromagnano",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "19",
        denominazione: "Villarosa",
        denominazione_provincia: "Enna",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "EN"
    },
    {
        codice_regione: "20",
        denominazione: "Villasalto",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "03",
        denominazione: "Villasanta",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "20",
        denominazione: "Villasimius",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Villasor",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "20",
        denominazione: "Villaspeciosa",
        denominazione_provincia: "Sud Sardegna",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "SU"
    },
    {
        codice_regione: "01",
        denominazione: "Villastellone",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Villata",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "20",
        denominazione: "Villaurbana",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "13",
        denominazione: "Villavallelonga",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "05",
        denominazione: "Villaverla",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "04",
        denominazione: "Ville d'Anaunia",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "04",
        denominazione: "Ville di Fiemme",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "02",
        denominazione: "Villeneuve",
        denominazione_provincia: "Valle d'Aosta/Vallée d'Aoste",
        denominazione_regione: "Valle d'Aosta/Vallée d'Aoste",
        flag_capoluogo: false,
        sigla_provincia: "AO"
    },
    {
        codice_regione: "06",
        denominazione: "Villesse",
        denominazione_provincia: "Gorizia",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "GO"
    },
    {
        codice_regione: "13",
        denominazione: "Villetta Barrea",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "01",
        denominazione: "Villette",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "03",
        denominazione: "Villimpenta",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "03",
        denominazione: "Villongo",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Villorba",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Vilminore di Scalve",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "03",
        denominazione: "Vimercate",
        denominazione_provincia: "Monza e della Brianza",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MB"
    },
    {
        codice_regione: "03",
        denominazione: "Vimodrone",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "01",
        denominazione: "Vinadio",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "14",
        denominazione: "Vinchiaturo",
        denominazione_provincia: "Campobasso",
        denominazione_regione: "Molise",
        flag_capoluogo: false,
        sigla_provincia: "CB"
    },
    {
        codice_regione: "01",
        denominazione: "Vinchio",
        denominazione_provincia: "Asti",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AT"
    },
    {
        codice_regione: "09",
        denominazione: "Vinci",
        denominazione_provincia: "Firenze",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "FI"
    },
    {
        codice_regione: "01",
        denominazione: "Vinovo",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Vinzaglio",
        denominazione_provincia: "Novara",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "NO"
    },
    {
        codice_regione: "01",
        denominazione: "Viola",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "03",
        denominazione: "Vione",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "04",
        denominazione: "Vipiteno",
        denominazione_provincia: "Bolzano/Bozen",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "BZ"
    },
    {
        codice_regione: "01",
        denominazione: "Virle Piemonte",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Visano",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "01",
        denominazione: "Vische",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "15",
        denominazione: "Visciano",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "06",
        denominazione: "Visco",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "01",
        denominazione: "Visone",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "11",
        denominazione: "Visso",
        denominazione_provincia: "Macerata",
        denominazione_regione: "Marche",
        flag_capoluogo: false,
        sigla_provincia: "MC"
    },
    {
        codice_regione: "03",
        denominazione: "Vistarino",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Vistrorio",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "19",
        denominazione: "Vita",
        denominazione_provincia: "Trapani",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "TP"
    },
    {
        codice_regione: "12",
        denominazione: "Viterbo",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: true,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "12",
        denominazione: "Viticuso",
        denominazione_provincia: "Frosinone",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "FR"
    },
    {
        codice_regione: "06",
        denominazione: "Vito d'Asio",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "12",
        denominazione: "Vitorchiano",
        denominazione_provincia: "Viterbo",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "VT"
    },
    {
        codice_regione: "19",
        denominazione: "Vittoria",
        denominazione_provincia: "Ragusa",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "RG"
    },
    {
        codice_regione: "05",
        denominazione: "Vittorio Veneto",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "13",
        denominazione: "Vittorito",
        denominazione_provincia: "L'Aquila",
        denominazione_regione: "Abruzzo",
        flag_capoluogo: false,
        sigla_provincia: "AQ"
    },
    {
        codice_regione: "03",
        denominazione: "Vittuone",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "15",
        denominazione: "Vitulano",
        denominazione_provincia: "Benevento",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "BN"
    },
    {
        codice_regione: "15",
        denominazione: "Vitulazio",
        denominazione_provincia: "Caserta",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "CE"
    },
    {
        codice_regione: "01",
        denominazione: "Viù",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "06",
        denominazione: "Vivaro",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "12",
        denominazione: "Vivaro Romano",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "01",
        denominazione: "Viverone",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "19",
        denominazione: "Vizzini",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "03",
        denominazione: "Vizzola Ticino",
        denominazione_provincia: "Varese",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "VA"
    },
    {
        codice_regione: "03",
        denominazione: "Vizzolo Predabissi",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "05",
        denominazione: "Vo'",
        denominazione_provincia: "Padova",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "PD"
    },
    {
        codice_regione: "03",
        denominazione: "Vobarno",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "07",
        denominazione: "Vobbia",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "01",
        denominazione: "Vocca",
        denominazione_provincia: "Vercelli",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VC"
    },
    {
        codice_regione: "05",
        denominazione: "Vodo Cadore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "03",
        denominazione: "Voghera",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "08",
        denominazione: "Voghiera",
        denominazione_provincia: "Ferrara",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "FE"
    },
    {
        codice_regione: "01",
        denominazione: "Vogogna",
        denominazione_provincia: "Verbano-Cusio-Ossola",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "VB"
    },
    {
        codice_regione: "04",
        denominazione: "Volano",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "15",
        denominazione: "Volla",
        denominazione_provincia: "Napoli",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "NA"
    },
    {
        codice_regione: "03",
        denominazione: "Volongo",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "05",
        denominazione: "Volpago del Montello",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "03",
        denominazione: "Volpara",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "01",
        denominazione: "Volpedo",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Volpeglino",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "01",
        denominazione: "Volpiano",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "03",
        denominazione: "Volta Mantovana",
        denominazione_provincia: "Mantova",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MN"
    },
    {
        codice_regione: "01",
        denominazione: "Voltaggio",
        denominazione_provincia: "Alessandria",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "AL"
    },
    {
        codice_regione: "05",
        denominazione: "Voltago Agordino",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "09",
        denominazione: "Volterra",
        denominazione_provincia: "Pisa",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "PI"
    },
    {
        codice_regione: "03",
        denominazione: "Voltido",
        denominazione_provincia: "Cremona",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CR"
    },
    {
        codice_regione: "16",
        denominazione: "Volturara Appula",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "15",
        denominazione: "Volturara Irpina",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "16",
        denominazione: "Volturino",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "01",
        denominazione: "Volvera",
        denominazione_provincia: "Torino",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "TO"
    },
    {
        codice_regione: "01",
        denominazione: "Vottignasco",
        denominazione_provincia: "Cuneo",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "CN"
    },
    {
        codice_regione: "18",
        denominazione: "Zaccanopoli",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "19",
        denominazione: "Zafferana Etnea",
        denominazione_provincia: "Catania",
        denominazione_regione: "Sicilia",
        flag_capoluogo: false,
        sigla_provincia: "CT"
    },
    {
        codice_regione: "18",
        denominazione: "Zagarise",
        denominazione_provincia: "Catanzaro",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CZ"
    },
    {
        codice_regione: "12",
        denominazione: "Zagarolo",
        denominazione_provincia: "Roma",
        denominazione_regione: "Lazio",
        flag_capoluogo: false,
        sigla_provincia: "RM"
    },
    {
        codice_regione: "18",
        denominazione: "Zambrone",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    },
    {
        codice_regione: "03",
        denominazione: "Zandobbio",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "05",
        denominazione: "Zanè",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "03",
        denominazione: "Zanica",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "16",
        denominazione: "Zapponeta",
        denominazione_provincia: "Foggia",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "FG"
    },
    {
        codice_regione: "03",
        denominazione: "Zavattarello",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Zeccone",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "20",
        denominazione: "Zeddiani",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "03",
        denominazione: "Zelbio",
        denominazione_provincia: "Como",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "CO"
    },
    {
        codice_regione: "03",
        denominazione: "Zelo Buon Persico",
        denominazione_provincia: "Lodi",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "LO"
    },
    {
        codice_regione: "03",
        denominazione: "Zeme",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Zenevredo",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "05",
        denominazione: "Zenson di Piave",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "08",
        denominazione: "Zerba",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "03",
        denominazione: "Zerbo",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "03",
        denominazione: "Zerbolò",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "20",
        denominazione: "Zerfaliu",
        denominazione_provincia: "Oristano",
        denominazione_regione: "Sardegna",
        flag_capoluogo: false,
        sigla_provincia: "OR"
    },
    {
        codice_regione: "09",
        denominazione: "Zeri",
        denominazione_provincia: "Massa-Carrara",
        denominazione_regione: "Toscana",
        flag_capoluogo: false,
        sigla_provincia: "MS"
    },
    {
        codice_regione: "05",
        denominazione: "Zermeghedo",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "05",
        denominazione: "Zero Branco",
        denominazione_provincia: "Treviso",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "TV"
    },
    {
        codice_regione: "05",
        denominazione: "Zevio",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "04",
        denominazione: "Ziano di Fiemme",
        denominazione_provincia: "Trento",
        denominazione_regione: "Trentino-Alto Adige/Südtirol",
        flag_capoluogo: false,
        sigla_provincia: "TN"
    },
    {
        codice_regione: "08",
        denominazione: "Ziano Piacentino",
        denominazione_provincia: "Piacenza",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "PC"
    },
    {
        codice_regione: "03",
        denominazione: "Zibido San Giacomo",
        denominazione_provincia: "Milano",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "MI"
    },
    {
        codice_regione: "07",
        denominazione: "Zignago",
        denominazione_provincia: "La Spezia",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SP"
    },
    {
        codice_regione: "05",
        denominazione: "Zimella",
        denominazione_provincia: "Verona",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VR"
    },
    {
        codice_regione: "01",
        denominazione: "Zimone",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "03",
        denominazione: "Zinasco",
        denominazione_provincia: "Pavia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "PV"
    },
    {
        codice_regione: "07",
        denominazione: "Zoagli",
        denominazione_provincia: "Genova",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "GE"
    },
    {
        codice_regione: "08",
        denominazione: "Zocca",
        denominazione_provincia: "Modena",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "MO"
    },
    {
        codice_regione: "03",
        denominazione: "Zogno",
        denominazione_provincia: "Bergamo",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BG"
    },
    {
        codice_regione: "08",
        denominazione: "Zola Predosa",
        denominazione_provincia: "Bologna",
        denominazione_regione: "Emilia-Romagna",
        flag_capoluogo: false,
        sigla_provincia: "BO"
    },
    {
        codice_regione: "16",
        denominazione: "Zollino",
        denominazione_provincia: "Lecce",
        denominazione_regione: "Puglia",
        flag_capoluogo: false,
        sigla_provincia: "LE"
    },
    {
        codice_regione: "03",
        denominazione: "Zone",
        denominazione_provincia: "Brescia",
        denominazione_regione: "Lombardia",
        flag_capoluogo: false,
        sigla_provincia: "BS"
    },
    {
        codice_regione: "05",
        denominazione: "Zoppè di Cadore",
        denominazione_provincia: "Belluno",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "BL"
    },
    {
        codice_regione: "06",
        denominazione: "Zoppola",
        denominazione_provincia: "Pordenone",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "PN"
    },
    {
        codice_regione: "05",
        denominazione: "Zovencedo",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "01",
        denominazione: "Zubiena",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "07",
        denominazione: "Zuccarello",
        denominazione_provincia: "Savona",
        denominazione_regione: "Liguria",
        flag_capoluogo: false,
        sigla_provincia: "SV"
    },
    {
        codice_regione: "05",
        denominazione: "Zugliano",
        denominazione_provincia: "Vicenza",
        denominazione_regione: "Veneto",
        flag_capoluogo: false,
        sigla_provincia: "VI"
    },
    {
        codice_regione: "06",
        denominazione: "Zuglio",
        denominazione_provincia: "Udine",
        denominazione_regione: "Friuli-Venezia Giulia",
        flag_capoluogo: false,
        sigla_provincia: "UD"
    },
    {
        codice_regione: "01",
        denominazione: "Zumaglia",
        denominazione_provincia: "Biella",
        denominazione_regione: "Piemonte",
        flag_capoluogo: false,
        sigla_provincia: "BI"
    },
    {
        codice_regione: "18",
        denominazione: "Zumpano",
        denominazione_provincia: "Cosenza",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "CS"
    },
    {
        codice_regione: "15",
        denominazione: "Zungoli",
        denominazione_provincia: "Avellino",
        denominazione_regione: "Campania",
        flag_capoluogo: false,
        sigla_provincia: "AV"
    },
    {
        codice_regione: "18",
        denominazione: "Zungri",
        denominazione_provincia: "Vibo Valentia",
        denominazione_regione: "Calabria",
        flag_capoluogo: false,
        sigla_provincia: "VV"
    }
]