import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take } from "rxjs";
import { ConfigurazioneBudgetProvinceDTO, DatiBudgetProvinciaDTO, RendicontazioneProvinciaDTO } from "../dtos";

@Injectable({
    providedIn: 'root'
})
export class ConfigurazioneBudgetProvinceService {
    private url_endpoint;
    constructor(private http: HttpClient, @Inject('env') env) {
        this.url_endpoint = env.environment.url_endpoint;
    }

    /**
   * Aggiunge una rendicontazione
   *
   * @param { RendicontazioneProvinciaDTO } rendicontazione
   * @returns { Promise<any> }
   */
    async aggiungiRendicontazione(rendicontazione: RendicontazioneProvinciaDTO) {
        return new Promise<any>(
            (resolve, reject) => {
                this.http.post(this.url_endpoint + api.configurazione_budget_province_rendicontazioni_aggiungi, rendicontazione, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Rimuovi configurazione
     *
     * @return { Promise<boolean> }
     */
        rimuoviConfigurazione(startDate: string, endDate: string): Promise<boolean> {
          return new Promise<boolean>(
              (resolve, reject) => {
                  const params = new HttpParams()
                      .append('startDate', startDate)
                       .append('endDate', endDate);
                  this.http.delete(this.url_endpoint + api.configurazione_budget_province_rimuovi, { params, observe: 'body' })
                      .pipe(take(1))
                      .subscribe({
                          next: (resp: any) => {
                              if (resp?.status == 'OK') {
                                  resolve(true);
                              } else {
                                  reject({
                                      status: 0,
                                      error: resp?.message
                                  })
                              }
                          },
                          error: (error: HttpErrorResponse) => {
                              reject(error);
                          }
                      });
              });
      }



    /**
     * Elimina una rendicontazione
     *
     * @param { number } idRendicontazione
     * @returns { Promise<any> }
     */
    async eliminaRendicontazione(idRendicontazione: number) {
        return new Promise<any>(
            (resolve, reject) => {
                this.http.delete(this.url_endpoint + api.configurazione_budget_province_rendicontazioni_elimina + '/' + idRendicontazione, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Ottiene la configurazione budget annuale per provincia
     *
     * @return { Promise<ConfigurazioneBudgetProvinceDTO> }
     */
    get(): Promise<ConfigurazioneBudgetProvinceDTO[]> {
        return new Promise<ConfigurazioneBudgetProvinceDTO[]>(
            (resolve, reject) => {
                this.http.get(this.url_endpoint + api.configurazione_budget_province_ottieni, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Ottiene i range di date delle configurazioni settate
     *
     * @return { Promise<ConfigurazioneBudgetProvinceDTO> }
     */
    getAnnualita(): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                this.http.get(this.url_endpoint + api.configurazione_budget_province_date_ottieni, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Ottieni i dati per il grafico di monitoraggio budget per province
     * @param { string } startDate
     * @param { string } endDate
     * @return { Promise<DatiBudgetProvinciaDTO[]> }
     */
    getDatiBudgetProvince(startDate: string, endDate: string): Promise<DatiBudgetProvinciaDTO[]> {
        return new Promise<any>(
            (resolve, reject) => {
                const params = new HttpParams()
                    .append('startDate', startDate)
                    .append('endDate', endDate);
                this.http.get(this.url_endpoint + api.grafici_budget_province_ottieni, { observe: 'body', params })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Ottiene la rendicontazioni
     *
     * @return { Promise<any> }
     */
    getRendicontazioni(page: number, elementsInPage: number, columnSorting: string, sortingAsc: boolean): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                const params = {
                    page: page,
                    elementsInPage: elementsInPage
                };
                if (columnSorting && sortingAsc != null) {
                    params['columnSorting'] = columnSorting;
                    params['sortingAsc'] = sortingAsc;
                }
                this.http.get(this.url_endpoint + api.configurazione_budget_province_rendicontazioni_ottieni, { observe: 'body', params: new HttpParams().appendAll(params) })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Invia la configurazione budget annuale per provincia
     *
     * @return { Promise<any> }
     */
    invia(configurazioneBudgetProvinceDTO: ConfigurazioneBudgetProvinceDTO): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                this.http.post(this.url_endpoint + api.configurazione_budget_province_aggiungi, configurazioneBudgetProvinceDTO, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Modifica la configurazione budget annuale per provincia
     * @param { ConfigurazioneBudgetProvinceDTO } configurazioneBudgetProvinceDTO
     * @return { Promise<any> }
     */
    modifica(configurazioneBudgetProvinceDTO: ConfigurazioneBudgetProvinceDTO): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                this.http.put(this.url_endpoint + api.configurazione_budget_province_aggiorna, configurazioneBudgetProvinceDTO, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Modifica una rendicontazione
     * @param { RendicontazioneProvinciaDTO } rendicontazione
     * @param { number } idRendicontazione
     * @return { Promise<any> }
     */
    modificaRendicontazione(rendicontazione: RendicontazioneProvinciaDTO, idRendicontazione: number): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                this.http.put(this.url_endpoint + api.configurazione_budget_province_rendicontazioni_modifica + '/' + idRendicontazione, rendicontazione, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }


}
