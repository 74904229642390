import { ConfigurazioneBudgetProvinceDTO } from "../dtos";
import {BudgetProvinceInterface } from "../interfaces";

export class BudgetProvinceModel implements BudgetProvinceInterface {
    protected budgetProvince:
        {
            idConfigurazione?: number;
            alias?: string;
            budgetConfigurato?: number;
            budgetSpeso?: number;
        }[];
    protected dataFine: string;
    protected dataInizio: string;
    protected programmata: boolean;

    constructor(dto: ConfigurazioneBudgetProvinceDTO) {
        this.budgetProvince = dto?.budgetProvince;
        this.dataFine = dto?.dataFine;
        this.dataInizio = dto?.dataInizio;
        this.programmata = dto?.programmata;
    }

    getAliasProvince(): string[] {
        return this.budgetProvince.map((item) => item.alias);
    }

    getBudgetByProvincia(alias: string): number {
        return this.budgetProvince.find((item) => item.alias == alias)?.budgetConfigurato;
    }

    // getId(): number;
    getBudgetRendicontato(): number {
        return this.budgetProvince.reduce((acc, el) => acc + el.budgetSpeso, 0);
    }

    getBudgetTotale(): number {
        return this.budgetProvince.reduce((acc, el) => acc + el.budgetConfigurato, 0);
    }

    getDataFine(): string {
        return this.dataFine;
    }

    getDataInizio(): string {
        return this.dataInizio;
    }

    getSpesaByProvincia(alias: string): number {
        return this.budgetProvince.find((item) => item.alias == alias)?.budgetSpeso;
    }

    isProgrammata(): boolean {
      return this.programmata;
    }
}
