import { AnnualitaConfigurazioneBudgetDTO } from "../dtos";
import { AnnualitaConfigurazioneBudgetInterface } from "../interfaces/";

export class AnnualitaConfigurazioneBudgetModel implements AnnualitaConfigurazioneBudgetInterface {
    protected id: number;
    protected dataInizioConfigurazione: string;
    protected dataFineConfigurazione: string;
    protected programmata: boolean;
    constructor(dto: AnnualitaConfigurazioneBudgetDTO) {
        this.id = dto?.id;
        this.dataInizioConfigurazione = dto?.dataInizioConfigurazione;
        this.dataFineConfigurazione = dto?.dataFineConfigurazione;
        this.programmata = dto?.programmata;
    }

    getId(): number {
        return this.id;
    }
    getStartDate(): string {
        return this.dataInizioConfigurazione;
    }

    getEndDate(): string {
        return this.dataFineConfigurazione;
    }

    getLabel(): string {
        const startDate = new Date(this.dataInizioConfigurazione);
        const endDate = new Date(this.dataFineConfigurazione);
        const startDay = (startDate.getDate() < 10 ? '0' : '') + startDate.getDate();
        const startMonth = (startDate.getMonth() < 9 ? '0' : '') + (startDate.getMonth()+1);
        const endDay = (endDate.getDate() < 10 ? '0' : '') + endDate.getDate();
        const endMonth = (endDate.getMonth() < 9 ? '0' : '') + (endDate.getMonth()+1);
        return `${startDay + '-' + startMonth + '-' + startDate.getFullYear()} / ${endDay + '-' + endMonth + '-' + endDate.getFullYear()}`
    }

    getYear(): number {
        const startDate = new Date(this.dataInizioConfigurazione);
        return startDate.getFullYear()
    }

    isGreaterThan(annualita: AnnualitaConfigurazioneBudgetInterface): boolean {
        const startDate = new Date(this.dataFineConfigurazione);
        const annualitaDate = new Date(annualita.getStartDate());
        return startDate.getTime() > annualitaDate.getTime();
    }

    isProgrammata(): boolean {
        return this.programmata;
    }
}