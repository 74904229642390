import { Component } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ConteggioNotificheObserver, CurrentUserObserver, NotificaInterface, Piattaforma, UtenteInterface, api } from 'core-model';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthFacade, NotificaFacade, UtenteFacade } from 'projects/app-front-office/src/shared/facades';

@Component({
  selector: 'app-header-slim',
  templateUrl: './header-slim.component.html',
  styleUrls: ['./header-slim.component.scss']
})
export class HeaderSlimComponent {
  public currentUser: UtenteInterface;
  public currentUser$: Subscription;
  private notifications$: Subscription;
  public notifiche: Array<NotificaInterface>
  private locale$: Subscription;
  public lang: string;
  public environment = environment;


  public logoutUrl;

  constructor(
    private authFacade: AuthFacade,
    private currentUserObserver: CurrentUserObserver,
    private notificheFacade: NotificaFacade,
    private translate: TranslateService,
    private utenteFacade: UtenteFacade,
    private notificationObserver: ConteggioNotificheObserver) {
    this.logoutUrl = environment.url_endpoint + api.authentication_logout;
    this.currentUser$ = this.currentUserObserver.on()
    .subscribe((currentUser) => {
      this.currentUser = currentUser;
    });
  }

  ngOnInit(): void {
    this.lang = this.translate.getDefaultLang();
    this.locale$ = this.translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        this.lang = event.lang;
       // this.languageService.setLanguage(this.lang);
      }
    );
    this.notifications$ = this.notificationObserver.on().subscribe((notifiche) => {
      this.getNotifiche(notifiche);
    })
  }

  async getNotifiche(notifiche: Array<NotificaInterface>){
    this.notifiche = notifiche?.filter((notifica) => notifica?.isVisualizzato() == false);
  }

  async goBackOffice(){
    this.authFacade.clearUser();
    await this.utenteFacade.switchPiattaforma(Piattaforma.BACKOFFICE);
  }

  // async login(){
  //   // await this.accessoService.login();
  // }

  ngOnDestroy(): void {
    if (this.locale$?.closed == false) {
      this.locale$.unsubscribe();
    }
    if (this.currentUser$?.closed == false) {
      this.currentUser$.unsubscribe();
    }
    if (this.notifications$?.closed == false) {
      this.notifications$.unsubscribe();
    }
  }

  public onSelect(lang:string) {
    this.translate.use(lang);
  }

  async logout(){
    const ok = await this.authFacade.logout();
    if(ok){
      this.authFacade.clearUser();
      window.location.href = this.logoutUrl
    }
  }
}
