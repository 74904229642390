import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardFreeInstanceComponent } from './card-free-instance.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { InfoFreeInstanceModule } from '../info-free-instance/info-free-instance.module';



@NgModule({
  declarations: [
    CardFreeInstanceComponent
  ],
  exports: [
    CardFreeInstanceComponent
  ],
  imports: [
    CommonModule,
    InfoFreeInstanceModule,
    RouterModule,
    TranslateModule
  ]
})
export class CardFreeInstanceModule { }
