import { StatoLavorazioneDTO } from "../dtos";
import { StatoLavorazioneInterface } from "../interfaces";

export class StatoLavorazioneModel implements StatoLavorazioneInterface {
  protected id?: number
  protected statoTradotto?: string;
  protected stato?: string
  protected attivo?: boolean;

  constructor(dto: StatoLavorazioneDTO) {
     this.id = dto?.id;
     this.statoTradotto = dto?.statoTradotto;
     this.attivo = dto?.attivo;
     this.stato = dto?.stato;
  }
  getStato(): string {
    return this.stato;
  }
  
  getStatoLavorazione(): string {
    return this.statoTradotto;
  }
  getId(): number {
    return this.id;
  }

  isAttivo(): boolean {
    return this.attivo;
  }

}
