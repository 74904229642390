/**
 * intercetta le date passate
 */
export function calculateYesterday(): string {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate());
  return yesterday.toISOString().split('T')[0];
}

/**
 * intercetta le date future
 */
export function calculateTommorow(): string {
  const tommorow = new Date();
  tommorow.setDate(tommorow.getDate());
  return tommorow.toISOString().split('T')[0];
}
