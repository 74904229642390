import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { Newsfacade } from '../../../news/facades';
import { NewsModel } from '../../../../models';
import { PaginationInterface } from '../../../../interfaces';

@Component({
  selector: 'lib-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  @ViewChild('carousel') carousel: NgbCarousel;

  news: PaginationInterface;

  carouselList: NewsModel[][] = [];

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth > 992) {
      this.carouselList = this.splitList(3);
    }
    else if (window.innerWidth > 768 && window.innerWidth < 992) {
      this.carouselList = this.splitList(2);
    }
    else {
      this.carouselList = this.splitList(1);
    }
  }

  constructor(private newsFacade: Newsfacade) { }

  ngOnInit(): void {
    this.loadList()
  }

  splitList(number: number): NewsModel[][] {
    const newsSplit: NewsModel[][] = [[]];

    this.news.getContent().forEach((news: NewsModel, index: number) => {
      if (index % number === 0 && index !== 0) {
        newsSplit.push([]);
      }

      newsSplit[newsSplit.length - 1].push(news);
    })

    return newsSplit;
  }

  loadList() {
    this.newsFacade.lista(0, 6).then((news: PaginationInterface) => {
      this.news = news;
      this.news.getContent().forEach((news: NewsModel) => {
        this.newsFacade.ottieniCopertina(news.getId()).then((copertina: any) => {
          news.setCopertina(copertina);
        });
      });
      this.onResize();
    })
  }
}

