import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CurrentUserObserver, UtenteInterface, UtenteModel, UtenteService, ValidaUtenteInterface, ValidaUtenteModel } from 'core-model';
// SINGLETONS
import { ConfigService, CryptoService } from 'core-model';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
    providedIn: 'root'
})
export class AuthFacade {
    protected SUFFIX_LOCAL_STORAGE: string;
    constructor(
        protected configService: ConfigService,
        protected cryptoService: CryptoService,
        protected currentUserObserver: CurrentUserObserver,
        protected userService: UtenteService,
        protected http: HttpClient,
        protected router: Router,
        protected spinner: NgxSpinnerService
    ) {
        this.SUFFIX_LOCAL_STORAGE = this.configService.sessionSuffix;
    }

    /**
     * Checks if the current user is valid and restores it if necessary.
     *
     * @return {Promise<UtenteInterface>} The restored current user, or null if the current user is not valid.
     */
    public async checkAndRestoreCurrentUser(): Promise<UtenteInterface> {
        if (this.getCurretUser() != null) {
            const dto = await this.userService.getUtente();
            const curretUser = new UtenteModel(dto);
            this.saveUser(curretUser);
            this.currentUserObserver.set(curretUser);
            return curretUser;
        }
        return null;
    }

    /**
     * Pulizia del local storage
     */
    public clearUser(): void {
        localStorage.removeItem(this.SUFFIX_LOCAL_STORAGE);
        this.currentUserObserver.set(null);
    }

    /**
     * Ottiene il curretUser
     *
     * @returns { UtenteInterface | ValidaUtenteInterface}
     */
    public getCurretUser(caseAccountValidation: boolean = false): UtenteInterface | ValidaUtenteInterface {
        if (localStorage.getItem(this.SUFFIX_LOCAL_STORAGE) != null) {
            const curretUser = this.cryptoService.get(localStorage.getItem(this.SUFFIX_LOCAL_STORAGE));
            const json = JSON.parse(curretUser);
            if (caseAccountValidation) {
                return new ValidaUtenteModel(json);
            }
            return new UtenteModel(json);
        } else {
            return null;
        }
    }

    /**
     * Handles an HTTP error response.
     *
     * @param {HttpErrorResponse} httpError - The HTTP error response.
     */
    public handleError(httpError: HttpErrorResponse) {
        if (httpError.status == 401 || httpError.status == 0) {
            this.clearUser();
            this.router.navigate(['/']);
        }
    }

    /**
     * Ritorna true se l'utente è autenticato
     *
     * @returns { boolean }
     */
    public isAuthenticated(): boolean {
        const curretUser = this.getCurretUser();
        if (!!curretUser?.hasLastEmailValid() && !!curretUser?.getIdentificativo()) {
            return true;
        }
        return false;
    }

    // public async logout() {
    //     try {
    //         this.spinner.show();
    //         await this.userService.logout();
    //         this.clearUser();
    //         this.router.navigate(['/']);
    //     } catch(error) {
    //         console.error(error);
    //     } finally {
    //         this.spinner.hide();
    //     }
    // }

    /**
    * Salva nel local storage i parametri del curretUser in forma criptata
    * @param { UtenteModel } curretUser
    */
    public saveUser(currentUser: UtenteInterface): void {
        // const curretUser_encrypted = this.cryptoService.set(JSON.stringify(curretUser.toJson()));
        const curretUser_encrypted = this.cryptoService.set(JSON.stringify(currentUser));
        localStorage.setItem(this.SUFFIX_LOCAL_STORAGE, curretUser_encrypted);
    }

      /**
   * Logout
   * @return {Promise<boolean>}
   */
      public async logout(): Promise<boolean> {
        try {
          this.spinner.show();
          await this.userService.logout();
          return true;
        } catch (error) {
         // this.errorFacade.handle(error, null);
        } finally {
          this.spinner.hide();
        }
        return false;
      }
}
