import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFamilyComponent } from './form-family.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorModule } from '../error/error.module';


@NgModule({
  declarations: [
    FormFamilyComponent
  ],
  exports: [
    FormFamilyComponent
  ],
  imports: [
    CommonModule,
    ErrorModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class FormFamilyModule { }
