import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UploadDragDropComponent } from './upload-drag-drop.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadDragDropDirective } from './upload-drag-drop.directive';
import { TranslateModule } from '@ngx-translate/core';
import { FileSizePipe } from 'core-model';


@NgModule({
  declarations: [UploadDragDropComponent, UploadDragDropDirective],
  exports: [UploadDragDropComponent],
  imports: [
    CommonModule,
    FileSizePipe,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule
  ], 
  // providers: [FileSizePipe]
})
export class UploadDragDropModule { }
