import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComuneResidenzaComponent } from './form-comune-residenza.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorModule } from '../error/error.module';


@NgModule({
  declarations: [
    FormComuneResidenzaComponent
  ],
  exports: [
    FormComuneResidenzaComponent
  ],
  imports: [
    AutocompleteLibModule,
    CommonModule,
    ErrorModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class FormComuneResidenzaModule { }
