import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take } from "rxjs";
import { ConfigurazioniSogliaMensileDTO } from "../dtos";

@Injectable({
    providedIn: 'root'
})
export class ConfigurazioneSogliaMensileService {

      private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }

    /**
     * Invia la configurazione soglia mensile
     *
     * @param { ConfigurazioniSogliaMensileDTO } dto
     * @return { Promise<any> }
     */
    aggiungi(dto: ConfigurazioniSogliaMensileDTO): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                this.http.post(this.url_endpoint + api.configurazione_soglia_mensile_aggiungi, dto, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
      * Ottiene la configurazione delle soglie mensili
      *
      * @return { Promise<ConfigurazioniSogliaMensileDTO> }
      */
    get(): Promise<ConfigurazioniSogliaMensileDTO> {
        return new Promise<ConfigurazioniSogliaMensileDTO>(
            (resolve, reject) => {
                this.http.get(this.url_endpoint + api.configurazione_soglia_mensile_ottieni, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }
}
