import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowcasePageInterface, UtenteInterface } from '../../interfaces';
import { CurrentUserObserver } from '../../observers';
import { ActivatedRoute } from '@angular/router';
import { TraduzioniVetrinaDTO } from '../../dtos';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit, OnDestroy {
  public currentUser: UtenteInterface; // UserModel
  public currentUser$: Subscription;

  public showcasePage: ShowcasePageInterface;
  public traduzioneVetrina: TraduzioniVetrinaDTO;

  constructor(private currentUserObserver: CurrentUserObserver,
              private activatedRoute: ActivatedRoute,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.currentUser$ = this.currentUserObserver.on()
    .subscribe((currentUser) => {
      this.currentUser = currentUser;
    });

    this.translate.onLangChange.subscribe((event: any) => {
      this.traduzioneVetrina = this.getCurrentPageByLocale(event.lang)
    });

    this.activatedRoute.data.subscribe((data) => {
      if (!!data['data']) {
        this.showcasePage = data['data'] as ShowcasePageInterface;
        this.traduzioneVetrina = this.getCurrentPageByLocale(this.translate.currentLang);
      }
    });
  }

  ngOnDestroy(): void {
    if(this.currentUser$?.closed === false) {
      this.currentUser$.unsubscribe();
    }
  }

  getCurrentPageByLocale(locale: string): TraduzioniVetrinaDTO {
    const translate = this.showcasePage?.getPaginaVetrina().find((v) => v.traduzioni.lingua == locale)
    return translate
  }
}
