import { PrivilegioDTO } from "../dtos";
import { PrivilegioInterface, RuoloInterface } from "../interfaces";
import { RuoloModel } from "./ruolo.model";

export class PrivilegioModel implements PrivilegioInterface {
  private id?: number;
  private alias?: string;
dataAttivazione?: Date;
  private dataDisattivazione?: Date;
  private dataCreazione?: Date;
  private dataAggiornamento?: Date;
  private ruoli?: Array<RuoloInterface>;
  protected attivo?: boolean;

  constructor(dto: PrivilegioDTO) {
    this.id = dto?.id;
    this.alias = dto?.alias;
    this.attivo = dto?.attivo;
    this.dataAttivazione = dto?.dataAttivazione ? new Date(dto.dataAttivazione) : undefined;
    this.dataDisattivazione = dto?.dataDisattivazione ? new Date(dto.dataDisattivazione) : undefined;
    this.dataCreazione = dto?.dataCreazione ? new Date(dto.dataCreazione) : undefined;
    this.dataAggiornamento = dto?.dataAggiornamento ? new Date(dto.dataAggiornamento) : undefined;
    this.ruoli = dto?.ruoli?.map((dto) => new RuoloModel(dto));
  }
  setAttivo(attivo: boolean): void {
    this.attivo = attivo;
  }
  isAttivo(): boolean {
    return this.attivo;
  }

  // Getter methods
  getId(): number | undefined {
    return this.id;
  }

  getAlias(): string | undefined {
    return this.alias;
  }

  getDataAttivazione(): Date | undefined {
    return this.dataAttivazione;
  }

  getDataDisattivazione(): Date | undefined {
    return this.dataDisattivazione;
  }

  getDataCreazione(): Date | undefined {
    return this.dataCreazione;
  }

  getDataAggiornamento(): Date | undefined {
    return this.dataAggiornamento;
  }

  getRuoli(): Array<RuoloInterface> | undefined {
    return this.ruoli;
  }

  // Setter methods
  setId(id: number): void {
    this.id = id;
  }

  setAlias(alias: string): void {
    this.alias = alias;
  }

  setDataAttivazione(dataAttivazione: Date): void {
    this.dataAttivazione = dataAttivazione;
  }

  setDataDisattivazione(dataDisattivazione: Date): void {
    this.dataDisattivazione = dataDisattivazione;
  }

  setDataCreazione(dataCreazione: Date): void {
    this.dataCreazione = dataCreazione;
  }

  setDataAggiornamento(dataAggiornamento: Date): void {
    this.dataAggiornamento = dataAggiornamento;
  }

  setRuoli(ruoli: Array<RuoloInterface>): void {
    this.ruoli = ruoli;
  }
}
