import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take } from "rxjs";
import { FileDTO } from "../dtos";


@Injectable({
    providedIn: 'root'
})
export class RiscontroService {

    private url_endpoint;
    constructor(private http: HttpClient, @Inject('env') env) {
        this.url_endpoint = env.environment.url_endpoint;
    }

    /**
     * Riscontro sospensione / Invia integrazioni
     *
     * @param { number } idGratuita
     * @param { string } noteRiscontro
     * @param { FileDTO[] } files
     * @return { Promise<any> }
     */
    invia(idGratuita: number, noteRiscontro: string, files: FileDTO[] = []): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                const dto = {
                    idGratuita: idGratuita,
                    noteRiscontro: noteRiscontro,
                    listaFile: files
                };
                // if (files.length > 0) {
                //     dto['listaFile'] = files;
                // }
                this.http.put(this.url_endpoint + api.riscontro, dto, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (resp?.status == 'OK') {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }
}
