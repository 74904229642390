export const COMUNI_PUGLIA = [
    {
      denominazione_straniera: "Accadia",
      denominazione: "Accadia",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Alberona",
      denominazione: "Alberona",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Anzano di Puglia",
      denominazione: "Anzano di Puglia",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Apricena",
      denominazione: "Apricena",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Ascoli Satriano",
      denominazione: "Ascoli Satriano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Biccari",
      denominazione: "Biccari",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Bovino",
      denominazione: "Bovino",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Cagnano Varano",
      denominazione: "Cagnano Varano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Candela",
      denominazione: "Candela",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Carapelle",
      denominazione: "Carapelle",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Carlantino",
      denominazione: "Carlantino",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Carpino",
      denominazione: "Carpino",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Casalnuovo Monterotaro",
      denominazione: "Casalnuovo Monterotaro",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Casalvecchio di Puglia",
      denominazione: "Casalvecchio di Puglia",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Castelluccio dei Sauri",
      denominazione: "Castelluccio dei Sauri",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Castelluccio Valmaggiore",
      denominazione: "Castelluccio Valmaggiore",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Castelnuovo della Daunia",
      denominazione: "Castelnuovo della Daunia",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Celenza Valfortore",
      denominazione: "Celenza Valfortore",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Celle di San Vito",
      denominazione: "Celle di San Vito",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Cerignola",
      denominazione: "Cerignola",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Chieuti",
      denominazione: "Chieuti",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Deliceto",
      denominazione: "Deliceto",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Faeto",
      denominazione: "Faeto",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Foggia",
      denominazione: "Foggia",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: true,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Ischitella",
      denominazione: "Ischitella",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Isole Tremiti",
      denominazione: "Isole Tremiti",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Lesina",
      denominazione: "Lesina",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Lucera",
      denominazione: "Lucera",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Manfredonia",
      denominazione: "Manfredonia",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Mattinata",
      denominazione: "Mattinata",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Monteleone di Puglia",
      denominazione: "Monteleone di Puglia",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Monte Sant'Angelo",
      denominazione: "Monte Sant'Angelo",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Motta Montecorvino",
      denominazione: "Motta Montecorvino",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Orsara di Puglia",
      denominazione: "Orsara di Puglia",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Orta Nova",
      denominazione: "Orta Nova",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Panni",
      denominazione: "Panni",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Peschici",
      denominazione: "Peschici",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Pietramontecorvino",
      denominazione: "Pietramontecorvino",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Poggio Imperiale",
      denominazione: "Poggio Imperiale",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Rignano Garganico",
      denominazione: "Rignano Garganico",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Rocchetta Sant'Antonio",
      denominazione: "Rocchetta Sant'Antonio",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Rodi Garganico",
      denominazione: "Rodi Garganico",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Roseto Valfortore",
      denominazione: "Roseto Valfortore",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "San Giovanni Rotondo",
      denominazione: "San Giovanni Rotondo",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "San Marco in Lamis",
      denominazione: "San Marco in Lamis",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "San Marco la Catola",
      denominazione: "San Marco la Catola",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "San Nicandro Garganico",
      denominazione: "San Nicandro Garganico",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "San Paolo di Civitate",
      denominazione: "San Paolo di Civitate",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "San Severo",
      denominazione: "San Severo",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Sant'Agata di Puglia",
      denominazione: "Sant'Agata di Puglia",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Serracapriola",
      denominazione: "Serracapriola",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Stornara",
      denominazione: "Stornara",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Stornarella",
      denominazione: "Stornarella",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Torremaggiore",
      denominazione: "Torremaggiore",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Troia",
      denominazione: "Troia",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Vico del Gargano",
      denominazione: "Vico del Gargano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Vieste",
      denominazione: "Vieste",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Volturara Appula",
      denominazione: "Volturara Appula",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Volturino",
      denominazione: "Volturino",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Ordona",
      denominazione: "Ordona",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Zapponeta",
      denominazione: "Zapponeta",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Foggia",
      flag_capoluogo: false,
      sigla_provincia: "FG"
    },
    {
      denominazione_straniera: "Acquaviva delle Fonti",
      denominazione: "Acquaviva delle Fonti",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Adelfia",
      denominazione: "Adelfia",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Alberobello",
      denominazione: "Alberobello",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Altamura",
      denominazione: "Altamura",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Bari",
      denominazione: "Bari",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: true,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Binetto",
      denominazione: "Binetto",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Bitetto",
      denominazione: "Bitetto",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Bitonto",
      denominazione: "Bitonto",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Bitritto",
      denominazione: "Bitritto",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Capurso",
      denominazione: "Capurso",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Casamassima",
      denominazione: "Casamassima",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Cassano delle Murge",
      denominazione: "Cassano delle Murge",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Castellana Grotte",
      denominazione: "Castellana Grotte",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Cellamare",
      denominazione: "Cellamare",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Conversano",
      denominazione: "Conversano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Corato",
      denominazione: "Corato",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Gioia del Colle",
      denominazione: "Gioia del Colle",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Giovinazzo",
      denominazione: "Giovinazzo",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Gravina in Puglia",
      denominazione: "Gravina in Puglia",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Grumo Appula",
      denominazione: "Grumo Appula",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Locorotondo",
      denominazione: "Locorotondo",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Modugno",
      denominazione: "Modugno",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Mola di Bari",
      denominazione: "Mola di Bari",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Molfetta",
      denominazione: "Molfetta",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Monopoli",
      denominazione: "Monopoli",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Noci",
      denominazione: "Noci",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Noicattaro",
      denominazione: "Noicattaro",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Palo del Colle",
      denominazione: "Palo del Colle",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Poggiorsini",
      denominazione: "Poggiorsini",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Polignano a Mare",
      denominazione: "Polignano a Mare",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Putignano",
      denominazione: "Putignano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Rutigliano",
      denominazione: "Rutigliano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Ruvo di Puglia",
      denominazione: "Ruvo di Puglia",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Sammichele di Bari",
      denominazione: "Sammichele di Bari",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Sannicandro di Bari",
      denominazione: "Sannicandro di Bari",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Santeramo in Colle",
      denominazione: "Santeramo in Colle",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Terlizzi",
      denominazione: "Terlizzi",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Toritto",
      denominazione: "Toritto",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Triggiano",
      denominazione: "Triggiano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Turi",
      denominazione: "Turi",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Valenzano",
      denominazione: "Valenzano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Bari",
      flag_capoluogo: false,
      sigla_provincia: "BA"
    },
    {
      denominazione_straniera: "Avetrana",
      denominazione: "Avetrana",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Carosino",
      denominazione: "Carosino",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Castellaneta",
      denominazione: "Castellaneta",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Crispiano",
      denominazione: "Crispiano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Faggiano",
      denominazione: "Faggiano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Fragagnano",
      denominazione: "Fragagnano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Ginosa",
      denominazione: "Ginosa",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Grottaglie",
      denominazione: "Grottaglie",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Laterza",
      denominazione: "Laterza",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Leporano",
      denominazione: "Leporano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Lizzano",
      denominazione: "Lizzano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Manduria",
      denominazione: "Manduria",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Martina Franca",
      denominazione: "Martina Franca",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Maruggio",
      denominazione: "Maruggio",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Massafra",
      denominazione: "Massafra",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Monteiasi",
      denominazione: "Monteiasi",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Montemesola",
      denominazione: "Montemesola",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Monteparano",
      denominazione: "Monteparano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Mottola",
      denominazione: "Mottola",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Palagianello",
      denominazione: "Palagianello",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Palagiano",
      denominazione: "Palagiano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Pulsano",
      denominazione: "Pulsano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Roccaforzata",
      denominazione: "Roccaforzata",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "San Giorgio Ionico",
      denominazione: "San Giorgio Ionico",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "San Marzano di San Giuseppe",
      denominazione: "San Marzano di San Giuseppe",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Sava",
      denominazione: "Sava",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Taranto",
      denominazione: "Taranto",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: true,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Torricella",
      denominazione: "Torricella",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Statte",
      denominazione: "Statte",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Taranto",
      flag_capoluogo: false,
      sigla_provincia: "TA"
    },
    {
      denominazione_straniera: "Brindisi",
      denominazione: "Brindisi",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Brindisi",
      flag_capoluogo: true,
      sigla_provincia: "BR"
    },
    {
      denominazione_straniera: "Carovigno",
      denominazione: "Carovigno",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Brindisi",
      flag_capoluogo: false,
      sigla_provincia: "BR"
    },
    {
      denominazione_straniera: "Ceglie Messapica",
      denominazione: "Ceglie Messapica",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Brindisi",
      flag_capoluogo: false,
      sigla_provincia: "BR"
    },
    {
      denominazione_straniera: "Cellino San Marco",
      denominazione: "Cellino San Marco",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Brindisi",
      flag_capoluogo: false,
      sigla_provincia: "BR"
    },
    {
      denominazione_straniera: "Cisternino",
      denominazione: "Cisternino",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Brindisi",
      flag_capoluogo: false,
      sigla_provincia: "BR"
    },
    {
      denominazione_straniera: "Erchie",
      denominazione: "Erchie",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Brindisi",
      flag_capoluogo: false,
      sigla_provincia: "BR"
    },
    {
      denominazione_straniera: "Fasano",
      denominazione: "Fasano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Brindisi",
      flag_capoluogo: false,
      sigla_provincia: "BR"
    },
    {
      denominazione_straniera: "Francavilla Fontana",
      denominazione: "Francavilla Fontana",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Brindisi",
      flag_capoluogo: false,
      sigla_provincia: "BR"
    },
    {
      denominazione_straniera: "Latiano",
      denominazione: "Latiano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Brindisi",
      flag_capoluogo: false,
      sigla_provincia: "BR"
    },
    {
      denominazione_straniera: "Mesagne",
      denominazione: "Mesagne",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Brindisi",
      flag_capoluogo: false,
      sigla_provincia: "BR"
    },
    {
      denominazione_straniera: "Oria",
      denominazione: "Oria",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Brindisi",
      flag_capoluogo: false,
      sigla_provincia: "BR"
    },
    {
      denominazione_straniera: "Ostuni",
      denominazione: "Ostuni",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Brindisi",
      flag_capoluogo: false,
      sigla_provincia: "BR"
    },
    {
      denominazione_straniera: "San Donaci",
      denominazione: "San Donaci",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Brindisi",
      flag_capoluogo: false,
      sigla_provincia: "BR"
    },
    {
      denominazione_straniera: "San Michele Salentino",
      denominazione: "San Michele Salentino",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Brindisi",
      flag_capoluogo: false,
      sigla_provincia: "BR"
    },
    {
      denominazione_straniera: "San Pancrazio Salentino",
      denominazione: "San Pancrazio Salentino",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Brindisi",
      flag_capoluogo: false,
      sigla_provincia: "BR"
    },
    {
      denominazione_straniera: "San Pietro Vernotico",
      denominazione: "San Pietro Vernotico",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Brindisi",
      flag_capoluogo: false,
      sigla_provincia: "BR"
    },
    {
      denominazione_straniera: "San Vito dei Normanni",
      denominazione: "San Vito dei Normanni",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Brindisi",
      flag_capoluogo: false,
      sigla_provincia: "BR"
    },
    {
      denominazione_straniera: "Torchiarolo",
      denominazione: "Torchiarolo",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Brindisi",
      flag_capoluogo: false,
      sigla_provincia: "BR"
    },
    {
      denominazione_straniera: "Torre Santa Susanna",
      denominazione: "Torre Santa Susanna",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Brindisi",
      flag_capoluogo: false,
      sigla_provincia: "BR"
    },
    {
      denominazione_straniera: "Villa Castelli",
      denominazione: "Villa Castelli",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Brindisi",
      flag_capoluogo: false,
      sigla_provincia: "BR"
    },
    {
      denominazione_straniera: "Alessano",
      denominazione: "Alessano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Alezio",
      denominazione: "Alezio",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Alliste",
      denominazione: "Alliste",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Andrano",
      denominazione: "Andrano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Aradeo",
      denominazione: "Aradeo",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Arnesano",
      denominazione: "Arnesano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Bagnolo del Salento",
      denominazione: "Bagnolo del Salento",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Botrugno",
      denominazione: "Botrugno",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Calimera",
      denominazione: "Calimera",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Campi Salentina",
      denominazione: "Campi Salentina",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Cannole",
      denominazione: "Cannole",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Caprarica di Lecce",
      denominazione: "Caprarica di Lecce",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Carmiano",
      denominazione: "Carmiano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Carpignano Salentino",
      denominazione: "Carpignano Salentino",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Casarano",
      denominazione: "Casarano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Castri di Lecce",
      denominazione: "Castri di Lecce",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Castrignano de' Greci",
      denominazione: "Castrignano de' Greci",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Castrignano del Capo",
      denominazione: "Castrignano del Capo",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Cavallino",
      denominazione: "Cavallino",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Collepasso",
      denominazione: "Collepasso",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Copertino",
      denominazione: "Copertino",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Corigliano d'Otranto",
      denominazione: "Corigliano d'Otranto",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Corsano",
      denominazione: "Corsano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Cursi",
      denominazione: "Cursi",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Cutrofiano",
      denominazione: "Cutrofiano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Diso",
      denominazione: "Diso",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Gagliano del Capo",
      denominazione: "Gagliano del Capo",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Galatina",
      denominazione: "Galatina",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Galatone",
      denominazione: "Galatone",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Gallipoli",
      denominazione: "Gallipoli",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Giuggianello",
      denominazione: "Giuggianello",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Giurdignano",
      denominazione: "Giurdignano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Guagnano",
      denominazione: "Guagnano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Lecce",
      denominazione: "Lecce",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: true,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Lequile",
      denominazione: "Lequile",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Leverano",
      denominazione: "Leverano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Lizzanello",
      denominazione: "Lizzanello",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Maglie",
      denominazione: "Maglie",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Martano",
      denominazione: "Martano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Martignano",
      denominazione: "Martignano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Matino",
      denominazione: "Matino",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Melendugno",
      denominazione: "Melendugno",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Melissano",
      denominazione: "Melissano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Melpignano",
      denominazione: "Melpignano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Miggiano",
      denominazione: "Miggiano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Minervino di Lecce",
      denominazione: "Minervino di Lecce",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Monteroni di Lecce",
      denominazione: "Monteroni di Lecce",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Montesano Salentino",
      denominazione: "Montesano Salentino",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Morciano di Leuca",
      denominazione: "Morciano di Leuca",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Muro Leccese",
      denominazione: "Muro Leccese",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Nardò",
      denominazione: "Nardò",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Neviano",
      denominazione: "Neviano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Nociglia",
      denominazione: "Nociglia",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Novoli",
      denominazione: "Novoli",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Ortelle",
      denominazione: "Ortelle",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Otranto",
      denominazione: "Otranto",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Palmariggi",
      denominazione: "Palmariggi",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Parabita",
      denominazione: "Parabita",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Patù",
      denominazione: "Patù",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Poggiardo",
      denominazione: "Poggiardo",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Racale",
      denominazione: "Racale",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Ruffano",
      denominazione: "Ruffano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Salice Salentino",
      denominazione: "Salice Salentino",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Salve",
      denominazione: "Salve",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Sanarica",
      denominazione: "Sanarica",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "San Cesario di Lecce",
      denominazione: "San Cesario di Lecce",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "San Donato di Lecce",
      denominazione: "San Donato di Lecce",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Sannicola",
      denominazione: "Sannicola",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "San Pietro in Lama",
      denominazione: "San Pietro in Lama",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Santa Cesarea Terme",
      denominazione: "Santa Cesarea Terme",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Scorrano",
      denominazione: "Scorrano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Seclì",
      denominazione: "Seclì",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Sogliano Cavour",
      denominazione: "Sogliano Cavour",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Soleto",
      denominazione: "Soleto",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Specchia",
      denominazione: "Specchia",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Spongano",
      denominazione: "Spongano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Squinzano",
      denominazione: "Squinzano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Sternatia",
      denominazione: "Sternatia",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Supersano",
      denominazione: "Supersano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Surano",
      denominazione: "Surano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Surbo",
      denominazione: "Surbo",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Taurisano",
      denominazione: "Taurisano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Taviano",
      denominazione: "Taviano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Tiggiano",
      denominazione: "Tiggiano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Trepuzzi",
      denominazione: "Trepuzzi",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Tricase",
      denominazione: "Tricase",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Tuglie",
      denominazione: "Tuglie",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Ugento",
      denominazione: "Ugento",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Uggiano la Chiesa",
      denominazione: "Uggiano la Chiesa",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Veglie",
      denominazione: "Veglie",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Vernole",
      denominazione: "Vernole",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Zollino",
      denominazione: "Zollino",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "San Cassiano",
      denominazione: "San Cassiano",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Castro",
      denominazione: "Castro",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Porto Cesareo",
      denominazione: "Porto Cesareo",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Presicce-Acquarica",
      denominazione: "Presicce-Acquarica",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Lecce",
      flag_capoluogo: false,
      sigla_provincia: "LE"
    },
    {
      denominazione_straniera: "Andria",
      denominazione: "Andria",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Barletta-Andria-Trani",
      flag_capoluogo: true,
      sigla_provincia: "BT"
    },
    {
      denominazione_straniera: "Barletta",
      denominazione: "Barletta",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Barletta-Andria-Trani",
      flag_capoluogo: true,
      sigla_provincia: "BT"
    },
    {
      denominazione_straniera: "Bisceglie",
      denominazione: "Bisceglie",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Barletta-Andria-Trani",
      flag_capoluogo: false,
      sigla_provincia: "BT"
    },
    {
      denominazione_straniera: "Canosa di Puglia",
      denominazione: "Canosa di Puglia",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Barletta-Andria-Trani",
      flag_capoluogo: false,
      sigla_provincia: "BT"
    },
    {
      denominazione_straniera: "Margherita di Savoia",
      denominazione: "Margherita di Savoia",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Barletta-Andria-Trani",
      flag_capoluogo: false,
      sigla_provincia: "BT"
    },
    {
      denominazione_straniera: "Minervino Murge",
      denominazione: "Minervino Murge",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Barletta-Andria-Trani",
      flag_capoluogo: false,
      sigla_provincia: "BT"
    },
    {
      denominazione_straniera: "San Ferdinando di Puglia",
      denominazione: "San Ferdinando di Puglia",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Barletta-Andria-Trani",
      flag_capoluogo: false,
      sigla_provincia: "BT"
    },
    {
      denominazione_straniera: "Spinazzola",
      denominazione: "Spinazzola",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Barletta-Andria-Trani",
      flag_capoluogo: false,
      sigla_provincia: "BT"
    },
    {
      denominazione_straniera: "Trani",
      denominazione: "Trani",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Barletta-Andria-Trani",
      flag_capoluogo: true,
      sigla_provincia: "BT"
    },
    {
      denominazione_straniera: "Trinitapoli",
      denominazione: "Trinitapoli",
      denominazione_regione: "Puglia",
      denominazione_provincia: "Barletta-Andria-Trani",
      flag_capoluogo: false,
      sigla_provincia: "BT"
    }
  ];