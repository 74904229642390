import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import {
  ConfigurazioneBudgetAnnualeService,
  ConfigurazioneBudgetProvinceService,
  ConfigurazioneSogliaMensileService,
  EnteService,
  GraficiService,
  LanguageService,
  MonitoraggioSpesaService,
  // ShowcasePageService
} from './services';
import {
  ButtonObserver,
  CurrentOperatorObserver,
  CurrentUserObserver
} from './observers';
// import { LanguageTranslationModule } from './modules';
import { ErrorObserverService } from './singletons';
import { QuillCustomConfigModule } from './modules/quill-custom-config/quill-custom-config.module';

@NgModule({
  imports: [
    QuillCustomConfigModule
  ]
})

export class CoreModelBoModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModelBoModule) {
    if (parentModule) {
      throw new Error(
        'CoreModelBoModule is already loaded. Import it in the AppModule only');
    }
  }
  public static forRoot(environment: any): ModuleWithProviders<CoreModelBoModule> {
    return {
      ngModule: CoreModelBoModule,
      providers: [
        ButtonObserver,
        CurrentOperatorObserver,
        CurrentUserObserver,
        ConfigurazioneBudgetAnnualeService,
        ConfigurazioneBudgetProvinceService,
        ConfigurazioneSogliaMensileService,
        EnteService,
        ErrorObserverService,
        GraficiService,
        LanguageService,
        MonitoraggioSpesaService,
        {
          provide: 'env',
          useValue: environment
        }
      ],

    };
  }
}
