import { Injectable } from "@angular/core";
import { FILE_TYPE, FileDTO, FileService, RinunciaInterface, RinunciaModel, FreeInstanceService, RinunciaService } from "core-model";
import { ErrorFacade } from "./error.facade";
import { NgxSpinnerService } from "ngx-spinner";
import * as FileSaver from 'file-saver';
import { TranslateService } from "@ngx-translate/core";
import { HttpEventType } from "@angular/common/http";
import { filter, catchError, finalize } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class RinunciaFacade {
    constructor(private errorFacade: ErrorFacade,
        private fileService: FileService,
        private freeInstanceService: FreeInstanceService,
        private rinunciaService: RinunciaService,
        private spinner: NgxSpinnerService,
        private translateService: TranslateService) { }

    /**
    * Download il documento firmato
    *
    * @param { RinunciaInterface } freeInstance
    * @return { Promise<any> }
    */
    async download(freeInstance: RinunciaInterface): Promise<any> {
        try {
            this.spinner.show();
            const file = await this.fileService.download(freeInstance.getId(), freeInstance.getIdFile(), FILE_TYPE.RENUNCIATION);
            const blob = new Blob([file], { type: 'application/pdf' });
            FileSaver.saveAs(blob, freeInstance.getNomeFile());
            return true;
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
    * Genera e scarica il documento da firmare
    *
    * @param { RinunciaInterface } freeInstance
    * @return { Promise<any> }
    */
    async genera(freeInstance: RinunciaInterface): Promise<any> {
        try {
            this.spinner.show();
            const file = await this.fileService.genera(freeInstance.getId(), FILE_TYPE.RENUNCIATION);
            const blob = new Blob([file], { type: 'application/pdf' });
            let name = freeInstance.getRichiedenteGratuita().getBeneficiario().getAnagrafica().getCognome() + '_' + freeInstance.getRichiedenteGratuita().getBeneficiario().getAnagrafica().getNome();
            name = name.replace(/[^\w\s]/gi, '').replace(/\s+/g, '');
            this.translateService.get('FILENAME.RINUNCIA', { name: name }).subscribe((translated: string) => {
                const fileName = translated + '.pdf';
                FileSaver.saveAs(blob, fileName);
            });
            return true;
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
     * Ottiene la lista dei requisiti di invalidità
     * @returns { Promise<RinunciaInterface> }
     */
    async getFreeInstanceById(id: number): Promise<RinunciaInterface> {
        try {
            try {
                this.spinner.show();
                const dto = await this.freeInstanceService.getById(id);
                return new RinunciaModel(dto);
            } catch (error) {
                this.errorFacade.handle(error, null);
            } finally {
                this.spinner.hide();
            }
            return null;
        } catch (error) {
            this.errorFacade.handle(error);
        }
        return null;
    }

    /**
     * Rinuncia
     *
     * @param { number } idGratuita
     * @param { string } noteRinuncia
     * @return { Promise<RinunciaInterface> }
     */
    async rinuncia(idGratuita: number, noteRinuncia: string): Promise<RinunciaInterface> {
        try {
            this.spinner.show();
            await this.rinunciaService.rinuncia(idGratuita, noteRinuncia);
            const dto = await this.freeInstanceService.getById(idGratuita);
            return new RinunciaModel(dto);
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    // /**
    //  * Rinuncia
    //  *
    //  * @param { number } idGratuita
    //  * @param { string } noteRinuncia
    //  * @return { Promise<any> }
    //  */
    // async rinuncia(idGratuita: number, noteRinuncia: string): Promise<any> {
    //     try {
    //         this.spinner.show();
    //         return await this.rinunciaService.rinuncia(idGratuita, noteRinuncia);
    //     } catch (error) {
    //         this.errorFacade.handle(error, null);
    //     } finally {
    //         this.spinner.hide();
    //     }
    //     return null;
    // }

    /**
     * Upload il documento firmato
     *
     * @param { number } idFreeInstance
     * @param { FileDTO } fileDto
     * @return { any }
     */
    upload(idFreeInstance: number, fileDto: FileDTO): any {
        try {
            const handleError = (error) => {
                this.spinner.hide();
                this.errorFacade.handle(error, null);
            };
            return this.fileService.upload(idFreeInstance, fileDto, FILE_TYPE.RENUNCIATION)
                .pipe(filter((event: { type: HttpEventType }) => event.type === HttpEventType.Response),
                    catchError((error) => {
                        handleError(error);
                        throw error;
                    }),
                    finalize(() => {
                        this.spinner.hide();
                    }));
        } catch (error) {
            this.errorFacade.handle(error, null);
        }
        return null;
    }
}