import { PaginationDTO } from "../dtos";
import { InvoicePaginationInterface } from "../interfaces/invoice-pagination.interface";
import { InvoicePaginationModel } from "./invoice-pagination.model";

export class InvoiceTableModel extends InvoicePaginationModel implements InvoicePaginationInterface {

    constructor(dto: PaginationDTO) {
        super(dto);
    }
    
    getRows(): any[] {
        return this.content.map((el) => {
            return {
                idFattura: el.getIdFattura(),
                numeroFattura: el.getNumeroFattura(),
                data: el.getData(),
                oggetto: el.getOggetto(),
                aliquotaIva: el.getAliquotaIva(),
                imponibile: el.getImponibile(),
                statoFattura: el.getStatoFattura(),
                operatore: el.getEnte().getAlias()
            }
        });
    }
}