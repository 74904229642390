import { BudgetAnnualeDTO, BudgetSpesoDTO } from "../dtos";
import { BudgetAnnualeInterface, BudgetSpesoInterface, VoceDiCostoInterface } from "../interfaces";
import { VoceDiCostoModel } from "./voce-di-costo.model";

export class BudgetAnnualeModel implements BudgetAnnualeInterface {
    protected budgetTotaleConfigurato: number;
    protected budgetSpeso: BudgetSpesoInterface;
    protected dataFineConfigurazione: Date;
    protected dataInizioConfigurazione: Date;
    protected id: number;
    protected programmata: boolean;
    protected vociDiCosto: Array<VoceDiCostoInterface>;
    constructor(dto: BudgetAnnualeDTO) {
        this.id = dto?.id;
        this.budgetTotaleConfigurato = dto?.budgetTotaleConfigurato;
        this.budgetSpeso = dto?.budgetSpeso ? new BudgetSpesoModel(dto?.budgetSpeso) : undefined;
        this.dataFineConfigurazione = !!dto?.dataFineConfigurazione ? new Date(dto.dataFineConfigurazione) : undefined;
        this.dataInizioConfigurazione = !!dto?.dataInizioConfigurazione ? new Date(dto.dataInizioConfigurazione) : undefined;
        this.vociDiCosto = dto?.vociDiCosto ? dto.vociDiCosto.map((v) => new VoceDiCostoModel(v)) : undefined;
        this.programmata = dto?.programmata;
    }

    getBudgetConfiguratoByEnte(alias: string): number {
        return !!this.budgetSpeso?.getBudgetConfiguratoByEnte(alias) ? this.budgetSpeso?.getBudgetConfiguratoByEnte(alias) : 0;
    }

    getId(): number {
        return this.id;
    }

    getBudgetSpesoTotale(): number {
        return !!this.budgetSpeso ? this.budgetSpeso.getSpesaTotale() : 0;
    }

    getBudgetTotale(): number {
        return this.budgetTotaleConfigurato;
    }

    getDataFineConfigurazione(): Date {
        return this.dataFineConfigurazione;
    }

    getDataInizioConfigurazione(): Date {
        return this.dataInizioConfigurazione;
    }

    getSpesaByEnte(alias: string): number {
        const spesa = !!this.budgetSpeso?.getSpesaByEnte(alias) ? this.budgetSpeso?.getSpesaByEnte(alias) : 0;
        return spesa;
    }

    getVociDiCosto(): VoceDiCostoInterface[] {
        return this.vociDiCosto;
    }

    isProgrammata(): boolean {
        return this.programmata;
    }
}

export class BudgetSpesoModel implements BudgetSpesoInterface {
    budgetSpesoTotale: number;
    listaOperatori: {
        alias?: string,
        budgetConfigurato?: number,
        budgetSpeso?: number
    }[];
    constructor(dto: BudgetSpesoDTO) {
        this.budgetSpesoTotale = dto?.budgetSpesoTotale;
        this.listaOperatori = dto?.listaOperatori;
    }
    getBudgetConfiguratoByEnte(alias: string): number {
        const budgetConfigurato = this.listaOperatori?.find((s) => s.alias === alias)?.budgetConfigurato;
        return !!budgetConfigurato ? budgetConfigurato : 0;
    }

    getSpesaTotale(): number {
        return !!this.budgetSpesoTotale ? this.budgetSpesoTotale : 0;
    }

    getSpesaByEnte(alias: string): number {
        const spesa = this.listaOperatori?.find((s) => s.alias === alias)?.budgetSpeso;
        return !!spesa ? spesa : 0;
    }
}