import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SubmitImageNewsDTO } from '../../../../dtos';
import { CustomValidators, getBase64 } from '../../../../utils';
import { ErrorObserverService } from '../../../../singletons';

@Component({
  selector: 'app-upload-drag-drop',
  templateUrl: './upload-drag-drop.component.html',
  styleUrls: ['./upload-drag-drop.component.scss']
})

export class UploadDragDropComponent implements OnInit {
  @Input() public set form(form: FormGroup) {
    if (form) {
      this.documents = form.get(this.controlName) as FormArray;
      this.documents.controls.forEach(control => {
        control.setValidators([Validators.required, CustomValidators.validatorImage()]);
        control.updateValueAndValidity();
      })
    }
  }

  @Input() public className: string;

  @Input() public controlName: string;

  @Input() public copertina: boolean = false;

  @Input() public dragDropId: string = 'immagine';

  @Input() titoloUpload: string = 'FORM.UPLOAD_DOCUMENTI';

  @ViewChild('dragfile') fileInput: ElementRef;

  public documents: FormArray = new FormArray([]);

  public MAX_SIZE = 10000000;

  constructor(private errorObserver: ErrorObserverService,
              private translateService: TranslateService) { }

  ngOnInit(): void { }

  async uploadDragDrop(event: FileList) {
    const fileList: FileList = event;
    for (const f of Array.from(fileList)) {
      if (this.fileExstensionPermission(f?.type)) {
        if (this.fileSizePermission(f?.size)) {
          const fileDto: SubmitImageNewsDTO = {
            base64: await getBase64(f),
            dimensione: f?.size,
            estensione: f?.type,
            descrizione: f?.name,
            copertina: this.copertina,
            alt: ''
          };
          this.documents.push(new FormControl(fileDto, [Validators.required, CustomValidators.validatorImage()]));
          this.documents.updateValueAndValidity();
        } else {
          const titolo = this.translateService.instant("GENERAL.ATTENZIONE");
          this.translateService.get('FORM.DIMENSIONI_CONSENTITI_LONG', { value: this.MAX_SIZE / 1000000 }).subscribe((resp) => {
            const messaggio = resp;
            this.errorObserver.send({ title: titolo, message: messaggio });
          });
          this.fileInput.nativeElement.value = '';
        }
      } else {
        const messaggio = this.translateService.instant("ERROR.FORMATO_NON_CONSENTITO");
        this.errorObserver.send({name: 'MAIN', title: 'GENERAL.ATTENZIONE', message: messaggio });
        this.fileInput.nativeElement.value = '';
      }
    }
  }

  uploadFile(event) {
    this.uploadDragDrop(event.target.files);
  }

  deleteFile(index) {
    this.documents.removeAt(index);
    this.fileInput.nativeElement.value = '';
    this.documents.updateValueAndValidity();
  }

  private fileSizePermission(size) {
    return size <= this.MAX_SIZE;
  }

  private fileExstensionPermission(type: string) {
    return type.includes('image/png') || type.includes('image/jpeg');
  }

  public updateDescription(event: any, document: any, index: number) {
    const altText = event.target.value;
    document.value.alt = altText;
    this.documents.controls[index].patchValue(document.value);
    this.documents.controls[index].updateValueAndValidity();
  }
}
