import { FamiliareInpsDTO } from "../dtos";
import { FamiliareInpsInterface } from "../interfaces";

export class FamiliareInpsModel implements FamiliareInpsInterface {
  private codiceFiscale: string;
  private cognome: string;
  private gratuitaAttiva: boolean;
  private nome: string;
  private rapportoConDichiarante: string;

  constructor(dto: FamiliareInpsDTO) {
    this.codiceFiscale = dto?.codiceFiscale;
    this.cognome = dto?.cognome;
    this.gratuitaAttiva = dto?.gratuitaAttiva;
    this.nome = dto?.nome;
    this.rapportoConDichiarante = dto?.rapportoConDichiarante;
  }

  getCodiceFiscale(): string {
    return this.codiceFiscale;
  }

  getCognome(): string {
    return this.cognome;
  }

  getNome(): string {
    return this.nome;
  }

  getRapportoConDichiarante(): string {
    return  this.rapportoConDichiarante;
  }

  hasGratuitaAttiva(): boolean {
    return this.gratuitaAttiva;
  }
}
