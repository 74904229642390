import { DatePipe } from '@angular/common';
import { AddressModel, AnagraficaModel } from ".";
import { BeneficiaryDTO, CategoriaDisabilitaBeneficiarioDTO, GradoDisabilitaBeneficiarioDTO, RequisitoDisabilitaBeneficiarioDTO } from "../dtos";
import { AddressInterface, AnagraficaInterface, BeneficiaryInterface } from "../interfaces";

export class BeneficiaryModel implements BeneficiaryInterface {
    private anagrafica: AnagraficaInterface;
    private categorieDisabilitaBeneficiario: Array<CategoriaDisabilitaBeneficiarioDTO>;
    private dataAggiornamento: Date;
    private dataCertificazioneInvalidita: Date;
    private dataCreazione: Date;
    private gradiDisabilitaBeneficiario: Array<GradoDisabilitaBeneficiarioDTO>;
    private id: number;
    private indirizzo: AddressInterface;
    private requisitiDisabilitaBeneficiario: Array<RequisitoDisabilitaBeneficiarioDTO>;

    constructor(dto: BeneficiaryDTO) {
        this.anagrafica = !!dto?.anagrafica ? new AnagraficaModel(dto?.anagrafica) : undefined;
        this.categorieDisabilitaBeneficiario = dto?.categorieDisabilitaBeneficiario;
        this.dataAggiornamento = dto?.dataAggiornamento ? new Date(dto.dataAggiornamento) : undefined;
        this.dataCreazione = dto?.dataCreazione ? new Date(dto.dataCreazione) : undefined;
        this.dataCertificazioneInvalidita = dto?.dataCertificazioneInvalidita ? new Date(dto.dataCertificazioneInvalidita) : undefined;
        this.gradiDisabilitaBeneficiario = dto?.gradiDisabilitaBeneficiario;
        this.id = dto?.id;
        this.indirizzo = !!dto?.indirizzo ? new AddressModel(dto?.indirizzo) : undefined;
        this.requisitiDisabilitaBeneficiario = dto?.requisitiDisabilitaBeneficiario;
    }

    getAnagrafica(): AnagraficaInterface {
        return this.anagrafica;
    }

    getCategoriaDisabilita(): string {
        return this.categorieDisabilitaBeneficiario?.at(0)?.categoriaDisabilita?.codice;
    }

    getCategoriaDisabilitaDescrizione(): string {
      return this.categorieDisabilitaBeneficiario?.at(0)?.categoriaDisabilita?.listaTraduzioniDescrizione?.at(0)?.descrizione;
    }

    getGradoDisabilitaDescrizione(): string {
      return this.gradiDisabilitaBeneficiario?.at(0)?.gradoDisabilita?.listaTraduzioniDescrizione?.at(0)?.descrizione;
    }

    getIndirizzo(): AddressInterface {
        return this.indirizzo;
    }

    getDataAggiornamento(): Date {
        return this.dataAggiornamento;
    }

    getDataCertificazioneInvalidita(): Date {
        return this.dataCertificazioneInvalidita;
    }

    getDataCreazione(): Date {
        return this.dataCreazione;
    }

    getGradoDisabilita(): string {
        return this.gradiDisabilitaBeneficiario?.at(0)?.gradoDisabilita?.codice;
    }

    getId(): number {
        return this.id;
    }

    getAllRequisitoDisabilita(): string[] {
        return this.requisitiDisabilitaBeneficiario?.map((requisito) => requisito.requisitoDisabilita?.codice);
    }

    getRequisitiDescrizione(): string[] {
      const requisitiDescrizione = [];
      this.requisitiDisabilitaBeneficiario?.forEach((requisito) => requisitiDescrizione.push(requisito.requisitoDisabilita?.listaTraduzioniDescrizione?.at(0)?.descrizione));
      return requisitiDescrizione;
    }

    toDTO(): BeneficiaryDTO {
        const datepipe: DatePipe = new DatePipe('en-US');
        return {
            anagrafica: this.anagrafica?.toDTO(),
            categorieDisabilitaBeneficiario: this.categorieDisabilitaBeneficiario,
            dataAggiornamento: this.dataAggiornamento?.toJSON(), // datepipe.transform(this.dataAggiornamento, 'yyyy-MM-dd'),
            dataCertificazioneInvalidita: datepipe.transform(this.dataCertificazioneInvalidita, 'yyyy-MM-dd'),
            dataCreazione: this.dataCreazione?.toJSON(), // datepipe.transform(this.dataCreazione, 'yyyy-MM-dd'),
            gradiDisabilitaBeneficiario: this.gradiDisabilitaBeneficiario,
            id: this.id,
            indirizzo: this.indirizzo?.toDTO(),
            requisitiDisabilitaBeneficiario: this.requisitiDisabilitaBeneficiario
        }
    }
}
