import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminLoginFacade } from './facade/admin-login.facade';
import { ErrorObserverService } from '../../singletons';
import { Router } from '@angular/router';
@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent {
  public form:FormGroup;
  public password:FormControl;
  public codiceFiscale:FormControl;
  private url_endpoint;
  public loginUrl;



  constructor(private fb:FormBuilder, @Inject('env') env,
  private adminFacade: AdminLoginFacade,
  private route: Router,
  private errorObserver: ErrorObserverService) {
    this.form = fb.group({
      codiceFiscale: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
    this.codiceFiscale = <FormControl>this.form.get('codiceFiscale');
    this.password = <FormControl>this.form.get('password');
    this.url_endpoint = env.environment.url_endpoint;
    this.loginUrl =   this.url_endpoint + "/api/v1/back-office/dev/it/superadmin/accesso?";


  }

  public async login(){
  const location = await this.adminFacade.accessoAdmin(this.password.value, this.codiceFiscale.value);
    if(location){
      window.location.href = location;
      //this.route.navigate(['authbo']);
    }
  }
}
