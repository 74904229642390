import { PROCESSING_TYPE, PROCESSING_TYPE_TRANSLATE } from "../constants";


/**
 * Una funzione che determina il tipo di elaborazione in base al tipo di input e alla lingua.
 *
 * @param {PROCESSING_TYPE} type - Il tipo di elaborazione da tradurre.
 * @param {string} language - La lingua per la traduzione.
 * @return {PROCESSING_TYPE_TRANSLATE} Il tipo di elaborazione tradotto.
 */
export function processingTypeByLanguage(type: PROCESSING_TYPE, language: string): string {
    return PROCESSING_TYPE_TRANSLATE[`${type}_${language.toUpperCase()}`];
}
