import { Injectable } from "@angular/core";
import { FreeInstanceInterface, FreeInstanceModel, FreeInstancePaginationModel, FreeInstanceService } from "core-model";
import { NgxSpinnerService } from "ngx-spinner";
import { ErrorFacade } from "./error.facade";
import { PaginationInterface } from "core-model";

@Injectable({
    providedIn: 'root'
})
export class FreeInstanceFacade {
    constructor(private errorFacade: ErrorFacade,
        private freeInstanceService: FreeInstanceService,
        private spinner: NgxSpinnerService) { }

    /**
    * Ottiene la gratuità
    * @param { number } id
    * @returns { Promise<FreeInstanceInterface,> }
    */
    async getFreeInstanceById(id: number): Promise<FreeInstanceInterface> {
        try {
            try {
                this.spinner.show();
                const dto = await this.freeInstanceService.getById(id);
                return new FreeInstanceModel(dto);
            } catch (error) {
                this.errorFacade.handle(error, null);
            } finally {
                this.spinner.hide();
            }
            return null;
        } catch (error) {
            this.errorFacade.handle(error);
        }
        return null;
    }

    /**
     * Ottiene una lista di gratuità in base alla stringa di ricerca.
     *
     * @param { string } searchString
     * @param { number } page
     * @param { number } pageSize
     * @param { boolean } [onlyActive=false] 
     */
    async search(searchString: string, page: number, pageSize: number, onlyActive = false): Promise<PaginationInterface> {
        try {
            this.spinner.show();
            const dto = await this.freeInstanceService.search(searchString, page, pageSize, onlyActive);
            return new FreeInstancePaginationModel(dto);
        } catch (error) {
            this.errorFacade.handle(error);
        } finally {
            this.spinner.hide();
        }
        return null;
    }
}
