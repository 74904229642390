import { RUOLO_UTENTE } from "../constants";
import { RuoloDTO } from "../dtos";
import { PrivilegioInterface, RuoloInterface, UtenteInterface } from "../interfaces";
import { UtenteModel, PrivilegioModel } from "./";

export class RuoloModel implements RuoloInterface {
  private alias?: string;
  protected attivo?: boolean;
  private dataAggiornamento?: Date;
  private dataAttivazione?: Date;
  private dataDisattivazione?: Date;
  private dataCreazione?: Date;
  private id?: number;
  private utenti?: Array<UtenteInterface>;
  private privilegi?: Array<PrivilegioInterface>;

  constructor(dto?: RuoloDTO) {
    this.alias = dto?.alias;
    this.attivo = dto?.attivo;
    this.dataAggiornamento = !!dto?.dataAggiornamento ? new Date(dto.dataAggiornamento) : undefined;
    this.dataAttivazione = !!dto?.dataAttivazione ? new Date(dto.dataAttivazione) : undefined;
    this.dataDisattivazione = !!dto?.dataDisattivazione ? new Date(dto.dataDisattivazione) : undefined;
    this.dataCreazione = !!dto?.dataCreazione ? new Date(dto.dataCreazione) : undefined;
    this.id = dto?.id;
    this.utenti = dto?.utenti?.map((dto) => new UtenteModel(dto));
    this.privilegi = dto?.privilegi?.map((dto) => new PrivilegioModel(dto));
  }


  // Getter methods
  getId(): number | undefined {
    return this.id;
  }

  setAttivo(attivo: boolean) {
    this.attivo = attivo;
  }
  isAttivo(): boolean {
    return this.attivo;
  }

  isManager(): boolean {
    return this.alias == RUOLO_UTENTE.ROLE_MANAGER;
  }

  isOperatore(): boolean {
    return this.alias == RUOLO_UTENTE.ROLE_OPERATOR;
  }

  isEditor(): boolean {
    return this.alias == RUOLO_UTENTE.ROLE_EDITOR;
  }

  getAlias(): string | undefined {
    return this.alias;
  }

  getDataAttivazione(): Date | undefined {
    return this.dataAttivazione;
  }

  getDataDisattivazione(): Date | undefined {
    return this.dataDisattivazione;
  }

  getDataCreazione(): Date | undefined {
    return this.dataCreazione;
  }

  getDataAggiornamento(): Date | undefined {
    return this.dataAggiornamento;
  }

  getUtenti(): Array<UtenteInterface> | undefined {
    return this.utenti;
  }

  getPrivilegi(): Array<PrivilegioInterface> {
    return this.privilegi;
  }

  // Setter methods
  setId(id: number): void {
    this.id = id;
  }

  setAlias(alias: string): void {
    this.alias = alias;
  }

  setDataAttivazione(dataAttivazione: Date): void {
    this.dataAttivazione = dataAttivazione;
  }

  setDataDisattivazione(dataDisattivazione: Date): void {
    this.dataDisattivazione = dataDisattivazione;
  }

  setDataCreazione(dataCreazione: Date): void {
    this.dataCreazione = dataCreazione;
  }

  setDataAggiornamento(dataAggiornamento: Date): void {
    this.dataAggiornamento = dataAggiornamento;
  }

  setUtenti(utenti: Array<UtenteModel>): void {
    this.utenti = utenti;
  }
}
