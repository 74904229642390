import { Injectable } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { FieldInterface } from "../interfaces";



@Injectable()
export class FiltroProvider {

    private formSession: FormGroup = null;
    private currentSearch: Object = {};

    constructor(private fb: FormBuilder) { }

    getCurrentSearch() {
        return this.currentSearch;
    }

    getFormSession() {
        return this.formSession;
    }

    public setCurrentSearch(currentSearch) {
        this.currentSearch = currentSearch;
    }

    setFormSession(fields: FieldInterface[] | FormGroup) {
        if (fields instanceof FormGroup) {
            this.formSession = fields;
        } else {
            this.formSession = this.fb.group(this.buildForm({}, fields, 0));
        }
    }

    public hasFormSession() {
        return this.formSession != null;
    }

    public filterAndSortFields(fields: FieldInterface[]): FieldInterface[] {
        return fields.filter((field) => field.filterable).sort((a, b) => a.positionSearch - b.positionSearch);
    }

    resetFormSession() {
        this.formSession = null;
        this.currentSearch = {};
        // this.fields = [];
    }

    /**
     * Verifica se la sessione del form esiste e ha un valore.
     *
     * @return {boolean} Restituisce true se la sessione del form esiste e ha un valore, altrimenti restituisce false.
     */
    // hasFields(): boolean {
    //     return this.fields.length > 0;
    // }

    /**
   * Costruisce ricorsivamente un oggetto form basandosi su un elenco di campi e dei loro tipi di controllo.
   *
   * @param {Object} formObject - L'oggetto form da costruire.
   * @param {FieldInterface[]} listRef - L'elenco dei campi da utilizzare per costruire il form.
   * @param {number} index - L'indice corrente del campo in fase di elaborazione.
   * @return {Object} - L'oggetto form costruito.
   */
    private buildForm(formObject: Object, listRef: FieldInterface[], index: number): Object {
        const field = listRef[index];
        if (!field) {
            return formObject;
        }

        if (field.controlType !== 'multiselect') {
            formObject[field.key] = this.fb.control(null);
        } else {
            formObject[field.key] = this.fb.array(
                field.options.map(option => this.fb.group({
                    name: this.fb.control(option.value),
                    selected: this.fb.control(option.selected ? true : false)
                }))
            );
        }

        return this.buildForm(formObject, listRef, index + 1);
    }

    /**
     * Costruisce un array di righe in base all'elenco di campi e al numero massimo di colonne.
     *
     * @param {FieldInterface[][]} rowsStart - L'array di righe iniziale.
     * @param {FieldInterface[]} listRef - L'elenco di campi da cui costruire le righe.
     * @param {number} [indexRef=0] - L'indice del campo attualmente in elaborazione.
     * @param {number} indexRow - L'indice della riga attualmente in elaborazione.
     * @param {number} maxColumns - Il numero massimo di colonne per riga.
     * @return {FieldInterface[][]} - L'array di righe risultante.
     */
    buildRows(rowsStart: FieldInterface[][], listRef: FieldInterface[], indexRef: number = 0, indexRow: number, maxColumns: number): FieldInterface[][] {
        const field = listRef[indexRef];
        if (!field) {
            return rowsStart;
        }
        if (rowsStart[indexRow].length == maxColumns) {
            indexRow += 1;
            rowsStart.push([]);
        }
        rowsStart[indexRow].push(field);

        return this.buildRows(rowsStart, listRef, indexRef + 1, indexRow, maxColumns);
    }

    /**
     * Costruisce il valore di ricerca corrente in base alle righe fornite.
     *
     * @param {FieldInterface[][]} rows - Le righe contenenti i campi.
     * @return {Object} - L'oggetto che rappresenta il valore di ricerca corrente.
     */
    public buildCurrentSearchValue(rows: FieldInterface[][] | FieldInterface[] | FormGroup): Object {
        const values = {};
        if (rows instanceof FormGroup) {
            Object.keys(rows.controls).forEach(key => {
                if (rows.controls[key] instanceof FormArray) {
                    values[key] = [];
                    for (const fg of this.getFormArrayControls(key)) {
                        if (fg.get('selected').value === true) {
                            values[key].push(fg.get('name').value);
                        }
                    }
                }
                else {
                    values[key] = rows.controls[key].value;
                }
            });
            return values;
        }

        const fields = rows.flat(1);

        for (const field of fields) {
            if (field.controlType === 'multiselect') {
                values[field.key] = [];
                for (const fg of this.getFormArrayControls(field.key)) {
                    if (fg.get('selected').value === true) {
                        values[field.key].push(fg.get('name').value);
                    }
                }
            } else {
                values[field.key] = this.formSession.get(field.key).value;
            }
        }

        return values
    }

    /**
     * Recupera l'array di controlli FormGroup associati alla chiave specificata dal formSession.
     *
     * @param {string} key - La chiave utilizzata per recuperare i controlli FormArray.
     * @return {Array<FormGroup>} Un array di controlli FormGroup.
     */
    public getFormArrayControls(key: string): Array<FormGroup> {
        return (this.formSession?.get(key) as FormArray)?.controls as Array<FormGroup>;
    }
}
