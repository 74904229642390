import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorMessageInterface } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ErrorObserverService {
  protected message$: Subject<ErrorMessageInterface>;
  constructor() {
    this.message$ = new Subject<ErrorMessageInterface>();
  }
  public send(message: ErrorMessageInterface) {
    this.message$.next(message);
  }
  public on(): Observable<ErrorMessageInterface> {
    return this.message$.asObservable();
  }

  public clear(name?: string) {
    const clear: ErrorMessageInterface = {
      clear: true,
      name: name,
      message: ''
    }
    this.message$.next(clear);
  }
}
