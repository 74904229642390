import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take } from "rxjs";
import { AclDTO, CensimentoDTO, RuoloDTO, UtenteDTO } from "../dtos";

@Injectable({
  providedIn: 'root'
})
export class SuperadminService {

  private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }


  accesso(passsword: string, identificatore: string): Promise<string>{
    return new Promise<any>(
      (resolve, reject) => {
        const superadmin  = {
          password: passsword,
          identificatore: identificatore
        }
        this.http.post(this.url_endpoint + api.accesso_admin, superadmin, { observe: 'response' })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              const location = resp.headers.get('Location');
              resolve(location);
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  /**
 * Changes the state based on the fiscal code.
 *
 * @param {string} fiscalCode - The fiscal code to identify the state change.
 * @return {Promise<any>}
 */
  cambiaStato(fiscalCode: string, attivo: boolean): Promise<any> {
    return new Promise<any>(
      (resolve, reject) => {
        const attivoStato = {
          attivo: attivo
        }
        this.http.put(this.url_endpoint + api.cambia_stato + '/' + fiscalCode + '/cambia-stato', attivoStato, { observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              if (resp?.status == 'OK') {
                resolve(resp?.body);
              } else {
                reject({
                  status: 0,
                  error: resp?.message
                })
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }


    /**
   * Ritorna l'operatore passando il codicefiscale.
   * @param {string} fiscalCode - The fiscal code to identify the state change.
   * @return {Promise<RuoloDTO[]>}
   */
    getOperatoreByCodiceFiscale(codicefiscale: string): Promise<UtenteDTO> {
      return new Promise((resolve, reject) => {
        this.http.get(this.url_endpoint + api.operatore_ottieni_codice_fiscale + '/' + codicefiscale, { observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              if (!!resp?.body && resp?.status == 'OK') {
                resolve(resp?.body);
              } else {
                reject(
                  {
                    status: 2,
                    error: resp?.message
                  }
                );
              }
            },
            error: (error) => {
              reject(error);
            }
          });
      })
    }


  /**
   * Ritorna la lista dei ruoli.
   *
   * @return {Promise<RuoloDTO[]>}
   */
  listaRuoli(): Promise<RuoloDTO[]> {
    return new Promise((resolve, reject) => {
      this.http.get(this.url_endpoint + api.lista_ruoli, { observe: 'body' })
        .pipe(take(1))
        .subscribe({
          next: (resp: any) => {
            if (!!resp?.body && resp?.status == 'OK') {
              resolve(resp?.body);
            } else {
              reject(
                {
                  status: 2,
                  error: resp?.message
                }
              );
            }
          },
          error: (error) => {
            reject(error);
          }
        });
    })
  }

    /**
     * Changes acl based on the fiscal code.
     *
     * @param {string} fiscalCode - The fiscal code to identify the state change.
     * @return {Promise<any>}
     */
    cambiaACL(fiscalCode: string, aclDTO: Array<AclDTO>): Promise<any> {
    return new Promise<any>(
      (resolve, reject) => {

        this.http.post(this.url_endpoint + api.cambia_acl + '/' + fiscalCode + '/acl', aclDTO, { observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              if (resp?.status == 'OK') {
                resolve(resp?.body);
              } else {
                reject({
                  status: 0,
                  error: resp?.message
                })
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

 /**
     * Censimento Operatore sul portale
     *
     * @param {string} fiscalCode - The fiscal code to identify the state change.
     * @return {Promise<any>}
     */
 censimentoOperatore(censimento: CensimentoDTO): Promise<any> {
  return new Promise<any>(
    (resolve, reject) => {
      this.http.post(this.url_endpoint + api.censimento_operatore, censimento, { observe: 'body' })
        .pipe(take(1))
        .subscribe({
          next: (resp: any) => {
            if (resp?.status == 'OK') {
              resolve(resp?.body);
            } else {
              reject({
                status: 0,
                error: resp?.message
              })
            }
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          }
        });
    });
}

}
