import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateCheck'
})
export class DateCheckPipe implements PipeTransform {

  /**
   * Questa pipe fa in controllo sulle date per verificare se la news è scaduta o è da pubblicare
   * @param start
   * @param end
   * @param type
   * @returns
   */
  transform(start: string | Date, end: string | Date, type?: 'class' | 'text'): string {
    const currentDate = this.resetTime(new Date());
    const startDate = this.resetTime(new Date(start));
    const endDate = this.resetTime(new Date(end));

    switch (type) {
      case 'class':
        if (startDate > currentDate) {
          return 'text-danger';
        } else if (startDate < currentDate && endDate < currentDate) {
          return 'text-danger';
        } else {
          return ''
        }
      case 'text':
      default:
        if (startDate > currentDate && endDate > currentDate) {
          return 'NEWS.DA_PUBBLICARE';
        } else if (startDate < currentDate && endDate < currentDate) {
          return 'NEWS.SCADUTA';
        } else {
          return ''
        }
    }
  }

  private resetTime(date: Date): Date {
    date.setHours(0, 0, 0, 0);
    return date;
  }
}

