<div class="d-flex gap-2 align-items-start">
  <div class="nav-item dropdown mt-1" ngbDropdown>
    <input #tagsInput id="dropdownTags" type="text" name="tags" (keydown.enter)="onChange($event)"
      (input)="autoComplete($event)" class="nav-link dropdown-toggle form-group" autocomplete="off"
      data-bs-toggle="dropdown" ngbDropdownToggle>
    <div class="dropdown-menu p-0" aria-labelledby="dropdownTags" ngbDropdownMenu
      [style]="{display: listTags.length > 0 ? '' : 'none'}">
      <div class="row" *ngIf="listTags.length > 0">
        <div class="col-12">
          <div class="link-list-wrapper">
            <ul class="link-list" style='max-height: 200px; overflow-y: scroll;'>
              <li *ngFor="let tag of listTags">
                <a class="dropdown-item list-item" (click)="selectTag(tag.getLabel())" tabindex="0"
                  (keydown.enter)="selectTag(tag.getLabel())">
                  <span>{{tag.getLabel().replace(searchRegExp, ' ')}}<span class="visually-hidden"></span></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button
    type="button"
    class="btn btn-xs mx-1 btn-primary"
    (click)="onChange(tagsInput.value)"
    style="height: 48px; width: 48px;">
    <span class="visually-hidden" translate>FORM.ADDIZIONE</span>
    <svg aria-hidden="true" class="icon icon-white icon-xs bg-primary">
      <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-plus-circle"></use>
    </svg>
  </button>
</div>

<div class="w-100">
  <ng-container *ngFor="let tag of tags.value; let i = index">
    <div class="chip h-100">
      <span class="chip-label h-100 mt-1 text-capitalize">{{tag.replace(searchRegExp, ' ')}}</span>
      <button type="button" class="h-100 mb-2" (click)="removeTag(i)">
        <svg class="icon">
          <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-close"></use>
        </svg>
        <span class="visually-hidden">Elimina tag</span>
      </button>
    </div>
  </ng-container>
</div>
