import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ShowcasePage } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ShowcasePageObserver {
  
  private showCasePage: BehaviorSubject<ShowcasePage> = new BehaviorSubject<ShowcasePage>(null);

  constructor() { }

  on(): Observable<ShowcasePage> {
      return this.showCasePage.asObservable();
  }

  setShowCasePage(showCasePage: ShowcasePage) {
      this.showCasePage.next(showCasePage);
  }
}
