import { BudgetIndividualeDTO } from "../dtos";
import { BudgetIndividualeInterface } from "../interfaces";

export class BudgetIndividualeModel implements BudgetIndividualeInterface {
  protected id: number;
  protected budgetSpeso: number;
  protected ticketUtilizzati: number;
  protected budgetDisponibile: number;
  protected ticketDisponibili: number;
  constructor(dto: BudgetIndividualeDTO) {
    this.id = dto?.id;
    this.budgetSpeso = dto?.maxAmount;
    this.ticketUtilizzati = dto?.maxTickets;
    this.budgetDisponibile = dto?.amountRemaining;
    this.ticketDisponibili = dto?.ticketsRemaining;
  }
  getId(): number {
    return this.id;
  }
  getBudgetSpeso(): number {
    return this.budgetSpeso;
  }
  getTicketUtilizzati(): number {
    return this.ticketUtilizzati;
  }
  getBudgetDisponibile(): number {
    return this.budgetDisponibile;
  }
  getTicketDisponibili(): number {
    return this.ticketDisponibili;
  }
}
