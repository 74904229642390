import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { CurrentUserObserver } from 'core-model';
import { AuthFacade, UtenteFacade } from '../facades';

export const personalAreaGuard: CanActivateFn = async () => {
  const authFacade: AuthFacade = inject(AuthFacade);
  const utenteFacade: UtenteFacade = inject(UtenteFacade);
  const userObserver: CurrentUserObserver = inject(CurrentUserObserver);
  try {
    const utente = await utenteFacade.getUtente();
    if(!!utente) {
      authFacade.saveUser(utente);
      userObserver.set(utente);
      return true;
    }
    userObserver.set(null);
    authFacade.clearUser();
  } catch (error) { console.warn(error); }
  return false;
};


export const checkUserGuard: CanActivateFn = async () => {
  const authFacade: AuthFacade = inject(AuthFacade);
  const router: Router = inject(Router);
  try {
    const utente = await authFacade.getCurretUser();
    if (!!utente) {
      if (utente.isAttivo() == false) {
        return router.navigate(['area-personale', 'valida-account']);
      }
      return true;
    } else {
      return false;
    }
  } catch (error) { console.warn(error); }
  return false
};
