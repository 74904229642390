import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    TranslateModule
  ],
  declarations: [
  ],
})
export class LanguageTranslationModule {
  constructor( private translate: TranslateService ) {
    this.translate.addLangs(['en', 'it']);
    this.translate.setDefaultLang('it');
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang?.match(/en|it/) ? browserLang : 'it');
  }
}
