import { ResponseDTO } from "../dtos";

export class ResponseModel {

  private status?: string;
  private body?: object;
  private message?: string;

  constructor(dto: ResponseDTO) {
      this.status = dto?.status;
      this.body = dto?.body;
      this.message = dto?.message;
  }

  getStatus(){
    return this.status;
  }

  getBody(){
    return this.body;
  }

  getMessage(){
    return this.message;
  }

}
