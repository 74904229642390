import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import {
  AccompagnatoreService,
  AppIoService,
  BeneficiarioService,
  CategoriaDisabilitaService,
  DelegatoService,
  EmailService,
  FamiliareService,
  FileService,
  FreeInstanceService,
  GradoDisabilitaService,
  LanguageService,
  PhoneNumberService,
  RequisitoDisabilitaService,
  RichiedenteService,
  RinunciaService,
  UtenteService
} from './services';
import { ShowcasePageService } from './services/showcase-page.service';
import {
  ButtonObserver,
  ConteggioNotificheObserver,
  CurrentOperatorObserver,
  CurrentUserObserver
} from './observers';
import { LanguageTranslationModule } from './modules';
import { ErrorObserverService } from './singletons';
import { QuillCustomConfigModule } from './modules/quill-custom-config/quill-custom-config.module';


@NgModule({
  declarations: [],
  imports: [
    QuillCustomConfigModule,
    LanguageTranslationModule
  ],
  exports: [],
  providers: [
    // AccompagnatoreService,
    // AppIoService,
    // BeneficiarioService,
    // ButtonObserver,
    // CategoriaDisabilitaService,
    // CurrentOperatorObserver,
    // CurrentUserObserver,
    // DelegatoService,
    // EmailService,
    // ErrorObserverService,
    // FamiliareService,
    // FileService,
    // FreeInstanceService,
    // GradoDisabilitaService,
    // LanguageService,
    // PhoneNumberService,
    // RequisitoDisabilitaService,
    // RichiedenteService,
    // RinunciaService,
    // ShowcasePageService,
    // UtenteService
  ]
})
export class CoreModelFoModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModelFoModule) {
    if (parentModule) {
      throw new Error(
        'CoreModelFoModule is already loaded. Import it in the AppModule only');
    }
  }
  public static forRoot(environment: any): ModuleWithProviders<CoreModelFoModule> {
    return {
      ngModule: CoreModelFoModule,
      providers: [
        AccompagnatoreService,
        AppIoService,
        BeneficiarioService,
        ButtonObserver,
        CategoriaDisabilitaService,
        ConteggioNotificheObserver,
        CurrentOperatorObserver,
        CurrentUserObserver,
        DelegatoService,
        EmailService,
        ErrorObserverService,
        FamiliareService,
        FileService,
        FreeInstanceService,
        GradoDisabilitaService,
        LanguageService,
        PhoneNumberService,
        RequisitoDisabilitaService,
        RichiedenteService,
        RinunciaService,
        ShowcasePageService,
        UtenteService,
        {
          provide: 'env',
          useValue: environment
        }
      ]
    };
  }
}
