import { PROCESSING_TYPE } from "../constants";
import { LavorazioneGratuitaDTO } from "../dtos/lavorazione-gratuita.dto";
import { LavorazioneGratuitaInterface, ProvvedimentoInterface, StatoLavorazioneInterface } from "../interfaces";
import { ProvvedimentoModel } from "./provvedimento.model";
import { StatoLavorazioneModel } from "./stato-lavorazione.model";

export class LavorazioneGratuitaModel implements LavorazioneGratuitaInterface {
  protected dataAggiornamento: string
  protected id: number
  protected statoLavorazione: StatoLavorazioneInterface;
  protected provvedimento: ProvvedimentoInterface;
  protected attivo: boolean;
  protected ultimoStato: boolean;

  constructor(dto: LavorazioneGratuitaDTO) {
    this.id = dto?.id;
    this.statoLavorazione = new StatoLavorazioneModel(dto?.statoLavorazione);
    this.dataAggiornamento = dto?.dataAggiornamento;
    this.attivo = dto?.attivo;
    this.provvedimento = !!dto?.provvedimento ? new ProvvedimentoModel(dto?.provvedimento) : null;
    this.ultimoStato = dto?.ultimoStato;
  }
  isEmpty(): boolean {
    return this.provvedimento == null || (this.provvedimento.getDataTermineUltimo() == null &&
      this.provvedimento.getRispostaProvvedimento() == null &&
      (this.provvedimento.getFile() == null || this.provvedimento.getFile().length <= 0) &&
      (this.provvedimento.getEstremi() == '' || this.provvedimento.getEstremi() == null) &&
      (this.provvedimento.getNote() == '' || this.provvedimento.getNote() == null))
  }

  getDataAggiornamento(): string {
    return this.dataAggiornamento;
  }

  getId(): number {
    return this.id;
  }

  getProvvedimento(): ProvvedimentoInterface {
    return this.provvedimento;
  }

  getStatoLavorazione(): StatoLavorazioneInterface {
    return this.statoLavorazione;
  }

  isAttivo(): boolean {
    return this.attivo;
  }

  isIntegrazione(): boolean {
    return this.getStatoLavorazione().getStato() == PROCESSING_TYPE.INTEGRATION;
  }

  isRevocata(): boolean {
    return this.getStatoLavorazione().getStato() == PROCESSING_TYPE.REVOCATION;
  }

  isSospesa(): boolean {
    return this.getStatoLavorazione().getStato() == PROCESSING_TYPE.SUSPENSION;
  }

  isUltima(): boolean {
    return this.ultimoStato;
  }

  isValidata(): boolean {
    return this.getStatoLavorazione().getStato() == PROCESSING_TYPE.ACCEPTED;
  }
}
