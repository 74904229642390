import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class ButtonObserver {
    protected button$: Subject<string>;

    constructor() {
        this.button$ = new Subject<string>();
    }

    public on(): Observable<string> {
        return this.button$.asObservable();
    }

    public submit(buttonName?:string) {
        this.button$.next(buttonName);
    }
  }
