import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Newsfacade } from '../../facades/news.facade';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NewsModel, TraduzioniNewsModel } from '../../../../models';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CurrentOperatorObserver } from '../../../../observers';
import { OperatoreInterface } from '../../../../interfaces';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card-news',
  templateUrl: './card-news.component.html',
  styleUrls: ['./card-news.component.scss']
})
export class CardNewsComponent implements OnInit, OnDestroy {

  @Output() delete: EventEmitter<void> = new EventEmitter();
  @Input() news: NewsModel;
  translateNews: TraduzioniNewsModel;
  locale: string = this.translate.currentLang;
  private locale$: Subscription;
  @ViewChild('deleteModal') public modal: NgbModalRef;
  isManager: boolean = false;

  public currentOperator: OperatoreInterface;

  constructor(private newsFacade: Newsfacade,
              private translate: TranslateService,
              private modalService: NgbModal,
              private currentOperatorObserver: CurrentOperatorObserver,
              private router: Router) { }

  ngOnInit(): void {
    let translate = this.news.getTraduzioneByLingua(this.locale);

    if (!translate) {
      translate = this.news.getTraduzioneByLingua(this.translate.getDefaultLang());
    }
    this.translateNews = this.setTranslateNewsByLingua(this.locale)

    this.newsFacade.ottieniCopertina(this.news.getId()).then((copertina: any) => {
      this.news.setCopertina(copertina);
    })

    this.translate.onLangChange.subscribe((event: any) => {
      this.locale = event.lang;
      this.translateNews = this.setTranslateNewsByLingua(this.locale)
    });

    this.currentOperator = this.currentOperatorObserver.get();
    this.isManager = this.currentOperator ? (this.currentOperator.isManager() || this.currentOperator.isEditor() || this.currentOperator.isSuperUser()) : false;
  }


  ngOnDestroy(): void {
    if (this.locale$?.closed == false) {
      this.locale$?.unsubscribe();
    }
  }

  private setTranslateNewsByLingua(locale: string): TraduzioniNewsModel {
    let translate = this.news.getTraduzioneByLingua(locale);

    if (!translate) {
      translate = this.news.getTraduzioneByLingua(this.translate.getDefaultLang());
    }
    return translate
  }

  deleteNews() {
    this.modalService.open(this.modal, { size: 'lg', ariaLabelledBy: 'title', ariaDescribedBy: 'description' }).result.then(
      async (result) => {
        if (typeof result == 'number') {
          await this.newsFacade.rimuovi(this.news.getId());
          this.delete.emit()
        }
      },
      (reason) => {
        console.log(reason);
      }
    )
  }

  pushPage(id: number) {
    this.router.navigate(['/', 'news', id]);
  }
}
