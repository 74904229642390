import { RiscontroDTO } from "../dtos";
import {RiscontroInterface } from "../interfaces";


export class RiscontroModel implements RiscontroInterface {
  protected dataEsitoControllo: string;
  protected noteControllo: string;
  protected tipologiaControllo: string;

  constructor(dto?: RiscontroDTO) {
    this.dataEsitoControllo = dto?.dataEsitoControllo;
    this.noteControllo = dto?.noteControllo;
    this.tipologiaControllo = dto?.tipologiaControllo;
  }
  getTipologiaControllo(): string {
    return this.tipologiaControllo;
  }
  getDataEsitoControllo(): string {
    return this.dataEsitoControllo;
  }
  getNoteControllo(): string {
    return this.noteControllo;
  }

}
