import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactFacade } from './facade/contact.facade';

@Component({
  selector: 'app-contatti',
  templateUrl: './contatti.component.html',
  styleUrls: ['./contatti.component.scss']
})
export class ContattiComponent implements OnInit {

  form: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private ContactFacade: ContactFacade) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      nome: new FormControl('', [Validators.required, Validators.minLength(3)]),
      cognome: new FormControl('', [Validators.required, Validators.minLength(3)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      topic: new FormControl(null, [Validators.required]),
      messaggio: new FormControl('', [Validators.required]),
    });
  }

  async onSubmit() {
    if (this.form.valid) {
      const result = await this.ContactFacade.invia(this.form.value);
      console.log(result);
    }
  }
}
