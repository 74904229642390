import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { api } from '../constants';
import { take } from 'rxjs';
import { AnalisiSpeseDTO, InvoiceDTO, PaginationDTO } from '../dtos';
import { InvoiceModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }

  ottieni(id: number): Promise<InvoiceDTO> {
    return new Promise<any>(
      (resolve, reject) => {
        const params = new HttpParams().append('idInvoice', id);

        this.http.get(this.url_endpoint + api.invoice_ottieni, { observe: 'body', params })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              if (resp?.status == 'OK' && !!resp?.body) {
                resolve(resp.body);
              } else {
                reject({
                  status: 0,
                  error: resp?.message
                })
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }
  lista(page: number, pageSize: number, startdate: string, endDate: string, columSorting: string, sortingAsc: boolean): Promise<PaginationDTO> {
    return new Promise<any>(
      (resolve, reject) => {
        const params = new HttpParams()
          .append('page', page)
          .append('elementsInPage', pageSize)
          .append('columnSorting', columSorting)
          .append('startDate', startdate)
          .append('endDate', endDate)
          .append('sortingAsc', sortingAsc);
        
        this.http.get(this.url_endpoint + api.invoice_lista, { observe: 'body', params })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              // TODO: aggiungere check sullo stato della risposta
              if (resp?.status == 'OK' && !!resp?.body) {
                resolve(resp.body);
              } else {
                reject({
                  status: 0,
                  error: resp?.message
                })
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  aggiungi(invoice: InvoiceModel) {
    return new Promise<any>(
      (resolve, reject) => {
        this.http.post(this.url_endpoint + api.invoice_aggiungi, invoice, { observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              if (resp?.status == 'OK') {
                resolve(resp?.body);
              } else {
                reject({
                  status: 0,
                  error: resp?.message
                })
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  aggiorna(invoice: InvoiceModel) {
    return new Promise<any>(
      (resolve, reject) => {
        this.http.put(this.url_endpoint + api.invoice_aggiorna, invoice, { observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              if (resp?.status == 'OK') {
                resolve(resp?.body);
              } else {
                reject({
                  status: 0,
                  error: resp?.message
                })
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  elimina(idInvoice: number) {
    return new Promise<any>(
      (resolve, reject) => {
        const params = new HttpParams()
        .append('idInvoice', idInvoice);
        this.http.delete(this.url_endpoint + api.invoice_elimina, { observe: 'body', params })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              if (resp?.status == 'OK') {
                resolve(resp?.body);
              } else {
                reject({
                  status: 0,
                  error: resp?.message
                })
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  grafici(startDate: string, endDate: string, idFinancialBody: number): Promise<AnalisiSpeseDTO> {
    return new Promise<any>(
      (resolve, reject) => {
        const params = new HttpParams()
          .append('startDate', startDate)
          .append('endDate', endDate)
          .append('idFinancialBody', idFinancialBody);
        this.http.get(this.url_endpoint + api.invoice_grafici, { observe: 'body', params })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              if (resp?.status == 'OK') {
                resolve(resp?.body);
              } else {
                reject({
                  status: 0,
                  error: resp?.message
                })
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }
}
