<div class="card-wrapper rounded-3 shadow-sm overflow-hidden"
tabindex="0"
[ariaLabel]="translateNews?.getTitolo()"
(keydown.enter)="pushPage(news.getId())">
    <div class="card card-img no-after">
        <div class="img-responsive-wrapper">
            <div class="img-responsive img-responsive-card">
                <figure class="img-wrapper">
                    <img src="assets/images/placeholder-SGP.png" title="placeholder news regione puglia"
                        alt="placeholder news regione puglia" appGetBase64 [setBase64]="news.getCopertina()">
                </figure>
            </div>
        </div>
        <div class="card-body body-height border-top border-5 border-primary">

            <span class="data"
                [ariaLabel]="news.getDataInizioVisualizzazione() | date: 'dd MMMM yyyy'">{{news.getDataInizioVisualizzazione()
                | date: 'dd/MM/yyyy'}} </span>
            <span class="data fw-bold"
            [ngClass]="news.getDataInizioVisualizzazione() | dateCheck:news.getDataDiScadenza():'class'"
            [ariaLabel]="news.getDataInizioVisualizzazione() | dateCheck:news.getDataDiScadenza():'text' | translate">
              {{news.getDataInizioVisualizzazione() | dateCheck:news.getDataDiScadenza():'text' | translate}}
            </span>

            <ng-container *ngTemplateOutlet="textCard; context: { $implicit: translateNews }"></ng-container>
            <ng-template #textCard let-translate>
                <h3 class="card-title h6 mt-2 ellipsis-3">{{translate?.getTitolo()}}</h3>
                <p class="card-text font-serif mt-2 ellipsis-3" [ariaLabel]="translate?.getDescrizione()">
                    {{translate?.getDescrizione()}}</p>
            </ng-template>

            <div class="row">
                <div class="col-6">
                    <div *ngIf="isManager" class="position-absolute bottom-0 start-0 ms-2 mb-2 d-flex gap-2">
                        <a [routerLink]="['/', 'news', news.getId(), 'modifica']" routerLinkActive="router-link-active"
                            class="btn p-2 btn-icon">
                            <svg class="bg-white text-white icon align-middle" aria-hidden="true">
                                <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-pencil"></use>
                            </svg>
                        </a>
                        <a class="btn p-2 btn-icon" (click)="deleteNews()">
                            <svg class="bg-white text-white icon align-middle" aria-hidden="true">
                                <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-delete"></use>
                            </svg>
                        </a>
                    </div>
                </div>
                <div class="col-6">
                    <a class="read-more bottom-0 end-0 me-2 mb-2" [routerLink]="['/', 'news', news.getId()]"
                    routerLinkActive="router-link-active" tabindex="0">
                    <span class="text text-primary" translate>NEWS.CONTINUA</span>
                    <svg class="icon fill-primary">
                        <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-arrow-right"></use>
                    </svg>
                </a>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #deleteModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="title" translate>NEWS.DELETE</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss(false)"></button>
    </div>

    <div class="modal-body" id="title" >
        <label for="title" class="h6 text-primary" translate>FORM.TITOLO</label>
        <p>{{translateNews.getTitolo()}}</p>
    </div>
    <div class="modal-footer">
        <a role="button" class="btn btn-primary d-flex align-items-center" (click)="modal.close(news.getId())"
            translate>
            <svg class="text-white icon me-2 " aria-hidden="true" style="fill: white !important;">
                <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-delete"></use>
            </svg>
            <span translate>FORM.ELIMINA</span>
        </a>
    </div>
</ng-template>
<!--
     <div class="position-absolute bottom-0 start-0 ms-2 mb-2 d-flex gap-2">
                <a class="btn btn-primary p-2 btn-icon">
                    <svg class="bg-white text-white icon align-middle" aria-hidden="true">
                        <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-pencil"></use>
                    </svg>
                </a>
                <a class="btn btn-primary p-2 btn-icon">
                    <svg class="bg-white text-white icon align-middle" aria-hidden="true">
                        <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-delete"></use>
                    </svg>
                </a>
            </div>
 -->
