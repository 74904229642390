import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { take } from 'rxjs';
import { PRESA_VISIONE, api } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class OperatorService {

  private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }

  presaVisione(presaVisione: PRESA_VISIONE) {
     return new Promise<boolean>(
       (resolve, reject) => {

         this.http.put(this.url_endpoint + api.operatore_presa_visione, { 'tipologiaPresaVisione': presaVisione}, { observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              if (!!resp?.body) {
                resolve(resp?.body);
              } else {
                reject({
                  status: 0,
                  error: resp?.message
                })
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  switch(piattaforma: string){
    return new Promise<boolean>(
      (resolve, reject) => {
        this.http.post(this.url_endpoint + api.operatore_switch, { 'tipologiaPortale': piattaforma}, { observe: 'body' })
         .pipe(take(1))
         .subscribe({
           next: (resp: any) => {
             if (resp?.body != null) {
               resolve(resp?.body);
             } else {
               reject({
                 status: 0,
                 error: resp?.message
               })
             }
           },
           error: (error: HttpErrorResponse) => {
             reject(error);
           }
         });
     });

  }
}
