import { PaginationDTO } from "../dtos";
import { SpesaInterface, SpesaRowInterface, SpesaPaginationInterface} from "../interfaces";
import { SpesaPaginationModel } from "./spesa-pagination.model";

export class SpesaTablePaginationModel extends SpesaPaginationModel implements SpesaPaginationInterface {
    constructor(dto?: PaginationDTO) {
        super(dto);
    }

    getRows(): Array<SpesaRowInterface> {
        return this.content.map((spesa: SpesaInterface) => {
            return {
                amount: spesa.getImporto(),
                beneficiaryMunicipality: spesa.getComuneBeneficiario(),
                beneficiaryProvince: spesa.getProvinciaBeneficiario(),
                month: spesa.getMese(),
                ticketType: spesa.getTipologiaBiglietto(),
                year: spesa.getAnno()
            }
        });
    }
}