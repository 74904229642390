import { Component } from '@angular/core';

@Component({
  selector: 'lib-button-login',
  templateUrl: './button-login.component.html',
  styleUrls: ['./button-login.component.css']
})
export class ButtonLoginComponent {

}
