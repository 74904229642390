import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take } from "rxjs";
import { ProcessingDTO } from "../dtos";


@Injectable({
  providedIn: 'root'
})
export class ProcessingService {

  private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }

  /**
   * Riscontro gratuita
   *
   * @param { number } idGratuita
   * @return { Promise<any> }
   */
  riscontro(idGratuita: number): Promise<any> {
    return new Promise<any>(
      (resolve, reject) => {
        const dto = {
          idGratuita: idGratuita,
        };
        this.http.put(this.url_endpoint + api.lavorazione_riscontro_gratuita, dto, { observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: HttpResponse<any>) => {
              if (!!resp?.body) {
                resolve(resp?.body);
              } else {
                resolve(null);
              }
            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }

  /**
 * Avvia l'istruttoria.
 *
 * @param {ProcessingDTO} processingDTO - The data transfer object for processing
 * @return {Promise<any>}
 */
  lavorazioneIstruttoria(processingDTO: ProcessingDTO): Promise<any> {
    return new Promise<any>(
      (resolve, reject) => {

        this.http.post(this.url_endpoint + api.lavorazione_aggiungi_gratuita, processingDTO, { observe: 'body' })
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {

              if (resp?.status == "OK") {
                resolve(resp?.body);
              } else {
                  reject(
                    {
                      status: 2,
                      error: resp?.message
                    }
                  );

              }

            },
            error: (error: HttpErrorResponse) => {
              reject(error);
            }
          });
      });
  }
}
