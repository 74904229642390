
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { api } from "../constants";
import { take } from "rxjs";
import { ConfigurazioneDTO, ConfigurazioneFinalDTO, FasciaIseePercentualeConfigurationDTO } from "../dtos";
import { ResponseModel } from "../models";


@Injectable({
    providedIn: 'root'
})
export class ConfigurazioneService {

      private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }


    /**
     * Inserimento dei dati di configurazione
     *
     * @param { ConfigurazioneDTO } configurazione
     * @return { Promise<void> }
     */
    aggiungiConfigurazione(configurazione: ConfigurazioneDTO): Promise<void> {
      return new Promise<void>(
        (resolve, reject) => {
          this.http.post(this.url_endpoint+api.configurazione_aggiungi, configurazione, {observe: 'body'})
            .pipe(take(1))
            .subscribe({
              next: (resp: any) => {
                if (resp?.status == "OK") {
                  resolve(resp?.body);
                } else {
                  reject(
                      {
                          status: 2,
                          error: resp?.message
                      }
                  );
              }
              },
              error: (error:HttpErrorResponse) => {
                reject(error);
            }
            });
        });
    }

  /**
     * Get the configuration from the server.
     *
     * @return {Promise<ConfigurazioneFinalDTO>} A promise that resolves with the final configuration DTO.
     */
    getConfigurazione(): Promise<ConfigurazioneFinalDTO> {
      return new Promise<ConfigurazioneFinalDTO>(
        (resolve, reject) => {
          this.http.get(this.url_endpoint+api.configurazione_ottieni, {observe: 'body'})
            .pipe(take(1))
            .subscribe({
              next: (resp: any) => {
                if (resp?.status == "OK") {
                  resolve(resp?.body);
                } else {
                  reject(
                      {
                          status: 2,
                          error: resp?.message
                      }
                  );
              }
              },
              error: (error:HttpErrorResponse) => {
                reject(error);
            }
            });
        });
    }


  /**
     * Get the configuration from the server by date.
     *
     * @return {Promise<ConfigurazioneFinalDTO>} A promise that resolves with the final configuration DTO.
     */
  getConfigurationByDate(date: string): Promise<ConfigurazioneFinalDTO> {
    return new Promise<ConfigurazioneFinalDTO>(
      (resolve, reject) => {
        this.http.get(this.url_endpoint+api.configurazione_storico_ottieni, {observe: 'body' , params: {scheduleDate: date}})
          .pipe(take(1))
          .subscribe({
            next: (resp: any) => {
              if (resp?.status == "OK") {
                resolve(resp?.body);
              } else {
                reject(
                    {
                        status: 2,
                        error: resp?.message
                    }
                );
            }
            },
            error: (error:HttpErrorResponse) => {
              reject(error);
          }
          });
      });
  }

    /**
     * Retrieves a list of historical configurations for a given year.
     *
     * @param {number} anno - the year for which historical configurations are requested
     * @return {Promise<Array<ConfigurazioneFinalDTO>>} a promise that resolves with an array of historical configuration objects
     */
    listaConfigurazioniStorici(anno: number): Promise<Array<ConfigurazioneFinalDTO>> {
      return new Promise<Array<ConfigurazioneFinalDTO>>(
        (resolve, reject) => {
          this.http.get(this.url_endpoint+api.configurazione_lista_storico, {observe: 'body', params: {year: anno}})
            .pipe(take(1))
            .subscribe({
              next: (resp: any) => {
                if (resp?.status == "OK") {
                  resolve(resp?.body);
                } else {
                  reject(
                      {
                          status: 2,
                          error: resp?.message
                      }
                  );
              }
              },
              error: (error:HttpErrorResponse) => {
                reject(error);
            }
            });
        });
    }


    listaConfigurazioni(): Promise<Array<ConfigurazioneFinalDTO>> {
      return new Promise<Array<ConfigurazioneFinalDTO>>(
        (resolve, reject) => {
          this.http.get(this.url_endpoint+api.configurazione_lista, {observe: 'body'})
            .pipe(take(1))
            .subscribe({
              next: (resp: any) => {
                if (resp?.status == "OK") {
                  resolve(resp?.body);
                } else {
                  reject(
                      {
                          status: 2,
                          error: resp?.message
                      }
                  );
              }
              },
              error: (error:HttpErrorResponse) => {
                reject(error);
            }
            });
        });
    }
  /**
   * Retrieves a list of ISEE configuration fasces.
   *
   * @return {Promise<Array<any>>}
   */
    listaFasceISEEConfigurazione(): Promise<Array<FasciaIseePercentualeConfigurationDTO>> {
      return new Promise<Array<FasciaIseePercentualeConfigurationDTO>>(
        (resolve, reject) => {
          this.http.get(this.url_endpoint+api.configurazione_lista_fasce, {observe: 'body'})
            .pipe(take(1))
            .subscribe({
              next: (resp: any) => {
                if (resp?.status == "OK") {
                  resolve(resp?.body);
                } else {
                  reject(
                      {
                          status: 2,
                          error: resp?.message
                      }
                  );
              }
              },
              error: (error:HttpErrorResponse) => {
                reject(error);
            }
            });
        });
    }

     /**
     * elimina configurazione
     *
     * @param { ConfigurazioneDTO } configurazione
     * @return { Promise<void> }
     */
     eliminaConfigurazione(dataConfigurazione: string): Promise<ResponseModel> {
      return new Promise<ResponseModel>(
        (resolve, reject) => {
          this.http.delete(this.url_endpoint+api.configurazione_elimina, {observe: 'body', params: {scheduleDate: dataConfigurazione}})
            .pipe(take(1))
            .subscribe({
              next: (resp: any) => {
                if (resp?.status == "OK") {
                  resolve(resp?.body);
                } else {
                  reject(
                      {
                          status: 2,
                          error: resp?.message
                      }
                  );
              }
              },
              error: (error:HttpErrorResponse) => {
                reject(error);
            }
            });
        });
    }


}
