import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { take } from 'rxjs';
import { NotificaDTO } from '../dtos';
import { api } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class NotificaService {

    private url_endpoint;
  constructor(private http: HttpClient, @Inject('env') env) {
    this.url_endpoint = env.environment.url_endpoint;
  }

  lista(): Promise<Array<NotificaDTO>> {
    return new Promise((resolve, reject) => {
      this.http.get(this.url_endpoint + api.notifiche_lista, { observe: 'body' })
        .pipe(take(1))
        .subscribe({
          next: (resp: any) => {
            if (!!resp?.body && resp?.status == 'OK') {
              resolve(resp?.body);
            } else {
              reject(
                {
                  status: 2,
                  error: resp?.message
                }
              );
            }
          },
          error: (error) => {
            reject(error);
          }
        });
    })
  }

  visualizza(id: Array<number>): Promise<any> {
    return new Promise((resolve, reject) => {
      const idNotifiche = {
        idNotifiche: id
      }
      this.http.put(this.url_endpoint + api.notifica_visualizza, idNotifiche ,{ observe: 'body' })
        .pipe(take(1))
        .subscribe({
          next: (resp: any) => {
            if (!!resp?.body && resp?.status == 'OK') {
              resolve(resp?.body);
            } else {
              reject(
                {
                  status: 2,
                  error: resp?.message
                }
              );
            }
          },
          error: (error) => {
            reject(error);
          }
        });
    })
  }
}
