import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { Config } from './config.service';

@NgModule({})
export class ConfigModule {
    constructor(@Optional() @SkipSelf() parentModule?: ConfigModule) {
        if (parentModule) {
            throw new Error(
                'ConfigModule is already loaded. Import it in the AppModule only');
        }
    }
    public static forRoot(config: Config): ModuleWithProviders<ConfigModule> {
        return {
            ngModule: ConfigModule,
            providers: [
                { provide: Config, useValue: config }
            ],
        };
    }
}
