import { FileDTO, UtenteDTO } from "../dtos";
import { AddressInterface, AnagraficaInterface, ApplicantInterface, EmailInterface, MobileInterface, RuoloInterface, UtenteInterface } from "../interfaces";
import { AnagraficaModel } from "./anagrafica.model";
import { EmailModel } from "./email.model";
import { AddressModel } from "./address.model";
import { MobileModel } from "./mobile.model";
import { RuoloModel } from "./ruolo.model";
import { AppIoModel } from "./appio.model";
import { TIPO_NOTIFICHE } from "../constants";
import { ApplicantModel } from "../models";

export class UtenteModel implements UtenteInterface {
  protected abilitato: boolean;
  protected anagrafica: AnagraficaInterface;
  protected appIo: AppIoModel;
  protected attivo: boolean;
  protected dataAbilitazione: Date;
  protected dataAggiornamento: Date;
  protected dataAttivazione: Date;
  protected dataCreazione: Date;
  protected dataDisabilitazione: Date;
  protected dataDisattivazione: Date;
  protected emailServiceProvider: string;
  protected emails: Array<EmailInterface>;
  protected file: Array<FileDTO>;
  protected fuoriRegione: boolean;
  protected identificativo: string;
  protected indirizzo: AddressInterface;
  protected numeriCellulare: Array<MobileInterface>;
  protected privacy: boolean;
  protected richiedentiGratuita: Array<ApplicantInterface>;
  protected ruoli: Array<RuoloInterface>;
  protected sceltaNotifica: TIPO_NOTIFICHE;
  protected superuser: boolean;

  constructor(dto: UtenteDTO) {
    this.abilitato = dto?.abilitato;
    this.anagrafica = !!dto?.anagrafica ? new AnagraficaModel(dto.anagrafica) : undefined;
    this.appIo = !!dto?.appIo ? new AppIoModel(dto.appIo) : undefined;
    this.attivo = dto?.attivo;
    this.dataAbilitazione = new Date(dto?.dataAbilitazione);
    this.dataAggiornamento = new Date(dto?.dataAggiornamento);
    this.dataAttivazione = new Date(dto?.dataAttivazione);
    this.dataCreazione = new Date(dto?.dataCreazione);
    this.dataDisabilitazione = new Date(dto?.dataDisabilitazione);
    this.dataDisattivazione = new Date(dto?.dataDisattivazione);
    this.emails = dto?.emails?.map((dto) => new EmailModel(dto));
    this.file = dto?.file;
    this.fuoriRegione = dto?.fuoriRegione;
    this.emailServiceProvider = dto?.emailServiceProvider;
    this.identificativo = dto?.identificativo;
    this.indirizzo = !!dto?.indirizzo ? new AddressModel(dto?.indirizzo) : undefined;
    this.numeriCellulare = dto?.numeriCellulare?.map((dto) => new MobileModel(dto));
    this.numeriCellulare?.sort((a, b) => a.getDataCreazione().getTime() - b.getDataCreazione().getTime());
    this.richiedentiGratuita = dto?.richiedentiGratuita?.map((dto) => new ApplicantModel(dto));
    this.ruoli = dto?.ruoli?.map((dto) => new RuoloModel(dto));
    this.privacy = dto?.privacy;
    this.sceltaNotifica = dto?.sceltaNotifica;
    this.superuser = dto?.superuser;
  }
  
  getLastEmailActive(): string {
    const email =this.getAllEmail().find((email: EmailInterface) => email?.isAttivo());
    return email?.getEmail();
  }

  enabledBackOffice(): boolean {
    return (this.isManager() || this.isOperatore());
  }
  getNominativo(): string {
    return this.getNome() + " " + this.getCognome();
  }

  getCodeAppIo(): string {
    return this.appIo?.getCode();
  }

  getCognome(): string {
    return this.anagrafica?.getCognome();
  }

  getNome(): string {
    return this.anagrafica?.getNome();
  }

  public getIdentificativo() {
    return this.identificativo;
  }

  public getEmailServiceprovider() {
    return this.emailServiceProvider;
  }

  public getAllEmail() {
    return this.emails?.sort((a, b) => b.getDataCreazione().getTime() - a.getDataCreazione().getTime());
  }

  public getAnagrafica(): AnagraficaInterface {
    return this.anagrafica;
  }

  public getAppIo() {
    return this.appIo;
  }

  public getDataAbilitazione() {
    return this.dataAbilitazione;
  }

  public getDataDisabilitazione() {
    return this.dataDisabilitazione;
  }

  public getDataCreazione() {
    return this.dataCreazione;
  }

  public getDataAggiornamento() {
    return this.dataAggiornamento;
  }

  public getIndirizzo() {
    return this.indirizzo;
  }

  public getRuoli() {
    return this.ruoli;
  }

  public getLastCodePhoneNumber(): string {
    const u = this.getNumeriCellulari().find(num => !!num?.getCodice())?.getCodice();
    return u;
  }

  public getLastEmail(): EmailInterface {
    return this.getAllEmail()?.at(0);
  }

  public getLastEmailInvalid(): EmailInterface {
    return this.getAllEmail()?.find((email: EmailInterface) => !email.isVerificato());
  }

  public getLastEmailValid(): EmailInterface {
    return this.getAllEmail()?.find((email: EmailInterface) => email.isVerificato());
  }

  public getLastExpiryDateCodePhoneNumber(): Date {
    return this.getNumeriCellulari().find(num => !num?.isVerificato())?.getDataScadenzaCodice();
  }

  public getLastPhoneNumber(): string {
    return this.getNumeriCellulari()[0]?.getNumero();
  }

  public getNumeriCellulari() {
    this.numeriCellulare?.sort((a, b) => b.getDataCreazione().getTime() - a.getDataCreazione().getTime());
    return this.numeriCellulare;
  }

  public getPhoneNumberValid(): string {
    return this.getNumeriCellulari().find(num => num?.isVerificato())?.getNumero();
  }

  public getSceltaNotifica(): TIPO_NOTIFICHE {
    if (!this.sceltaNotifica) {
      if (this.numeriCellulare?.length > 0) {
        return TIPO_NOTIFICHE.SMS;
      } else if (!!this.appIo) {
        return TIPO_NOTIFICHE.APPIO;
      }
    }
    return this.sceltaNotifica;
  }

  public hasAcceptedPrivacy(): boolean {
    return !!this.privacy;
  }

  public hasAnagrafica(): boolean {
    return !!this.anagrafica;
  }

  public hasAnagraficaVerificata(): boolean {
    return this.anagrafica?.isVerificata();
  }

  hasAppIo(): boolean {
    return !!this.appIo;
  }

  hasComuneResidenza(): boolean {
    return !!this.indirizzo?.getComuneResidenza();
  }

  public hasEmail(): boolean {
    return this.getAllEmail()?.length > 0;
  }

  public hasIndirizzo(): boolean {
    return !!this.indirizzo;
  }

  public hasPhoneNumber(): boolean {
    return this.getNumeriCellulari()?.length > 0;
  }

  public hasLastEmailValid(): boolean {
    if (this.getAllEmail()?.length > 0) {
      return !!this.getAllEmail()?.at(0)?.isVerificato() == true;
    }
    return false;
  }

  public hasLastEmailInvalid(): boolean {
    if (this.getAllEmail()?.length > 0) {
      return !!this.getAllEmail()?.at(0)?.isVerificato() == false;
    }
    return false;
  }

  public hasSceltaNotifica(): boolean {
    return !!this.getSceltaNotifica();
  }

  public hasValidAppIo(): boolean {
    return this.appIo?.isVerificato() == true;
  }

  public hasValidPhoneNumber(): boolean {
    return !!this.getNumeriCellulari().find(num => num?.isVerificato());
  }

  public hasNotValidPhoneNumber(): boolean {
    if (this.getNumeriCellulari()?.length > 0) {
      return !!this.getNumeriCellulari().find(num => num?.isVerificato() == false);
    }
    return false;
  }

  public isAbilitato() {
    return this.abilitato;
  }

  public isAttivo(): boolean {
    return this.attivo;
  }

  public isFuoriRegione() {
    return this.fuoriRegione;
  }

  isManager(): boolean {
    return this.ruoli?.some((ruolo) => (ruolo.isManager() && ruolo?.isAttivo()));
  }
  isOperatore(): boolean {
    return this.ruoli?.some((ruolo) => (ruolo.isOperatore() && ruolo?.isAttivo()));
  }
  isSuperUser(): boolean {
    return this.superuser;
  }


  public toDTO(): UtenteDTO {
    return {
      abilitato: this.abilitato,
      anagrafica: this.anagrafica?.toDTO(),
      appIo: this.appIo?.toDTO(),
      attivo: this.attivo,
      dataAbilitazione: this.dataAbilitazione?.toJSON(),
      dataAggiornamento: this.dataAggiornamento?.toJSON(),
      dataAttivazione: this.dataAttivazione?.toJSON(),
      dataCreazione: this.dataCreazione?.toJSON(),
      dataDisabilitazione: this.dataDisabilitazione?.toJSON(),
      dataDisattivazione: this.dataDisattivazione?.toJSON(),
      emailServiceProvider: this.emailServiceProvider,
      emails: this.getAllEmail()?.map((email: EmailInterface) => email.toDTO()),
      fuoriRegione: this.fuoriRegione,
      identificativo: this.identificativo,
      indirizzo: this.indirizzo?.toDTO(),
      numeriCellulare: this.getNumeriCellulari()?.map((num: MobileInterface) => num.toDTO()),
      // richiedentiGratuita: this.getRichiedentiGratuita()?.map((app: ApplicantInterface) => app.toDTO()),
      // ruoli: this.getRuoli()?.map((ruolo: RuoloInterface) => ruolo.toDTO()),
      sceltaNotifica: this.getSceltaNotifica(),
    }
  }

  public setAppIo(appIo: AppIoModel): void {
    this.appIo = appIo;
  }
}
