import { VoceDiCostoDTO } from "../dtos";
import { EnteInterface, VoceDiCostoInterface } from "../interfaces";
import { EnteModel } from "./ente.model";

export class VoceDiCostoModel implements VoceDiCostoInterface {
    protected id: number;
    protected alias: string;
    protected descrizione: string;
    protected importoIniziale: number;
    protected importoEvoluto: number;
    protected dataEvoluzioneImporto: string;
    protected enteFinanziatore: EnteInterface;
    protected operazioniFinanziarie: any[];
    constructor(dto: VoceDiCostoDTO) {
        this.id = dto?.id;
        this.alias = dto?.alias;
        this.descrizione = dto?.descrizione;
        this.importoIniziale = dto?.importoIniziale;
        this.importoEvoluto = dto?.importoEvoluto;
        this.dataEvoluzioneImporto = dto?.dataEvoluzioneImporto;
        this.enteFinanziatore = dto?.enteFinanziatore ? new EnteModel(dto.enteFinanziatore) : undefined;
        // { id: number; alias: string; descrizione: string; }
        this.operazioniFinanziarie = dto?.operazioniFinanziarie;
    }

    getAliasEnte(): string {
        return this.enteFinanziatore?.getAlias();
    }

    getBudgetIniziale(): number {
        return this.importoIniziale;
    }

    getBudgetAttuale(): number {
        return this.importoEvoluto;
    }

    getEnte(): EnteInterface {
        return this.enteFinanziatore;
    }

    getId(): number {
        return this.id;
    }

    getNomeEnte(): string {
        return this.enteFinanziatore?.getDescrizione();
    }
}