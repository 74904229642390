import { ANALISI_SPESE_TYPE } from "../constants/analisi-spese.enum";
import { GraficiAnalisiSpeseDto, SeriesDto } from "../dtos";
import { GraficiAnalisiSpeseInterface, SeriesInterface } from "../interfaces/grafici-analisi-spese.interface";

export class GraficiAnalisiSpeseModel implements GraficiAnalisiSpeseInterface {

    protected series: SeriesInterface[];

    constructor(dto?: GraficiAnalisiSpeseDto) {
        this.series = dto.series.map((s) => new SeriesModel(s));
    }

    getSeries(): SeriesInterface[] {
        return this.series;
    }
    
}

export class SeriesModel implements SeriesInterface {

    protected name: ANALISI_SPESE_TYPE;
    protected type: string;
    protected radius: string;
    protected data: { value: number, name: string }[];
    protected label: any;
    protected tooltip: any;
    
    constructor(dto?: SeriesDto) {
        this.name = dto?.name;
        this.type = dto?.type;
        this.radius = dto?.radius;
        this.data = dto?.data;
        this.label = dto?.label;
        this.tooltip = dto?.tooltip;
    }

    getName(): ANALISI_SPESE_TYPE {
        return this.name;
    }
    getType(): string {
        return this.type;
    }
    getRadius(): string {
        return this.radius;
    }
    getData(): { value: number; name: string; }[] {
        return this.data;
    }
    getLabel(): any {
        return this.label;
    }
    getTooltip() {
        return this.tooltip;
    }

    setData(data: { value: number; name: string; }, index: number): number {
        this.data[index] = data;
        return index;
    }
    setLabel(label: any) {
        this.label = label;
    }
    setTooltip(tooltip: any): void {
        this.tooltip = tooltip;
    }

    differenceData(data: { value: number, name: string }, dataSub: { value: number, name: string }): { value: number, name: string } {
        const diff = data.value - dataSub.value;
        return { value: diff, name: data.name };
    }
}