import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CryptoConfig } from './crypto.service';

@NgModule({})
export class CryptoModule {
    constructor(@Optional() @SkipSelf() parentModule?: CryptoModule) {
        if (parentModule) {
            throw new Error(
                'CryptoModule is already loaded. Import it in the AppModule only');
        }
    }
    public static forRoot(config: CryptoConfig): ModuleWithProviders<CryptoModule> {
        return {
            ngModule: CryptoModule,
            providers: [
                { provide: CryptoConfig, useValue: config }
            ],
        };
    }
}
