import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessageInterface, ErrorObserverService } from 'core-model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {
  @Input() dismissible: boolean = true;
  @Input() name: string = 'MAIN';
  @Input() type: 'alert' | 'modal' | 'text' = 'alert';

  @ViewChild('content') modal: any;

  public error: ErrorMessageInterface;
  private error$: Subscription;
  constructor(
    private errorObserver: ErrorObserverService,
    private modalService: NgbModal
  ) { }
  ngOnInit(): void {
    // setTimeout(()=> {
    //   this.errorObserver.send({ id: this.id , title: 'titolo', message: 'ciao ciao' });
    // }, Math.floor(Math.random() * (1000 - 500 + 1) ) + 500);

    this.error$ = this.errorObserver.on().subscribe((error: ErrorMessageInterface) => {
      if (!!error && !!error?.name ? error.name === this.name : true) {
        if (!!error?.clear) {
          this.error = null;
        } else {
          if (!this.error || (this.error?.message !== error?.message && this.error?.name !== error?.name)) {
            this.error = error;
          }
          if (this.type == 'modal') {
            this.modalService.open(this.modal, { ariaLabelledBy: 'title', ariaDescribedBy: 'description' }).result.then(
              () => {
                this.error = null;
              },
              () => {
                this.error = null;
              },
            );
          }
        }
      }
      if (!error) {
        this.error = null;
      }
    });
  }
  ngOnDestroy(): void {
    if (!this.error$?.closed) {
      this.error$?.unsubscribe();
    }
  }

  clear() {
    this.error = null;
  }
}
