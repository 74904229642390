
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { TIPO_NOTIFICHE, api } from "../constants";
import { take } from "rxjs";
import { AddressDTO, AnagraficaDTO, UtenteDTO } from "../dtos";

@Injectable({
    providedIn: 'root'
})
export class RichiedenteService {

    private url_endpoint;
    constructor(private http: HttpClient, @Inject('env') env) {
        this.url_endpoint = env.environment.url_endpoint;
    }

    acceptPrivacy(): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                this.http.put(this.url_endpoint + api.richiedente_accetta_privacy, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: () => {
                            resolve(true);
                            // if (!!resp?.body) {
                            //     resolve(true);
                            // }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
    * Recupera il richiedente.
    *
    * @return {Promise<UtenteDTO>} Una promise che si risolve nell'oggetto Utente.
    */
    getUtente(): Promise<UtenteDTO> {
        return new Promise<UtenteDTO>(
            (resolve, reject) => {
                this.http.get(this.url_endpoint + api.richiedente_ottieni, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (!!resp?.body) {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }

    /**
     * Invio dei dati di anagrafica dell'utente
     *
     * @param { AnagraficaDTO } registry
     * @param { AddressDTO } address
     * @param { boolean } fuoriRegione
     * @returns { Promise<UtenteDTO> }
     */
    postGenerality(registry: AnagraficaDTO, address: AddressDTO, fuoriRegione: boolean): Promise<UtenteDTO> {
        return new Promise<UtenteDTO>(
            (resolve, reject) => {
                const body = {
                    anagraficaUser: registry,
                    fuoriRegione: fuoriRegione
                };
                if (address) {
                    body['indirizzoUser'] = address;
                }
                this.http.post(this.url_endpoint + api.richiedente_aggiungi_generalita, body, { observe: 'body' })
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (!!resp?.body) {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }


    // TODO ELIMINARE sceltaNotifica
    /**
      * Il richiedente sceglie se vuole essere notificato tramite AppIo o SMS.
      *
      * @param { TIPO_NOTIFICHE } notificationType - Il tipo di notifica.
      * @return { Promise<UtenteDTO> } Una promise che si risolve nell'oggetto Utente.
      */
    sceltaNotifica(notificationType: TIPO_NOTIFICHE): Promise<UtenteDTO> {
        return new Promise<UtenteDTO>(
            (resolve, reject) => {
                const body = {
                    notificationType: notificationType
                }
                this.http.put(this.url_endpoint + api.richiedente_scelta_notifica, body)
                    .pipe(take(1))
                    .subscribe({
                        next: (resp: any) => {
                            if (!!resp?.body) {
                                resolve(resp?.body);
                            } else {
                                reject({
                                    status: 0,
                                    error: resp?.message
                                })
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            reject(error);
                        }
                    });
            });
    }
}
