 export enum PROCESSING_TYPE {
  INVESTIGATION = 'INVESTIGATION',
  INTEGRATION = 'INTEGRATION',
  ACCEPTED = 'ACCEPTED',
  SUSPENSION = 'SUSPENSION',
  REVOCATION = 'REVOCATION'
}

// TODO: aggiungere/aggiornare i tipi per ogni lingua
export enum PROCESSING_TYPE_TRANSLATE {
  SUSPENSION_EN = 'SUSPENSION',
  SUSPENSION_IT = 'SOSPESA',
  ACCEPTED_EN = 'ACCEPTED',
  ACCEPTED_IT = 'VALIDATA',
  INTEGRATION_EN = 'INTEGRATION',
  INTEGRATION_IT = 'INTEGRAZIONE',
}
