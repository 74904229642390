import { DatePipe } from '@angular/common';
import { DelegatoDTO } from "../dtos";
import { AddressInterface, AnagraficaInterface, DelegatoInterface } from "../interfaces";
import { AddressModel } from "./address.model";
import { AnagraficaModel } from "./anagrafica.model";

export class DelegatoModel implements DelegatoInterface {
  private anagrafica: AnagraficaInterface;
  private codiceFiscaleDelegante: string;
  private cognomeDelegante: string;
  private dataAggiornamento: Date;
  private dataCreazione: Date;
  private dataNascitaDelegante: Date;
  private id: number;
  private indirizzo: AddressInterface;
  private luogoNascitaDelegante: string;
  private nomeDelegante: string;
  private numeroCellulareDelegante: string;
  private provinciaNascitaDelegante: string;
  private rapportoBeneficiarioDelegante: string;
  constructor(dto: DelegatoDTO) {
    this.anagrafica = !!dto?.anagrafica ? new AnagraficaModel(dto.anagrafica) : undefined;
    this.codiceFiscaleDelegante = dto?.codiceFiscaleDelegante;
    this.cognomeDelegante = dto?.cognomeDelegante;
    this.dataAggiornamento = !!dto?.dataAggiornamento ? new Date(dto.dataAggiornamento) : undefined;
    this.dataCreazione = !!dto?.dataCreazione ? new Date(dto.dataCreazione) : undefined;
    this.dataNascitaDelegante = dto?.dataNascitaDelegante ? new Date(dto.dataNascitaDelegante) : undefined;
    this.id = dto?.id;
    this.indirizzo = !!dto?.indirizzo ? new AddressModel(dto.indirizzo) : undefined;
    this.luogoNascitaDelegante = dto?.luogoNascitaDelegante;
    this.nomeDelegante = dto?.nomeDelegante;
    this.numeroCellulareDelegante = dto?.numeroCellulareDelegante;
    this.provinciaNascitaDelegante = dto?.provinciaNascitaDelegante;
    this.rapportoBeneficiarioDelegante = dto?.rapportoBeneficiarioDelegante;
  }
  getNominativoDelegante(): string {
    return this.nomeDelegante + ' ' + this.cognomeDelegante;
  }

  getAnagrafica(): AnagraficaInterface {
    return this.anagrafica;
  }
  getCodiceFiscaleDelegante(): string {
    return this.codiceFiscaleDelegante;
  }
  getCognomeDelegante(): string {
    return this.cognomeDelegante;
  }

  getDataAggiornamento(): Date {
    return this.dataAggiornamento;
  }
  getDataCreazione(): Date {
    return this.dataCreazione;
  }
  getDataNascitaDelegante(): Date {
    return this.dataNascitaDelegante;
  }
  getId(): number {
    return this.id;
  }
  getIndirizzo(): AddressInterface {
    return this.indirizzo;
  }
  getLuogoNascitaDelegante(): string {
    return this.luogoNascitaDelegante;
  }
  getNomeDelegante(): string {
    return this.nomeDelegante;
  }
  getNumeroCellulareDelegante(): string {
    return this.numeroCellulareDelegante;
  }
  getProvinciaNascitaDelegante(): string {
    return this.provinciaNascitaDelegante;
  }
  getRapportoBeneficiarioDelegante(): string {
    return this.rapportoBeneficiarioDelegante;
  }

  toDTO(): DelegatoDTO {
    const datepipe: DatePipe = new DatePipe('en-US');
    return {
      anagrafica: this.anagrafica.toDTO(),
      codiceFiscaleDelegante: this.codiceFiscaleDelegante,
      cognomeDelegante: this.cognomeDelegante,
      dataAggiornamento: this.dataAggiornamento?.toJSON(), // datepipe.transform(this.dataAggiornamento, 'yyyy-MM-dd'),
      dataCreazione: this.dataCreazione?.toJSON(), // datepipe.transform(this.dataCreazione, 'yyyy-MM-dd'),
      dataNascitaDelegante: datepipe.transform(this.dataNascitaDelegante, 'yyyy-MM-dd'),
      id: this.id,
      indirizzo: this.indirizzo.toDTO(),
      luogoNascitaDelegante: this.luogoNascitaDelegante,
      nomeDelegante: this.nomeDelegante,
      numeroCellulareDelegante: this.numeroCellulareDelegante,
      provinciaNascitaDelegante: this.provinciaNascitaDelegante,
      rapportoBeneficiarioDelegante: this.rapportoBeneficiarioDelegante
    }
  }
}
