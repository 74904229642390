import { CategoriaPercentualeDTO } from "../dtos";
import { CategoriaPercentualeInterface, PercentualeBigliettoInterface } from "../interfaces";
import { PercentualeBigliettoModel } from "./percentuale-tipologia-biglietto.model";

export class CategoriaPercentualeModel implements CategoriaPercentualeInterface{
  protected idCategoriaInvalidita : number;
  protected listaGradi: Array<number>;
  protected listaRequisiti: Array<number>;
  protected percentualiTipologiaBiglietto: Array<PercentualeBigliettoInterface>

  constructor(dto?: CategoriaPercentualeDTO){
    if(dto){
      this.idCategoriaInvalidita = dto?.id;
      this.listaGradi = this.getOnlyIdGradi(dto?.listaGradi);
      this.listaRequisiti = this.getOnlyIdRequisiti(dto?.listaRequisiti);
      this.percentualiTipologiaBiglietto = dto?.percentualiTipologiaBiglietto?.map((dto) => new PercentualeBigliettoModel(dto));
    }
  }

  getIDCategoriaInvalidita(): number {
    return this.idCategoriaInvalidita;
  }
  getListaGradi(): number[] {
    return this.listaGradi;
  }
  getListaRequisiti(): number[] {
    return this.listaRequisiti;
  }
  getPercentualiTipologiaBiglietto(): PercentualeBigliettoInterface[] {
    return this.percentualiTipologiaBiglietto;
  }
  setIDCategoriaInvalidita(id: number) {
    this.idCategoriaInvalidita = id;
  }
  setListaGradi(gradi: number[]) {
    this.listaGradi = gradi;
  }
  setListaRequisiti(requisiti: number[]) {
    this.listaRequisiti = requisiti;
  }
  setPercentualiTipologiaBiglietto(percentuali: PercentualeBigliettoInterface[]) {
    this.percentualiTipologiaBiglietto = percentuali;
  }

  getOnlyIdGradi(listaGradi): number[] {
    const list = [];
    for(const lista of listaGradi){
      list.push(lista?.id);
    }
    return list;
  }

  getOnlyIdRequisiti(listaRequisiti): number[] {
    const list = [];
    for(const lista of listaRequisiti){
      list.push(lista?.id);
    }
    return list;
  }

}
