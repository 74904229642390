import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { CurrentOperatorObserver } from '../observers';
import { map } from 'rxjs';

export const checkUserGuard: CanActivateFn = () => {
  const currentOperatorObserver: CurrentOperatorObserver = inject(CurrentOperatorObserver);
  const router: Router = inject(Router);
  return currentOperatorObserver.on().pipe(
    map((operator) => operator?.isManager()),
    map((isManager) => {
      if(isManager != null || isManager == true) {
        return true;
      }
      return router.createUrlTree(['home']);
    })
  );
};
