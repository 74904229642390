export function downLoadFile(data: any, filename: string, extension?: string): boolean {
  const blob = !!extension ? new Blob([data], { type: extension}) : new Blob([data]);
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.download = filename;
  anchor.href = url;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  return true;
}


// export function downLoadFile(data: any, filename: string, extension?: string): boolean {
//   // Decodifica la stringa Base64 in un array di byte
//   var byteCharacters = atob(data);
//   var byteNumbers = new Array(byteCharacters.length);
//   for (var i = 0; i < byteCharacters.length; i++) {
//       byteNumbers[i] = byteCharacters.charCodeAt(i);
//   }
//   var byteArray = new Uint8Array(byteNumbers);

//   // Crea un oggetto Blob
//   var blob = new Blob([byteArray], { type: 'application/octet-stream' });

//   // Crea un URL per il Blob
//   var url = URL.createObjectURL(blob);

//   // Crea un elemento <a> nell'HTML
//   var a = document.createElement('a');
//   a.style.display = 'none';
//   a.href = url;
//   a.download = filename;

//   // Aggiungi l'elemento <a> al documento e simula un clic
//   document.body.appendChild(a);
//   a.click();

//   // Rimuovi l'elemento <a> dal documento
//   document.body.removeChild(a);

//   // Libera la memoria
//   URL.revokeObjectURL(url);
//   return true;
// }
