import { TagsDTO } from "../dtos";
import { TagsInterface } from "../interfaces";


export class TagsModel implements TagsInterface {
    private id: number;
    private label: string;
    private dataAttivazione: Date;
    private attivo: boolean;
    private dataDisattivazione: Date;
    private dataCreazione: Date;
    private dataAggiornamento: Date;

    constructor(dto: TagsDTO) {
        this.id = dto.id;
        this.label = dto.label;
        this.attivo = dto.attivo;
        this.dataAttivazione = dto.dataAttivazione ? new Date(dto.dataAttivazione) : undefined;
        this.dataDisattivazione = dto.dataDisattivazione ? new Date(dto.dataDisattivazione) : undefined;
        this.dataCreazione = dto.dataCreazione ? new Date(dto.dataCreazione) : undefined;
        this.dataAggiornamento = dto.dataAggiornamento ? new Date(dto.dataAggiornamento) : undefined;
    }
    getId(): number {
        return this.id;
    }

    getLabel(): string {
        return this.label;
    }
    getAttivo(): boolean {
        return this.attivo
    }
    getDataAttivazione(): Date {
        return this.dataAttivazione
    }
    getDataDisattivazione(): Date {
        return this.dataDisattivazione
    }
    getDataAggiornamento(): Date {
        return this.dataAggiornamento
    }
    getDataCreazione(): Date {
        return this.dataCreazione
    }
}