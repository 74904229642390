import { Injectable } from "@angular/core";
import {NotificaInterface, NotificaModel, NotificaService } from "core-model";
import { ErrorFacade } from "./error.facade";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class NotificaFacade {
  constructor(
    private errorFacade: ErrorFacade,
    private spinner: NgxSpinnerService,
    private notificaService: NotificaService,
) { }

  /**
   * Ritorna le notifiche
   * @returns {Promise<Array<NotificaInterface>>}
   */
  async getNotifiche(): Promise<Array<NotificaInterface>> {
    try {
      this.spinner.show();
      const dtos = await this.notificaService.lista();
      return dtos.map((dto) => new NotificaModel(dto));
    } catch (error) {
      console.error(error);
    }finally{
      this.spinner.hide();
    }
    return null;
  }

  async visualizza(ids: Array<number>): Promise<void> {
    try {
      this.spinner.show();
      await this.notificaService.visualizza(ids);
    } catch (error) {
      this.errorFacade.handle(error);
    }finally{
      this.spinner.hide();
    }
    return null;

  }

}
