import { EmailDTO } from "../dtos";
import { EmailInterface, UtenteInterface } from "../interfaces";
import { UtenteModel } from "./utente.model";

export class EmailModel implements EmailInterface {
  private attivo: boolean;
  private attivoPerNotifica: boolean;
  private codice: string;
  private dataAttivazione: Date;
  private dataDisattivazione: Date;
  private dataCreazione: Date;
  private dataAggiornamento: Date;
  private dataScadenzaCodice: Date;
  private email: string;
  private id: number;
  private utente: UtenteInterface;
  private verificato: boolean;
  constructor(dto: EmailDTO) {
    this.attivo = dto?.attivo;
    this.attivoPerNotifica = dto?.attivoPerNotifica;
    this.codice = dto?.codice;
    this.dataAttivazione = dto?.dataAttivazione ? new Date(dto?.dataAttivazione) : undefined;
    this.dataDisattivazione = dto?.dataDisattivazione ? new Date(dto?.dataDisattivazione) : undefined;
    this.dataCreazione = dto?.dataCreazione ? new Date(dto?.dataCreazione) : undefined;
    this.dataAggiornamento = dto?.dataAggiornamento ? new Date(dto?.dataAggiornamento) : undefined;
    this.dataScadenzaCodice = dto?.dataScadenzaCodice ? new Date(dto?.dataScadenzaCodice) : undefined;
    this.email = dto?.email;
    this.id = dto?.id;
    this.utente = dto?.utente ? new UtenteModel(dto?.utente) : undefined;
    this.verificato = dto?.verificato;
  }

  getId(): number {
    return this.id;
  }

  getEmail(): string {
    return this.email;
  }

  getCode(): string {
    return this.codice;
  }

  getExpiryCodeDate(): Date {
    //  return new Date(2024,0,21,15,42,0,0);
    return this.dataScadenzaCodice;
  }

  isVerificato(): boolean {
    return !!this.verificato;
  }

  isAttivo(): boolean {
    return this.attivo;
  }

  isAttivoPerNotifica(): boolean {
    return !!this.attivoPerNotifica;
  }

  getDataAttivazione(): Date {
    return this.dataAttivazione;
  }

  getDataDisattivazione(): Date {
    return this.dataDisattivazione;
  }

  getDataCreazione(): Date {
    return this.dataCreazione;
  }

  getDataAggiornamento(): Date {
    return this.dataAggiornamento;
  }

  getUtente(): UtenteInterface {
    return this.utente;
  }

  toDTO(): EmailDTO {
    return {
      attivoPerNotifica: this.attivoPerNotifica,
      codice: this.codice,
      dataAttivazione: this.dataAttivazione?.toJSON(),
      dataDisattivazione: this.dataDisattivazione?.toJSON(),
      dataCreazione: this.dataCreazione?.toJSON(),
      dataAggiornamento: this.dataAggiornamento?.toJSON(),
      dataScadenzaCodice: this.dataScadenzaCodice?.toJSON(),
      email: this.email,
      id: this.id,
      utente: this.utente?.toDTO(),
      verificato: this.verificato
    };
  }
}
