import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErroreGenericoRoutingModule } from './errore-generico-routing.module';
import { ErroreGenericoComponent } from './errore-generico.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    ErroreGenericoComponent
  ],
  imports: [
    CommonModule,
    ErroreGenericoRoutingModule,
    TranslateModule
  ]
})
export class ErroreGenericoModule { }
