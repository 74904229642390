import { DatiBudgetProvinciaDTO } from "../dtos";
import { DatiBudgetProvinceInterface } from "../interfaces";

export class DatiBudgetProvinceModel implements DatiBudgetProvinceInterface {
    protected dati: Array<DatiBudgetProvinciaDTO>;

    constructor(dto: DatiBudgetProvinciaDTO[]) {
        this.dati = dto;
    }

    getTotale(): any {
        return this.getDatiProvincia('totale');
    }

    getDatiProvincia(provincia: string): any {
        const data = this.dati.find((dato) => dato?.series.at(0).name.toUpperCase() == provincia.toUpperCase())?.series.at(0).data;
        const copy  = [
            {
                value: 0,
                name: "Budget configurato",
            },
            {
                value: 0,
                name: "Budget rendicontato",
            }
        ];
        if (data) {
            const budgetConfigurato = data.find((d) => d.name == 'Budget configurato');
            const budgetRendicontato = data.find((d) => d.name == 'Budget rendicontato');
            if (!!budgetConfigurato) {
                copy.at(0).value = budgetConfigurato.value;
                copy.at(0)['configurato'] = budgetConfigurato.value;
                if (!!budgetRendicontato) {
                    copy.at(1).value = budgetRendicontato.value;
                    copy.at(1)['configurato'] = budgetConfigurato.value;
                    copy.at(0).value = budgetConfigurato.value - budgetRendicontato.value;
                }
            }
        }
        return copy.sort((a, b) => a.value - b.value);
    }

    hasDatiProvincia(provincia: string): boolean {
        return !!this.dati.find((dato) => dato?.series.at(0).name.toUpperCase() == provincia.toUpperCase());
    }

    hasTotale(): any {
        return !!this.getDatiProvincia('totale');
    }
}