<ng-container *ngIf="news?.getTotalElements() > 0">
    <ngb-carousel #carousel class="sr-only sr-only-focusable" [ariaLabel]="'Notizie'">
        <ng-container *ngFor="let newsList of carouselList; let i = index">
            <ng-template ngbSlide [id]="i.toString()" class="sr-only sr-only-focusable" aria-label="Card">
                <div class="w-100 row ps-5 pe-4 my-4" >
                    <ng-container *ngFor="let news of newsList">
                        <div class="col-12 col-md-6 col-lg-4">
                            <app-card-news [news]="news" (delete)="loadList()"></app-card-news>
                        </div>
                    </ng-container>
                </div>
            </ng-template>
        </ng-container>
    </ngb-carousel>
</ng-container>
