import { STATO_CAMBIO_RICHIEDENTE, TIPOLOGIA_RICHIEDENTE } from "../constants";
import { DelegatoModel, FamilyModel, UtenteModel } from ".";
import { ApplicantDTO } from "../dtos";
import { ApplicantInterface, BeneficiaryInterface, DelegatoInterface, FamilyInterface, UtenteInterface } from "../interfaces";
import { BeneficiaryModel } from "./beneficiary.model";

export class ApplicantModel implements ApplicantInterface {
    protected attivo: boolean;
    protected beneficiario: BeneficiaryInterface;
    protected codice: string;
    protected codiceFiscaleNuovoRichiedente: string;
    protected dataAttivazione: string;
    protected dataDisattivazione: string;
    protected dataCreazione: string;
    protected dataAggiornamento: string;
    protected delegato: DelegatoInterface;
    protected familiare: FamilyInterface;
    protected id: number;
    protected richiedente: UtenteInterface;
    protected statoCambioRichiesta: STATO_CAMBIO_RICHIEDENTE;
    protected tipologiaRichiedente: TIPOLOGIA_RICHIEDENTE;

    constructor(dto: ApplicantDTO) {
        this.attivo = dto?.attivo;
        this.beneficiario = !!dto?.beneficiario ? new BeneficiaryModel(dto?.beneficiario) : undefined;
        this.codice = dto?.codice;
        this.codiceFiscaleNuovoRichiedente = dto?.codiceFiscaleNuovoRichiedente;
        this.dataAttivazione = dto?.dataAttivazione;
        this.dataDisattivazione = dto?.dataDisattivazione;
        this.dataCreazione = dto?.dataCreazione;
        this.dataAggiornamento = dto?.dataAggiornamento;
        this.delegato = !!dto?.delegato ? new DelegatoModel(dto?.delegato) : undefined;
        this.familiare = !!dto?.familiare ? new FamilyModel(dto?.familiare) : undefined;
        this.id = dto?.id;
        this.richiedente = dto?.richiedente ? new UtenteModel(dto?.richiedente) : undefined;
        this.statoCambioRichiesta = dto?.statoCambioRichiesta;
        this.tipologiaRichiedente = dto?.tipologiaRichiedente;
    }

    getBeneficiario(): BeneficiaryInterface {
        return this.beneficiario;
    }

    getCodice(): string {
        return this.codice;
    }

    getCodiceFiscaleNuovoRichiedente(): string {
        return this.codiceFiscaleNuovoRichiedente;
    }

    getDataAttivazione(): Date {
        return new Date(this.dataAttivazione);
    }

    getDelegato(): DelegatoInterface {
        return this.delegato;
    }

    getRichiedente(): UtenteInterface {
        return this.richiedente;
    }

    getTipologiaRichiedente(): TIPOLOGIA_RICHIEDENTE {
        return this.tipologiaRichiedente;
    }

    hasBeneficiario(): boolean {
        return !!this.beneficiario;
    }

    hasDelegato(): boolean {
        return !!this.delegato;
    }

    isAttivo(): boolean {
        return this.attivo;
    }

    isCambioRichiedente(): boolean {
        return this.statoCambioRichiesta == STATO_CAMBIO_RICHIEDENTE.ATTIVO;
    }

    isDeleganteBeneficiario(): boolean {
        if (this.hasBeneficiario() && this.hasDelegato()) {
            return this.delegato?.getCodiceFiscaleDelegante() == this.beneficiario?.getAnagrafica()?.getCodiceFiscale();
        }
        return undefined;
    }

    toDTO(): ApplicantDTO {
        return {
            attivo: this.attivo,
            beneficiario: this.beneficiario?.toDTO(),
            codice: this.codice,
            codiceFiscaleNuovoRichiedente: this.codiceFiscaleNuovoRichiedente,
            dataAttivazione: this.dataAttivazione,
            dataDisattivazione: this.dataDisattivazione,
            dataCreazione: this.dataCreazione,
            dataAggiornamento: this.dataAggiornamento,
            delegato: this.delegato?.toDTO(),
            id: this.id,
            richiedente: this.richiedente?.toDTO(),
            statoCambioRichiesta: this.statoCambioRichiesta,
            tipologiaRichiedente: this.tipologiaRichiedente,
            familiare: this.familiare?.toDTO()
        }
    }
}