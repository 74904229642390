import { PercentualeDTO, PrivilegioDTO } from "../dtos";
import { PercentualeInterface, PrivilegioInterface, RuoloInterface, TipologiaBigliettoInterface } from "../interfaces";
import { RuoloModel } from "./ruolo.model";
import { TipologiaBigliettoModel } from "./tipologia-biglietto.model";

export class PercentualeModel implements PercentualeInterface {

  private id?: number;
  private tipologiaBiglietto?: TipologiaBigliettoInterface;
  private percentuale?: number;
  constructor(dto: PercentualeDTO) {
    this.id = dto?.id;
    this.tipologiaBiglietto = new TipologiaBigliettoModel(dto?.tipologiaBiglietto);
    this.percentuale = dto?.percentuale;
  }
  getId(): number {
    return this.id;
  }
  getTipologiaBiglietto(): TipologiaBigliettoInterface {
    return this.tipologiaBiglietto;
  }
  getPercentuale(): number {
    return this.percentuale;
  }

}
