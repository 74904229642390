import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoFreeInstanceComponent } from './info-free-instance.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    InfoFreeInstanceComponent
  ],
  exports: [
    InfoFreeInstanceComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class InfoFreeInstanceModule { }
