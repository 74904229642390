<section role="region" class="h-content">
  <div class="container row">
    <div class="col-10">
      <ng-container *ngIf="!!traduzioneVetrina?.html">
        <quill-view-html [content]="traduzioneVetrina?.html"></quill-view-html>
      </ng-container>

      <a class="ms-3" href="assets/documents/SGP Manuale utente v.1.1.pdf" download="SGP Manuale utente.pdf" translate>GENERAL.SCARICA_MANUALE</a>
    </div>
    <div class="col-2"></div>
  </div>
</section>
