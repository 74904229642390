import { PaginationDTO } from "../dtos";
import { FreeInstancePaginationInterface, FreeInstanceRowInterface } from "../interfaces";
import { FreeInstancePaginationModel } from "./free-instance-pagination.model";
import { FreeInstanceModel } from "./free-instance.model";

export class FreeInstanceTablePaginationModel extends FreeInstancePaginationModel implements FreeInstancePaginationInterface {
    constructor(dto?: PaginationDTO) {
        super(dto);
    }

    getRows(): Array<FreeInstanceRowInterface> {
        return this.content.map((freeInstance: FreeInstanceModel) => {
            const beneficiario = freeInstance.getRichiedenteGratuita()?.getBeneficiario();
            const accompagnatori = freeInstance.getAccompagnatoriAbilitati();
            return {
                acquisityDate: freeInstance.getDataAcquisizione(),
                credentialCode: freeInstance.getCodiceCredenziale(),
                fiscalCodeBeneficiary: freeInstance.getCodiceFiscaleBeneficiario(),
                fiscalCodeCompanion: accompagnatori?.length > 0 ? accompagnatori.map( (accompagnatore) => accompagnatore.getCodiceFiscale()).join(', ') : '',
                id: freeInstance.getId(),
                lastNameBeneficiary: !!beneficiario ? beneficiario.getAnagrafica().getCognome() + ' ' + beneficiario.getAnagrafica().getNome() : '',
                lastNameCompanion: accompagnatori?.length > 0 ? accompagnatori.map( (accompagnatore) => accompagnatore.getCognome() + ' ' + accompagnatore.getNome()).join(', ') : '',
                municipalityResidence: beneficiario?.getIndirizzo()?.getComuneResidenza(),
                processingStatusType: freeInstance.getStatoLavorazioneAttivaTradotto()
            }
        });
    }
}
