import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShowcasePageInterface } from '../../interfaces';
import { TraduzioniVetrinaDTO } from '../../dtos';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-come-accedere',
  templateUrl: './come-accedere.component.html',
  styleUrls: ['./come-accedere.component.scss']
})
export class ComeAccedereComponent implements OnInit {

  public showcasePage: ShowcasePageInterface;
  public traduzioneVetrina: TraduzioniVetrinaDTO;

  constructor(private activatedRoute: ActivatedRoute,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: any) => {
      this.traduzioneVetrina = this.getCurrentPageByLocale(event.lang)
    });

    this.activatedRoute.data.subscribe((data) => {
      this.showcasePage = data['data'] as ShowcasePageInterface;
      this.traduzioneVetrina = this.getCurrentPageByLocale(this.translate.currentLang);
    });
  }

  getCurrentPageByLocale(locale: string): TraduzioniVetrinaDTO {
    const translate = this.showcasePage?.getPaginaVetrina().find((v) => v.traduzioni.lingua == locale)
    return translate
  }
}
