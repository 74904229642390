<div class="it-header-navbar-wrapper">
    <div class="container">
      <div class="row">
        <div class="col">
          <!--start nav-->
          <nav class="navbar navbar-expand-lg" [ariaLabel]="'ACCESSIBILITA.NAVIGAZIONE_PRINCIPALE' | translate" role="navigation">
            <button class="custom-navbar-toggler" type="button" aria-controls="navC1" tabindex="0"
              [attr.aria-expanded]="toggle"
              [attr.aria-label]="'ACCESSIBILITA.MOSTRA_NASCONDI_NAVIGAZIONE' | translate" data-bs-toggle="navbarcollapsible" data-bs-target="#navC1" (click)="toggleMenu()">
              <svg class="icon icon-primary" aria-label="[title]">
                <title>{{ 'ACCESSIBILITA.ESPANDI' | translate }}</title>
                <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-burger" xlink:href="assets/bootstrap-italia/dist/svg/sprites.svg#it-burger"></use>
              </svg>
            </button>
            <div class="navbar-collapsable" id="navC1" [ngClass]="{'d-none': toggle == false, 'expanded': toggle == true}" [ngStyle]="{'z-index': toggle == false ? 'auto': 1000, 'display': toggle == false ? 'none': 'block'}">
              <div class="overlay" [ngStyle]="{'display': toggle == false ? 'none': 'block'}"></div>
              <div class="close-div">
                <button class="btn close-menu" type="button" (click)="toggleMenu()">
                  <span class="visually-hidden" translate>ACCESSIBILITA.NASCONDI_NAVIGAZIONE</span>
                  <svg class="icon" aria-label="[title]">
                    <title>{{ 'ACCESSIBILITA.CHIUDI' | translate }}</title>
                    <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-close-big" xlink:href="assets/bootstrap-italia/dist/svg/sprites.svg#it-close-big"></use>
                  </svg>
                </button>
              </div>
              <div class="menu-wrapper">
                <ul class="navbar-nav">
                  <!-- <ng-container *ngFor="let vetrina of showCasePage">
                    <li class="nav-item" tabindex="0">
                      <a class="nav-link" [routerLink]="[vetrina.getPath()]" routerLinkActive="active" ariaCurrentWhenActive="page"><span>{{ 'NAVIGATION.' + vetrina.getNavbarLabel() | translate }}</span></a>
                    </li>
                  </ng-container> -->
                  <li class="nav-item" tabindex="0">
                    <a class="nav-link" [routerLink]="['home']" routerLinkActive="active" ariaCurrentWhenActive="page"><span>{{ 'NAVIGATION.HOME' | translate }}</span></a>
                  </li>
                  <li class="nav-item" tabindex="0">
                    <a class="nav-link" [routerLink]="['come-accedere']" routerLinkActive="active" ariaCurrentWhenActive="page"><span>{{ 'NAVIGATION.HOW_TO_ACCESS' | translate }}</span></a>
                  </li>
                  <li class="nav-item" tabindex="0">
                    <a class="nav-link" [routerLink]="['news']" routerLinkActive="active" ariaCurrentWhenActive="page"><span>{{ 'NAVIGATION.NEWS' | translate }}</span></a>
                  </li>
                  <li class="nav-item" tabindex="0">
                    <a class="nav-link" [routerLink]="['contatti']" routerLinkActive="active" ariaCurrentWhenActive="page"><span>{{ 'NAVIGATION.CONTACTS' | translate }}</span></a>
                  </li>
                  <li class="nav-item" tabindex="0" *ngIf="!!currentUser">
                    <a class="nav-link" [routerLink]="['area-personale']" routerLinkActive="active" ariaCurrentWhenActive="page"><span>{{ 'NAVIGATION.PERSONAL_AREA' | translate }}</span></a>
                  </li>
                  <li class="nav-item" tabindex="0" *ngIf="!currentUser && environment?.production == false">
                    <a class="nav-link" [routerLink]="['fake-login']" routerLinkActive="active" ariaCurrentWhenActive="page"><span>Login</span></a>
                  </li>
                  <li class="nav-item" tabindex="0" *ngIf="!currentUser && environment?.production == true">
                    <a class="nav-link" [href]="'https://sgc.myzerodddevdomain.it/accessoServizi'" routerLinkActive="active" ariaCurrentWhenActive="page"><span>Login</span></a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
