import { ApplicantModel, DelegatoModel, DocumentModel, LavorazioneGratuitaModel, PercentualeModel } from ".";
import { FILE_TYPE, PROCESSING_TYPE, STATO_BOZZA, TIPOLOGIA_RICHIEDENTE } from "../constants";
import { FreeInstanceDTO } from "../dtos";
import { ApplicantInterface, CompanionInterface, DelegatoInterface, DocumentInterface, FreeInstanceInterface, LavorazioneGratuitaInterface, PercentualeInterface, RiscontroInterface } from "../interfaces";
import { CompanionModel } from "./companion.model";
import { RiscontroModel } from "./riscontro.model";

export class FreeInstanceModel implements FreeInstanceInterface {
  protected accompagnatori: Array<CompanionInterface>;
  protected acquisizioneFirmaDelega: boolean;
  protected acquisizioneFirmaRinuncia: boolean;
  protected acquisizioneGratuita: boolean;
  protected biglietti: Array<any>;
  protected controlloDatiIstruttoria: boolean;
  protected riscontriDatiAnagrafici: Array<RiscontroInterface>;
  protected codiceCredenziale: string;
  protected dataAggiornamento: Date;
  protected dataAcquisizione: string;
  protected dataAttivazione: Date;
  protected dataCreazione: Date;
  protected dataDisattivazione: Date;
  protected delegato: DelegatoInterface;
  protected id: number;
  protected lavorazioniGratuita: Array<LavorazioneGratuitaInterface>;
  protected file: Array<DocumentInterface>;
  protected noteRinuncia: string;
  protected stato: string;
  protected percentuali: Array<PercentualeInterface>;
  protected protocollo: string;
  protected richiedenteGratuita: ApplicantInterface;
  protected rinunciato: boolean;
  protected tipologia: string;

  constructor(dto?: FreeInstanceDTO) {
    this.accompagnatori = dto?.accompagnatori?.map((dto) => new CompanionModel(dto));
    this.acquisizioneGratuita = dto?.acquisizioneGratuita;
    this.acquisizioneFirmaDelega = dto?.acquisizioneFirmaDelega;
    this.acquisizioneFirmaRinuncia = dto?.acquisizioneFirmaRinuncia;

    this.biglietti = dto?.biglietti;
    this.codiceCredenziale = dto?.codiceCredenziale;
    this.controlloDatiIstruttoria = dto?.controlloDatiIstruttoria;
    this.riscontriDatiAnagrafici = !!dto?.riscontriDatiAnagrafici ? dto?.riscontriDatiAnagrafici.map((dto) => new RiscontroModel(dto)) : undefined;
    this.dataAttivazione = dto?.dataAttivazione ? new Date(dto.dataAttivazione) : undefined;
    this.dataAcquisizione = !!dto?.dataAcquisizione ? dto?.dataAcquisizione : dto?.dataAquisizione;
    this.dataDisattivazione = dto?.dataDisattivazione ? new Date(dto.dataDisattivazione) : undefined;
    this.dataCreazione = dto?.dataCreazione ? new Date(dto.dataCreazione) : undefined;
    this.dataAggiornamento = dto?.dataAggiornamento ? new Date(dto.dataAggiornamento) : undefined;
    this.delegato = new DelegatoModel(dto?.delegato);
    this.id = dto?.id;
    this.lavorazioniGratuita = !!dto?.lavorazioniGratuita ? dto?.lavorazioniGratuita.map((dto) => new LavorazioneGratuitaModel(dto)) : undefined;
    this.file = !!dto?.file ? dto?.file.map((dto) => new DocumentModel(dto)) : undefined;
    this.noteRinuncia = dto?.noteRinuncia;
    this.percentuali = !!dto?.percentuali ? dto?.percentuali.map((dto) => new PercentualeModel(dto)) : undefined;
    this.protocollo = dto?.protocollo;
    dto?.richiedentiGratuita?.sort((a, b) => new Date(b.dataAttivazione).getTime() - new Date(a.dataAttivazione).getTime());
    const richiedenteGratuita = dto?.richiedentiGratuita?.find((richiedente) => richiedente.attivo == true);
    this.richiedenteGratuita = !!richiedenteGratuita ? new ApplicantModel(richiedenteGratuita) : undefined;
    this.rinunciato = dto?.rinunciato;
    this.stato = dto?.stato;
  }

  getAccompagnatori(): CompanionInterface[] {
    return this.accompagnatori;
  }

  getAccompagnatoriAbilitati(): CompanionInterface[] {
    return this.accompagnatori.filter((accompagnatore) => accompagnatore.isAbilitato());
  }

  getCodiceFiscaleBeneficiario(): string {
    const beneficiario = this.richiedenteGratuita?.getBeneficiario();
    return beneficiario?.getAnagrafica()?.getCodiceFiscale();
  }

  getCodiceFiscaleRichiedente(): string {
    const richiedente = this.richiedenteGratuita?.getRichiedente();
    return richiedente?.getAnagrafica()?.getCodiceFiscale();
  }

  getDataAcquisizione(): string {
    return this.dataAcquisizione;
  }

  getDatiInvalidita(): string {
    return '';
  }

  getCodiceCredenziale(): string {
    return this.codiceCredenziale;
  }

  getCodiceCambio(): string {
    return this.richiedenteGratuita?.getCodice();
  }

  getDelegato(): DelegatoInterface {
    return this.delegato;
  }

  getEstremiProvvedimentoRevoca(): string {
    if(this.isRevocata()) {
      const provvedimento = this.lavorazioniGratuita.find(lavorazione => lavorazione?.isAttivo() && lavorazione?.isUltima())?.getProvvedimento();
      return provvedimento?.getEstremi();
    }
    return null;
  }

  getFileCartaIdentitaDelegante(): DocumentInterface {
    return this.file?.find((file) => file?.getTipologiaDocumento() == FILE_TYPE.IDENTITY_CARD);
  }

  getFileDelega(): DocumentInterface {
    return this.file?.find((file) => file?.getTipologiaDocumento() == FILE_TYPE.DELEGATION);
  }

  getFileRevoca(): DocumentInterface {
    this.file?.sort((a, b) => a.getDataAttivazione().getTime() - b.getDataAttivazione().getTime());
    return this.file?.find((file) => file?.getTipologiaDocumento() == FILE_TYPE.REVOCATION);
  }

  getFileRinuncia(): DocumentInterface {
    this.file?.sort((a, b) => a.getDataAttivazione().getTime() - b.getDataAttivazione().getTime());
    return this.file?.find((file) => file?.getTipologiaDocumento() == FILE_TYPE.RENUNCIATION);
  }

  getFileRichiestaIntegrazioni(): DocumentInterface {
    this.file?.sort((a, b) => a.getDataAttivazione().getTime() - b.getDataAttivazione().getTime());
    return this.file?.find((file) => file?.getTipologiaDocumento() == FILE_TYPE.INTEGRATION);
  }

  getFileRiscontroIntegrazioni(): DocumentInterface {
    this.file?.sort((a, b) => a.getDataAttivazione().getTime() - b.getDataAttivazione().getTime());
    return this.file?.find((file) => file?.getTipologiaDocumento() == FILE_TYPE.INTEGRATION_RESPONSE);
  }

  getFileRiscontroSospensione(): DocumentInterface {
    this.file?.sort((a, b) => a.getDataAttivazione().getTime() - b.getDataAttivazione().getTime());
    return this.file?.find((file) => file?.getTipologiaDocumento() == FILE_TYPE.SUSPENSION_RESPONSE);
  }

  getFileSospensione(): DocumentInterface {
    this.file?.sort((a, b) => a.getDataAttivazione().getTime() - b.getDataAttivazione().getTime());
    return this.file?.find((file) => file?.getTipologiaDocumento() == FILE_TYPE.SUSPENSION);
  }

  getId(): number {
    return this.id;
  }

  getIdFileDelega(): number {
    const file = this.file?.find((file) => file?.getTipologiaDocumento() === FILE_TYPE.DELEGATION);
    return file?.getId();
  }

  getIdFileRevoca(): number {
    this.file?.sort((a, b) => a.getDataAttivazione().getTime() - b.getDataAttivazione().getTime());
    const file = this.file?.find((file) => file?.getTipologiaDocumento() === FILE_TYPE.REVOCATION);
    return file?.getId();
  }

  getIdFileRichiesta(): number {
    const file = this.file?.find((file) => file?.getTipologiaDocumento() === FILE_TYPE.FREEINSTANCE);
    return file?.getId();
  }

  getIdFileRichiestaIntegrazioni(): number {
    this.file?.sort((a, b) => a.getDataAttivazione().getTime() - b.getDataAttivazione().getTime());
    const file = this.file?.find((file) => file?.getTipologiaDocumento() === FILE_TYPE.INTEGRATION);
    return file?.getId();
  }

  getIdFileRiscontroIntegrazioni(): number {
    this.file?.sort((a, b) => a.getDataAttivazione().getTime() - b.getDataAttivazione().getTime());
    const file = this.file?.find((file) => file?.getTipologiaDocumento() === FILE_TYPE.INTEGRATION_RESPONSE);
    return file?.getId();
  }

  getIdFileRiscontroSospensione(): number {
    this.file?.sort((a, b) => a.getDataAttivazione().getTime() - b.getDataAttivazione().getTime());
    const file = this.file?.find((file) => file?.getTipologiaDocumento() === FILE_TYPE.SUSPENSION_RESPONSE);
    return file?.getId();
  }

  getIdFileSospensione(): number {
    this.file?.sort((a, b) => a.getDataAttivazione().getTime() - b.getDataAttivazione().getTime());
    const file = this.file?.find((file) => file?.getTipologiaDocumento() === FILE_TYPE.SUSPENSION);
    return file?.getId();
  }

  getLavorazioneAttiva(): LavorazioneGratuitaInterface {
    const statoLavorazioneAttivo = this.lavorazioniGratuita.filter(lavorazione => lavorazione?.isAttivo() && lavorazione?.isUltima());
    // statoLavorazioneAttivo.sort((a, b) => new Date(b?.getDataAggiornamento()).getTime() - new Date(a?.getDataAggiornamento()).getTime());
    return statoLavorazioneAttivo?.at(0);
  }

  getLavorazioniGratuita(): LavorazioneGratuitaInterface[] {
    return this.lavorazioniGratuita;
  }

  getLuogoResidenza(): string {
    const beneficiario = this.richiedenteGratuita?.getBeneficiario();
    return beneficiario?.getIndirizzo()?.getComuneResidenza();
  }

  getNomeBeneficiario(): string {
    const beneficiario = this.richiedenteGratuita?.getBeneficiario();
    return beneficiario?.getAnagrafica()?.getNome() + ' ' + beneficiario?.getAnagrafica()?.getCognome();
  }

  getNomeRichiedente(): string {
    const richiedente = this.richiedenteGratuita?.getRichiedente();
    return richiedente?.getAnagrafica()?.getNome() + ' ' + richiedente?.getAnagrafica()?.getCognome();
  }

  getNomeFileRevoca(): string {
    this.file?.sort((a, b) => a.getDataAttivazione().getTime() - b.getDataAttivazione().getTime());
    const file = this.file?.find((file) => file?.getTipologiaDocumento() === FILE_TYPE.REVOCATION);
    return file?.getNomeDocumento();
  }

  getNomeFileRichiesta(): string {
    const file = this.file?.find((file) => file?.getTipologiaDocumento() === FILE_TYPE.FREEINSTANCE);
    return file?.getNomeDocumento();
  }

  getNomeFileRichiestaIntegrazioni(): string {
    const file = this.file?.find((file) => file?.getTipologiaDocumento() === FILE_TYPE.INTEGRATION);
    return file?.getNomeDocumento();
  }

  getNomeFileRiscontroIntegrazioni(): string {
    this.file?.sort((a, b) => a.getDataAttivazione().getTime() - b.getDataAttivazione().getTime());
    const file = this.file?.find((file) => file?.getTipologiaDocumento() === FILE_TYPE.INTEGRATION_RESPONSE);
    return file?.getNomeDocumento();
  }

  getNomeFileRiscontroSospensione(): string {
    this.file?.sort((a, b) => a.getDataAttivazione().getTime() - b.getDataAttivazione().getTime());
    const file = this.file?.find((file) => file?.getTipologiaDocumento() === FILE_TYPE.SUSPENSION_RESPONSE);
    return file?.getNomeDocumento();
  }

  getNomeFileSospensione(): string {
    this.file?.sort((a, b) => a.getDataAttivazione().getTime() - b.getDataAttivazione().getTime());
    const file = this.file?.find((file) => file?.getTipologiaDocumento() === FILE_TYPE.SUSPENSION);
    return file?.getNomeDocumento();
  }

  getNotaRinuncia(): string {
    return this.noteRinuncia;
  }

  getPercAbbonamento(): number {
    return this.percentuali.find( (percentuale) => percentuale?.getTipologiaBiglietto()?.getTipologiaBiglietto() == 'ABBONAMENTO' )?.getPercentuale();
  }

  getPercBigliettoSingolo(): number {
    return this.percentuali.find( (percentuale) => percentuale?.getTipologiaBiglietto()?.getTipologiaBiglietto() == 'BASE' )?.getPercentuale();
  }

  getProtocollo(): string {
    return this.protocollo;
  }

  getRichiedenteGratuita(): ApplicantInterface {
    return this.richiedenteGratuita;
  }

  getRiscontriDatiAnagrafici(): Array<RiscontroInterface> {
    return this.riscontriDatiAnagrafici;
  }

  getStatoLavorazioneAttiva(): string {
    const statoLavorazioneAttivo = this.lavorazioniGratuita.filter(lavorazione => lavorazione?.isAttivo() && lavorazione?.isUltima());
    // statoLavorazioneAttivo.sort((a, b) => new Date(b?.getDataAggiornamento()).getTime() - new Date(a?.getDataAggiornamento()).getTime());
    if (statoLavorazioneAttivo.length > 0) {
      return statoLavorazioneAttivo?.at(0)?.getStatoLavorazione()?.getStato();
    } else {
      return '';
    }
  }

  getStatoLavorazioneAttivaTradotto(): string {
    const statoLavorazioneAttivo = this.lavorazioniGratuita.filter(lavorazione => lavorazione?.isAttivo() && lavorazione?.isUltima());
    // statoLavorazioneAttivo.sort((a, b) => new Date(b?.getDataAggiornamento()).getTime() - new Date(a?.getDataAggiornamento()).getTime());
    if (statoLavorazioneAttivo.length > 0) {
      return statoLavorazioneAttivo?.at(0)?.getStatoLavorazione()?.getStatoLavorazione();
    } else {
      return 'GRATUITA.SOTTOMESSA';
    }
  }

  getTipologiaRichiedente(): TIPOLOGIA_RICHIEDENTE {
    return this.richiedenteGratuita?.getTipologiaRichiedente();
  }

  hasAccompagnatore(): boolean {
    return this.getAccompagnatoriAbilitati()?.length > 0;
  }

  hasEstremiProvvedimentoRevoca(): boolean {
    if(this.isRevocata()) {
      const provvedimento = this.lavorazioniGratuita.find(lavorazione => lavorazione?.isAttivo() && lavorazione?.isUltima())?.getProvvedimento();
      return !!provvedimento?.getEstremi() && provvedimento?.getEstremi() != '';
    }
    return false;
  }

  hasFileRevoca(): boolean {
    return !!this.file?.find((f) => f.getTipologiaDocumento() == FILE_TYPE.REVOCATION);
  }

  hasFileRichiestaIntegrazioni(): boolean {
    return !!this.file?.find((f) => f.getTipologiaDocumento() == FILE_TYPE.INTEGRATION);
  }

  hasFileSospensione(): boolean {
    return !!this.file?.find((f) => f.getTipologiaDocumento() == FILE_TYPE.SUSPENSION);
  }

  hasRiscontroLavorazioneAttiva(): boolean {
    if (this.getStatoLavorazioneAttiva() == PROCESSING_TYPE.SUSPENSION || this.getStatoLavorazioneAttiva() == PROCESSING_TYPE.INTEGRATION) {
      const lavorazione = this.getLavorazioneAttiva();
      return lavorazione?.getProvvedimento()?.hasRispostaProvvedimento() == true;
    }
    return false;
  }

  isAttiva(): boolean {
    return this.acquisizioneGratuita == true && this.isSospesa() == false && this.isRinunciato() == false && this.isRevocata() == false;
  }

  isBozza(): boolean {
    if (!!this.acquisizioneGratuita) {
      return false;
    }
    return Object.getOwnPropertyNames(STATO_BOZZA).map((name) => STATO_BOZZA[name]).indexOf(this.stato) > -1;
  }

  isCheckDatiIstruttoria(): boolean {
    return this.controlloDatiIstruttoria;
  }

  isInIntegrazione(): boolean {
    return this.getStatoLavorazioneAttiva() == PROCESSING_TYPE.INTEGRATION;
  }

  isIstruttoria(): boolean {
    return this.getStatoLavorazioneAttiva() == PROCESSING_TYPE.INVESTIGATION;
  }

  isRevocata(): boolean {
    return this.getStatoLavorazioneAttiva() == PROCESSING_TYPE.REVOCATION;
  }

  isRinunciato(): boolean {
    return !!this.rinunciato && !!this.acquisizioneFirmaRinuncia;
  }

  isSospesa(): boolean {
    return this.getStatoLavorazioneAttiva() == PROCESSING_TYPE.SUSPENSION;
  }

  isValidata(): boolean {
    return this.getStatoLavorazioneAttiva() == PROCESSING_TYPE.ACCEPTED;
  }

  toDTO(): FreeInstanceDTO {
    return {
      accompagnatori: this.accompagnatori.map((accompagnatore) => accompagnatore.toDTO()),
      acquisizioneFirmaDelega: this.acquisizioneFirmaDelega,
      acquisizioneFirmaRinuncia: this.acquisizioneFirmaRinuncia,
      acquisizioneGratuita: this.acquisizioneGratuita,
      biglietti: this.biglietti,
      codiceCredenziale: this.codiceCredenziale,
      dataAggiornamento: this.dataAggiornamento?.toJSON(),//datepipe.transform(this.dataAggiornamento, 'yyyy-MM-dd'),
      dataAttivazione: this.dataAttivazione?.toJSON(), //datepipe.transform(this.dataAttivazione, 'yyyy-MM-dd'),
      dataCreazione: this.dataCreazione?.toJSON(), //datepipe.transform(this.dataCreazione, 'yyyy-MM-dd'),
      dataDisattivazione: this.dataDisattivazione?.toJSON(), //datepipe.transform(this.dataDisattivazione, 'yyyy-MM-dd'),
      id: this.id,
      file: this.file,
      noteRinuncia: this.noteRinuncia,
      protocollo: this.protocollo,
      richiedentiGratuita: [this.richiedenteGratuita?.toDTO()],
      rinunciato: this.rinunciato,
      stato: this.stato,
    }
  }
}
