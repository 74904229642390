import { Injectable } from "@angular/core";
import { CondizioneJoinDTO, SORT, SearchQueryDTO, TicketPaginationInterface, TicketService, TicketsTablePaginationModel, TipologiaBigliettoInterface, TipologiaBigliettoModel } from "core-model";
import { ErrorFacade } from "./error.facade";
import { NgxSpinnerService } from "ngx-spinner";
import * as FileSaver from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class StoricoFacade {
    constructor(private errorFacade: ErrorFacade,
        private ticketService: TicketService,
        private spinner: NgxSpinnerService) { }
    /**
     * Ottiene la lista delle tipolgie di ticket
     * @returns { TipologiaBigliettoInterface[] }
     */
    async getTipologie(): Promise<TipologiaBigliettoInterface[]> {
        try {
            this.spinner.show();
            const dtos = await this.ticketService.get();
            return dtos.map((dto) => new TipologiaBigliettoModel(dto));
        } catch (error) {
            this.errorFacade.handle(error, null);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

    /**
     * Ricerca avanzata sui titoli di viaggio
     *
     * @param { any } search
     * @param { number } page
     * @param { number } pageSize
     * @param { <{key: string, sort: SORT}> } sortBy
     * @return {Promise<TicketPaginationInterface>}
     */
    async search(search: {
        creationDate?: string,
        arrivalPoint?: string,
        departurePoint?: string,
        fiscalCodeCompanion?: string,
        id?: number,
        lastNameCompanion?: string,
        ticketType?: string,
        validityEndDate?: string,
        validityStartDate?: string,
    }, page: number, pageSize: number, sortBy: { key?: string, sort?: SORT }): Promise<TicketPaginationInterface> {
        try {
            const searchQuery: SearchQueryDTO = {
                clausola: "AND",
                distinct: false,
                numeroPagina: page,
                numeroElementiPagina: pageSize,
                gruppoFiltriDiRicerca: [
                    {
                        clausolaSearchFilter: "AND",
                        filtriDiRicerca: [
                            {
                                proprieta: "isActive",
                                operatore: "=",
                                valore: true
                            }
                        ]
                    }
                ],
                injectJoinToken: {
                    posizioneInject: [
                        "freeInstance",
                        "applicantFreeInstances"
                    ],
                    tabelleJoin: [
                        "applicant",
                        "registry"
                    ],
                    proprietà: "fiscalCode"
                },
                ordinamento: {},
                condizioniJoin: [
                    {
                        joinNomeColonna: "freeInstance",
                        tipoJoin: "INNER",
                        gruppoFiltriDiRicerca: [
                            {
                                clausolaSearchFilter: "AND",
                                filtriDiRicerca: [
                                    {
                                        proprieta: "isActive",
                                        operatore: "=",
                                        valore: true
                                    },
                                    {
                                        proprieta: "id",
                                        operatore: "=",
                                        valore: search?.id
                                    }
                                ]
                            }
                        ],
                        condizioniJoin: [
                            {
                                joinNomeColonna: "applicantFreeInstances",
                                tipoJoin: "INNER",
                                gruppoFiltriDiRicerca: [
                                    {
                                        clausolaSearchFilter: "AND",
                                        filtriDiRicerca: [
                                            {
                                                proprieta: "isActive",
                                                operatore: "=",
                                                valore: true
                                            }
                                        ]
                                    }
                                ],
                                condizioniJoin: []
                            }
                        ]
                    }
                ]
            };


            if (!!sortBy?.key && !!sortBy?.sort) {
                searchQuery['ordinamento'] = {};
                if (sortBy.sort == SORT.ASC) {
                    searchQuery['ordinamento']['ordinamentoAttributiAscendente'] = sortBy.key;
                }
                if (sortBy.sort == SORT.DESC) {
                    searchQuery['ordinamento']['ordinamentoAttributiDiscendente'] = sortBy.key;
                }
            }


            if (search?.creationDate?.length > 0) {
                searchQuery.gruppoFiltriDiRicerca.at(0).filtriDiRicerca.push({
                    proprieta: "creationDate",
                    operatore: "=",
                    valore: search?.creationDate
                });
            }

            if (search?.arrivalPoint?.length > 0) {
                searchQuery.gruppoFiltriDiRicerca.at(0).filtriDiRicerca.push({
                    proprieta: "arrivalPoint",
                    operatore: "LIKE",
                    valore: search?.arrivalPoint
                });
            }

            if (search?.departurePoint?.length > 0) {
                searchQuery.gruppoFiltriDiRicerca.at(0).filtriDiRicerca.push({
                    proprieta: "departurePoint",
                    operatore: "LIKE",
                    valore: search?.departurePoint
                });
            }

            if (search?.ticketType?.length > 0) {
                const condizioneJoinTicketType = {
                    joinNomeColonna: "ticketType",
                    tipoJoin: "LEFT",
                    gruppoFiltriDiRicerca: [
                        {
                            clausolaSearchFilter: "AND",
                            filtriDiRicerca: [
                                {
                                    proprieta: "isActive",
                                    operatore: "=",
                                    valore: true
                                },
                                {
                                    proprieta: "alias",
                                    operatore: "=",
                                    valore: search?.ticketType
                                }

                            ]
                        }
                    ]
                };
                searchQuery.condizioniJoin.push(condizioneJoinTicketType);
            }

            if (search?.validityStartDate?.length > 0) {
                searchQuery.gruppoFiltriDiRicerca.at(0).filtriDiRicerca.push({
                    proprieta: "validityStartDate",
                    operatore: ">=",
                    valore: search?.validityStartDate
                })
            }

            if (search?.validityEndDate?.length > 0) {
                searchQuery.gruppoFiltriDiRicerca.at(0).filtriDiRicerca.push({
                    proprieta: "validityEndDate",
                    operatore: "<=",
                    valore: search?.validityEndDate
                })
            }
            const condizioneJoinCompanion: CondizioneJoinDTO = {
                joinNomeColonna: "companion",
                tipoJoin: "LEFT",
                gruppoFiltriDiRicerca: [
                    {
                        clausolaSearchFilter: "OR",
                        filtriDiRicerca: [
                            {
                                proprieta: "isActive",
                                operatore: "=",
                                valore: true
                            },
                            {
                                proprieta: "isActive",
                                operatore: "IsNull"
                            }
                        ]
                    },
                    // {
                    //     clausolaSearchFilter: "OR",
                    //     filtriDiRicerca: [
                    //         {
                    //             proprieta: "isEnabled",
                    //             operatore: "=",
                    //             valore: true
                    //         },
                    //         {
                    //             proprieta: "isEnabled",
                    //             operatore: "IsNull"
                    //         }
                    //     ]
                    // }
                ],
                condizioniJoin: [
                    {
                        joinNomeColonna: "registry",
                        tipoJoin: "LEFT",
                        gruppoFiltriDiRicerca: [
                            {
                                clausolaSearchFilter: "OR",
                                filtriDiRicerca: [
                                    {
                                        proprieta: "isActive",
                                        operatore: "=",
                                        valore: true
                                    },
                                    {
                                        proprieta: "isActive",
                                        operatore: "IsNull"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            };

            if (search?.lastNameCompanion?.length > 0 || search?.fiscalCodeCompanion?.length > 0) {
                if (search?.lastNameCompanion?.length > 0) {
                    condizioneJoinCompanion.condizioniJoin.at(0).gruppoFiltriDiRicerca.push({
                        clausolaSearchFilter: "OR",
                        filtriDiRicerca: [
                            {
                                proprieta: "lastName",
                                operatore: "=",
                                valore: search?.lastNameCompanion
                            },
                            {
                                proprieta: "lastName",
                                operatore: "IsNull"
                            }
                        ]
                    });
                }
                if (search?.fiscalCodeCompanion?.length > 0) {
                    condizioneJoinCompanion.condizioniJoin.at(0).gruppoFiltriDiRicerca.push({
                        clausolaSearchFilter: "OR",
                        filtriDiRicerca: [
                            {
                                proprieta: "fiscalCode",
                                operatore: "=",
                                valore: search?.fiscalCodeCompanion
                            },
                            {
                                proprieta: "fiscalCode",
                                operatore: "IsNull"
                            }
                        ]
                    });
                }
            }
            searchQuery.condizioniJoin.push(condizioneJoinCompanion);
            this.spinner.show();
            const dto = await this.ticketService.advancedSearch(searchQuery);
            return new TicketsTablePaginationModel(dto);
        } catch (error) {
            console.error(error);
            this.errorFacade.handle(error);
        } finally {
            this.spinner.hide();
        }
        return null;
    }
    
    /**
     * Scarica il file con la lista delle grauità ottenute dalla ricerca avanzata
     *
     * @param { 'CSV' | 'PDF' } fileType
     * @param { string[] } columns
     * @param { any } search
     * @param { number } pageSize
     * @param { <{key: string, sort: SORT}> } sortBy
     * @return {Promise<any>}
     */
    async searchDownload(fileType: 'CSV' | 'PDF', columns: string[], search: {
        creationDate?: string,
        arrivalPoint?: string,
        departurePoint?: string,
        fiscalCodeCompanion?: string,
        id?: number,
        lastNameCompanion?: string,
        ticketType?: string,
        validityEndDate?: string,
        validityStartDate?: string,
    }, pageSize: number, sortBy: { key?: string, sort?: SORT }): Promise<any> {
        try {
            const searchQuery: SearchQueryDTO = {
                clausola: "AND",
                distinct: false,
                numeroPagina: 0,
                numeroElementiPagina: pageSize,
                gruppoFiltriDiRicerca: [
                    {
                        clausolaSearchFilter: "AND",
                        filtriDiRicerca: [
                            {
                                proprieta: "isActive",
                                operatore: "=",
                                valore: true
                            }
                        ]
                    }
                ],
                injectJoinToken: {
                    posizioneInject: [
                        "freeInstance",
                        "applicantFreeInstances"
                    ],
                    tabelleJoin: [
                        "applicant",
                        "registry"
                    ],
                    proprietà: "fiscalCode"
                },
                ordinamento: {},
                condizioniJoin: [
                    {
                        joinNomeColonna: "freeInstance",
                        tipoJoin: "INNER",
                        gruppoFiltriDiRicerca: [
                            {
                                clausolaSearchFilter: "AND",
                                filtriDiRicerca: [
                                    {
                                        proprieta: "isActive",
                                        operatore: "=",
                                        valore: true
                                    },
                                    {
                                        proprieta: "id",
                                        operatore: "=",
                                        valore: search?.id
                                    }
                                ]
                            }
                        ],
                        condizioniJoin: [
                            {
                                joinNomeColonna: "applicantFreeInstances",
                                tipoJoin: "INNER",
                                gruppoFiltriDiRicerca: [
                                    {
                                        clausolaSearchFilter: "AND",
                                        filtriDiRicerca: [
                                            {
                                                proprieta: "isActive",
                                                operatore: "=",
                                                valore: true
                                            }
                                        ]
                                    }
                                ],
                                condizioniJoin: []
                            }
                        ]
                    }
                ]
            };

            if (!!sortBy?.key && !!sortBy?.sort) {
                searchQuery['ordinamento'] = {};
                if (sortBy.sort == SORT.ASC) {
                    searchQuery['ordinamento']['ordinamentoAttributiAscendente'] = sortBy.key;
                }
                if (sortBy.sort == SORT.DESC) {
                    searchQuery['ordinamento']['ordinamentoAttributiDiscendente'] = sortBy.key;
                }
            }

            if (search?.creationDate?.length > 0) {
                searchQuery.gruppoFiltriDiRicerca.at(0).filtriDiRicerca.push({
                    proprieta: "creationDate",
                    operatore: "=",
                    valore: search?.creationDate
                });
            }

            if (search?.arrivalPoint?.length > 0) {
                searchQuery.gruppoFiltriDiRicerca.at(0).filtriDiRicerca.push({
                    proprieta: "arrivalPoint",
                    operatore: "LIKE",
                    valore: search?.arrivalPoint
                });
            }

            if (search?.departurePoint?.length > 0) {
                searchQuery.gruppoFiltriDiRicerca.at(0).filtriDiRicerca.push({
                    proprieta: "departurePoint",
                    operatore: "LIKE",
                    valore: search?.departurePoint
                });
            }

            if (search?.ticketType?.length > 0) {
                const condizioneJoinTicketType = {
                    joinNomeColonna: "ticketType",
                    tipoJoin: "LEFT",
                    gruppoFiltriDiRicerca: [
                        {
                            clausolaSearchFilter: "AND",
                            filtriDiRicerca: [
                                {
                                    proprieta: "isActive",
                                    operatore: "=",
                                    valore: true
                                },
                                {
                                    proprieta: "alias",
                                    operatore: "=",
                                    valore: search?.ticketType
                                }

                            ]
                        }
                    ]
                };
                searchQuery.condizioniJoin.push(condizioneJoinTicketType);
            }

            if (search?.validityStartDate?.length > 0) {
                searchQuery.gruppoFiltriDiRicerca.at(0).filtriDiRicerca.push({
                    proprieta: "validityStartDate",
                    operatore: ">=",
                    valore: search?.validityStartDate
                })
            }

            if (search?.validityEndDate?.length > 0) {
                searchQuery.gruppoFiltriDiRicerca.at(0).filtriDiRicerca.push({
                    proprieta: "validityEndDate",
                    operatore: "<=",
                    valore: search?.validityEndDate
                })
            }

            const condizioneJoinCompanion: CondizioneJoinDTO = {
                joinNomeColonna: "companion",
                tipoJoin: "LEFT",
                gruppoFiltriDiRicerca: [
                    {
                        clausolaSearchFilter: "OR",
                        filtriDiRicerca: [
                            {
                                proprieta: "isActive",
                                operatore: "=",
                                valore: true
                            },
                            {
                                proprieta: "isActive",
                                operatore: "IsNull"
                            }
                        ]
                    },
                    // {
                    //     clausolaSearchFilter: "OR",
                    //     filtriDiRicerca: [
                    //         {
                    //             proprieta: "isEnabled",
                    //             operatore: "=",
                    //             valore: true
                    //         },
                    //         {
                    //             proprieta: "isEnabled",
                    //             operatore: "IsNull"
                    //         }
                    //     ]
                    // }
                ],
                condizioniJoin: [
                    {
                        joinNomeColonna: "registry",
                        tipoJoin: "LEFT",
                        gruppoFiltriDiRicerca: [
                            {
                                clausolaSearchFilter: "OR",
                                filtriDiRicerca: [
                                    {
                                        proprieta: "isActive",
                                        operatore: "=",
                                        valore: true
                                    },
                                    {
                                        proprieta: "isActive",
                                        operatore: "IsNull"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            };

            if (search?.lastNameCompanion?.length > 0 || search?.fiscalCodeCompanion?.length > 0) {
                if (search?.lastNameCompanion?.length > 0) {
                    condizioneJoinCompanion.condizioniJoin.at(0).gruppoFiltriDiRicerca.push({
                        clausolaSearchFilter: "OR",
                        filtriDiRicerca: [
                            {
                                proprieta: "lastName",
                                operatore: "=",
                                valore: search?.lastNameCompanion
                            },
                            {
                                proprieta: "lastName",
                                operatore: "IsNull"
                            }
                        ]
                    });
                }
                if (search?.fiscalCodeCompanion?.length > 0) {
                    condizioneJoinCompanion.condizioniJoin.at(0).gruppoFiltriDiRicerca.push({
                        clausolaSearchFilter: "OR",
                        filtriDiRicerca: [
                            {
                                proprieta: "fiscalCode",
                                operatore: "=",
                                valore: search?.fiscalCodeCompanion
                            },
                            {
                                proprieta: "fiscalCode",
                                operatore: "IsNull"
                            }
                        ]
                    });
                }
            }
            searchQuery.condizioniJoin.push(condizioneJoinCompanion);

            this.spinner.show();
            const file = await this.ticketService.advancedSearchDowload(search.id, fileType, searchQuery, columns);
            if(fileType == 'PDF') {
                const blob = new Blob([file], { type: 'application/pdf' });
                FileSaver.saveAs(blob, 'titoli_viaggio.pdf');
            } else {                
                const blob = new Blob([file], { type: 'text/csv;charset=utf-8' });
                FileSaver.saveAs(blob, 'titoli_viaggio.csv');
            }
            return true;
        } catch (error) {
            console.error(error);
            this.errorFacade.handle(error);
        } finally {
            this.spinner.hide();
        }
        return null;
    }

}