import { DocumentDTO } from "../dtos/document.dto";
import { ApplicantInterface } from "../interfaces";
import { DocumentInterface } from "../interfaces/document.interface"
import { ApplicantModel } from "./applicant.model";

export class DocumentModel implements DocumentInterface {
    protected attivo: boolean;
    protected dataAggiornamento: string;
    protected dataAttivazione: string;
    protected dataCreazione: string;
    protected dataDisattivazione: string;
    protected dataUltimoAggiornamentoDocumento: string;
    protected dimensioneDocumento: number;
    protected estensioneDocumento: string;
    protected id: number;
    protected md5Documento: string;
    protected nomeDocumento: string;
    protected otp: Array<any>;
    protected pathDocumento: string;
    protected richiedente: ApplicantInterface;
    protected tipologiaDocumento: string;
    protected ultimoAggiornamentoDocumento: number;

    constructor(dto: DocumentDTO) {
        this.attivo = dto?.attivo;
        this.dataAggiornamento = dto?.dataAggiornamento;
        this.dataAttivazione = dto?.dataAttivazione;
        this.dataCreazione = dto?.dataCreazione;
        this.dataDisattivazione = dto?.dataDisattivazione;
        this.dataUltimoAggiornamentoDocumento = dto?.dataUltimoAggiornamentoDocumento;
        this.dimensioneDocumento = dto?.dimensioneDocumento;
        this.estensioneDocumento = dto?.estensioneDocumento;
        this.id = dto?.id;
        this.md5Documento = dto?.md5Documento;
        this.nomeDocumento = dto?.nomeDocumento;
        this.otp = dto?.otp;
        this.pathDocumento = dto?.pathDocumento;
        this.richiedente = !!dto?.richiedente ? new ApplicantModel(dto.richiedente) : undefined;
        this.tipologiaDocumento = dto?.tipologiaDocumento;
        this.ultimoAggiornamentoDocumento = dto?.ultimoAggiornamentoDocumento;

    }
      getEstensioneDocumento(): string {
      return this.estensioneDocumento;
      }
      getDimensioneFile(): number {
        return this.dimensioneDocumento;
      }

    getDataAttivazione():Date {
        return new Date(this.dataAttivazione);
    }

    getId(): number {
        return this.id;
    }

    getCodiceFiscaleRichiedente(): string {
        return this.richiedente?.getRichiedente()?.getAnagrafica()?.getCodiceFiscale();
    }
    getNomeDocumento(): string {
        return this.nomeDocumento;
    }

    getTipologiaDocumento(): string {
        return this.tipologiaDocumento;
    }
}
