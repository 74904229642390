import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthFacade } from '../facades';
import { LanguageService } from 'core-model';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authFacade: AuthFacade, private languageService: LanguageService) { }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    let acceptLanguage = '';
    switch (this.languageService.getCurrentLanguage()) {
      case 'en': acceptLanguage = 'en';
        break
      default: acceptLanguage = 'it';
        break;
    }
if (req.url.includes('/come-accedere') || req.url.includes('/contatti') || req.url.includes('/news') || req.url.includes('/home')) {
      req = req.clone({
        setHeaders: {
          'Accept': 'application/json',
          'Accept-Language': acceptLanguage,
          'Content-Type': 'application/json'
        }
      });
    } else if (req.url.includes('genera-qrcode')) {
      req = req.clone({
        withCredentials: true,
        setHeaders: {
          'Accept': 'image/png',
          'Accept-Language': acceptLanguage,
          'Content-Type': 'image/png;charset=utf-8',
          'Media-Type': 'image/png'
        }
      });
    } else if(req.url.includes('ottieni-titoli-viaggio') || req.url.includes('genera-documento') || req.url.includes('genera-delega-riscatto')) {
      req = req.clone({
        withCredentials: true,
        setHeaders: {
          'Accept': 'application/octet-stream',
          'Accept-Language': acceptLanguage,
          'Content-Type': 'application/json'
        }
      });
    } else {
      req = req.clone({
        withCredentials: true,
        setHeaders: {
          'Accept': 'application/json',
          'Accept-Language': acceptLanguage,
          'Content-Type': 'application/json'
        }
      });
    }

    return next.handle(req).pipe(catchError((error: any) => {
      if(!req.url.includes('superadmin/accesso')){
        this.authFacade.handleError(error);
      }
      return throwError(() => error);
    }));
  }
}
