<div class="row">
  <div class="col-10">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" [lang]="locale">
      <form [formGroup]="getCurrentFormByLocale()">
        <label for="titolo" class="h6 mt-2"
          [ngClass]="{ 'text-danger': getCurrentFormByLocale().get('titolo').invalid && (getCurrentFormByLocale().get('titolo').dirty || getCurrentFormByLocale().get('titolo').touched)}">
          {{'FORM.TITOLO' | translate}} *</label>
        <input id="titolo" type="text" class="form-control" formControlName="titolo"
          [ngClass]="{ 'is-invalid': getCurrentFormByLocale().get('titolo').invalid && (getCurrentFormByLocale().get('titolo').dirty || getCurrentFormByLocale().get('titolo').touched)}"
          placeholder="Titolo">
      </form>

      <form [formGroup]="getCurrentFormByLocale()">
        <label for="descrizione" class="h6 mt-3"
          [ngClass]="{ 'text-danger': getCurrentFormByLocale().get('descrizione').invalid && (getCurrentFormByLocale().get('descrizione').dirty || getCurrentFormByLocale().get('descrizione').touched)}">
          {{'FORM.SOMMARIO' | translate}} *</label>
        <input id="descrizione" type="text" class="form-control"
          [ngClass]="{ 'is-invalid': getCurrentFormByLocale().get('descrizione').invalid && (getCurrentFormByLocale().get('descrizione').dirty || getCurrentFormByLocale().get('descrizione').touched)}"
          formControlName="descrizione" [maxlength]="255" placeholder="Descrizione">
      </form>

      <div class="row">
        <div class="col-8 mt-3">
          <label for="copertina" class="h6 ">{{'FORM.COPERTINA' | translate}} *</label>
          <app-upload-drag-drop dragDropId="copertina" [form]="form" controlName='copertina'
            titoloUpload="FORM.UPLOAD_IMMAGINI_COPERTINA" [copertina]="true" />
        </div>
      </div>

      <hr />

      <form [formGroup]="getCurrentFormByLocale()">
        <label class="h6 mt-3"
          [ngClass]="{ 'text-danger': getCurrentFormByLocale().get('html').invalid && (getCurrentFormByLocale().get('html').dirty || getCurrentFormByLocale().get('html').touched)}">{{'FORM.TESTO'
          | translate}} *</label>
        <quill-editor id="html" class="w-100 h-100 form-control" placeholder="Inserisci un testo" formControlName="html"
          [styles]="{height: '300px'}"
          [ngClass]="{ 'border border-danger': getCurrentFormByLocale().get('html').invalid && (getCurrentFormByLocale().get('html').dirty || getCurrentFormByLocale().get('html').touched)}">
        </quill-editor>
      </form>

      <label for="immagini" class="h6 mt-3">{{'FORM.CAROSSELLO_IMMAGINI' | translate}}</label>
      <app-upload-drag-drop dragDropId="immagini" [form]="form" controlName='immagini' [className]="'w-100'"
        titoloUpload="FORM.UPLOAD_IMMAGINI" [copertina]="false" />

      <hr />

      <div class="row">
        <div class="col-6 mt-3">
          <label for="data" class="h6">{{'FORM.DATA_PUBBLICAZIONE' | translate}} *</label>
          <input id="data" type="date" class="form-group" formControlName="dataInizioVisualizzazione"
            placeholder="FORM.DATA_PUBBLICAZIONE" appDateToString [date]="form.value.dataInizioVisualizzazione">
        </div>
        <div class="col-6 mt-3">
          <label for="data" class="h6">{{'FORM.DATA_SCADENZA' | translate}} *</label>
          <input id="data" type="date" class="form-group" formControlName="dataDiScadenza"
            placeholder="FORM.DATA_SCADENZA" appDateToString [date]="form.value.dataDiScadenza">
        </div>
      </div>

      <label for="tags" class="h6">{{'FORM.TAGS' | translate}}</label>
      <app-tags #tags id="tags" [form]="form.controls['tags']"></app-tags>

      <button type="submit" [disabled]="form.invalid" class="btn btn-primary">{{'FORM.SALVA' | translate}}</button>
      <ng-container *ngIf="!checkTranslateForm()">
        <div class="d-flex mt-2">
          <svg class="icon icon-sm align-middle icon-danger me-2" role="img" aria-hidden="true">
            <use href="assets/bootstrap-italia/dist/svg/sprites.svg#it-warning-circle"></use>
          </svg>
          <span class="text-danger" translate
            [translateParams]="{ locale: getInvalidFormLocale() }">NEWS.MODULO_INCOMPLETO</span>
        </div>
      </ng-container>
    </form>
  </div>
  <div class="col-2">
    <app-dropdown-language (langEvent)="setLanguage($event)"></app-dropdown-language>
  </div>
</div>

<app-error type="modal"></app-error>
